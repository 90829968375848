import React from "react";
import { CheckboxButton } from "oolib";

const TableCheck = (props) => {
   return (
      <div
         onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
         }}
      >
         <CheckboxButton {...props} />
      </div>
   );
}

export default TableCheck;