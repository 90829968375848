import React, { useState } from "react";

import { ModalConfirm } from "oolib";
export default function ConfirmAction({
  children,
  enabled = true,
  title = 'Are you sure you want to do this action',
  subtitle,
  onConfirmText
}) {

  const [modal, setModal] = useState(false);

  let callback;

  const handleParentClick = (e) => {
    setModal(true);
  };

  const handleConfirm = (e) => {
    callback();
    setModal(false);
  };

  const getChildren = () => {
    if (!enabled) {
      return children;
    } else {
      return React.Children.map(children, (child) => {
        // Check if the child is a React component
        if (React.isValidElement(child)) {
          // Access props of the child component
          callback = child.props.onClick;
          return React.cloneElement(child, { onClick: handleParentClick });
        }

        // Return non-component children as they are
        return child;
      });
    }
  };

  return (
    <>
      {modal ? (
        <ModalConfirm
          title={title}
          subTitle={subtitle}
          onClose={() => setModal(false)}
          onConfirmText={onConfirmText}
          onConfirm={handleConfirm}
        />
      ) : null}

      {getChildren()}
    </>
  );
}
