import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { useBannerContext } from 'oolib'



export const useCreateComment = () => {
  const queryClient = useQueryClient()
  const {SET_ALERT_BANNER}  = useBannerContext()
  return useMutation(
    ({data, contentType, contentId}) =>
    axios.post(
      `/api/comments/createComment/${contentType}/${contentId}`, 
      data, 
      { headers: { 'Content-Type': 'application/json' }}
    ),
    {
      onSuccess: (res, { contentType, contentId}) => {
        queryClient.invalidateQueries(['Contributions', 'view', contentType, contentId])
        SET_ALERT_BANNER('Comment Posted Successfully', 'green', 5000 )
      },
      onError : () => {
        SET_ALERT_BANNER( "Post Comment Error", "red", 5000 )
      }
    }
  )
}

export const useDeleteComment = () => {
  const queryClient = useQueryClient()
  const {SET_ALERT_BANNER}  = useBannerContext();
  return useMutation(
    ({contentType, contentId, commentId}) => axios.delete(`/api/comments/deleteComment/${contentType}/${contentId}/${commentId}`), 
    {
      onSuccess: (res, { contentType, contentId}) => {
        queryClient.invalidateQueries(['Contributions', 'view', contentType, contentId])
        SET_ALERT_BANNER('Comment Deleted Successfully', 'green', 5000 )
      },
      onError : () => {
        SET_ALERT_BANNER( "Delete Comment Error", "red", 5000 )
      }
    }
  )
}
export const useUpdateComment = () => {
  const queryClient = useQueryClient()
  const {SET_ALERT_BANNER}  = useBannerContext();
  return useMutation(
    ({contentType, contentId, commentId,text}) => {
      const config = { headers: { "Content-Type": "application/json" } };
     return  axios.patch(
        `/api/comments/updateComment/${contentType}/${contentId}/${commentId}`,
        { text },
        config
      );
    },
    {
      
      onSuccess: (res, {contentType,contentId,onSuccess}) => {        
       queryClient.invalidateQueries(['Contributions', 'view', contentType, contentId])
        SET_ALERT_BANNER('Comment Updated Successfully', 'green', 5000 )      
        if(onSuccess){         
          onSuccess(res)
         }      
      },
      onError : () => {
        SET_ALERT_BANNER( "Update Comment Error", "red", 5000 )
      }
    }
  );
};

