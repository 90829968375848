import React, { Fragment, useEffect, useMemo } from "react";
import { PageScrollIndicator } from "oolib";
import { useFormValidationContext } from "../../../../contexts/formValidationContext";
import { useHandleContentChange } from "../../../../utils/contributionFlowUtils";
import { __GetContentTypeConfigNew } from "../../../../utils/getters/gettersV2";
import { formValidationCheckV2 } from "../../../../utils/validation/formValidation";
import { getThisLayout } from "./configs";
import { useFireOneAutosaveAtATime } from "./utils/useFireOneAutosaveAtATime";
import ReviewerNotes from "../ReviewerNotes";

export default function EditLayoutRenderer({
  tpl,
  autosave,
  isModerateMode,
  contentState: [content, setContent],
  isAutoSaving: _isAutoSaving,
  isAutoSaved,
  publishBtnTextAndOnClickAndDisabled,

  H_PrimaryCTA,
  H_SaveStatus,
  H_CloseButton
}) {
  const { setFormValidation } = useFormValidationContext();


  const { Layout, blocksToValidate } = useMemo(() => getThisLayout({ tpl, content }), [tpl?.layout]);

  const templateIndex = __GetContentTypeConfigNew(tpl.kp_content_type)?.general
    ?.templateIndex;

  useEffect(() => {
    const { failedBlocks } = formValidationCheckV2({
      blocks: blocksToValidate,
      content,
    });
    setFormValidation((p) => ({ ...p, failedBlocks }));
  }, []);

  const { handleContentChange } = useHandleContentChange();

  const { setPendingAutosaveContent, isAutoSaving } = useFireOneAutosaveAtATime(
    {
      autosave,
      isAutoSaved,
      _isAutoSaving,
      isModerateMode,
    }
  );

  const handleOnChange = (block, value, options = {}) =>
    handleContentChange({
      block,
      value,
      setContent,
      setFormValidation,
      blocksToValidate,
      options,
      mutate: ({ updatedContent }) => {
        if (isAutoSaving.current) {
          setPendingAutosaveContent(updatedContent);
        } else {
          // console.log('autosave fired', {isAutoSaving: isAutoSaving.current})
          autosave({
            Contribution: updatedContent,
            ACTION: isModerateMode ? "MODERATE" : undefined,
          });
        }
      },
    });

  return (
    <div className="OKE-Tpl">
      <Fragment>
        <PageScrollIndicator />
        {content.reviewerNotes?<ReviewerNotes notes={content.reviewerNotes} />:null} 
        <Layout
          {...{
            tpl,
            content,
            handleOnChange,
            showIndexbar: templateIndex?.enableOnContributeTpl,
            indexType: templateIndex?.type,
            publishBtnTextAndOnClickAndDisabled,

            //header comps
            H_PrimaryCTA,
            H_SaveStatus,
            H_CloseButton
          }}
        />
      </Fragment>
    </div>
  );
}
