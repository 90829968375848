export const prepareEditConfig = (tpl) => {
    const { inputs: { title, subtitle, coverComponent } } = tpl.kp_templates.intro.configs || {};
    
    if (title && title.props) {
        title.props.invert = false;
    }
    if (subtitle && subtitle.props) {
        subtitle.props.invert = false;
        subtitle.props.align = "left"
    }

    const formBuilders = tpl.kp_templates.body.subSpaces.filter((sub) => sub.builder === "FormBuilder")

    const formBuilderComps = formBuilders.map((builder) => builder.configs.map((comp) => comp))

    return [ coverComponent, title, subtitle , ...formBuilderComps.flat() ].filter(Boolean);
};