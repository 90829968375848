import { useEffect, useState, useMemo, useRef } from "react"
import { sortData } from "oolib"


export const useSort_FRONTEND = ({
	tableConfig,
	dataProp,
	isBackendSort
  }) => {
	const firstTimeActiveSortWasSet = useRef(false);
	
	const [activeSorts, _setActiveSorts] = useState(
	  !isBackendSort && setInitActiveSorts_FRONTEND({tableConfig})
	)
  
	const setActiveSorts = (newSorts) => {
	  _setActiveSorts(newSorts);
	  if (!firstTimeActiveSortWasSet.current) {
		firstTimeActiveSortWasSet.current = true;
	  }
	}
  
	const data = useMemo(() => {
	  if(isBackendSort) return dataProp;
	  
	  // First time/initial load case
	  if (!firstTimeActiveSortWasSet.current) {
		return setInitSortedData_FRONTEND({tableConfig, dataProp});
	  }
  
	  // Handle active sorts after first time
	  const { sortedData } = handleSortTableData(dataProp, activeSorts, tableConfig);
	  return sortedData;
	}, [JSON.stringify(dataProp), JSON.stringify(activeSorts), isBackendSort, tableConfig])
  
	useEffect(() => {
	  if(!isBackendSort){
		setActiveSorts(setInitActiveSorts_FRONTEND({tableConfig}))
	  }
	},[JSON.stringify(dataProp)])
  
	return ({data, activeSorts, setActiveSorts})
  }

const setInitActiveSorts_FRONTEND = ({tableConfig, dataProp}) => {
  let activeSorts = {}
  tableConfig.cellData.map((d) => {
    if (d.sortConfig) {
      activeSorts[d.colId] = d.defaultSort
        ? (d.defaultSortBy || 'a')
        : 'none'
    }
  })
  return activeSorts
}

const setInitSortedData_FRONTEND = ({tableConfig, dataProp}) => {
  //cuz sometimes data hasn't loaded yet. messy stuff. need to clean up
  if(!dataProp) return []
  let sortedData;
  tableConfig.cellData.map((d) => {
    if (d.sortConfig && d.defaultSort === true) {
      let sortBy = d.defaultSortBy || 'a'
      sortedData = sortData({
        data: dataProp,
        sortBy,
        ...d.sortConfig
      })
    }
  })
  return sortedData || dataProp
}


export const handleSortTableData = (
	data,
	activeSorts,
	tableConfig
  ) => {
	// Find which column is being sorted
	const activeSort = Object.entries(activeSorts).find(([_, value]) => value !== 'none');
	if (!activeSort) return { sortedData: data };
  
	const [colId, sortBy] = activeSort;
	const thisColConfig = tableConfig.cellData.find(d => d.colId === colId);
  
	let sortedData = sortData({
	  data,
	  sortBy,
	  ...thisColConfig.sortConfig
	})
	return { sortedData }
  }