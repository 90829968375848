import React from "react";
import { useState } from "react";
import { FeedbackStyled, ThumbsDownStyled, ThumbsUpStyled } from "./styled";
import { ButtonPrimary, TextInput, UI_BODY_SM, icons } from "oolib";
import { useSendFeedback } from "../../../hooks";

const { ThumbsUp, ThumbsDown } = icons;

export const Feedback = ({ reaction = "", comments = "", messageId }) => {
  const [userReaction, setUserReaction] = useState(reaction);
  const [userComments, setUserComments] = useState(comments);

  const { mutate: feedback, status } = useSendFeedback();

  return (
    <FeedbackStyled
      style={{
        display: "flex",
        gap: "1rem",
        width: "100%",
        alignItems: "center",
      }}
    >
      <ThumbsUpStyled
        active={userReaction === "positive"}
        onClick={() => {
          setUserReaction("positive");
          feedback({ messageId, reaction: "positive" });
        }}
      >
        <ThumbsUp
          color={userReaction === "positive" ? "white" : "#808080"}
          size={20}
          setShowInput
        />
      </ThumbsUpStyled>

      <ThumbsDownStyled
        active={userReaction === "negative"}
        onClick={() => {
          setUserReaction("negative");
          feedback({ messageId, reaction: "negative" });
        }}
      >
        <ThumbsDown
          color={userReaction === "negative" ? "white" : "#808080"}
          size={20}
        />
      </ThumbsDownStyled>

      {userReaction === "negative" && !comments ? (
        <div style={{ display: "flex", gap: ".5rem", width: "100%" }}>
          <div style={{ flex: "1" }}>
            <TextInput
              style={{ width: "100%" }}
              value={userComments}
              onChange={(id, value) => setUserComments(value)}
              S
              placeholder="Tell us what went wrong..."
            />
          </div>

          <ButtonPrimary
            disabled={status === "loading"}
            S
            onClick={() => feedback({ messageId, comments: userComments })}
          >
            {status === "loading" ? status : "Submit"}
          </ButtonPrimary>
        </div>
      ) : null}

      {userReaction === "positive" || comments ? (
        <UI_BODY_SM>Thanks for your feedback!</UI_BODY_SM>
      ) : null}
    </FeedbackStyled>
  );
};
