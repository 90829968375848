import { ButtonGhost, CardContent, mediaQuery } from "oolib";
import React, { useRef } from "react";

import styled from "styled-components";

const StyledContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
  scroll-behavior: smooth;

  overflow: hidden;

  ${mediaQuery("sm")} {
    grid-auto-columns: calc(100% / 2);
  }

  ${mediaQuery("md")} {
    grid-auto-columns: calc(100% / 3);
  }
`;

/* display: "grid",
          gap: "1rem",
          gridAutoColumns:"calc((100%)/3)",
          gridAutoFlow:"column",
          overflow: "hidden", */


export default function CardsSlider({ data=[] }) {
  const config = {
    cardLabel: "$thisContentType",
    title: ["main.kp_title", "main.title"],
    metaBlock: [
      {
        key: "name",
        valuePath: "meta.kp_contributed_by.name",
      },
      {
        key: "date",
        valuePath: "kp_date_published",
      },
    ],
  };

  const ScrollContainerRef = useRef();
  const firstCardRef = useRef();

  const handleLeftClick = () => {
    console.log({ width: firstCardRef.current?.offsetWidth });

    ScrollContainerRef.current.scrollLeft += -(
      firstCardRef.current.offsetWidth + 10
    );
  };
  const handleRightClick = () => {
    console.log({ width: firstCardRef.current?.offsetWidth });

    ScrollContainerRef.current.scrollLeft +=
      firstCardRef.current.offsetWidth + 10;
  };

  return (
    <div>
      <div style={{ display: "flex", gap: "1rem", justifyContent: "end",marginBottom:"1rem" }}>
        <ButtonGhost icon="ArrowLeft" onClick={handleLeftClick} />
        <ButtonGhost icon="ArrowRight" onClick={handleRightClick} />
      </div>
      <StyledContainer ref={ScrollContainerRef} style={{}}>
        {data.map((data, index) => (
          <div ref={index === 0 ? firstCardRef : null}>
            <CardContent config={config} data={data} to={`/published-page/${data.meta.kp_content_type}?id=${data._id}`} />
          </div>
        ))}
      </StyledContainer>
    </div>
  );
}
