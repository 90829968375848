import { toggleTagTypesLookup } from "../../../../../components/inputs/TextEditor/LexicalTextEditor/plugins/AnnoPlugin/AnnoLightbox/configs/toggleTagTypesLookup";
import { extractAllBlocksFromTpl } from "../../../../../utils/templating/extractAllBlocksFromTpl";

export const injectFragmentsDictBlocksAsPerToggleTagTypesConfig = (tplProp) => {
  const allBlocks = extractAllBlocksFromTpl({ tpl: tplProp });
  const lexBlocks = allBlocks.filter((bl) => bl.comp === "LexicalTextEditor");
  let toggleTagTypes = lexBlocks
    .map((bl) => bl.props.annotation?.toggleTagTypesConfig)
    .filter(Boolean)
    .reduce((a, b) => [...a, ...b], [])
    .map((d) => d.tagType);
  toggleTagTypes = [...new Set(toggleTagTypes)]; //this removes duplicates
  toggleTagTypes = toggleTagTypes.map((tagType) =>
    toggleTagTypesLookup.find((d) => d.tagType === tagType)
  );

  const tpl =
    toggleTagTypes.length === 0
      ? tplProp
      : {
          ...tplProp,
          kp_templates: {
            ...tplProp.kp_templates,
            synth: {
              ...tplProp.kp_templates.synth,
              subSpaces: [
                ...tplProp.kp_templates.synth.subSpaces,
                ...toggleTagTypes.map((d) => ({
                  display: d.display,
                  value: `toggleTagType_${d.tagType}`,
                  builder: "FormBuilder",
                  configs: [
                    {
                      comp: "FragmentsDict",
                      valuePath: `annotations.tags.${d.tagType}`,
                      props: {
                        id: `annotations.tags.${d.tagType}`,
                        tagType: d.tagType
                      },
                    },
                  ],
                })),
              ],
            },
          },
        };

  return tpl;
};
