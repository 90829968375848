import { isArray } from "lodash";
import handleBlockDisplayConditions from "../../handleBlockDisplayConditions";
import { dateRangePickerHasValue, isAlwaysConsideredPopulatedAndValid, richTextHasValue, simpleTableHasValue, tagsSelectCompHasValue, valueArrayIsNotEmpty, valueNotFalsyOrEmptyAryObj, valueIsNotUndefined, lexicalTextEditorHasValue, BACKWARDS_COMPAT_lexicalTextEditorHasValue, inputIsTruthy, displayConditionsConfigBlockHasValue, groupQuestionsInputSingleHasValue } from "../validatorFns";
import { getVal } from 'oolib';
 
export const checkIfIsValid = (comp) => {
  switch (comp) {

    
    case "DropdownMulti": 
    case "ImageInput":
    case "PDFInput":
    case "CheckboxList":
    return valueArrayIsNotEmpty;

    case "GroupQuestionsInputSingle":
      return groupQuestionsInputSingleHasValue;

    case 'SimpleTable':
		return simpleTableHasValue

    case 'TagsInputSingle':
    case 'TagsInputMulti':
    return tagsSelectCompHasValue

    //since these inputs can return falsy values such as 0 / false which are considered valid in this context 
    case 'RadioList':
    return valueIsNotUndefined

    case 'SwitchSingle':
    return isAlwaysConsideredPopulatedAndValid

    case 'KPRichInput':
    case 'RichTextEditor':
    return richTextHasValue;

    case 'TitleInput':
    case 'SubtitleInput':
      return inputIsTruthy;

    case 'SummaryInput':
      return BACKWARDS_COMPAT_lexicalTextEditorHasValue;

    case 'LexicalTextEditor':
      return lexicalTextEditorHasValue

    case 'DateRangePicker':
    return dateRangePickerHasValue
  
    //------- PLATFORM BUILDER SPECIFIC -------- //
    case 'DisplayConditionsConfigBlock':
      return displayConditionsConfigBlockHasValue

    default:
      return valueNotFalsyOrEmptyAryObj
  }
}


const runValidationConditionsV2 = (block, Resource, options = {}) => {
  
  if(block.readOnly || block.displayOnly) return ({isInvalid: false})

  /**
   * options.ignoreIsOptional: 
   * useful if sometimes we want to find empty blocks irrespective of the 'isRequired' value being false. 
   * 
   * Was used earlier in some deprecated code. but leaving it be in case its handy in the future
   */
  if(
    !block.isRequired && 
    !options.ignoreIsOptional &&
    ['Repeater', 'RepeaterTabs', 'FragmentsSet'].indexOf(block.comp) === -1 //i dont think this is needed
  ) return ({isInvalid: false})

  if(
    block.displayConditions && 
    !handleBlockDisplayConditions({content: Resource, block, parentContent: options.parentContent}) // means block is not displaying right now
  ) return ({isInvalid: false})

  
    
  let BlockVal = getVal( Resource, block.valuePath)

  //new
  if(['Repeater', 'RepeaterTabs', 'FragmentsSet'].indexOf(block.comp) !== -1){

    if(BlockVal === undefined) return ({isInvalid: block.isRequired ? true : false})
    if(isArray(BlockVal) && BlockVal.length === 0) return ({isInvalid: block.isRequired ? true : false})

    let isInvalid, subBlocksFormValidation;
    switch(block.comp){
      case 'Repeater':
      case 'RepeaterTabs':
        ({isInvalid, subBlocksFormValidation} = validateRepeaterInputs({block, value: BlockVal, parentContent: Resource}))
        return ({isInvalid, subBlocksFormValidation})
      case 'FragmentsSet':
        ({isInvalid, subBlocksFormValidation} = validateRepeaterInputs({block, value: BlockVal.data, parentContent: Resource}))
        return ({isInvalid, subBlocksFormValidation})
      default:
        return;  
    }
  }

  else{
    let validatorFn = checkIfIsValid(block.comp)

    if (validatorFn) {
      // console.log({
      //   comp: block.comp,
      //   isInvalid: !validatorFn(BlockVal)
      // })
      return ({isInvalid: !validatorFn(BlockVal, block.props)})
    }  
  }

}

export const formValidationCheckV2 = ({
  blocks,
  content,
  parentContent,
  ignoreIsOptional,
  depthIdx = 0,
  failedBlocks = [],
  sectionStack = []
}) => {
 
  blocks.map((block, i) => {
    let isSection = !!block.blocks;

    if (isSection === true) {
      /**
       * basically:
       * if section display conditions dont exist OR
       * section display conditions exist, and the section is being rendered currently, THEN 
       * go ahead and check if those blocks are invalid.
       * in short: dont run validation on blocks in a section that is currently hidden.
       */
      if(
        !block.displayConditions || //remember block here = section
        handleBlockDisplayConditions({content, block}) === true
      ){
        formValidationCheckV2({
          blocks: block.blocks,
          content,
          parentContent,
          depthIdx: depthIdx + 1,
          failedBlocks,
          sectionStack: [
            ...sectionStack,
            { sectionId: block.sectionId, sectionTitle: block.sectionTitle },
          ],
        });
      }
      
    } else {
      let {isInvalid, subBlocksFormValidation} = runValidationConditionsV2(block, content, {ignoreIsOptional, parentContent})
      if (isInvalid === true) {
        /** legacy stuff. but it means, that this block is indeed invalid */

        failedBlocks.push({
          sectionStack,
          valuePath: block.valuePath,
          subBlocksFormValidation
        });
        // return;
      }
    }
  });

  return { failedBlocks };
};


const validateRepeaterInputs = ({block, value : valueAry, parentContent}) => {
  
  let subBlocksFormValidation = [];
  valueAry.forEach(value => {
    const { failedBlocks } = formValidationCheckV2({
      blocks: block.props.blocks,
      content: value,
      parentContent
    })
    subBlocksFormValidation.push(
      { failedBlocks, makeVisible: false }
    )
  })
  const isInvalid = subBlocksFormValidation.some(d => d.failedBlocks?.length > 0)
  return ({
    isInvalid,
    subBlocksFormValidation
  })
}

