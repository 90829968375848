//new technique, should apply it to everything below


import {
  fragmentsDictHasValue,
  fragmentsSetHasValue,
  inputIsTruthy,
  isAlwaysConsideredPopulatedAndValid,
  linkEmbedWithInputHasValue,
  repeaterHasValue,
  richTextHasValue,
  simpleTableHasValue,
  valueNotFalsyOrEmptyAryObj,
  tagsSelectCompHasValue,
  valueArrayIsNotEmpty,
  valueIsNotUndefined,
  lexicalTextEditorHasValue,
  BACKWARDS_COMPAT_lexicalTextEditorHasValue,
  linkHasValue
} from "./validatorFns";

/** validation functions */

/** end validation functions */

export const isPopulatedValidators = (comp, props) => {
  switch (comp) {

    case "RadioList":
      return valueIsNotUndefined; //falsy check is not enough cuz, saveValueAsString could return a selected value of 'false'

    case "GroupQuestionsInputSingle":      
    case "DropdownSingle":
    case "ComboboxSingleLanguage":
    case "DropdownSingleLanguage":
    case "TableTags":
      return valueNotFalsyOrEmptyAryObj;

    
    case "DropdownMulti":
    
    case "ProfileImageInput":
    case "ImageInput":
    case "VideoInput":
    case "AudioInput":
    case "PDFInput":
    case "CheckboxList":
      return valueArrayIsNotEmpty;
    
    case "TagsInputSingle":
    case "TagsInputMulti":
      return tagsSelectCompHasValue;

    case "Repeater":
    case "RepeaterTabs":
      return repeaterHasValue;

    case "FragmentsSet":
      return fragmentsSetHasValue;

    case "FragmentsDict":
      return fragmentsDictHasValue;

    case "RichTextAsPlainText":
    case "KPRichInput":
    case "RichTextEditor":
      return richTextHasValue;

    case "SummaryInput":
    case "RichTextAsPlainTextLex": //cuz this comp is backwards compat. i.e it accepts value as either draftjs shape or lexical shape
      return BACKWARDS_COMPAT_lexicalTextEditorHasValue;

    case "LexicalTextEditor":
      return lexicalTextEditorHasValue;

    case "TextInput":
    case "TableTextInput":
    case "JSONInput":
    case "PasswordInput":
    case "EmailInput":
    case "PhoneInput":
    case "URLInput":
    case "NumberInput":
    case "Tags__Intro":
    case "SANS_3":
    case "SANS_2":
    case "SANS_0":
    case "DateDisplay":
    case "CalcTimePeriodDisplay":
    case "ElapsedDays":
    case "StatusTagOutlineStyle":
    case "StatusTag":
    case "IFrameInput":
    case "CommaSeparatedWords":
    case "UserRoleBadge":
    case "TableText":
    case "TitleInput":
    case "SubtitleInput":
      return inputIsTruthy;

    case "TableLink": 
      return linkHasValue;

    case "RightWrong":
    case 'SwitchSingle': 
    case 'CheckboxButton': //else a '-' will show in table comp
    case 'TableCheck': //else a '-' will show in table comp
    case "TableCheckPlusDropdown": //else a '-' will show in table comp
    case "TableArrayCount":
      return isAlwaysConsideredPopulatedAndValid;

    case "LinkEmbedWithInput":
      return linkEmbedWithInputHasValue;

    case "SimpleTable":
      return simpleTableHasValue;

    default:
      return undefined;
  }
};

export const blockIsPopulatedConditions = (block, value) => {
  let validatorFn = isPopulatedValidators(block.comp, block.props);
  if (validatorFn) {
    return validatorFn(value, block.props);
  } else {
    //deprecated stuff...
    return (
      (value &&
        ((block.comp === "ResourceInput" && value.length > 0) ||
          (block.comp === "KPTagsInput" && value.length > 0) ||
          (block.comp === "KPFAQComp" && checkFAQCompIsPopulated(value)) ||
          
          block.comp === "KPLikeContactChannelsBlock" ||
          (block.comp === "KPLikeOrgAndDesignation" &&
            (value.name.internal.length > 0 ||
              value.name.external.length > 0)) ||
          (block.comp === "KPMultiProfilesDisplay" &&
            checkMultiProfileCardIsPopulated(value)) ||
          block.comp === "KPDropdown" ||
          block.comp === "DatePicker" ||
          block.comp === "DateRangePicker" ||
          block.comp === "DateTimeRangePicker" ||
          block.comp === "DynamicRefetchDropdown")) ||
      block.comp === "ButtonTab" ||
      block.comp === "KPMetaPrimary" ||
      block.comp === "TplMetaBlock" ||
      block.comp === "PageIconBlock" ||
      block.comp === "Timeline" ||
      block.comp==="PluginSentinel"  ||
      // block.comp === 'KPCasesDataOverview' ||
      block.comp === "MarkAsCompleteToggle" ||
      block.comp==="InfoText"
    );
  }
};


export const checkFAQCompIsPopulated = (value) => {
  return (
    value &&
    value.length > 0 &&
    value.some((d) =>
      Object.values(d).every((dd) => dd && dd.blocks[0].text.length > 0)
    ) //basically we are saying, that at least 1 of the Q&A pairs in the value should have a value for both Q & A
  );
};

export const checkMultiProfileCardIsPopulated = (value) => {
  return (
    value &&
    value.length > 0 &&
    value.some((cardData) => checkIfCardIsPopulated(cardData) === true) //basically we are saying that at least 1 card should be populated
  );
};

export const checkIfCardIsPopulated = (cardData) => {
  if (
    cardData &&
    ((cardData.img.imgData && cardData.img.imgData.length > 0) ||
      cardData.nameString.length > 0 ||
      (cardData.desc &&
        cardData.desc.blocks[0] &&
        cardData.desc.blocks[0].text.length > 0) ||
      cardData.selected.length > 0) //basically we are saying, at least 1 field should be populated
  ) {
    return true;
  } else {
    return false;
  }
};
