import React from "react";
import { PaddingTopBottom15 } from "oolib";
import { useFormValidationContext } from "../../../../../../contexts/formValidationContext";
import { EditBlockGenerator } from "../../../../../../utils/blockGenerators";
import { prepareEditConfig } from "../../utils/prepareEditConfig";
import { DefaultFormWrapper } from "../FormConfigsRenderer";

export const EditLayout = ({ tpl, content, memo, handleOnChange }) => {
    const { formValidation, setFormValidation } = useFormValidationContext();
    const editConfig = prepareEditConfig(tpl);
  
    return (
      <DefaultFormWrapper>
        {editConfig.map((block, i) => (
          <EditBlockGenerator
            key={i}
            memo={memo}
            block={block}
            onChange={handleOnChange}
            content={content}
            Wrapper={PaddingTopBottom15}
            formValidation={formValidation}
            setFormValidation={setFormValidation}
            isNewTemplate={true}
          />
        ))}
      </DefaultFormWrapper>
    );
  };