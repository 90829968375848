
import React, {
  createContext,
  useContext,
  useState
} from "react";
import { useParams } from "react-router-dom";

// import { useSocketContext } from "../../contexts/socketContext";
import { useSearchParamsState } from "../../utils/customHooks";

import { useSaveTplChanges } from "../../utils/react-query-hooks/tci";
import {
  defaultSettingsSpace,
  defaultTemplatesSpace,
  genInitTplConfig,
  spaceOps
} from "../../utils/templating/spaceAndDivisionConfigs";

import { _handleRepositionBlock } from "./handlers/_handleRepositionBlock";

import { cloneDeep, debounce } from "lodash";
import { deleteVal, getVal, setVal, toArray } from "oolib";
import { useHandleContentChange } from "../../utils/contributionFlowUtils";
import { useGetDataQuery } from "../../utils/react-query-hooks/getData";
import { formValidationCheckV2 } from "../../utils/validation/formValidation";
import { _handleAddPage } from "./handlers/_handleAddPage";
import { _HANDLE_DUPLICATE_BLOCK } from "./handlers/_HANDLE_DUPLICATE_BLOCK";
import { _HANDLE_DUPLICATE_SECTION } from "./handlers/_HANDLE_DUPLICATE_SECTION";
import { _HANDLE_UPDATE_TPL_CONFIG } from "./handlers/_HANDLE_UPDATE_TPL_CONFIG";
import { _HANDLE_UPDATE_TPL_META } from "./handlers/_HANDLE_UPDATE_TPL_META";
import { saveTplChangesDebounced } from "./utils/saveTplChangesDebounced";
import { _genAutosaveStatusMsg } from "./utils/_genAutosaveStatusMsg";
import { useGetQueryData } from "../../utils/react-query-hooks/general";
import { useValidateTplPublish } from "./utils/useValidateTplPublish";

export const debouncedMutate = debounce(({
  tplConfig, 
  mutate, 
  onSuccess, 
  onError
}) => {
  mutate(
    { tplConfig },
    {
      onSuccess,
      onError
    }    
  )
}, 1000);


export const TCIContext = createContext();

export const TCIContextProvider = ({ children, tplConfig: tplConfigProp, tplPublishedStatus }) => {
  //4
  const {BASE_LAYOUT_CONFIGS} = useGetQueryData('platformConfigs')

  

  const [tplConfig, setTplConfig] = useState(
    BASE_LAYOUT_CONFIGS[tplConfigProp.category]?.version >= 1
      ? tplConfigProp
      : genInitTplConfig(tplConfigProp)
  );

  const {enableTplPublish} = useValidateTplPublish({tplConfig})

  const [lockValuePaths, setLockValuePaths] = useState(true) //default is true. better to be safe than sorry

  useGetDataQuery({
    contentTypes: [tplConfigProp.kp_content_type],
    env: 'prod',
    useCountDAL: true,
    queryOptions: {
      onSuccess: (res) => {
        let count = res?.data[0]?.count
        //count if truthy meaning it is more than 0
        if(count) setLockValuePaths(true)
        else setLockValuePaths(false)
      },
      onError: (err) => {
        console.log(err)
        setLockValuePaths(false)
      }
    }
  });

  
  

  // -------- each of these 3 trigger then opening of Sidebars of their own -------- //
  const [inputBlockToConfigure, _setInputBlockToConfigure] = useState(undefined);
  const setInputBlockToConfigure = (value) => {
    _setInputBlockToConfigure(value);
  };
  const resetInputBlockToConfigure = () => {
    _setInputBlockToConfigure(undefined);
  };

  
  const [metaToConfigure, _setMetaToConfigure] = useState(undefined);
  const setMetaToConfigure = (value) => {
    _setMetaToConfigure(value);
  };
  const resetMetaToConfigure = () => {
    _setMetaToConfigure(undefined);
  };

  const [openSubSpaceBuilder, setOpenSubSpaceBuilder] = useState(undefined)
  // -------- END each of these 3 trigger then opening of Sidebars of their own -------- //

  //6
  const [
    showConfirmDeleteBlockOrSectionModal,
    setShowConfirmDeleteBlockOrSectionModal,
  ] = useState(undefined);

  //7 dev - normal user mode
  const [developerMode, setDeveloperMode] = useState(false)

  //0 SocketIo stuff

  const [usersOnThisTpl, setUsersOnThisTpl] = useState([]);

  

  const { contentType } = useParams();
  // const {
  //   socket,
  //   ENTER_TCI_SPACE,
  //   EXIT_TCI_SPACE,
  //   UPDATE_TCI_TPL,
  //   DISCONNECT_USER,
  // } = useSocketContext();
  // useEffect(() => {
  //   //new

  //   socket.emit(ENTER_TCI_SPACE, { contentType });
  //   socket.on(ENTER_TCI_SPACE, ({ usersData }) => setUsersOnThisTpl(usersData));

  //   socket.on(EXIT_TCI_SPACE, ({ usersData }) => setUsersOnThisTpl(usersData));

  //   socket.on(UPDATE_TCI_TPL, ({ newTplData }) => {
  //     console.log("RECEIVED UPDATED CONF", newTplData);
  //     setTplConfig(newTplData.drafts.active);
  //   });

  //   socket.on(DISCONNECT_USER, ({ userData }) =>
  //     setUsersOnThisTpl((prev) => {
  //       /**
  //        * we use socketId here and not user._id cuz
  //        * 2 instances of the same user could be on the template
  //        * in which case _id would be same.
  //        */
  //       return prev.filter(({ socketId }) => socketId !== userData?.socketId);
  //     })
  //   );

  //   return () => {
  //     socket.emit(EXIT_TCI_SPACE, { contentType });
  //   };
  // }, [socket]);

  const saveTplChanges = useSaveTplChanges();

  //--- V2 -----------------------------------------------

  /**
   *
   * @param {array|object|function} _updateData of the shape { value, valuePath } where value is the 'block config' & valuePath is the path to this block within this tempalate config. It could also be a function that receives the `prev`TplConfig from inside `setTplConfig` function, but it still ultimately has to return an array or object of the structure described earlier.
   * @param {{onSuccess: function, onError: function}} options can pass an `onSuccess` and an `onError` callback within options, to trigger something once the mutation within this function is complete.
   * @description can be a pair of valuePath & value OR an array of multiple valuePath-value pairs
   * @example { valuePath, value } OR [ { valuePath, value }, ... ]
   */
  const handleUpdateTplConfigV2 = (_updateData, options = {}) => {
    const {onSuccess, onError } = options;
    setTplConfig((prev) => {
      let newTplConfig = cloneDeep(prev);
      const updateData = typeof _updateData === 'function'
        ? _updateData(prev)
        : _updateData
      toArray(updateData).map(({ valuePath, value }) => {
        newTplConfig = value === undefined
          ? deleteVal(newTplConfig, valuePath)
          : setVal(newTplConfig, valuePath, value);
          
      });
      
      if(options.blocksToValidate){
        const { failedBlocks } = formValidationCheckV2({
          blocks: options.blocksToValidate,
          content: getVal(newTplConfig, options.blockPath),
        });
        options.setFormValidation && options.setFormValidation((p) => ({ ...p, failedBlocks }));
      }
      

      //autosave
      if(!options.disableAutosave){
        debouncedMutate({
          tplConfig: newTplConfig,
          mutate: saveTplChanges.mutate,
          onSuccess,
          onError
        });
      }
      
      return newTplConfig;
    });
  };

  //--------------------------------------------------------

  const SAVE_TPL_CHANGES = ({ newTplConfig }) => {
    saveTplChangesDebounced(
      saveTplChanges,
      newTplConfig,
      newTplConfig.kp_content_type
    );
  };

  const genAutosaveStatusMsg = () => {
    return _genAutosaveStatusMsg(saveTplChanges.status);
  };

  //handlers

  const HANDLE_UPDATE_TPL_CONFIG = (updateData) =>
    _HANDLE_UPDATE_TPL_CONFIG({
      updateData,
      setTplConfig,
      SAVE_TPL_CHANGES,
    });

  

  const handleRepositionBlock = ({ value, valuePath }) =>
    _handleRepositionBlock({
      value,
      valuePath,
      HANDLE_UPDATE_TPL_CONFIG,
    });

  const HANDLE_DUPLICATE_BLOCK = (block, blockAryPath, blockIdx) =>
    _HANDLE_DUPLICATE_BLOCK({
      block,
      blockAryPath,
      blockIdx,
      setTplConfig,
      SAVE_TPL_CHANGES,
    });

  const HANDLE_DUPLICATE_SECTION = ({ sectionPath }) =>
    _HANDLE_DUPLICATE_SECTION({
      sectionPath,
      setTplConfig,
      SAVE_TPL_CHANGES,
    });

  const handleAddPage = () =>
    _handleAddPage({
      HANDLE_UPDATE_TPL_CONFIG,
      activeTciSpace,
      tplConfig,
    });

  const HANDLE_UPDATE_TPL_META = ({ tplMeta }) =>
    _HANDLE_UPDATE_TPL_META({
      tplMeta,
      setTplConfig,
      SAVE_TPL_CHANGES,
    });

  //2
  const [showCopyToOtherTplModal, setShowCopyToOtherTplModal] =
    useState(undefined);

  //3
  const [activePageIdx, setActivePageIdx] = useState(0);

  //5
  //test content. wont be saved to db
  const [content, setContent] = useState({
    meta: { 
      kp_content_type: contentType, //this is important, else things like parseSpecialSyntax (such as $thisContentType) will break in tci
      kp_contributed_by: {
        name: 'TCI Admin',
        accountDeactivated: true
      }
    },
    main: {},
    kp_date_published: "1000-01-01T12:00:00.000+00:00"
  });
  
  const { handleContentChange } = useHandleContentChange();

  //7
  const [sectionToConfigure, setSectionToConfigure] =
    useState(undefined);

  //8
  const [sectionTitleEdit, setSectionTitleEdit] = useState(false);

  //9
  const tciSpaceOps =
    BASE_LAYOUT_CONFIGS[tplConfigProp.category]?.version >= 1
      ? [
          ...(BASE_LAYOUT_CONFIGS[tplConfigProp.category].settings
            ? [
                {
                  display: "Settings",
                  value: "settings",
                  valuePath: "kp_settings",
                  pages: true,
                },
              ]
            : []),
          { display: "Template", value: "template", valuePath: "kp_templates" },
        ]
      : [
          ...(spaceOps[tplConfigProp.category].some(
            (c) => c.value === "settings"
          )
            ? [defaultSettingsSpace]
            : []),
          defaultTemplatesSpace,
        ];
  const [activeTciSpace, setActiveTciSpace] = useSearchParamsState({
    key: "activeTciSpace",
    value: tciSpaceOps[0],
  });


  // ListingPageBuilder related.
  /**
   * this is the 'final table config' that is prepared after parsing the contentType property 
   * inside tableConfig, and ultimately used to render the table. this is done inside TableElem, so we use
   * utility functions inside of the builderProps, to broadcast this final config out here.
   * 
   * This final config is needed inside CardConfigPanelTCI > TableColsConfigurer for rendering
   * the hide/show columns interface
   */
  const [parsedTableConfig, setParsedTableConfig] = useState(undefined);

  return (
    <TCIContext.Provider
      value={{
        resetInputBlockToConfigure,
        showCopyToOtherTplModal,
        setShowCopyToOtherTplModal,
        activePageIdx,
        setActivePageIdx,

        content,
        setContent,
        handleContentChange,
        showConfirmDeleteBlockOrSectionModal,
        setShowConfirmDeleteBlockOrSectionModal,
        sectionToConfigure,
        setSectionToConfigure,
        sectionTitleEdit,
        setSectionTitleEdit,

        tciSpaceOps,
        activeTciSpace,
        setActiveTciSpace,

        usersOnThisTpl,

        //save changes to db
        saveTplChangesStatus: saveTplChanges.status,
        genAutosaveStatusMsg,

        //handlers
        HANDLE_DUPLICATE_BLOCK,
        
        handleRepositionBlock,

        HANDLE_DUPLICATE_SECTION,

        handleAddPage,

        HANDLE_UPDATE_TPL_META,

        handleUpdateTplConfigV2,
        tplConfig,
        setTplConfig,
        inputBlockToConfigure, 
        setInputBlockToConfigure,

        developerMode, setDeveloperMode,

        tplPublishedStatus,
        lockValuePaths,
        BASE_LAYOUT_CONFIGS,
        
        enableTplPublish,

        metaToConfigure,
        setMetaToConfigure,
        resetMetaToConfigure,

        openSubSpaceBuilder, 
        setOpenSubSpaceBuilder,

        parsedTableConfig, 
        setParsedTableConfig
      }}
    >
      {children}
    </TCIContext.Provider>
  );
};

export const useTCIContext = () => useContext(TCIContext);
