import { getMappableTemplateConfig } from "../../../../../utils/templating";

import ApplicationFormsStyle1Layout from "../../../layouts/ApplicationFormsStyle1/Layout";
import GroupsStyle1Layout from "../../../layouts/GroupsStyle1/Layout";

import KnowledgeResources2Layout from "../../../layouts/KnowledgeResources2/Layout";
import ResearchNotesLayout from "../../../layouts/DEPR/ResearchNotes/Layout";
import ResearchNotes2Layout from "../../../layouts/ResearchNotes2/Layout";

//this only works for BASE_LAYOUT_CONFIGS of version1 and above
const _getBlocksToValidate = ({ tpl }) => {
  const extractValidatableBlocks = ({ builder, configs }) => {
    if (builder === "MetaBuilder")
      return configs?.inputs ? Object.values(configs.inputs) : [];
    else if (builder === "FormBuilder") return configs || [];
    else return [];
  };

  const toReturn = [];
  //parse through each space,
  for (let spaceId in tpl.kp_templates) {
    let { subSpaces, builder, configs } = tpl.kp_templates[spaceId];
    if (!subSpaces) {
      // if subspace doesnt exist,
      // if metaBuilder, then obj.value sab ko daal do
      // if formbuilder, the configs kay array ko spread kar kay daal do
      toReturn.push(...extractValidatableBlocks({ builder, configs }));
    } else {
      // if subspaces then cycle through each, and do the same as above.
      subSpaces
        .filter((ss) => ss.subSpaceHiddenInTCI !== true) // cuz these subspaces as good as dont exist
        .forEach((sub) => {
          toReturn.push(
            ...extractValidatableBlocks({
              builder: sub.builder,
              configs: sub.configs,
            })
          );
        });
    }
  }

  return toReturn;
};

export const getThisLayout = ({ tpl, content }) => {
  const Layouts = {
    researchNotes: {
      Layout: ResearchNotesLayout,
      getBlocksToValidate: () => [
        ...(tpl.kp_templates.raw.blocks || []),
        ...(tpl.kp_templates.synth.blocks || []),
      ],
    },
    groupsStyle1: {
      Layout: GroupsStyle1Layout,

      getBlocksToValidate: () => _getBlocksToValidate({ tpl }),
    },
    knowledgeResources2: {
      Layout: KnowledgeResources2Layout,
      getBlocksToValidate: () => _getBlocksToValidate({ tpl }),
    },
    applicationFormsStyle1: {
      Layout: ApplicationFormsStyle1Layout,

      getBlocksToValidate: () => _getBlocksToValidate({ tpl }),
    },
    researchNotes2: {
      Layout: ResearchNotes2Layout,

      getBlocksToValidate: () => _getBlocksToValidate({ tpl }),
    },
  };
  const { Layout, getBlocksToValidate } =
    Layouts[tpl.layout || "knowledgeResources2"];
  return {
    Layout,
    blocksToValidate: getBlocksToValidate(),
  };
};
