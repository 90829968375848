import {
  SANS_2,
  UI_BODY_SEMIBOLD_SM,
  UI_TAG,
  colors,
  colors2,
  useScreenWidth,
  icons,
  transition,
} from "oolib";
import React, { Fragment, useRef, useState } from "react";

import { FilterInput } from "../../../../inputs/DynamicTagsInputs/FilterInput";
import { FilterActionsButton } from "../FilterActionsButton";
import {
  StyledSideFilterFlexVerticalGapShell,
  SideFilterHeaderStyled,
  SideFilterListWrapper,
  StyledSideFilterSectionPaddingShell,
  StyledAsideFilter,
  StyledAsideFilterContainer,
  StyledAsideFilterContentsWrapper,
  StyledFilterHeaderInnerWrapper,
} from "./styled";
import styled from "styled-components";

const { CaretDown } = icons;

const StyledIconRotator = styled.div`
  ${transition("transform")};
  transform: rotate(${({ open }) => (open ? "0" : "180deg")});
  display: flex;
`;

// this is the second level section
const AccordionFilterGroupSection = ({
  children,
  title,
  open: openFromParent = true,
}) => {
  const [open, setOpen] = useState(openFromParent);

  return (
    <div
      style={{
        border: `1px solid ${colors2.grey10}`,
        borderRadius: "6px",
      }}
    >
      <div
        style={{
          borderBottom: open ? `1px solid ${colors2.grey10}` : "none",
          padding: "0 0.4rem",
          cursor: "pointer",
        }}
        onClick={() => setOpen((prev) => !prev)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0.8rem",
          }}
        >
          {title && <UI_BODY_SEMIBOLD_SM>{title}</UI_BODY_SEMIBOLD_SM>}
          <StyledIconRotator open={open}>
            <CaretDown size={16} />
          </StyledIconRotator>
        </div>
      </div>
      <div style={{ padding: "0.4rem", display: open ? "block" : "none" }}>
        {children}
      </div>
    </div>
  );
};

export default function AsideFilter({
  onChange,
  selectedValues,

  activeFilters,
  setActiveFilters,
  filterConfig,
  presetActiveFilters,
  contentTypes,
  mobileHeader,
  isMobile,
  topPanelHeight = 0,
}) {
  const handleClearAllTags = () => setActiveFilters([]);

  const ref_asideFilter = useRef(null);
  const ref_sideFilterHeader = useRef(null);
  const actionsAreaRef = useRef(null);
  const screenWidth = useScreenWidth();
  const isTab = screenWidth < 800 && screenWidth > 700; // exepction

  const generateFilters = (filterConfig) => {
    const genFilterInput = (f) => {
      const { filterId } = f;
      return (
        <FilterInput
          ref_asideFilter={ref_asideFilter}
          ref_sideFilterHeader={ref_sideFilterHeader}
          key={filterId}
          comp="AccordionMultiSelect"
          value={selectedValues[filterId] || []}
          onChange={(_, value) => {
            onChange({
              id: filterId,
              value,
            });
          }}
          filterConfig={f}
          activeFilters={activeFilters}
          presetActiveFilters={presetActiveFilters}
          contentTypes={contentTypes}
        />
      );
    };

    // Split into 3 categories
    const doubleNestedConfigs = filterConfig.filter(
      (f) => f.configs && f.configs.some((ff) => ff.configs)
    );

    const singleNestedConfigs = filterConfig.filter(
      (f) => f.configs && !f.configs.some((ff) => ff.configs)
    );

    const directConfigs = filterConfig.filter((f) => !f.configs);

    return (
      <div>
        {/* Double nested configs (f.configs with ff.configs) */}
        {doubleNestedConfigs.map((f, fI) => (
          <div
            key={f.sectionId}
            style={
              fI !== doubleNestedConfigs.length - 1
                ? { borderBottom: `1px solid ${colors2.grey10}` }
                : {}
            }
          >
            {f.sectionTitle && (
              <UI_TAG
                style={{
                  paddingTop: "1rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                  color: colors2.grey50,
                }}
              >
                {f.sectionTitle}
              </UI_TAG>
            )}
            <StyledSideFilterSectionPaddingShell>
              <StyledSideFilterFlexVerticalGapShell>
                {f.configs.map((ff) => (
                  <AccordionFilterGroupSection
                    key={ff.sectionId}
                    title={ff.sectionTitle}
                    >
                    <StyledSideFilterFlexVerticalGapShell>
                      {ff.configs.map((fff) => genFilterInput(fff))}
                    </StyledSideFilterFlexVerticalGapShell>
                  </AccordionFilterGroupSection>
                ))}
              </StyledSideFilterFlexVerticalGapShell>
            </StyledSideFilterSectionPaddingShell>
          </div>
        ))}

        {/* Single nested configs (just f.configs) */}
        {singleNestedConfigs.map((f, fI) => (
          <div
            key={f.sectionId}
            style={
              fI !== singleNestedConfigs.length - 1
                ? { borderBottom: `1px solid ${colors2.grey10}` }
                : {}
            }
          >
            {f.sectionTitle && (
              <UI_TAG
                style={{
                  paddingTop: "1rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                  color: colors2.grey50,
                }}
              >
                {f.sectionTitle}
              </UI_TAG>
            )}
            <StyledSideFilterSectionPaddingShell>
              <StyledSideFilterFlexVerticalGapShell>
                {f.configs.map((ff) => genFilterInput(ff))}
              </StyledSideFilterFlexVerticalGapShell>
            </StyledSideFilterSectionPaddingShell>
          </div>
        ))}

        {/* Direct configs (no nesting) */}
        <StyledSideFilterSectionPaddingShell>
          <StyledSideFilterFlexVerticalGapShell>
            {directConfigs.map((f) => genFilterInput(f))}
          </StyledSideFilterFlexVerticalGapShell>
        </StyledSideFilterSectionPaddingShell>
      </div>
    );
  };

  return (
    <StyledAsideFilter
      id="asideFilter"
      ref={ref_asideFilter}
      top={isMobile ? "0" : topPanelHeight}
    >
      <StyledAsideFilterContainer>
        <div
          ref={actionsAreaRef}
          style={{
            height: "max-content",
            width: "100%",
            position: "sticky",
            top: 0,
          }}
        >
          {mobileHeader && mobileHeader}
        </div>
        {isTab ? <div ref={ref_sideFilterHeader} /> : null}{" "}
        {/* ref is not available at this breakpoint as clear all filters  button moves to top */}
        <div
          id={"filtersWrapper"}
          style={{
            height: `calc(100% - ${
              actionsAreaRef.current?.getBoundingClientRect().height
            }px)`,
            overflow: "auto",
          }}
        >
          <div>
            {!isMobile && (
              <SideFilterHeaderStyled ref={ref_sideFilterHeader}>
                <StyledFilterHeaderInnerWrapper>
                  <FilterActionsButton
                    onClick={handleClearAllTags}
                    icon={"XCircle"}
                  >
                    Clear All Filters
                  </FilterActionsButton>
                </StyledFilterHeaderInnerWrapper>
              </SideFilterHeaderStyled>
            )}
            <StyledAsideFilterContentsWrapper
              headerHeight={isMobile ? "auto" : topPanelHeight}
            >
              <SideFilterListWrapper>
                {generateFilters(filterConfig)}
              </SideFilterListWrapper>
            </StyledAsideFilterContentsWrapper>
          </div>
        </div>
      </StyledAsideFilterContainer>
    </StyledAsideFilter>
  );
}
