import React, { useState } from 'react';
import { Dropdown } from '.';

import { useGetPlaces } from "../../../utils/react-query-hooks/general";
import { getVal, makeArrayFromLength } from 'oolib';
import { _Locale } from '../../locale/Locale';

const reverseMorphValueShape = (valueFromDropdown, options={}) => {
    /**
     * change the standard dropdown shape : { display: xx, value: xx, ( in this case : ) data : {xx}, type: xx }
     * into the prev dynamicsearchinput shape : { internal : [{...}], external: [{...}] }
     */
    //we always want the value shape to be an array
    let value = Array.isArray(valueFromDropdown) 
    ? valueFromDropdown 
    : [valueFromDropdown];

    if(options.shape === 'simpleArray') return value.map(d => d.data); // --> places api case

    if(options.shape === 'wordTagsArray') return value.map(d => d.data); 

    //else
    return {
        internal : value
                    .filter(d => d.type === 'internal').map(d => d.data),
        external : value
                    .filter(d => d.type === 'external')
                    .map(d => { return { string : d.display, _id : d.value } })
    }
}

const genOptionData = (d, type, pathsToUse) => {
	let dataToReturn = { type, data: d }
	Object.values(pathsToUse).map((valuePath, i) => {
		let key = Object.keys(pathsToUse)[i]
		dataToReturn[key] =
			key === 'icon'
				? valuePath //for icons the valuePath string is the name of the icon itself, which works for us.
				: getVal(d, valuePath)
		//if an image key has been defined and we have no value,
		//we still want to pass an empty obj so at least a
		//placeholder is generated
		if (key === 'image' && dataToReturn[key] === undefined) {
			dataToReturn[key] = {}
		}
	})
	return dataToReturn
}

const morphDataShape = (data, selected_option, options = {}) => {
	if (!data) return undefined

	const { compPaths, dropdownVariant } = options
	let pathsToUse = compPaths
	let valueToReturn = []

	switch (selected_option) {
		case 'selected':
			/**
			 * data shape here is:
			 * {
			 *      internal : [ { main, meta, ...} ],
			 *      external : [ { string, _id } ]
			 * }
			 */
			Object.values(data).map((dAry, i) => {
				let type = Object.keys(data)[i]
				//skip out the 'searchString' property that most of the older dynamicdropdown values have
				Array.isArray(dAry) &&
					dAry.map((d) => {
						dropdownVariant.includes('single')
							? (valueToReturn = genOptionData(d, type, pathsToUse))
							: valueToReturn.push(genOptionData(d, type, pathsToUse))
					})
			})
			break

		case 'tags_selected':
			data.map((d) => {
				valueToReturn.push(genOptionData(d, undefined, pathsToUse))
			})
			break

		case 'places_selected':
			/**
			 *
			 * data shape here is simple array:
			 * [ {...} ]
			 *
			 */
			data.map((d) => {
				dropdownVariant.includes('single')
					? (valueToReturn = genOptionData(d, undefined, pathsToUse))
					: valueToReturn.push(genOptionData(d, undefined, pathsToUse))
			})
			break

		case 'places_option':
		case 'tags_option':
			data.map((d) => {
				valueToReturn.push(genOptionData(d, undefined, pathsToUse))
			})
			break

		case 'option':
			data.map((d) => {
				valueToReturn.push(genOptionData(d, 'internal', pathsToUse))
			})
			break
		
		default:
			return;	
	}
	return valueToReturn
}

export const DynamicRefetchDropdown = (props) => {
	const {
		id,
		compPaths,
		onChange,
		value,
		variant,
		// ... can have several other props as well, check the Dropdown component to know which those are.
	} = props

	const dropdownVariant = variant || 'combobox-single' // can be : single | multi | and then there are a couple of other variants that make this into a tags comp. but must rethink that implementation. too buggy right now

	const [searchString, setSearchString] = useState('')

	const { data, status } = useGetPlaces(searchString)

	let defaultCompPaths = {
		//image --> we dont pass some default values to image, cuz we might not want to render an image at all sometimes.
		icon: 'MapPin',
		display: [
			'name',
			'user.name',
			'title.blocks.0.text',
			'title',
			'description',
		],
		//desc
		value: 'place_id',
	}

	const onDropdownChange = (k, v) => {
		onChange &&
			onChange(id, reverseMorphValueShape(v, { shape: 'simpleArray' }))
	}
	const getCompPaths = () =>
		compPaths ? { ...defaultCompPaths, ...compPaths } : defaultCompPaths

	return (
		<Dropdown
			{...props}
			searchbarPlaceholder='Type here to search for a location'
			placeholder={_Locale('Select a location')}
			variant={dropdownVariant}
			onChange={(k, v) => onDropdownChange(k, v)}
			value={morphDataShape(value, 'places_selected', {
				id,
				compPaths: getCompPaths(),
				dropdownVariant,
			})}
			options={
				status === 'loading'
					? makeArrayFromLength(8).map((d) => {
							return { loading: true }
					  })
					: morphDataShape(data, 'places_option', {
							id,
							compPaths: getCompPaths(),
							dropdownVariant,
					  })
			}
			setSearchString={setSearchString}
		/>
	)
}





