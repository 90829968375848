import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../..";
import axios from "axios";

export const useLoadChat = () =>
  useQuery({
    queryKey: ["aiChat"],
    queryFn: () => axios.get("/api/aiChat/getChat").then((data) => data.data),
  });

export const useDeleteChat = () => {
  return useMutation(() => axios.delete("/api/aiChat/deleteChat"), {
    onSuccess: () => {
      queryClient.removeQueries("aiChat");
    },
  });
};


export const useSendFeedback = () => {
  return useMutation(({ reaction, comments, messageId }) =>
    axios.put("/api/aiChat/feedback", { reaction, comments, messageId })
  );
};
