import React , { useState } from "react";
import { colors2, UI_CAPTION_SEMIBOLD, icons } from "oolib";
import { StyledButton, StyledIconWrapper } from "../styled";
import { TagList } from "./TagList";

const { CaretUp } = icons;

export const TagSectionOnCard = ({ annoTags, docTags, annoTagsTitle, docTagsTitle, cardWidth }) => {

    const [show, setShow] = useState(false)
  
    return (
    <div style={{ borderTop: `1px solid ${colors2.grey10}`, paddingTop: '1rem', width: 'inherit' }}>
      <div>
          <StyledButton onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setShow(prev => !prev)
              }}>
                    <UI_CAPTION_SEMIBOLD style={{ color: colors2.grey80 }}>
                        Show Tags
                    </UI_CAPTION_SEMIBOLD>

                    <StyledIconWrapper show={show} >
                        <CaretUp color={colors2.grey40} size={16}/>
                    </StyledIconWrapper>
          </StyledButton>
      </div>
      <div
        style={{
          ...(!show ? { paddingBottom: 0} : { paddingTop: '1rem' }),
          height: show ? 'auto' : 0,
          overflow: 'hidden',
          display: "grid",
          width: "inherit",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
        }}
      > 
        <TagList tags={annoTags} title={annoTagsTitle} cardWidth={cardWidth} />
        <TagList tags={docTags} title={docTagsTitle} cardWidth={cardWidth} />
      </div>
    </div>
  )};