import {
  ActionMenu,
  ButtonPrimary,
  colors,
  Loader,
  ModalConfirm,
  OKELink,
  ProfileImageInput,
  SANS_2,
  SANS_3,
} from "oolib";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from 'react-router-dom';

import {
  __CheckAccessToActionAsOwnerOrModerator,
  __CheckAccessToModule,
  __IsAuthor,
} from "../../../utils/accessControl";

//actions
import { createContentCardDate } from "../../../utils/momentManipulate";
import {
  useCreateComment,
  useDeleteComment,
} from "../../../utils/react-query-hooks/comments";

import { nanoid } from "nanoid";
import { UIContent } from "../../../UIContent";
import { lexicalTextEditorHasValue } from "../../../utils/validation/validatorFns";

import { _Locale } from "../../locale/Locale";
import UpdateCommentModule from "./UpdateCommentModule";
import { useScrollHashLinkIntoView } from "../../../utils/customHooks/useScrollHashLinkIntoView";
import LexicalTextEditor from "../../inputs/TextEditor/LexicalTextEditor";
import { extractMentions } from "../../inputs/TextEditor/LexicalTextEditor/plugins/MentionsPlugin/utils/extractMentions";
import { useSendNotification } from "../../../utils/react-query-hooks/notifications";


const { greyColor40, greyColor70 } = colors;

const CommentsModule = ({ comments, contentType, contentId,contributedById }) => {
  let queryClient = useQueryClient();
  let { data : userData } = queryClient.getQueryState('userData')
 

  

  
  

  //--------------
  // const [commentBlockClickCount, setCommentBlockClickCount] = useState(0)
  // const [editorReadOnly, setEditorReadOnly] = useState(true);
  // const handleCommentBlockFirstClick = () => {
  //   setCommentBlockClickCount(commentBlockClickCount + 1);
  //   setEditorReadOnly(false);
  // }
  //-------------- all this is simply to workaround a daym strange draftjs behaviour where, if a non readOnly block is placed between readOnly blocka, it thorws an error, so instead, we make all editors readOnly. and only when the input comments one is clicked we focus on it.

  const deleteComment = useDeleteComment();
  const createComment = useCreateComment();
 
  const navigate = useNavigate();
  const location = useLocation();
  const CommentsModule_ref = useRef(null);

  const [commentInputValue, setCommentInputValue] = useState(location.state?.commentInputValue || undefined);
  // const [commentInputResetValue, setCommentInputResetValue] = useState(undefined);
  const [idFlaggedToDelete, setIdFlaggedToDelete] = useState(undefined); //need this so that we can show a loader against the appropriate comment, when the delete is underway

  const [idFlaggedToUpdate, setIdFlaggedToUpdate] = useState(undefined);
  const sendNotification = useSendNotification();

  const handlePostComment = (commentInputValue) => {
    const mentions = extractMentions(commentInputValue)
    createComment.mutate(
      {
        data: { text: commentInputValue },
        contentType,
        contentId
      },
      {
        onSuccess: ({ data }) => {
          // setCommentInputResetValue({ blocks: [{ text: '' }], entityMap: {} });
          // setCommentInputResetValue(undefined); 
          //switch it back to undefined, so that the next time setCommentInputResetValue runs, it fires the useEffect inside RichTextEditor, that makes the reset work
          setCommentInputValue(undefined);

          const userIds = mentions?.map(m => ({ id: m.mentionId }))
          if (userIds.length > 0) {
            sendNotification.mutate({
              userIds, 
              body: {
                dateCreated: new Date(data.comments[0].dateCreated).getTime(),
                activityType: "MENTION_IN_COMMENT",
                content: {
                  title: data.main.title,
                  contentType: data.meta.kp_content_type,
                  id: data._id,
                  commentId: data.comments[0]._id,
                }
              }
            })
          }
          if (__IsAuthor(
            contributedById,
            userData?.user._id
          ))
          {
            queryClient.invalidateQueries('userData')
          }
        }
      }
    )
  }

  

  const handleDeleteComment = () => {
    deleteComment.mutate(
      {
        contentType, 
        contentId, 
        commentId: idFlaggedToDelete
      }
    );
    setIdFlaggedToDelete(undefined);
  }


  useEffect(() => {
    if(location.state?.commentInputValue){  
      CommentsModule_ref.current && 
      CommentsModule_ref.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [location.state])

  
  useScrollHashLinkIntoView()
  return (
    <Fragment>
     {idFlaggedToDelete && (
        <ModalConfirm
          title={_Locale('Are you sure you want to delete this comment?')}
          onClose={() => {
            setIdFlaggedToDelete(undefined);
          }}
          onConfirm={() => handleDeleteComment()}
        ></ModalConfirm>
      )}

          <div ref={CommentsModule_ref} id="commentsModule">
         { 
         /**
          * what this means is:
          * is user is not logged in, then show the comments box, cuz it has a button called 'login to comment'
          * which will handle the redirection.
          * However, if the user is already logged in, then check if they have access to this module
          */
         (!userData || __CheckAccessToModule(userData?.user, "CREATE", "comments")) && 
         <div
              // onClick={() => commentBlockClickCount === 0 && handleCommentBlockFirstClick()}
            > 
              <LexicalTextEditor
                  key={`${userData?.user._id + comments.length}`} 
                  id={'CommentsModule__inputEditor'}
                  style={{
                    padding: '2rem',
                    minHeight: '10rem',
                    backgroundColor: colors.greyColor5
                  }}
                  typo='SANS_3'
                  placeholderColor={colors.greyColor40}
                  enableMentions={true}
                  placeholder={_Locale(UIContent.CommentsModule.commentInputPlaceholder)}
                  // forceValue={commentInputResetValue}
                  value={commentInputValue}
                  variant='simple'
                  onChange={(k, v) => setCommentInputValue(v)}
              />
              {/* <RichTextEditor
                id={'CommentsModule__inputEditor'}
                style={{
                  padding: '2rem',
                  minHeight: '10rem',
                  backgroundColor: colors.greyColor5
                }}
                typo='SANS_3'
                placeholderColor={colors.greyColor40}
                placeholder={_Locale(UIContent.CommentsModule.commentInputPlaceholder)}
                forceValue={commentInputResetValue}
                value={commentInputValue}
                variant='simple'
                onChange={(k, v) => setCommentInputValue(v)}
              /> */}
              <div 
                style={{
                  display: 'flex', 
                  justifyContent: 'flex-end', 
                  paddingTop: '1rem'
                }}
                >
                <ButtonPrimary
                  disabled={!lexicalTextEditorHasValue(commentInputValue)} //if isPop then disabled === false , hence the inverse is applied
                  onClick={() => {
                    userData?.user
                    ? handlePostComment(commentInputValue)
                    : navigate("/login", {
                        
                        state: {
                          from: {
                            pathname: location.pathname,
                            search: location.search,
                            state: { commentInputValue },
                          },
                        },
                      });
                }}
              >
                {createComment.status === "loading"
                  ? `${_Locale("Loading")}...`
                  : userData?.user
                  ? _Locale("Comment")
                  : _Locale("Login To Comment")}
              </ButtonPrimary>
            </div>
          </div>}
       
        { __CheckAccessToModule(userData?.user, "READ", "comments") && 
          comments &&
          comments.map((comment, i) => {
       
            return (
              <div
                key={nanoid(4)} //important to change this on every rerender, so that the newly added comment shows up
                style={{
                  padding: "2rem 0 1.5rem 0",
                  borderBottom: `1px solid ${colors.greyColor10}`,
                }}
                id={`Comment_${comment._id}`}
                >
                <div style={{ display: 'flex', alignItems: 'flex-start'}}>
                  <div style={{flexShrink:0}}>
                  <ProfileImageInput
                    readOnly
                    id='CommentsModuleUserProfileImage'
                    value={comment.user.avatar?.userUploaded}
                    size={30}
                    imageTitle={comment.user.name}
                    
                  />
                  </div>
                  <div style={{ paddingLeft: '1.5rem', flexGrow: 1 }}>
                    <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between'}}>
                      <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          height: '3rem'         
                        }}>
                      <SANS_3 semibold>
                        <OKELink
                          color={'black'}
                          invertUnderline
                          text={comment.user.name}
                          link={`/profile/userProfiles/${comment.user.profileType || 'userProfiles'}/${comment.user.userId}?activeTab=about`}
                          />
                          </SANS_3>
                        <div style={{width: '0.5rem', height: '0.5rem', borderRadius: '50%', margin: '0 1rem', backgroundColor: greyColor40}}></div>
                        <SANS_2 semibold style={{ color: greyColor70 }}>{comment.dateModified.length!==0?createContentCardDate(comment.dateModified[comment.dateModified.length-1]):createContentCardDate(comment.dateCreated)}</SANS_2>
                      </div>
                      {                    
                        <div>
                          {deleteComment.status === "loading" &&
                          idFlaggedToDelete === comment._id  ? (
                            <Loader S isBlock={false} />
                          ) : (
                            <ActionMenu
                              actions={[
                                __CheckAccessToActionAsOwnerOrModerator(
                                  userData?.user,
                                  comment.user.userId,
                                  "DELETE",
                                  "__CheckAccessToModule",
                                  "comments"
                                ).hasAccess && (idFlaggedToUpdate !==comment._id && idFlaggedToDelete !==comment._id)
                                  ? {
                                      icon: "Trash",
                                      display: "Delete",
                                      onClick: () => {
                                     
                                        setIdFlaggedToDelete(comment._id);
                                      },
                                    }
                                  : null,
                                __CheckAccessToActionAsOwnerOrModerator(
                                  userData?.user,
                                  comment.user.userId,
                                  "UPDATE",
                                  "__CheckAccessToModule",
                                  "comments"
                                ).hasAccess && (idFlaggedToUpdate !==comment._id && idFlaggedToDelete !==comment._id)
                                  ? {
                                      icon: "PencilSimple",
                                      display: "Edit",
                                      onClick: () => {   
                                                                       
                                        setIdFlaggedToUpdate(comment._id);
                                      },
                                    }
                                  : null,
                              ].filter(Boolean)}
                            />
                          )}
                        </div>
                      }
                    </div>
                <UpdateCommentModule                    
                     comment={comment}                     
                      idFlaggedToUpdate={idFlaggedToUpdate}
                      setIdFlaggedToUpdate={setIdFlaggedToUpdate}                      
                      userData={userData}
                      contentType={contentType}
                      contentId={contentId}          
                   />
                  </div>  
                </div>  
              </div>
            )
          })}
          </div>
    </Fragment>

  )
}

export default CommentsModule
