import React, { Fragment } from 'react';
import Draggable from '../../components/generalUI/Draggable';
import { DefaultSectionWrapperComp } from './tplSectionWrapperComps/DefaultSectionWrapperComp';
import handleBlockDisplayConditions from '../handleBlockDisplayConditions';
import { getVal } from 'oolib';

import { SettingsSectionShell } from './tplSectionWrapperComps/SettingsSectionShell';
import { SidebarSectionShell } from '../../pageTpls/TCI/TCISections/TemplateBuilder/comps/sections/FormConfigSectionTCI/comps/SidebarSectionShell';
import { queryClient } from '../..';

/*
 * HELPER FUNCTION FOR blockMapper
 */

// only pass blocks from within a division or subject or page
  // ( depending on which is the last piece that exists within that tpl )
export const drilldownSection = ({
  blocks,
  callback,
  blockIndexes = [],
  blockPath_untilDivisionBlocks = '',
  sectionId = undefined,
  injectCustomUI,
  SectionWrapperComp,
  activeTab,
  handleDrag,
  draggableWrapper,
  indexList,
  content,
  enableSectionDisplayConditions,
  depthIdx = 0,
  blockSectionIdPrefix = '',
  tpl,
}) => {

  const GEN_blockPath_fromDivisionBlocks_untilThisBlockOrSection = blockIdx => [
    ...blockIndexes,
      blockIdx,
  ].join(".blocks.");

  const isSectionOrBlock = (block) => !!block.blocks ? 'section' : 'block';
  
  const genSection = (block, blockIdx, ops={}) => {

    /**
     * if an active tab is being passed through,
     * we want to hide / show the first level sections
     * depending on match or not
     */
    if(activeTab && ops.sectionId && activeTab.value !== ops.sectionId) return null;
    //else
    
    const SectionWrapperCompsLookup = {
      SidebarSectionShell,
      SettingsSectionShell,
      DefaultSectionWrapperComp
    }
    let sectionBlocksArray = block.blocks;
    const blockPath_fromDivisionBlocks_untilThisBlockOrSection = 
      GEN_blockPath_fromDivisionBlocks_untilThisBlockOrSection(blockIdx)
    let SectionWrapper = activeTab && ops.sectionId && activeTab.value === ops.sectionId
      ? 'div' //if this section is actually a tab, dont render a section title+subtitle
      : SectionWrapperComp || ( //SectionWrapperComp gets preference, because that is defined in code, and we want it to override configs
          block.SectionWrapperComp 
          ? SectionWrapperCompsLookup[block.SectionWrapperComp] 
          : DefaultSectionWrapperComp
        );

    if(enableSectionDisplayConditions && !handleBlockDisplayConditions({content, block})){
      return null;
    }

    return (
      <div 
        className='sectionIndexTracker' 
        id={`${blockSectionIdPrefix}TplSection_${block.sectionId}`} //very important. Needed to make the template index work
        >
      <SectionWrapper 
        title={block.sectionTitle} 
        subtitle={block.sectionSubtitle}
        sectionPath={`${blockPath_untilDivisionBlocks}.${blockPath_fromDivisionBlocks_untilThisBlockOrSection}`}
        depthIdx={depthIdx}
        contentType = {content?.meta?.kp_content_type}
        >
          {drilldownSection({
            blocks: block.blocks,
            callback,
            blockIndexes: [...blockIndexes, blockIdx],
            blockPath_untilDivisionBlocks,
            sectionId: ops.sectionId,
            injectCustomUI,
            SectionWrapperComp,
            handleDrag,
            draggableWrapper,
            indexList,
            depthIdx : depthIdx + 1,
            content,
            enableSectionDisplayConditions,
            tpl,
            /**
             * we do not pass activeTab to the resursive drilldown, because 
             * activeTab is meant to hide/show only the first level of sections, not nested sections
             */
          })}
        {injectCustomUI &&
          injectCustomUI({
            blockPath: `${blockPath_untilDivisionBlocks}.${blockPath_fromDivisionBlocks_untilThisBlockOrSection}.blocks.${sectionBlocksArray.length}`,
          })}
      </SectionWrapper>
      </div>
    );
  }

  const genBlock = (block, blockIdx, ops={}) => {
    return callback(
      block,
      GEN_blockPath_fromDivisionBlocks_untilThisBlockOrSection(blockIdx),
      ops.sectionId,
      ops.isLastBlockInSection
    )
  }

  const genSectionOrBlock = (block, blockIdx) => {
    if(isSectionOrBlock(block) === 'section'){
      return genSection(block, blockIdx, { sectionId: block.sectionId })
    }else{ //is block
      return genBlock(block, blockIdx, {sectionId, isLastBlockInSection: blockIdx === blocks.length - 1})
    }
  }

  if(!handleDrag){ // no drag <-- the more frequent use case
    return blocks.map(genSectionOrBlock)
  }else{ // handleDrag function exists. so use draggable
    return (
      <Draggable 
        ary={blocks}
        idValPath={['valuePath', 'sectionId']}
        handleReposition={(newAry) => {
          const genAryValuePath = () => {
            let path = `${blockPath_untilDivisionBlocks}.${GEN_blockPath_fromDivisionBlocks_untilThisBlockOrSection(0)}`;
            return path.substring(0, path.length - 2);
          }
          handleDrag({value: newAry, valuePath: genAryValuePath()})
        }}
        renderChildren={genSectionOrBlock}
        Wrapper={draggableWrapper}
        />
    )
  }
  
};

export const blockMapper = ({
    activeSpace,
    
    activeTab,
    activePageIdx,
    tplConfig,
    blockGenerator,
    injectCustomUI,
    ContentBlocksWrapper = 'div',
    SectionWrapperComp,
    handleDrag,
    draggableWrapper,
    indexList,

    enableSectionDisplayConditions = true, //this is turned off on TCI sidebar, cuz obviously we dont want those sections to disappear cuz of display conditions
    content, //needed for section display conditions to work
    blockSectionIdPrefix //this is important, else the section in the sidebar, and the section in the actual template, will have the same id
  }) => {
    const tpl = queryClient.getQueryData(["tpl", content?.meta?.kp_content_type]);

    let tplConfig_inActiveSpace = (
      getVal(tplConfig, activeSpace.valuePath) || [] 
      //sometimes the block config hasnt even been defined yet. so this empty array fallback ensures the page doesnt break
    )

    let blockPath_untilPageBlocks = `${activeSpace.valuePath}${
      activePageIdx !== undefined ? `.${activePageIdx}.blocks` : ""
    }`;


    let activePageConfig = activePageIdx !== undefined //cuz it can be 0
      ? tplConfig_inActiveSpace[activePageIdx]?.blocks
      : tplConfig_inActiveSpace;

    if(!activePageConfig) return null;

    return (
      <Fragment>
        <ContentBlocksWrapper>
            {drilldownSection({
              blocks: activePageConfig,
              // cuz this is the 'else' scenario wherein, there are no pages
              blockPath_untilDivisionBlocks: blockPath_untilPageBlocks,
              injectCustomUI,
              SectionWrapperComp,
              activeTab,
              handleDrag,
              draggableWrapper,
              indexList,
              content,
              enableSectionDisplayConditions,
              blockSectionIdPrefix,
              tpl: tpl,
              callback: (
                block,
                blockPath_fromDivisionBlocks_untilThisBlockOrSection,
                sectionId,
                isLastBlockInSection
              ) => {
                return blockGenerator({
                  block,
                  blockPath: `${blockPath_untilPageBlocks}.${blockPath_fromDivisionBlocks_untilThisBlockOrSection}`,
                  blockLocation: {
                    // something about space should be passed in?,
                    page: activePageIdx !== undefined 
                      ? tplConfig_inActiveSpace[activePageIdx].pageId 
                      : undefined,
                    division: undefined,
                    sectionId,
                    isLastBlockInSection
                  }
                });
              },
            })}
            {injectCustomUI &&
              injectCustomUI({
                /** this is equal to what the idx of this block will be once it is added to tplConfig */
                blockPath: `${blockPath_untilPageBlocks}.${activePageConfig.length}`,
              })}
          </ContentBlocksWrapper>
      </Fragment>
    );
  };