import { motion } from "framer-motion";
import styled from "styled-components";
export const SidebarContainerStyled = styled(motion.div)`
width: 35rem;
padding: 2rem 1rem;
padding-top: 6.5rem;
background-color: white;
position: fixed;
right: 0;
top: 0;
bottom: 0;
box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
background-color: white;
z-index: 100;
`;

export const SidebarWrapperStyled = styled.div`
display: flex;
flex-direction: column;
gap: 1rem;
overscroll-behavior: contain;
overflow-y: scroll;
height: 100%;
scrollbar-width: none;
`;


export const CardWrapperStyled = styled.div`
  background: #ffffff;
  border: 1px solid #ebebeb;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  height: fit-content;

  border-radius: 2px;
  z-index: 1000;
`;
