import { SANS_2, useBannerContext } from "oolib";
import React, { useEffect } from "react";
import { useGetDataQuery } from "../../../../../utils/react-query-hooks/getData";
import { __GetContentTypeConfigNew } from "../../../../../utils/getters/gettersV2";
import CardsSlider from "../CardsSlider";

const formatData = (data) => {
  const output = [];

  for (let i in data?.taggedResources) {
    for (let d of data.taggedResources[i]?.data) {
      if (d?._id?.kp_published_status === "published") {
        output.push(d._id);
      }
    }
  }

  return output;
};

const getTagTypeAndParticipantId = (tagsObj) => {
  const tagType = tagsObj["pKenya"]
    ? "pKenya"
    : tagsObj["pNigeria"]
    ? "pNigeria"
    : tagsObj["pSenegal"]
    ? "pSenegal"
    : tagsObj["pIndia"]
    ? "pIndia"
    : undefined; // hacky

  if (!tagType) return {};

  const participantIds = tagsObj[tagType].data.map((d) => d._id);

  return { tagType, participantIds };
};

export default function DisplayLinksToOtherImmersion({ content }) {
  const { tagType, participantIds } = getTagTypeAndParticipantId(content.tags);

  const { SET_INFO_BANNER, REMOVE_INFO_BANNER } = useBannerContext();
  const { status, data } = useGetDataQuery({
    contentTypes: [tagType],
    findQuery: { _id: { $in: participantIds } },
    projection: { taggedResources: 1, "tags.participantTypes": 1 },
    populateTaggedResources: {
      populate: true,
      $project: { "meta.kp_content_type": 1, kp_published_status: 1 },
    },
  });

  const formattedData = status === "success" ? formatData(data.data[0]) : [];

  console.log({thisOne:data})

  useEffect(() => {
    let bannerId;
    if (status === "success" && tagType) {
      if (
        data.data[0]?.tags?.participantTypes.data[0].tagId === "influencer" ||
        data.data[0]?.tags?.participantTypes.data[0].tagId === "enabler"
      ) {
        bannerId = SET_INFO_BANNER(
          "Influencers and enablers don’t have prioritized attributes or determinants as this co-creation activity was only done with WRAs.",
          "grey"
        );
      }
    }

    return () => {
     bannerId && REMOVE_INFO_BANNER(null, bannerId);
    };
  }, [status]);


  if(!tagType) return null

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {status === "loading" ? (
        <SANS_2>Loading...</SANS_2>
      ) : status === "error" ? (
        <SANS_2>Something went wrong!</SANS_2>
      ) : formattedData.length > 0 ? (
        <CardsSlider data={formattedData} />
      ) : (
        <SANS_2>No Data</SANS_2>
      )}
    </div>
  );
}
