import { TabBarV2 } from "oolib";
import React from "react";
import { StyledInnerWrapper, StyledTabsPanelWrapper } from "./styled";

export const TabsPanel = ({
  options,
  onChange,
  value,
  tabsRef,
  isMobile,
  showSideBarLeft,
  stickyTitleHeight,
  style = {},
}) => {
  console.log({valllll: value})
  return (
    <StyledTabsPanelWrapper
      ref={tabsRef}
      isMobile={isMobile}
      sidebar={showSideBarLeft}
      stickyTitleHeight={stickyTitleHeight}
      style={{
        ...style,
      }}
    >
      <StyledInnerWrapper>
      <TabBarV2
        options={options}
        onChange={(id, v) => onChange(id, v)}
        value={value}
        saveValueAsString
        style={{ borderRadius: "8px" }}
        responsive
        title={"page"}
      />
      </StyledInnerWrapper>
    </StyledTabsPanelWrapper>
  );
};
