import {
  Tooltip,
  UI_BODY_BOLD_SM,
  UI_BODY_SEMIBOLD_SM,
  UI_BODY_SM,
  colors2,
} from "oolib";
import React from "react";
import { TagClear } from "oolib/dist/v2/components/Tags"; 
import { CaretRight, LinkSimple } from "phosphor-react";
import {
  StyledFilterAccordionHeader,
  StyledFilterAccordionTitleWrapper,
  StyledSelectedValuesWrapper,
} from "../styled";

export const AccordionHeader = ({
  title,
  value,
  expand,
  onToggle,
  onChange,
  id,
  filterConfig,
}) => {
  const TITLE_TYPO = value?.length ? UI_BODY_SEMIBOLD_SM : UI_BODY_SM;

  return (
    <StyledFilterAccordionHeader
      expand={expand}
      value={value}
      onClick={onToggle}
    >
      <StyledFilterAccordionTitleWrapper>
        <div style={{display: 'flex', alignItems: 'center', gap: '0.4rem'}}>
          {["nestedRollupTagType", "rollupValuePathType"].includes(
            filterConfig.target.filterType
          ) && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: '0.2rem',
                backgroundColor: colors2.grey10,
                borderRadius: "0.4rem",
              }}
            >
              {filterConfig.tooltip ? <Tooltip text={filterConfig.tooltip} position="right">
                <LinkSimple size={14} />
              </Tooltip> : <LinkSimple size={14} />}
            </div>
          )}
          <TITLE_TYPO semibold>{title}</TITLE_TYPO>
        </div>
        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          {value?.length ? (
            <UI_BODY_BOLD_SM>{value.length}</UI_BODY_BOLD_SM>
          ) : null}
          <CaretRight size={16} color={colors2.grey40} />
        </div>
      </StyledFilterAccordionTitleWrapper>

      {value?.length > 0 && (
        <StyledSelectedValuesWrapper>
          {value.map((v) => (
            <TagClear
              key={v.value}
              grey
              id={v.value}
              display={v.display}
              XS
              onClick={() =>
                onChange(
                  id,
                  value.filter((d) => d.value !== v.value)
                )
              }
            />
          ))}
        </StyledSelectedValuesWrapper>
      )}
    </StyledFilterAccordionHeader>
  );
};
