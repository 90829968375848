import React from "react";
import { useManageCollectionPageTabs } from "./utils/useManageCollectionPageTabs.js";
import { prepareCoverConfig } from "./utils/prepareCoverConfig.js";
import { prepareBodyConfig } from "./utils/prepareBodyConfig.js";
import { ReadOnlyLayout } from "./comps/ReadOnlyLayout/index.js";
import { EditLayout } from "./comps/EditLayout/index.js";


const Layout = ({
  tpl,
  content,
  readOnly: readOnlyProp,
  memo,
  handleOnChange,
  activeTab: activeTabFromParent,
  setActiveTab: setActiveTabInParent,
}) => {
  const subSpaces = tpl.kp_templates.body.subSpaces.filter(
    (ss) => ss.subSpaceHiddenInTCI !== true
  );

  const { tabOps, activeTab, setActiveTab } = useManageCollectionPageTabs({
    subSpaces,
    readOnlyProp,
    content,
    activeTabFromParent,
    setActiveTabInParent,
  });

  const coverConfig = prepareCoverConfig({tpl, content, configs: tpl.kp_templates.intro.configs });
  const bodyConfig = prepareBodyConfig(subSpaces, content, memo);

  return (
    <>
      {readOnlyProp ? (
        <ReadOnlyLayout
          content={content}
          coverConfig={coverConfig}
          bodyConfig={bodyConfig}
          tabOps={tabOps}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          collectionId={content._id}
        />
      ) : (
        <EditLayout
          tpl={tpl}
          content={content}
          memo={memo}
          handleOnChange={handleOnChange}
        />
      )}
    </>
  );
};

export default Layout;