import React, { Fragment, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {
    CheckboxInput,
    ButtonSecondaryCompact,
    TextLoader,
    UI_BODY_SEMIBOLD,
    UI_BODY_SEMIBOLD_SM,
    colors2,
    useScreenWidth,
    useScroll,
    DateRangePicker
} from "oolib";
import { TextInput } from "oolib/dist/v2/components/TextInputs"; // temp import
import { FilterActionsButton } from "../../../discovery/FilterModule/comps/FilterActionsButton";
import { 
    StyledActionsArea, 
    StyledFiltersWrapper, 
    StyledOptionsLightboxWrapper, 
    StyledOptionWrapper, 
    StyledSelectClearButtonsWrapper, 
    StyledSelectClearInnerWrapper, 
} from "../styled";
import { ButtonGhost } from "oolib/dist/v2/components/Buttons";

const LightboxBreakpointPX = 700;

const getLightBoxHeight = (asideFilter) => {
  const bounds = asideFilter.current?.getBoundingClientRect();

  const topOfSidebarToBottomOfScreen = window.innerHeight - bounds.top - 50;
  const topOfSiderbarToBottomOfSidebar = bounds.bottom - bounds.top - 50;

  return Math.min(topOfSidebarToBottomOfScreen, topOfSiderbarToBottomOfSidebar);
}

const MultiSelectFilterTypes = ({id, options, onChange, value}) => {

  const handleOptionClick = (option) => {
    let newValue = [...value];
    let isSelected = newValue.some((d) => d.value === option.value);
    if (!isSelected) {
      newValue.push(option);
    } else {
      newValue = newValue.filter((d) => d.value !== option.value);
    }

    onChange(id, newValue);
};

  return (
    <Fragment>
      {options?.map((d) => (
          <StyledOptionWrapper
            key={d.value}
            onClick={() => handleOptionClick(d)}
          >
            {d.loading ? (
              <TextLoader style={{ width: "8rem", height: "1.5rem" }} />
            ) : (
              <CheckboxInput option={d} value={value} />
            )}
          </StyledOptionWrapper>
        ))}
    </Fragment>
  )
}

const DateRangeType = ({id, onChange, value}) => {
const [val, setVal] = useState(value);
const handleChange = (k, v) => {
  setVal(v)
  if(Array.isArray(v) && v.length === 2 && v.every(vv => !!vv === true)){
    onChange(id, v)
  }
}
  return (
    <DateRangePicker
      onChange={handleChange}
      value={val}
      id={id}
      />
  )
}

const LightboxComp = ({id, comp, props, onChange, value}) => {

  const compsLookup = {
    DateRangeType,
    MultiSelectFilterTypes
  }

  const Comp = compsLookup[comp]

  return (
<Comp
  id={id}
  onChange={onChange}
  value={value}
  {...props}
  />
  )
}

export const OptionsLightbox = ({
    ref_optionsLightboxWrapper,
    ref_sideFilterHeader,
    ref_asideFilter,
    lightboxConfig,
    onChange,
    value,
    title,
    id,
    setExpand
  }) => {
    
    const screenWidth = useScreenWidth();
    const actionsAreaRef = useRef();
    
    // const [ search, setSearch ] = useState("");
    
 

  const getBoundsOfSideFilterHeader = () => ref_sideFilterHeader?.current?.getBoundingClientRect();

  const [ optionsLightboxTop, setOptionsLightboxTop ] = useState(getBoundsOfSideFilterHeader()?.bottom);
  
  useScroll(() => {
    setOptionsLightboxTop(getBoundsOfSideFilterHeader()?.bottom);
  });

  const isMobile = screenWidth < LightboxBreakpointPX;
  const lightBoxHeight = getLightBoxHeight(ref_asideFilter);
  
  const desktopFiltersWrapperHeight = lightBoxHeight - (actionsAreaRef.current?.getBoundingClientRect().height || 0);
  const optionsLightboxWidth = isMobile ? 300 : 220;

  return createPortal(
    <StyledOptionsLightboxWrapper
      ref={ref_optionsLightboxWrapper}
      width={optionsLightboxWidth + 'px'}
      isMobile={isMobile}
      height={lightBoxHeight + 'px'}
      optionsLightboxTop={isMobile ? 0 : optionsLightboxTop}
      optionsLightboxLeft={isMobile ? 0 : ref_asideFilter.current.offsetWidth + 4}
    >

      {/* Title area mobile */}
      {isMobile && 
        <div style={{borderBottom: `1px solid ${colors2.grey10}`,}}>
          <div style={{ padding: '1.2rem 1.4rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
            <UI_BODY_SEMIBOLD>{title}</UI_BODY_SEMIBOLD>
            <ButtonSecondaryCompact onClick={() => setExpand(false)} value={"Close"}/>
          </div>
        </div>
      }

      {/* Actions area, Search, sort, clear, select all */}
      <StyledActionsArea ref={actionsAreaRef}>
        {!isMobile && <UI_BODY_SEMIBOLD_SM style={{ padding: "0.9rem 1rem", borderBottom: `1px solid ${colors2.grey10}` }}>{title}</UI_BODY_SEMIBOLD_SM>}
        {/* <TextInput 
          value={search}
          onChange={(id, v) => setSearch(v)}
          placeholder={"Search"} 
          icon="MagnifyingGlass"
          /> */}
        {lightboxConfig?.comp === 'MultiSelectFilterTypes' && <StyledSelectClearButtonsWrapper>
          <StyledSelectClearInnerWrapper>
            <ButtonGhost
              disabled={lightboxConfig.props?.options?.length === value.length}
              color={colors2.grey80}
              icon="CheckCircle"
              iconWeight="bold"
              iconColor={colors2.grey80}
              onClick={() => onChange(id, lightboxConfig.props?.options)}
              responsive={true}
              style={{
                paddingLeft: isMobile ? "12px" : "7px",
                paddingRight: "10px",
                paddingTop: isMobile ? "12px" : "9px",
                paddingBottom: isMobile ? "12px" : "9px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              Select All
            </ButtonGhost>
            <ButtonGhost
              disabled={value.length === 0}
              color={colors2.grey80}
              icon="XCircle"
              iconWeight="bold"
              iconColor={colors2.grey80}
              onClick={() => onChange(id, [])}
              responsive={true}
              style={{
                paddingLeft: isMobile ? "12px" : "7px",
                paddingRight: "10px",
                paddingTop: isMobile ? "12px" : "9px",
                paddingBottom: isMobile ? "12px" : "9px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              Clear All
            </ButtonGhost>
          </StyledSelectClearInnerWrapper>
        </StyledSelectClearButtonsWrapper>}
      </StyledActionsArea>
      

      {/* Filter Options area */}
      <StyledFiltersWrapper style={{ maxHeight: isMobile ? '100svh' : desktopFiltersWrapperHeight }}>
        <LightboxComp
          id={id}
          onChange={onChange}
          value={value}
          comp={lightboxConfig.comp}
          props={lightboxConfig.props}
          />
      </StyledFiltersWrapper>

    </StyledOptionsLightboxWrapper>,
    document.body
  );
};