import {
  Container,
  Divider,
  PaddingBottom10,
  PaddingTop10,
  PaddingTopBottom10,
  Section,
  Wrapper700,
  colors
} from "oolib";
import React from "react";
import Tags__Intro from "../../../../../../../../components/generalUI/Tags__Intro";
import TplMetaBlock from "../../../../../../../../components/generalUI/TplMetaBlock";
import { MetaPrimary } from "../../../../../../../../components/generalUI/V2_MetaComps/MetaPrimary";
import { useAppSettingsContext } from "../../../../../../../../contexts/appSettingsContext";

export const Cover = ({
  pageCoverRef,
  generateBlock,
  tpl,
  content,
  readOnly,
  align = 'center', //alt 'left'
  customComp_afterTagsIntro
}) => {

  const invert = readOnly ? true : false //invert only in readOnly state
  const { APP_SETTINGS } = useAppSettingsContext();
  const injectInvertIntoBlock = (block) => ({
    ...block,
    props: {
      ...block.props,
      invert
    }
  })

  const injectAlignmentLeftIntoBlock = block => align === 'center' ? block : ({
    ...block,
    props: {
      ...block.props,
      textAlignment: 'left'
    }
  })

  const conditionsToHideCoverInEditMode = (
    !tpl?.kp_templates?.intro?.configs?.metaPrimary?.props &&
    !tpl.kp_templates.intro?.configs?.inputs?.title &&
    !tpl.kp_templates.intro?.configs?.inputs?.subtitle &&
    !tpl.kp_templates.intro?.configs?.tagsIntro?.props &&
    !tpl.kp_templates.intro?.configs?.inputs?.coverComponent 
  )

  const conditionsToHideCoverInReadOnlyMode = (
    conditionsToHideCoverInEditMode &&
    !tpl.kp_templates.intro?.configs.tplMetaBlock
  ) 

  if(!readOnly && conditionsToHideCoverInEditMode) return null;
  if(readOnly && conditionsToHideCoverInReadOnlyMode) return null;
  

  return (
    <>
    <Section style={{ 
      padding: "3rem 0 2rem 0",
      backgroundColor: invert ? colors.greyColor100 : colors.greyColor3
      }}
      key={APP_SETTINGS.lang?.value}
      >
      <div ref={pageCoverRef}>
        <Container>
          <Wrapper700>
            {tpl?.kp_templates?.intro?.configs?.metaPrimary?.props && (
              <MetaPrimary
                {...tpl.kp_templates.intro.configs.metaPrimary.props}
                content={content}
                invert={invert}
                align={align}
              />
            )}
            {tpl.kp_templates.intro?.configs?.inputs?.title && (
              <PaddingTopBottom10>
                {generateBlock({
                  block: injectAlignmentLeftIntoBlock(
                    injectInvertIntoBlock(tpl.kp_templates.intro.configs.inputs.title)
                  ),
                })}
              </PaddingTopBottom10>
            )}
            {tpl.kp_templates.intro?.configs?.inputs?.subtitle && (
              <PaddingBottom10>
                {generateBlock({
                  block: injectAlignmentLeftIntoBlock(
                    injectInvertIntoBlock(tpl.kp_templates.intro.configs.inputs.subtitle)
                  ),
                })}
              </PaddingBottom10>
            )}

            {tpl.kp_templates.intro?.configs?.tagsIntro?.props && (
              <Tags__Intro
                {...tpl.kp_templates.intro.configs.tagsIntro.props}
                content={content}
                invert={invert}
                Wrapper={PaddingTopBottom10}
              />
            )}
            {customComp_afterTagsIntro}
            {tpl.kp_templates.intro?.configs?.inputs?.coverComponent && (
              <PaddingTopBottom10>
                {generateBlock({
                  block: injectInvertIntoBlock(tpl.kp_templates.intro.configs.inputs.coverComponent),
                })}
              </PaddingTopBottom10>
            )}
            {tpl.kp_templates.intro?.configs.tplMetaBlock && readOnly && (
              <PaddingTop10>
                <TplMetaBlock
                  {...tpl.kp_templates.intro.configs.tplMetaBlock?.props}
                  align={align}
                  showSeparator={true}
                  content={content}
                />
              </PaddingTop10>
            )}
          </Wrapper700>
        </Container>
      </div>
      
    </Section>
    {invert === false && <Divider/> }
    </>
  );
};
