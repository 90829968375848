import { useEffect, useMemo } from "react";
import { useSearchParamsState } from "../../../../../utils/customHooks";
import { parseSpecialSyntax } from "../../../../../utils/parseSpecialSyntax";
import { blockIsPopulatedConditions } from "../../../../../utils/validation/blockIsPopulatedConditions";
import { getVal } from 'oolib';



export const useManageCollectionPageTabs = ({subSpaces, readOnlyProp, content, activeTabFromParent, setActiveTabInParent}) => {
  const tabOps = useMemo(() =>
    getTabOps({ readOnly: readOnlyProp, subSpaces, content })
  );

  const [activeTab, _setActiveTab] = useSearchParamsState({
    key: "activeTab",
    value: tabOps[0]?.value,
  });

  const setActiveTab = (v) => {
    _setActiveTab(v);
    console.log("in here", setActiveTabInParent);
    if (setActiveTabInParent) setActiveTabInParent(v);
  };
  useEffect(() => {
    if (activeTabFromParent && activeTabFromParent !== activeTab)
      setActiveTab(activeTabFromParent);
  }, [activeTabFromParent]);

  return ({
    tabOps,
    activeTab,
    setActiveTab
  })
}


export const recurs = ({ary, cb}) => {
  ary.map(item => {
    if(!item.blocks){
      cb(item)
    }else{
      recurs({ary: item.blocks, cb})
    }
  })
}

export const getTabOps = ({readOnly, subSpaces, content}) => {
  
  const TCIIsActive = sessionStorage.getItem("TCIIsActive") === "true"

  let tabs = subSpaces.map((d) => {

    //1/ if !readOnly dont show the tabs with anything but formbuilder and metabuilder
    if(
      !readOnly && 
      ['FormBuilder'].indexOf(d.builder) === -1 &&
      !TCIIsActive 
    ) return undefined

    //2/ if readOnly, formbuilder tab then, recursively find blocks and check if populated. if none populated, then dont show tab
    if(readOnly && ['FormBuilder'].indexOf(d.builder) !== -1){
      let populatedBlocks = 0;
      recurs({
        ary: d.configs,
        cb: (block) => {
          let value = getVal(content, block.valuePath);
          if(blockIsPopulatedConditions(block, value)){
            populatedBlocks = populatedBlocks+1
          }
        }
      })
      if(populatedBlocks === 0) return undefined;
    }
    
    //3/ else
    return ({
      display: d.display ? (parseSpecialSyntax({string: d.display}) || d.display) : d.display,
      value: d.value,
    })


  })
  
  return tabs.filter(Boolean); ;

}

