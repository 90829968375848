import React from "react"
import { UI_BODY_SEMIBOLD, UI_HEADLINE_SM, VideoInput, icons, colors2, UI_BODY_SM, UI_BODY_SEMIBOLD_SM, ImagePlaceholder  } from "oolib";
import { StyledCoverSection, StyledCTA, StyledDescSection, StyledImage, StyledLeftSection, StyledMediaSection, StyledMediaSectionPlaceholder, StyledMetaSection, StyledTPLActionsWrapper } from "./styled";
import { MetaPrimary } from "../../../../../../components/generalUI/V2_MetaComps/MetaPrimary";
import TplActions from "../../../../../../components/generalUI/TplActions";
import Tags__Intro from "../../../../../../components/generalUI/Tags__Intro";
import { useTheme } from "styled-components";

const { Plus } = icons;
const { grey50, grey80, secondaryContainer } = colors2;

export const MemoPlaceholder  = React.memo(({ title }) => <ImagePlaceholder title={title} strechToFullHeight/>); 

export const DesktopLayout = ({ content, title, desc, btn, coverImage,  coverVideo, metaPrimary, tagsIntro, isMobile, showSideBarLeft, coverExists }) => {
  const theme = useTheme()

  return(
    <StyledCoverSection
      sidebar={showSideBarLeft} 
      isMobile={isMobile}
      style={{ padding: (title || desc) && "2rem" }}
    >  

      <StyledLeftSection>
        <StyledMetaSection>
          <div>
            {metaPrimary && (
                <div style={{ width: '100%' }}>
                  <MetaPrimary 
                    {...metaPrimary} 
                    content={content}
                    align={'left'}
                    />
                </div>   
            )}


            {title && <UI_HEADLINE_SM color={grey80}>{title}</UI_HEADLINE_SM>}
          </div>

          {tagsIntro ? (
              <Tags__Intro
              {...tagsIntro}
              content={content}
              align={'left'}
              style={{ padding: 0 }}
              />
            ) : null}

          

          {content?.meta && ( // positioned absolute at top right of Cover Section
              <StyledTPLActionsWrapper>
                <TplActions content={content} kebabStyle tplActionsIcon="DotsThree"/>
              </StyledTPLActionsWrapper>
          )}
        </StyledMetaSection>
        
      
        {desc && (
          <StyledDescSection>
              <UI_BODY_SEMIBOLD_SM color={grey80}>{'Description'}</UI_BODY_SEMIBOLD_SM>
              <UI_BODY_SM style={{ alignSelf: "flex-start", color: grey50 }}>{desc}</UI_BODY_SM>
          </StyledDescSection>
        )}
        
        {(btn?.action && btn?.text) && (
          <div id="coverCTA"> {/*important for grid layout to work, so the button take entire space*/}
          <StyledCTA onClick={() => btn.action()} desc={desc} coverExists={coverExists} theme={theme}>
              <Plus size={18} weight="bold" style={{ marginTop: "0.1rem" }}/>
              <UI_BODY_SEMIBOLD>
              {btn.text || "Publish"}
              </UI_BODY_SEMIBOLD>
          </StyledCTA>
          </div>
        )}
      
      </StyledLeftSection>

      <> 
        { 
          coverVideo?.[0]?.publicUrl ?  <StyledMediaSection><VideoInput value={coverVideo} readOnly={true}/></StyledMediaSection> 
          : 
          coverImage?.[0]?.publicUrl ?  <StyledMediaSection>
              <StyledImage src={coverImage?.[0]?.publicUrl}/>
              </StyledMediaSection>
          : coverExists &&
          <StyledMediaSectionPlaceholder>
              <MemoPlaceholder title={title}/>
          </StyledMediaSectionPlaceholder> 
        }
      </>

    </StyledCoverSection>
    )
}