import { colors2, getVal, MetaBlockV2, UI_CAPTION, UI_CAPTION_SEMIBOLD, UI_TAG } from "oolib";
import React, { useRef } from "react";
import LexicalTextEditor from "../../../../inputs/TextEditor/LexicalTextEditor";
import { uiContent } from "../../uiContent";
import { StyledCardShell, StyledMetaBlockWrapper } from "./styled";
import { useGetContribution } from "../../../../../utils/react-query-hooks/contributions";
import { TagSectionOnCard } from "./comps/TagSectionOnCard";

// const { IndiaFlag } = icons;

// const flagsMap = {
//   "india" : IndiaFlag
// }

export const CardAnnoExplorer = ({ singleAnno, lastCardObserverRef }) => {
  // const FlagIcon = flagsMap[singleAnno.docTags.country.data[0].tagId]  
  const annoCardRef = useRef();

  return (
    <a
      target="_blank"
      href={`/published-page/${singleAnno.meta.kp_content_type}?id=${singleAnno._id}`}
      ref={annoCardRef}
    >
     
      <StyledCardShell
        key={singleAnno._id + singleAnno.annoKey}
        // className={singleAnnoI === thisAry.length - 1 && 'has ref'}
        ref={lastCardObserverRef}
      >
        <div style={{ padding: "1.6rem", paddingBottom: "0" }}>
          
          {/* Content type title */}
          <div style={{ height: '1.8rem', display: 'flex', justifyContent: "space-between" }}> {/* Temp height as there is no icon */}
            <UI_TAG style={{ color: colors2.grey60 }}>{getVal(singleAnno, "meta.contentTypeTitle")}</UI_TAG>
            {/* {FlagIcon && <FlagIcon size={20} /> } */}
          </div>

          {/* Single anno lex */}
          <div style={{ paddingBottom: "1.3rem", color: colors2.grey80 }}>
            <LexicalTextEditor style={{ color: colors2.grey80 }} value={singleAnno.fragment} readOnly disableAnnoColors={true} annotation={false}/>
          </div>
          
          {/* title */}
          <div style={{ paddingBottom: "1.3rem" }}>
            <UI_CAPTION_SEMIBOLD style={{ color: colors2.grey60 }}>{singleAnno.main.title}</UI_CAPTION_SEMIBOLD>
          </div>

          {/* Meta Block */}
          <StyledMetaBlockWrapper>
            {/* <MetaBlockV2 
              data={{ "date": singleAnno.kp_date_published, "name": singleAnno.meta?.kp_contributed_by}}
              config={[
                { key: "date", valuePath: "date"},
                { key: "name", valuePath: "name"}
              ]}
            /> */}
            <UI_CAPTION>{(new Date(singleAnno.kp_date_published)).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(',', '')}</UI_CAPTION>
            
            {/* seperator */}
            <div style={{  width: '0.3rem', height: '0.3rem', borderRadius: '50%', background: colors2.grey60 }}/>
            
              <UI_CAPTION>{singleAnno?.meta?.kp_contributed_by?.name}</UI_CAPTION>
          </StyledMetaBlockWrapper>

        </div>

        <div
          style={{
            padding: "0rem 1.6rem 1.6rem",
            display: "grid",
            gridTemplateColumns: "1fr",
            gap: "1rem",
            maxWidth: '100%'
          }}
        >
          <TagSectionOnCard
            {...{
              annoTags: singleAnno.annoTags,
              annoTagsTitle: uiContent.annoTagsTitle,
              docTags: singleAnno.docTags,
              docTagsTitle: uiContent.docTagsTitle,
              cardWidth: annoCardRef?.current?.getBoundingClientRect().width,
            }}
            />
        </div>
      </StyledCardShell>
    </a>
  );
};
