import React, { useEffect, useMemo } from "react";
import {OverflowNode} from '@lexical/overflow'
import {MarkNode} from '@lexical/mark'
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";

import {TablePlugin} from "@lexical/react/LexicalTablePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { CodeHighlightNode, CodeNode } from "@lexical/code";

import { BlockLabel, getBlockLabelProps } from "oolib";
import { useState } from "react";
import { _Locale } from "../../../locale/Locale";
import { ToolbarPlugin } from "./plugins/ToolbarPlugin";
import { StyledEditorWrapperLex, StyledPlaceholderLex } from "./styled";

import { ImageInputNode } from "./nodes/ImageInputNode";
import { AudioInputNode } from "./nodes/AudioInputNode";
import { AutoLinkPlugin } from "./plugins/AutoLinkPlugin";
import { LinkPlugin } from "./plugins/LinkPlugin";
import { CharacterLimitPlugin }  from "./plugins/CharacterLimitPlugin";
import { ListItemNode, ListNode } from "@lexical/list";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import {
  codeHighlightThemeConfig,
  codeThemeConfig,
  textCodeThemeConfig,
} from "./themes/code";
import {
  tableThemeConfig
} from './themes/table'
import { annoThemeConfig } from "./themes/anno";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import { ExternalLinkEmbedNode } from "./nodes/ExternalLinkEmbedNode";
import { InternalLinkEmbedNode } from "./nodes/InternalLinkEmbedNode";
import { LinkToEmbedPlugin } from "./plugins/LinkToEmbedPlugin";
import { VideoInputNode } from "./nodes/VideoInputNode";
import TableActionMenuPlugin from "./plugins/TableActionMenuPlugin";
// import TableCellResizerPlugin from "./plugins/TableCellResizerPlugin";
import { AnnoPlugin } from "./plugins/AnnoPlugin";
import { AnnoContextProviderLex } from "./plugins/AnnoPlugin/context";
import { OnChangePlugin } from "./plugins/OnChangePlugin";
import { isEqual } from "lodash";
import { reshapeDraftJSToLex_variantSimple } from "./utils/backwardsCompatibility/reshapeDraftJSToLex";
import { PDFInputNode } from "./nodes/PDFInputNode";
import { IFrameInputNode } from "./nodes/IFrameInputNode";
import MentionsPlugin from "./plugins/MentionsPlugin"; // Import the new plugin
import { MentionNode } from "./nodes/MentionNode"; // Import the MentionNode
import { AnnoSuggestionsPlugin } from "./plugins/AnnoSuggestionsPlugin";

//lexical playground code: https://github.com/facebook/lexical/tree/main/packages/lexical-playground
//codesandbox with most of the playground code (easier to play with): https://codesandbox.io/s/lexical-rich-text-example-5tncvy

/**
 * KNOWN ISSUES:
 * - autolingplugin created links when wrapped in anno, we get this strange double underline thing, cuz the node tree gets a bit messed up
 * 
 * PENDINGS:
 * - figure out how to do isEndToEndSelection in lexical. see inside annolightbox to understand what we talking about.
 * - handle onAnnoClick inside changed elems useEffect above
 * 
 * QUESTIONS:
 * - natively lexical highlights both annotations on an overlap. Should we keep it that way?
 *   if we do, then we have to figure out how to show 2 or more display lightboxes
 */

const fixedThemeLex = {
  // Theme styling goes here
  // ...
  link: "OKELink",
  heading: {
    h1: "CONTENT_H1",
    h2: "CONTENT_H2",
    h3: "CONTENT_H3",
    h4: "CONTENT_BODY",
    h5: "CONTENT_BODY",
    h6: "CONTENT_BODY",
  },
  paragraph: "$typoProp", //$typoProp gets replaced by whatever typo prop is passed into this comp
  quote: "CONTENT_QUOTE",
  list: {
    ol: "orderedListStyleLex",
    ul: "unorderedListStyleLex",
    listitem: "$typoProp listItemStyleLex",
    listitemChecked: "temp_listItemCheckedStyleLex",
    listitemUnchecked: "temp_listItemUncheckedStyleLex",
  },
  //-- anno related --
  ...annoThemeConfig,
  
  //-- anno related --
  text: {
    bold: "temp_bold",
    italic: "temp_italic",
    underline: "temp_underline",
    code: textCodeThemeConfig,
  },
  code: codeThemeConfig,
  codeHighlight: codeHighlightThemeConfig,
  ...tableThemeConfig
};

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error) {
  console.error(error);
}

function LexicalTextEditor({
  id,
  placeholder = "Write Here",
  placeholderColor,
  typo = "CONTENT_BODY",
  invert,
  readOnly,
  onChange: parentOnChange,
  value: _value,
  annotation,
  enableAIAnnotation,
  variant = 'rich', //alt = simple
  charLimit,
  textAlignment,
  enableMentions,
  disableAnnoColors,
  valuePath,
  content
}) {

  // console.log('re-render lex', id)
  // console.log({ readOnly })
  const value = useMemo(() => {
    return variant === 'rich'
      ? _value //PENDING: we need to write a reshaper for rich variant as well. but this will not be straightforward
      : reshapeDraftJSToLex_variantSimple(_value)
  },[])
  
  const props = arguments[0];

  const initialConfig = {
    editorState: value?.editorState ? JSON.stringify(value.editorState) : undefined,
    editable: !readOnly,
    namespace: "MyEditor",
    theme: {
      ...JSON.parse(
        JSON.stringify(fixedThemeLex).replaceAll("$typoProp", typo)
      ),
      RTE_COMP_PROPS: props, //we pass all props into this theme object, because theme is accessible across all child comps via useLexicalComposerContext()[1].getTheme()
    },
    onError,
    nodes: [
      HeadingNode,
      QuoteNode,
      LinkNode,
      AutoLinkNode,
      ListNode,
      ListItemNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,

      AudioInputNode,
      ImageInputNode,
      VideoInputNode,
      PDFInputNode,
      IFrameInputNode,
      ExternalLinkEmbedNode,
      InternalLinkEmbedNode,

      MarkNode,
      OverflowNode,
      MentionNode
      // CustomLinkNode,
      // {
      //   replace: LinkNode,
      //   with: (node) => {
      //     const {__url} = node;
      //     return new CustomLinkNode(__url);
      //   }
      // }
    ],
  };
  

  // in lexical playground this is the method they use to extract the ref
  // to the ContentEditable. So we are doing the same.
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const EditorPlugin = variant === 'rich' ? RichTextPlugin: PlainTextPlugin

  return (
    <div>
      <BlockLabel {...getBlockLabelProps(props)} />
      <AnnoContextProviderLex value={value}>
        <StyledEditorWrapperLex
          {...{
            invert,
            readOnly,
            textAlignment,
            disableAnnoColors,

            //.OKELink specific props
            color: "greyColor100",
          }}
        >
          <LexicalComposer initialConfig={initialConfig}>
            <EditorPlugin
              contentEditable={
                <div
                  style={{
                    position: "relative",
                    zIndex: 1, //otherwise it blocks the ability to focus on the editor when you click on top of placeholder
                  }}
                  ref={onRef}
                >
                  <ContentEditable
                    id="contentEditableLex"
                    className="contentEditableLex"
                  />
                </div>
              }
              placeholder={
                <StyledPlaceholderLex 
                  textAlignment={textAlignment} 
                  placeholderColor={placeholderColor} 
                  className={typo}
                  invert={invert}
                  >
                  {_Locale(placeholder)}
                </StyledPlaceholderLex>
              }
              ErrorBoundary={LexicalErrorBoundary}
            />
            {charLimit !== undefined  && <CharacterLimitPlugin maxLength={parseInt(charLimit)} readOnly={readOnly} />}
            {(variant === 'rich' )&& <ToolbarPlugin invert={invert} annotation={annotation} enableAIAnnotation={enableAIAnnotation}/>}
            <ListPlugin />
            <TablePlugin />
            {floatingAnchorElem && (
              <TableActionMenuPlugin
                anchorElem={floatingAnchorElem}
                cellMerge={true}
              />
            )}
            {/* <TableCellResizerPlugin /> */}
            <CheckListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <LinkToEmbedPlugin />
            {annotation && (
              <AnnoPlugin annotation={annotation} readOnly={readOnly} />
            )}
            {annotation && enableAIAnnotation && (
              <AnnoSuggestionsPlugin
                value={value}
                annotation={annotation}
                fieldValuePath={valuePath}
                _id={content._id}
                contentType={content.meta.kp_content_type}
              />
            )}

            <CodeHighlightPlugin />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            <HistoryPlugin />
            <OnChangePlugin id={id} parentOnChange={parentOnChange} />
            {enableMentions && <MentionsPlugin/>}
          </LexicalComposer>
        </StyledEditorWrapperLex>
      </AnnoContextProviderLex>
    </div>
  );
}

/**
 * basically what we are saying here is,
 * if there is a change in any of the props 
 * APART FROM onChange & value, then re-render this component.
 * Else dont. 
 * Basically the onChange prop cannot change from the first time
 * that it is passed down to lexical editor. If it doesn it causes
 * strange issues like the cursor jumping across instances of lexical 
 * editor.
 * value prop also we have memoized only because lex editor is uncontrolled
 * and its wasteful to rerender it when value prop changes in the parent
 */

export default React.memo(LexicalTextEditor, (prevProps, updatedProps) => {
  //means dont rerender if all props (apart from onChange & value) are the same
  //
  return isEqual(
  {...prevProps, onChange: undefined, value: undefined },
    {...updatedProps, onChange: undefined, value: undefined },
  )
}) 