import {
  Container, MarginLeftRightMinus20, Section,
  TabBarStyle1, Wrapper1500, getBreakPoint, useScreenWidth
} from "oolib";
import React, { useMemo, useRef } from "react";
import { useFormValidationContext } from "../../../../contexts/formValidationContext";
import {
  useSearchParamsState
} from "../../../../utils/customHooks";




import { TableOfContent } from "../../../../components/generalUI/TableOfContent";
import { useCreateIndexList } from "../../../../utils/templateIndex/useCreateIndexList";
import { ContentHeader } from "../../comps/ContentHeader";
import { CustomCompRenderer } from "../../layouts/GroupsStyle1/comps/CustomCompRenderer";
import { FormConfigsRenderer } from "../../layouts/GroupsStyle1/comps/FormConfigsRenderer";

import { getTabOps } from "../GroupsStyle1/utils/useManageCollectionPageTabs";
import { CoverConfigsRenderer } from "../GroupsStyle1/comps/CoverConfigsRenderer";


const Layout = ({
  tpl,
  handleOnChange,
  content,
  readOnly,
  memo,
  showIndexbar,
  indexType
}) => {


  const subSpaces = tpl.kp_templates.body?.subSpaces
    ? tpl.kp_templates.body?.subSpaces.filter(ss => ss.subSpaceHiddenInTCI !== true)
    : undefined
  const tabOps = useMemo(() => {
    return subSpaces ? getTabOps({readOnly, subSpaces, content}) : []
  }, [])

  const [activeTab, setActiveTab] = useSearchParamsState({
    key: "activeTab",
    value: tabOps[0]?.value,
  });

  const { formValidation } = useFormValidationContext();

  //index
  const {
    indexList,
    activeIndexOp,
    setActiveIndexOp
  } = useCreateIndexList({
    tpl,
    content,
    readOnly
  })

  const renderers = {
    FormBuilder: FormConfigsRenderer,
    ListingBuilder : 'div',
    CustomCompInjector: CustomCompRenderer
  };

  const genRenderer = ({space_subSpace}) => {
    /**
           * if it NOT readOnly (edit mode)
           * && builder is NOT FormBuilder, then dont render at all
           * cuz only FormBuilder renders in edit mode
           */
    if(!readOnly && ['FormBuilder'].indexOf(space_subSpace.builder) === -1) return null
          
    const Renderer = renderers[space_subSpace.builder];

    return !Renderer
      ? null
      : <Renderer {...{ 
          configs: space_subSpace.configs, 
          wrapperConfig: space_subSpace.wrapper,
          readOnly,
          content,
          memo,
          handleOnChange,
          indexList
        }}
        />;
  }

  const pageCoverRef = useRef(null);
const screenWidth = useScreenWidth()

const genIndexBar = () => showIndexbar ? (
  <TableOfContent
    type={'anchorLinks'}
    indexType={indexType}
    options={indexList}
    value={activeIndexOp}
    onChange={(k, v) => setActiveIndexOp(v)}
    failedBlocks={
      !readOnly &&
      formValidation.makeVisible &&
      formValidation.failedBlocks
    }
  />
) : null
  return (
    <>
    {
    readOnly && 
    <ContentHeader
      content={content}
      pageCoverRef={pageCoverRef}
      />}
      <div ref={pageCoverRef}>
        <CoverConfigsRenderer
          {...{
            readOnly,
            content,
            tpl,
            memo,
            handleOnChange,
            coverComp: 'KnowledgeResources2Intro1',
            align: 'center'
          }}
        />
      </div>

      {tabOps.length > 0 && (
        <Section borderBottom>
          <Container>
            <Wrapper1500 left>
              <MarginLeftRightMinus20>
                <TabBarStyle1
                  options={tabOps}
                  onChange={(k, v) => setActiveTab(v)}
                  value={activeTab}
                  saveValueAsString
                />
              </MarginLeftRightMinus20>
            </Wrapper1500>
          </Container>
        </Section>
      )}
      <div style={{ position: "relative" }}>
        {screenWidth >= getBreakPoint('xl') && genIndexBar()}

        {subSpaces
          ? subSpaces.map((sub) => {
              if (sub.value === activeTab) {
              return genRenderer({space_subSpace: sub})
              }
            })
        : genRenderer({space_subSpace: tpl.kp_templates.body})
        }
        {
          /** 
           * we need to position it as the last element on small 
           * screen width to make position sticky work. also we are 
           * doing sticky cuz fixed causes issues on tci. but we 
           * dont know yet what sideeffects sticky mioght have 
           * */
        }
        {screenWidth < getBreakPoint('xl') && genIndexBar() } 
      </div>
    </>
  );
};

export default Layout;
