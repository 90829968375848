import { Container, SANS_3 } from "oolib";
import React from "react";
import LexicalTextEditor from "../../../../components/inputs/TextEditor/LexicalTextEditor";
import { ReviewNotesContainerStyled, ReviewNotesWrapperStyled } from "./styled";

export default function ReviewerNotes({
 notes
}) {
  return (
    <ReviewNotesContainerStyled>
      <ReviewNotesWrapperStyled>
        <Container>
          <SANS_3 style={{ marginBottom: "1rem" }} semibold capitalize>
            Reviewer Notes
          </SANS_3>
          <LexicalTextEditor
            value={notes}
            typo="SANS_3"
            variant={"simple"}
            readOnly={true}
          />
        </Container>
      </ReviewNotesWrapperStyled>
    </ReviewNotesContainerStyled>
  );
}
