import { Loader, toArray } from 'oolib'
import React from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { __CheckAccessToProfileType } from '../../../utils/accessControl'
import { useGetQueryState } from '../../../utils/react-query-hooks/general'

const RouteAccessOneProfile = ({ children, ACTION }) => {
  const location = useLocation()
  const { profileType } = useParams()
  const { status, data: userData } = useGetQueryState('userData')

  if (status === 'loading') {
    return <Loader debug={'route access one profile'} />
  }

  const checkAccess = () => {
    // Handle cases where profileType might contain '+'
    const profileTypes = profileType?.split('+') || []

    if (!userData?.user) {
      return {
        has: toArray(profileTypes).some(type =>
          __CheckAccessToProfileType(undefined, ACTION || 'READ', type)
        ),
        redirectTo: '/login'
      }
    }

    return {
      has: toArray(profileTypes).some(type =>
        __CheckAccessToProfileType(userData?.user, ACTION || 'READ', type)
      ),
      redirectTo: '/'
    }
  }

  const access = checkAccess()

  if (!access.has) {
    return (
      <Navigate 
        to={access.redirectTo} 
        state={
          access.redirectTo === '/login' 
            ? { from: location.pathname + location.search }
            : undefined
        }
        replace 
      />
    )
  }

  return children
}

export default RouteAccessOneProfile