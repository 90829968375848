import { useSort_FRONTEND } from "./useSort_FRONTEND";

/**
 *
 * @note hooks cannot be called conditionally,
 * hence we have to deal with front-end and backend-sort
 * in the same hook
 *
 * notes about the logic of the backendSort functionality:
 *
 * - when first api call is made, it needs to know the default sort
 * - then data is received and this comp is rendered and this hook is fired
 * - we need to read the config and display the appropriate sort on the table
 * - we leave data untouched.
 * - when a sort is triggered from the table, this is set to the sort state
 * - in parent.
 * - that will trigger a data update and voila
 *
 */
export const useTableSort = ({
  tableConfig,
  dataProp,
  setBackendActiveSort,
  backendActiveSort,
}) => {
  
  const isBackendSort = !!backendActiveSort;
  
  const { data, setData, activeSorts, setActiveSorts } = useSort_FRONTEND({
    tableConfig,
    dataProp,
    isBackendSort,
  });
  /**
   * the data and activeSorts states are completely ignored if
   * it happens to be backend sort
   */

  const handleTableSort = (thisColConfig, value) => {
    

    !isBackendSort
      ? setActiveSorts(updateActiveSorts(activeSorts, thisColConfig, value)) 
      : setBackendActiveSort((prev) => {
          let sortValuePath = Object.keys(thisColConfig.backendSortConfig.sort)[0]
          return {
            ...prev,
            sort: {
              [sortValuePath]: value // value can be 1 or -1
            },
          };
        }); 
  };

  return {
    data: !isBackendSort ? data : dataProp,
    activeSorts: !isBackendSort && activeSorts,
    handleTableSort,
  };
};



export const updateActiveSorts = (activeSorts, d, value) => {
	let prev = { ...activeSorts }

	let newActiveSorts = {}
	Object.keys(prev).map((prevK) => {
		if (prevK === d.colId) {
			newActiveSorts[prevK] = value
		} else {
			newActiveSorts[prevK] = 'none'
		}
	})

	return newActiveSorts
}