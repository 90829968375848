import { ButtonSecondary, ModalSmall, useBannerContext } from "oolib";
import React, { useEffect, useState } from "react";
import TagsInputSingle from "../components/inputs/DynamicTagsInputs/TagsInputSingle";
import { useQuickUpdateContent } from "../pageTpls/ContributeTpl/queryHooks/useQuickUpdateContent";
import { plugins_UIContent } from "./plugins_UIContent";
import styled from "styled-components";

const StyledModalChildrenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

export const useMarkAsWinnerPlugin = ({ contentType }) => {
  const [showModal, setShowModal] = useState(undefined);
  const [selectedCampaign, setSelectedCampaign] = useState(undefined);

  /**
   * why we need this extra state:
   * If no winner tag is selected for a story by default when the modal is opened,
   * selectedCampaign will have a falsy value, and the "Remove winner tag" button
   * will not be displayed. But then if we select a tag from the dropdown,
   * selectedCampaign will hold a value, and the "Remove winner tag" button will be
   * displayed but we don't have to display in this case.
   */
  const [isTagAlreadyAssigned, setIsTagAlreadyAssigned] = useState(false);

  useEffect(() => {
    if (showModal?.type === "markAsWinner") {
      if (showModal?.data?.tags?.campaigns?.data?.length) {
        setSelectedCampaign(showModal?.data?.tags?.campaigns);
        setIsTagAlreadyAssigned(true);
      } else {
        setSelectedCampaign(undefined);
        setIsTagAlreadyAssigned(false);
      }
    } else if (showModal?.type === "shortlist") {
      if (showModal?.data?.tags?.shortlistPsa?.data?.length) {
        setSelectedCampaign(showModal?.data?.tags?.shortlistPsa);
        setIsTagAlreadyAssigned(true);
      } else {
        setSelectedCampaign(undefined);
        setIsTagAlreadyAssigned(false);
      }
    }
  }, [showModal]);

  const quickUpdateContent = useQuickUpdateContent();
  const { SET_INFO_BANNER } = useBannerContext();

  const Component = showModal && (
    <ModalSmall
      title={plugins_UIContent.gsl.rowActionPlugin[showModal.type].modalTitle}
      onClose={() => setShowModal(undefined)}
      onConfirmText={plugins_UIContent.gsl.rowActionPlugin[showModal.type].modalOnConfirmText}
      showActionPanel
      onConfirm={
        selectedCampaign
          ? () => {
              const key =
                showModal.type === "markAsWinner"
                  ? "tags.campaigns"
                  : showModal.type === "shortlist" && "tags.shortlistPsa";
              quickUpdateContent.mutate(
                {
                  contentType,
                  contentId: showModal.data._id,
                  body: {
                    [key]: selectedCampaign,
                  },
                },
                {
                  onSuccess: () => {
                    setShowModal(undefined);
                    SET_INFO_BANNER(
                      plugins_UIContent.gsl.rowActionPlugin[showModal.type].onSuccessBannerContent,
                      "green",
                      3000
                    );
                  },
                }
              );
            }
          : undefined
      }
    >
      <StyledModalChildrenWrapper>
        <div style={{ width: "100%" }}>
          <TagsInputSingle
            isTagsStyle={false}
            tagType={
              showModal.type === "markAsWinner"
                ? "campaigns"
                : showModal.type === "shortlist" && "shortlistPsa"
            }
            value={selectedCampaign}
            onChange={(k, v) => setSelectedCampaign(v)}
          />
        </div>
        {isTagAlreadyAssigned && (
          <ButtonSecondary
            onClick={() => {

              const body =
                showModal.type === "markAsWinner"
                  ? {
                      "tags.campaigns": {
                        data: [],
                      },
                    }
                  : showModal.type === "shortlist" && {
                      "tags.shortlistPsa": { data: [] },
                    };

              quickUpdateContent.mutate(
                {
                  contentType,
                  contentId: showModal.data._id,
                  body
                },
                {
                  onSuccess: () => {
                    setShowModal(undefined);
                    SET_INFO_BANNER(
                      plugins_UIContent.gsl.rowActionPlugin[showModal.type].removeTagSuccessBannerContent,
                      "green",
                      3000
                    );
                  },
                }
              );
            }}
          >
            {plugins_UIContent.gsl.rowActionPlugin[showModal.type].modalRemoveTagButtonText}
          </ButtonSecondary>
        )}
      </StyledModalChildrenWrapper>
    </ModalSmall>
  );

  const actionsConfig = {
    gsl_markAsWinner: (e, data) => {
      setShowModal({ type: "markAsWinner", data });
    },
    gsl_shortlistForWinner: (e, data) => {
      setShowModal({ type: "shortlist", data });
    },
  };

  const actions = [
    {
      display:
        plugins_UIContent.gsl.rowActionPlugin.shortlist.actionMenuBtnDisplay,
      action: "gsl_shortlistForWinner",
    },
    {
      display: plugins_UIContent.gsl.rowActionPlugin.markAsWinner.actionMenuBtnDisplay,
      action: "gsl_markAsWinner",
    },
  ];

  const injectPluginConfigIntoListingPageConfig = ({
    base_listingPageConfigs,
    activePublishStatusTab,
  }) => {
    return activePublishStatusTab === "inPublish"
      ? {
          ...base_listingPageConfigs,
          listingConfig: {
            ...base_listingPageConfigs.listingConfig,
            ...(actionsConfig ? { actionsConfig } : {}),
            tableConfig: {
              ...base_listingPageConfigs.listingConfig.tableConfig,
              ...(actions ? { actions } : {}),
            },
          },
        }
      : base_listingPageConfigs;
  };

  return {
    actions,
    actionsConfig,
    Component,
    injectPluginConfigIntoListingPageConfig,
  };
};
