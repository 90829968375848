import React from "react";

import { ListContent } from "oolib";





const RelatedContentModule = ({ 
  data : _data, //diff names...
  content, //diff names...
  setShowModuleContainer 
}) => {

  const data = _data || content ///...but only one god

  /**
   * 
   * @param {Object} d 
   * @returns {Boolean}
   * 
   * note that on WQN sometimes, some _ids dnt seem to get populated. and we dont know why..
   */
  const filterOut_unpopulated_and__unpublished = (datum) => {
    let d = datum?._id //since _id is the field that is populated
    return (
      !!d === true &&
      typeof d !== 'string' &&
      ["draft", "awaitingModeration", "underModeration"].indexOf(
        d.kp_published_status
      ) === -1
    )
  }

  const getValidData = taggedResources => {
    return taggedResources &&
      Object.values(data?.taggedResources)
        .map(value => value.data || [])
        .reduce((add, next) => [...add, ...next] ,[] )
        .filter(filterOut_unpopulated_and__unpublished);
  }

   // if no tagged resources exist
   if (
    !data?.taggedResources || getValidData(data?.taggedResources).length === 0
  ){
    setShowModuleContainer && setShowModuleContainer(p => ({...p, relatedContent: false}))
    return null;
  }
    
  

  const genList = () => {
    
    // pull out the data from the populated _id field ( on the backend )
    let resourceData = getValidData(data?.taggedResources)
     
    return (
      <div>
        {resourceData &&
          resourceData.map((datum, i) => {
            let d = datum._id; //_id is the populated field. hence.
            let linkTo = `/published-page/${d.meta.kp_content_type}?id=${d._id}`;
            return (
             
              <ListContent 
                key={i}
                to={linkTo}
                data={d}
                config={{   title: 'main.title',
                            cardLabel: '$thisContentType',
                        }}
              />    
            );
          })}
      </div>
    );
  };

  return genList()

};

export default RelatedContentModule;
