import { ButtonSecondary, Loader, useBannerContext } from "oolib";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import uuid from "uuid";

export default function PdfDownload() {
  const [loading, setLoading] = useState(false);

  const { SET_ALERT_BANNER } = useBannerContext();

  useEffect(() => {
    // Check if the script with the unique ID already exists
    const existingScript = document.getElementById("cdnScript");

    if (!existingScript) {
      // Dynamically create a script element and set its src attribute
      const script = document.createElement("script");
      script.src =
        "https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js"; // Replace with your CDN link
      script.id = "cdnScript"; // Set a unique ID for the script

      script.onload = () => {
        // CDN script has been loaded successfully
        // You can now use the functionality provided by the CDN resource
      };

      // Append the script element to the document's head
      document.head.appendChild(script);
    }
  }, []);

  const handleClick = async () => {
    setLoading(true);

    // html2PDF
    const options = {
      filename: `${uuid.v4()}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 2, allowTaint: true, useCORS: true }, // important for cross origin resopurce ( images )
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      pagebreak: {
        mode: ["avoid-all", "css", "legacy"],
      },
    };

    const elementToConvert = document.getElementById("TPL"); // You can specify the element you want to convert

    try {
      await window.html2pdf().set(options).from(elementToConvert).save();
    } catch (error) {
      SET_ALERT_BANNER(
        "PDF download failed, please try again later",
        "red",
        3000
      );
    }

    setLoading(false);
  };

  return (
    <ButtonSecondary
      disabled={loading}
      icon="DownloadSimple"
      onClick={handleClick}
    >
      {loading ? <Loader S /> : "Download"}
    </ButtonSecondary>
  );
}
