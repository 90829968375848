import React, { useRef, useState, useEffect } from "react";
import {
  ButtonGhost,
  ButtonPrimary,
  colors,
  SANS_2,
  TextInput,
  TagSelect,
  CheckboxInput,
  icons,
  SkeletonLoader
} from "oolib";
import { cloneDeep, isEmpty } from "lodash";

import {
  useCreateTagV3,
  useMultipleGetTagsV3,
} from "../../../../../../../../../utils/react-query-hooks/tags";

import {
  convertMultiValueToTagsValue,
  convertSingleValueToTagsValue,
  convertTagDocsDataToOptions,
  convertTagsValueToMultiValue,
  useGetTypeCategoryAndTags,
} from "../../../../../../../DynamicTagsInputs/utils";

import { __GetContentTypeConfigNew, __GetTagTypeConfig } from "../../../../../../../../../utils/getters/gettersV2";



import {AddTagContainerStyled,AddTagWrapperStyled,ErrorContainerStyled,SubMenuContainerStyled,SubMenuWrapperStyled,SubMenuItemStyled,AddOthersStyled,TagSelectWrapperStyled,MenuContainerStyled,MenuItemStyled,OtherOptionsContainerStyled,Divider,ActionButtonsContainerStyled} from './styled'
import { BadgeNumber } from "../../../../../../../../generalUI/BadgeNumber";

const {
  //   white,
  //   greyColor10,
  //   greyColor100,
    greyColor15,
    lightPink,
    cyan,
  //   red,
  greyColor5,
} = colors;
const { CaretRight, Plus } = icons;
// All styled definitions should be out side to avoid re-creations on each re-render, otherwise re-renders will create random bugs
// why? because we use styled comps as react comps <StyledComp />

// TODO: probably create a new file for styled.js

// //------------------------------------- MAIN MENU STYLED COMPS ----------------------------------------------------

// const MenuContainerStyled = styled.div`
//   background-color: ${white};
//   border-radius: 3px;
//   display: flex;
//   flex-direction: column;
//   border: 1px solid ${greyColor10};
//   min-width: 23.3rem;
// `;

// const MenuItemStyled = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 1rem 1.5rem;
//   gap: 1rem;
//   justify-content: space-between;
//   cursor: pointer;

//   ${({ active }) =>
//     active &&
//     css`
//       background-color: ${({ theme: { colors } }) => colors.primaryColor10};
//     `}

//   :hover {
//     background-color: ${({ theme: { colors } }) => colors.primaryColor10};
//   }
// `;

// const Divider = styled.div`
//   border: 1px solid ${greyColor10};
// `;

const bgColor = {
  quotes: lightPink, // lightPink
  vocabs: cyan, // vocab
};

// const OtherOptionsContainerStyled = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
//   padding: 0 1rem 1rem 1rem;
// `;

// const ActionButtonsContainerStyled = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   gap: 1rem;
// `;


// //------------------------------------- SUB MENU STYLED COMPS ----------------------------------------------------

// const SubMenuWrapperStyled = styled.div`
//   background-color: ${white};
//   border-radius: 3px;
//   display: flex;
//   flex-direction: column;
//   border: 1px solid ${greyColor10};
//   position: absolute;
//   top: 0;
//   transition: all 0.3s ease 0s;
//   width: fit-content;
//   max-width: 33rem;
//   ${({ top }) =>
//     top && top === "auto"
//       ? css`
//           top: ${top};
//         `
//       : css`
//           top: ${top}px;
//         `}
//   ${({ left }) =>
//     left &&
//     css`
//       left: ${left}px;
//     `}
// ${({ right }) =>
//     right &&
//     css`
//       right: ${right}px;
//     `}
// ${({ bottom }) =>
//     bottom &&
//     css`
//       bottom: ${bottom}px;
//     `}

// ${({ visibility }) =>
//     visibility &&
//     css`
//       visibility: ${visibility};
//     `}
// `;

// const SubMenuContainerStyled = styled.div`
//   /* height: 21.6rem; */
//   max-height: 21.6rem;

//   overflow-y: scroll;
//   white-space: nowrap;

//   scrollbar-width: none; /* Firefox */

//   ::-webkit-scrollbar {
//     width: 0px; /* webkit */
//   }
// `;

// const SubMenuItemStyled = styled.div`
//   display: flex;
//   align-items: center;
//   cursor: pointer;
//   padding: 1rem 1.5rem;
//   button {
//     flex-shrink: 0;
//     div {
//       pointer-events: none;
//     }
//   }
// `;

// const AddTagWrapperStyled = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 0.5rem;
//   border-top: 1px solid ${greyColor15};
// `;
// const AddTagContainerStyled = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 0.5rem 1.5rem;
// `;

// const AddOthersStyled = styled.div`
//   display: flex;
//   gap: 1.5rem;
//   padding: 1rem 1.5rem;
//   min-width: 25rem;
//   align-items: center;
//   cursor: pointer;
//   color: ${({ theme: { colors } }) => colors.primaryColorText};
//   :hover {
//     background-color: ${({ theme: { colors } }) => colors.primaryColor10};
//     color: ${greyColor100};
//   }
// `;

// const ErrorContainerStyled = styled.div`
//   color: ${red};
//   display: flex;
//   align-items: center;
//   padding: 0.5rem 1.5rem;
// `;

// const TagSelectWrapperStyled = styled.div`
//   h4 {
//     pointer-events: none;
//   }
// `;

//------------------------------------- SUB MENU COMPONENTS ----------------------------------------------------

const AddTag = ({
  showTagsLightbox,
  createTagApi,
  handleClose,
  value,
  handleAddTag,
  scrollableRef,
}) => {
  const [text, setText] = useState("");
  const [showError, setShowError] = useState(false);
  // const InputRef = useRef()

  console.log("re-render");
  const handleCreateTag = () => {
    createTagApi(
      { type: showTagsLightbox.tagType, display: text, isUserGenerated: true },
      {
        onSuccess: (res) => {
          const d = {
            _id: res.data._id,
            display: res.data.display,
            tagId: res.data.tagId,
          };

          let preVal = value[showTagsLightbox.tagType];

          if (preVal) {
            preVal.data.push(d);
          } else {
            preVal = { collectionId: showTagsLightbox.tagType, data: [d] };
          }

          handleAddTag(showTagsLightbox.tagType, preVal);
          setText("");
          scrollableRef.current.scrollBy({
            top: scrollableRef.current.scrollHeight,
            behavior: "smooth",
          });
        },
        onError: (err) => {
          // if (err.res.response.status === 400) {
          setShowError(true);

          const elem = document.getElementById(err.response.data.data);
          // elem.style.border = `1px solid ${red}`

          // setTimeout(() => {
          //   elem.style.border = ''
          //   setShowError(false)
          // }, 2000)

          // console.log({ thiTop: elem.offsetTop })

          scrollableRef.current.scrollTo({
            top: elem?.offsetTop,
            behavior: "smooth",
          });
          // }

          console.log(err, "create tag error");
        },
      }
    );
  };

  const handleOnChange = (id, value) => {
    setText(value);
  };

  const actionBtn = { text: "Add", onClick: handleCreateTag };
  return (
    <AddTagWrapperStyled>
      <AddTagContainerStyled>
        <SANS_2 semibold>Others, Please specify</SANS_2>
        <ButtonGhost
          S
          icon={"X"}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleClose();
          }}
        ></ButtonGhost>
      </AddTagContainerStyled>
      <div
        /*ref={InputRef}*/ onMouseDown={(e) => {
          /**
           * v.v. important that we stop the propagation
           *
           * because the ancestor TagTypeMenu's wrapper div has a
           * prevent default on its mousedown.
           * this seems to block the focus event from happening on the
           * TextInput and so it doesnt work.\
           *
           * e.stopPropagation (i guess stopping the propagation of TextInput's
           * mouseDown event, allows it to work)
           *
           * still not fully sure why and how this works...
           */
          e.stopPropagation();
        }}
      >
        <TextInput
          value={text}
          onChange={handleOnChange}
          actionBtn={actionBtn}
        />
        {showError && (
          <ErrorContainerStyled>
            <SANS_2>Tag is already present !</SANS_2>
          </ErrorContainerStyled>
        )}
      </div>
    </AddTagWrapperStyled>
  );
};

const TagsSubmenu = ({
  status,
  showTagsLightbox,
  containerRef,
  createTagApi,
  value,
  data,
  onChange,
}) => {
  const subMenuRef = useRef();
  const scrollableRef = useRef();

  const [pos, setPos] = useState({ visibility: "hidden" });
  const [showAddTag, setShowAddTag] = useState(false);

  const {
    right: menuRight,
    height: menuHeight,
    bottom: menuBottom,
  } = containerRef.current.parentElement.getBoundingClientRect();

  useEffect(() => {
    // if(status!=="success") return
    const menuWidth = containerRef.current.offsetWidth;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    const { top, height } = showTagsLightbox;

    const { width: subMenuWidth, height: subMenuHeight } =
      subMenuRef.current.getBoundingClientRect();

    const localPos = {
      left: menuWidth,
      top: top,
      visibility: "visible",
    };

    if (menuRight + subMenuWidth >= screenWidth) {
      localPos.right = localPos.left;
      delete localPos.left;
    }

    const mainMenuHeightNSubmenuHeightDiff = Math.abs(
      subMenuHeight - menuHeight
    );

    // console.log({ mainMenuHeightNSubmenuHeightDiff })

    if (menuBottom + top >= screenHeight) {
      localPos.top = -(
        menuHeight -
        (top + height + mainMenuHeightNSubmenuHeightDiff)
      );
    }

    setPos(localPos);
  }, [showTagsLightbox.tagType, status]);

  const handleOptionClick = (option) => {
    let prev = convertTagsValueToMultiValue(value[showTagsLightbox.tagType]);

    let newValue = prev?.length > 0 ? cloneDeep(prev) : undefined;
    if (newValue) {
      if (newValue.some((dd) => dd.value === option.value)) {
        newValue = newValue.filter((dd) => dd.value !== option.value); //deselect
      } else {
        newValue.push(option); //select
      }
    } else {
      newValue = [option];
    }

    onChange(
      showTagsLightbox.tagType,
      convertMultiValueToTagsValue(showTagsLightbox.tagType, newValue)
    );
  };

  return (
    <SubMenuWrapperStyled {...pos} ref={subMenuRef}>
      <SubMenuContainerStyled ref={scrollableRef}>
        {status === "loading" ? (
          <SkeletonLoader
            style={{
              minWidth:"25rem",
            height:"21.6rem",
            backgroundColor:greyColor5
            }}
            
          />
        ) : status === "error" ? (
          <span>Error</span>
        ) : (
          status === "success" &&
          convertTagDocsDataToOptions(data, "tags").map((d) => {
            return (
              <SubMenuItemStyled
                onMouseDown={(ev) => {
                  ev.preventDefault();
                  /**
                   * stopPropagation prevents this click event from bubbling up to
                   * any of its parents, because of which several strage bugs
                   * get fixed. 1 such being the add tag textinput doesnt
                   * remove the selection from the 'to annotate' text.
                   *
                   * Another being, the svg checkbox doesnt trigget 'outside click'
                   * (for some reason svgs are not read as children of html parents)
                   * which would hide the lightbox.
                   * Not sure why this is. but worth understanding at some point
                   */
                  ev.stopPropagation();
                  handleOptionClick(d);
                }}
                key={d.value}
                id={d.value}
              >
                <CheckboxInput
                  S
                  option={d}
                  value={convertTagsValueToMultiValue(
                    value[showTagsLightbox.tagType]
                  )}
                />
              </SubMenuItemStyled>
            );
          })
        )}
      </SubMenuContainerStyled>

      {status === "success" &&
        showTagsLightbox.allowCreateTag !== false &&
        (showAddTag ? (
          <AddTag
            showTagsLightbox={showTagsLightbox}
            createTagApi={createTagApi}
            handleClose={() => setShowAddTag(false)}
            value={value}
            handleAddTag={onChange}
            scrollableRef={scrollableRef}
          />
        ) : (
          <AddOthersStyled
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowAddTag(true);
            }}
          >
            <Plus weight="bold" size={12} />
            <SANS_2 semibold>Add Others</SANS_2>
          </AddOthersStyled>
        ))}
    </SubMenuWrapperStyled>
  );
};



const TagTypesMenu = ({
  tagTypesConfig,
  showTagsLightbox,
  value,
  toggleTagTypesQueries,
  status,
  data,
  createTagApi,
  onChange,
  containerRef,
  setShowTagsLightbox,
  handleOnConfirm,
  handleOnCancel,
}) => {
  const MainMenuRef = useRef();

  useEffect(() => {
    const headeroffSet = 70;
    const toolbarOffset = 120;

    const { top, bottom } = MainMenuRef.current.getBoundingClientRect();

    if (top - headeroffSet < 0) {
      window.scrollBy({ top: top - headeroffSet, behavior: "smooth" });

      // hacky solution to fix that one issue where the lighbox is not visibile when there's no scroll up space available
      setTimeout(() => {
        const { top } = MainMenuRef.current.getBoundingClientRect();
        if (top < 60) {
          const elem = document.getElementById("input-light-box");
          elem.style.top = `${headeroffSet + 10}px`;
        }
      }, 1000);
    }

    const screenHeight = window.screen.height - toolbarOffset;

    if (bottom > screenHeight) {
      window.scrollBy({ top: bottom - screenHeight, behavior: "smooth" });
    }
  }, [toggleTagTypesQueries.every((q) => q.status === "success")]);

  const handleShowTagsLightbox = (e, thisTagTypeConfig) => {
    e.preventDefault();
    setShowTagsLightbox({
      top: e.currentTarget.offsetTop,
      height: e.currentTarget.offsetHeight,
      ...thisTagTypeConfig, //tagType && //allowCreateTag
    });
  };

  const genToggleTags = () => {

    if(!toggleTagTypesQueries || toggleTagTypesQueries?.length===0) return null

    console.log({toggleTagTypesQueries})

    let dataAry = toggleTagTypesQueries.map((d) => d.data);

    return (
      <div
        style={{
          display: "flex",
          alignContent: "center",
          gap: "1rem",
          border: "none",
        }}
      >
        {dataAry.map((d) => {
          let option = convertTagDocsDataToOptions(d, "tags")[0];
          if(!option) return null;
          let isSelected =  value[option.tagType]?.data?.length > 0;

          return (
            <TagSelectWrapperStyled>
              <TagSelect
                display={option.display}
                isSelected={isSelected}
                style={
                  isSelected
                    ? {
                        backgroundColor: bgColor[option.tagType],
                        border: `2px solid ${bgColor[option.tagType]}`,
                      }
                    : { border: `2px solid ${greyColor15}` }
                }
                onMouseDown={(e) => {
                  console.log({ target: e.target });
                  e.preventDefault();
                  onChange(
                    option.tagType,
                    isSelected
                      ? undefined
                      : convertSingleValueToTagsValue(option.tagType, option)
                  );
                }}
              ></TagSelect>
            </TagSelectWrapperStyled>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <MenuContainerStyled ref={MainMenuRef}>
        {tagTypesConfig.map((d) => {
          const title =
            __GetTagTypeConfig(d.tagType)?.content.title || __GetContentTypeConfigNew(d.tagType).general?.content?.title || d.tagType;
          const active = showTagsLightbox.tagType === d.tagType;
          const selectCount = value[d.tagType] && value[d.tagType].data.length;

          return (
            <MenuItemStyled
              key={d.tagType}
              onMouseDown={(e) => handleShowTagsLightbox(e, d)}
              active={active}
            >
              <div style={{ display: "flex", gap: "1rem" }}>
                <SANS_2 semibold={active}>{title}</SANS_2>{" "}
                {selectCount ? <BadgeNumber number={selectCount} /> : null}
              </div>
              <CaretRight weight={active ? "bold" : "regular"} size={12} />
            </MenuItemStyled>
          );
        })}

        <OtherOptionsContainerStyled>
          <Divider />
          {toggleTagTypesQueries.every((q) => q.status === "success") &&
            genToggleTags()}
          <Divider />
          <ActionButtonsContainerStyled>
            <ButtonGhost onClick={(e) => handleOnCancel(e)}>Cancel</ButtonGhost>
            <ButtonPrimary disabled={isEmpty(value)} onClick={handleOnConfirm}>
              Apply
            </ButtonPrimary>
          </ActionButtonsContainerStyled>
        </OtherOptionsContainerStyled>
      </MenuContainerStyled>

      {showTagsLightbox.tagType && (
        <TagsSubmenu
          status={status}
          data={data}
          value={value}
          onChange={onChange}
          containerRef={containerRef}
          createTagApi={createTagApi}
          showTagsLightbox={showTagsLightbox}
        />
      )}
    </>
  );
};

//------------------------------------------------------------- PARENT COMP ----------------------------------------------------

const InputTagTypes = (props) => {
  const {
    // id,
    onChange,
    value,
    tagTypesConfig,
    toggleTagTypes: toggleTagTypesProp,
    handleOnConfirm,
    handleOnCancel,
    // ... can have several other props as well, check the Dropdown component to know which those are.
  } = props;

  // const toggleTagTypes = toggleTagTypesProp || [
  //   { tagType: "quotes" },
  //   { tagType: "vocabs" },
  // ];
  const toggleTagTypes = toggleTagTypesProp
  

  
  // let tagType = handleSiblingValuePath(tagTypeProp, siblingValuePath, content)

  //---------------------------------------REACT HOOKS----------------------------------------------------------
  const [showTagsLightbox, setShowTagsLightbox] = useState({});
  const containerRef = useRef(null);

  //---------------------------------------QUERY HOOKS----------------------------------------------------------
  const { mutate: createTagApi } = useCreateTagV3();

  const {
    data,
    status,
    // isRefetching, // <--
    // tagType,
    // tagCategory,
    // tagSegment
  } = useGetTypeCategoryAndTags({
    tagTypeProp: showTagsLightbox.tagType,
    incExcTagsList: showTagsLightbox.incExcTagsList
  })

  const toggleTagTypesQueries = useMultipleGetTagsV3(
    toggleTagTypes?.map((d) => d.tagType),{disabled:(toggleTagTypes&&toggleTagTypes.length>0)?false:true} 
  );


  return (
    <div ref={containerRef} onMouseDown={(ev) => ev.preventDefault()}>
      <TagTypesMenu
        tagTypesConfig={tagTypesConfig}
        showTagsLightbox={showTagsLightbox}
        setShowTagsLightbox={setShowTagsLightbox}
        value={value}
        toggleTagTypesQueries={toggleTagTypesQueries}
        status={status}
        data={data}
        onChange={onChange}
        containerRef={containerRef}
        createTagApi={createTagApi}
        handleOnConfirm={handleOnConfirm}
        handleOnCancel={handleOnCancel}
      />
    </div>
  );
};

export default InputTagTypes;
