import handleBlockDisplayConditions from "../../../../../utils/handleBlockDisplayConditions";

/**
 * this is what it could look like:
 * displayConditions: [
          {
            operator: 'SOME_IN',
            valuePath: 'stateFilter.values',
            value: ['maharashtra']
          }
        ]
 */

export const handleFilterInputDisplayConditions = ({
  activeFilters,
  presetActiveFilters,
  filterConfig,
}) => {
  const allActiveFiltersAsObject = [
    ...activeFilters,
    ...presetActiveFilters,
  ].reduce((a, b) => ({ ...a, [b.filterId]: b }), {});

  return handleBlockDisplayConditions({
    content: allActiveFiltersAsObject,
    block: filterConfig,
  });
};
