import React, { useState } from "react";


import { CheckboxList, DropdownMulti } from "oolib";
import { AccordionMultiSelect } from "../../AccordionMultiSelect";
import { useGetOptionsByFilterType } from "./utils/useGetOptionsByFilterType";
import { handleFilterInputDisplayConditions } from "./utils/handleFilterInputDisplayConditions";
import { CustomSelectTopbarFilter } from "./comps/CustomSelectTopbarFilter";



export const FilterInput = ({
  id,
  onChange,
  value,
  comp = 'AccordionMultiSelect', //DropdownMulti //CheckboxList
  filterConfig,
  contentTypes = [],
  activeFilters = [],
  presetActiveFilters = [],
  className,
  initEnableDataFetch, //cuz in the case of mobile filters, we enable the data fetch the minute it mounts.
  ref_asideFilter,
  ref_sideFilterHeader,
  
}) => {
  
  

  const {
    setEnableDataFetch,
    lightboxConfig,
    display
  } = useGetOptionsByFilterType({
    initEnableDataFetch,
    filterConfig,
    contentTypes,
    activeFilters
  })

  const [showDropdownOptions, setShowDropdownOptions] = useState(false)

  // dont render filter, if the display condition config says not to.
  let shouldRender = handleFilterInputDisplayConditions({
    activeFilters,
    presetActiveFilters,
    filterConfig
  })
  if(!shouldRender) return null;
  
 
  
  const FilterComps = {
    DropdownMulti,
    AccordionMultiSelect,
    CheckboxList
    // --> some other accordion component 
    // --> CheckboxInput
  } 
  const FilterComp = FilterComps[comp]

  const getPropsByComp = comp => {
    switch(comp){
      case 'DropdownMulti':
        return ({
          broadcastShowOptions: (bool) => {
            setEnableDataFetch(bool) //this only set it to true, never false, 
            setShowDropdownOptions(bool) //hence we need another state to track showoptions
          },
          isSearchable: true,
          genCustomSelectComp: () => <CustomSelectTopbarFilter {...{ showOptions: showDropdownOptions, display }}/>
        })
      case 'AccordionMultiSelect':
        return ({
          broadcastExpandState: (bool) => setEnableDataFetch(bool),
          title: display
        }) 
      case 'CheckboxList':
        return ({
          label: display,
          optionsLimit: 15,
          inputStyle: "tagSelect"
        }) 
        default: 
        return ({})
    }
  }

  return (

      <FilterComp
        ref_sideFilterHeader={ref_sideFilterHeader}
        ref_asideFilter={ref_asideFilter}
        id={filterConfig.filterId}     
        value={value}
        options={lightboxConfig?.props?.options} //only exists to support DropdownMulti & CheckboxList which will eventually be removed from filters
        lightboxConfig={lightboxConfig}
        onChange={(k,v) => onChange(filterConfig.filterId, v)}  
        className={className}
        filterConfig={filterConfig}
        {...getPropsByComp(comp)}
        />
    
  );
};
