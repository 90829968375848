import { getVal, toArray } from "oolib"

export const dynamicPropsFnLookup = {
    batchRegister_readOnlyIfValidData: ({colConfig, tableRowData}) => {
      const readOnly = getVal(tableRowData, 'valid') === true
            ? true
            : tableRowData?.remarks?.some(r => {
                return r.affectedKeys.includes(colConfig.valuePath)
            }) ? false : true
      return ({readOnly})
    },
    batchRegister_remarkTooltip: ({colConfig, tableRowData}) => {
      const remarks = tableRowData?.remarks?.map(r => {
        if(r.affectedKeys.includes(colConfig.valuePath)){
          return r.remark
        }else{
          return undefined
        }
      }).filter(Boolean)

      if(remarks?.length > 0){
        return ({
          tooltip: {
            text: remarks.join('\n')
          }
        })
      }else{
        return({})
      }
    },
    batchRegister_highlightErrorCell: ({colConfig, tableRowData}) => {
        const remarks = tableRowData?.remarks?.map(r => {
            if(r.affectedKeys.includes(colConfig.valuePath)){
              return r.remark
            }else{
              return undefined
            }
          }).filter(Boolean)

          if(remarks?.length > 0){
            return 'error'
          }else{
            return false
          }
    }
  }

export const generateDynamicProps = ({dynamicPropsFn, colConfig, tableRowData}) => {
    
    let toReturn = {}
    toArray(dynamicPropsFn).forEach(fnName => {
      const props = dynamicPropsFnLookup[fnName]({colConfig, tableRowData});
      toReturn = {
        ...toReturn,
        ...props
      }
    })

    return toReturn;
  }