import { cloneDeep } from "lodash";
import {
  ActionMenu,
  ButtonGhost,
  ButtonPrimary,
  ButtonSecondary,
  getBreakPoint,
  Loader,
  LoaderOverlay,
  useScreenWidth
} from "oolib";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from 'react-router-dom';
import _SaveStatus from "../../components/generalUI/SaveStatus";
import { _Locale } from "../../components/locale/Locale";

import { useFormValidationContext } from "../../contexts/formValidationContext";

import ConfirmAction from "../../components/generalUI/ConfirmAction";
import { UIContent } from "../../UIContent";
import { isDeviceOnline } from "../../utils/environmentConfig";
import { getQueryParam } from "../../utils/general";
import {
  useAutoSave,
  useContributionCloneForModeration,
  useGetContribution
} from "../../utils/react-query-hooks/contributions";
import EditLayoutRenderer from "./comps/EditLayoutRenderer";
import { HeaderContributeTpl } from "./comps/HeaderContributeTpl";
import {
  RawSynthToggleDesktop,
  RawSynthToggleMobile,
} from "./layouts/DEPR/RawSynthToggle";
import { SendBackModal } from "./utils/SendBackModal";
import { useGetPrimaryActionsConfig } from "./utils/useGetPrimaryActionsConfig";
import { handleInjectSerialNumbersBasedOnTplConfig } from "../../utils/addSerialNumbersToTemplate";

export default function ContributeTpl({
  onboardingProps,
  CustomHeader,
  publishOnClick
}) {
  
  const { pathname, search: queryString } = useLocation()
  const { content_type } = useParams()
  
  const edit_moderate =
    onboardingProps?.edit_moderate || pathname?.split("/")[1];
  const contributionId =
    onboardingProps?.contributionId || getQueryParam(queryString, "id");
  const urlParamContentType =
    onboardingProps?.contentType || content_type;

  const { resetFormValidation, formValidation, makeFormValidationVisible } =
    useFormValidationContext();

  useEffect(() => {
    return () => resetFormValidation();
  }, []);

  const {
    mutate: autosave,
    isLoading: isAutoSaving,
    isSuccess: isAutoSaved,
  } = useAutoSave();

  const { data: contributionQueryData, status: getContributionStatus } =
    useGetContribution({
      contentType: urlParamContentType,
      contentId: contributionId,
      mode: edit_moderate, // = edit
    });
  const { data: moderationQueryData, status: contributionCloneStatus } =
    useContributionCloneForModeration({
      contentType: urlParamContentType,
      contentId: contributionId,
      mode: edit_moderate, // = moderate
    });

  const CompMap = {
    ActionMenu,
  }

  const disablePublishButton = () => formValidation.makeVisible && (formValidation.failedBlocks.length > 0 ||
  formValidation.invalidInputBlocks.length > 0);

  const getContentLoadStatus = () => {
    return getContributionStatus !== "idle"
      ? getContributionStatus
      : contributionCloneStatus;
  };

  const [content, setContent] = useState(null);

  useEffect(() => {
    if (getContentLoadStatus() === "success") {
      const { contribution } = contributionQueryData || moderationQueryData;
      setContent(cloneDeep(contribution));
    }
  }, [getContentLoadStatus()]);

  const screenWidth = useScreenWidth();

  const {
    loadingText: loadingTextPrimaryActions,
    isLoading: isLoadingPrimaryActions,
    
    setOpenSendBackModal,
    openSendBackModal,

    sendBackMutate,
    submitPublishContent,
    moderationApproveQuery,

    publishButtonDisabled,

    closeButtonConfig,
    publishButtonConfig, 
    otherLeftsideButtonConfigs, 
    otherRightsideButtonConfigs
  } = useGetPrimaryActionsConfig({
    kp_published_status: (contributionQueryData || moderationQueryData)?.contribution.kp_published_status,
    contentType: urlParamContentType,
    contributionQueryData,
    moderationQueryData,
    isAutoSaving,
    edit_moderate,
    content,
    contributionId
  })

  const H_PrimaryCTA = () => {
    if (!publishButtonConfig) return null;

    return (
      // Publish / Approve / Submit
      <div
        onClick={(e) => publishButtonConfig.wrapperOnClick(e)}
      >
        <ConfirmAction
          {...publishButtonConfig.confirmAction}
        >
          <ButtonPrimary
            id={"h_PrimaryCTA"} /** Id is v. important for coachmark to work */
            style={{ pointerEvents: isAutoSaving ? "none" : "" }}
            onClick={publishOnClick || publishButtonConfig.onClick} //publishOnClick is an optional prop that can be passed through. only used is selfServeSurvey
            disabled={disablePublishButton()}
          >
            {publishButtonConfig.display ? disablePublishButton() ? `Fill all required fields to ${publishButtonConfig.display}` : publishButtonConfig.display : "No actions"}
          </ButtonPrimary>
        </ConfirmAction>
      </div>
    );
  }

  const H_SaveStatus = () => (
    <_SaveStatus
      status={_Locale(
        isAutoSaved
          ? !isDeviceOnline()
            ? "Saved Offline"
            : "Saved!"
          : isAutoSaving
          ? !isDeviceOnline()
            ? "Saving Offline..."
            : "Saving..."
          : ""
      )}
    />
  );

  const H_DEPRECATED_researchNotesInject = ({ desktop_mobile }) => {
    const { tpl } = contributionQueryData || moderationQueryData;

    return tpl.category !== "researchNotes"
      ? null
      : desktop_mobile === "desktop"
      ? screenWidth >= getBreakPoint("md") && <RawSynthToggleDesktop />
      : desktop_mobile === "mobile" &&
        screenWidth < getBreakPoint("md") && <RawSynthToggleMobile />;
  };

  const H_CloseButton = () => {
    if(!closeButtonConfig) return null;

    return (
      <ConfirmAction {...closeButtonConfig.confirmAction}>
        <ButtonGhost
          id={"h_CloseButton"} /** Id is v. important for coachmark to work */
          disabled={isAutoSaving}
          onClick={closeButtonConfig.onClick}
        >
          {closeButtonConfig.display}
        </ButtonGhost>
      </ConfirmAction>
    );
  }

  const PRIMARY_BUTTON = () => {
    return (
      <>
        {publishButtonConfig && <H_PrimaryCTA />}        
        {otherLeftsideButtonConfigs.length > 0 && (
          <ConfirmAction
            title={UIContent.confirmActionSendBack.title}
            subtitle={UIContent.confirmActionSendBack.subtitle}
            onConfirmText={UIContent.confirmActionSendBack.onConfirmText}
          >
            <ButtonSecondary 
              disabled={isAutoSaving} 
              onClick={() => setOpenSendBackModal(true)}
            >
              {UIContent.sendBackBtn}
            </ButtonSecondary>
          </ConfirmAction>
        )}
      </>
    );
  };
  
  const CLOSE_BUTTON = () => {
    const Comps = otherRightsideButtonConfigs.map((rightComp, index) => {
      const Comp = CompMap[rightComp.comp];
      if (!Comp) {
        console.error(`Component not found: ${rightComp.comp}`);
        return null;
      }
      return <Comp key={`${rightComp.comp}-${index}`} {...rightComp.props} ButtonComp="ButtonSecondary" />;
    });
  
    return (
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        {closeButtonConfig && <H_CloseButton />}        
        {otherRightsideButtonConfigs.length > 0 ? Comps : null}
      </div>
    );
  };

  const genComp = () => {
    let status = getContentLoadStatus();

    switch (status) {
      case "loading":
        return <Loader />;
      case "error":
        return <h1>Error</h1>;
      case "success":
        return (
          content && (
            <>
            
              {CustomHeader ? (
                <CustomHeader
                  onSave={(onboardingCb) => {
                    const content = contributionQueryData.contribution;
                    submitPublishContent.mutate(
                      {
                        contentType: content.meta.kp_content_type,
                        contentId: content._id,
                        userAction: "PUBLISH",
                        disableRedirectOnPublish: true,
                      },
                      {
                        onSuccess: (res) => {
                          onboardingCb &&
                            onboardingCb({
                              dynamicOnboardingProps: {
                                contentType: res.data.content.meta.kp_content_type,
                                contributionId: res.data.content._id,
                              },
                            });
                        },
                      }
                    );
                  }}
                  saveDisabled={publishButtonDisabled}
                  customRightComp={<H_SaveStatus />}
                />
              ) : 
              //eventually we want to move this header altogether into each Layout comp
              ['researchNotes2'].indexOf((contributionQueryData || moderationQueryData).tpl.category) === -1 && 
              (
                <HeaderContributeTpl
                  PrimaryCTA={<PRIMARY_BUTTON />}
                  SaveStatus={<H_SaveStatus />}
                  CenterComp={
                    <H_DEPRECATED_researchNotesInject
                      desktop_mobile={"desktop"}
                    />
                  }
                  CloseButton={<CLOSE_BUTTON />}
                  AfterComp={
                    <H_DEPRECATED_researchNotesInject desktop_mobile={"mobile"} />
                  }
                />
              )}
              <EditLayoutRenderer
                tpl={handleInjectSerialNumbersBasedOnTplConfig({
                  tpl: (contributionQueryData || moderationQueryData).tpl
                })}
                autosave={autosave}
                isAutoSaving={isAutoSaving}
                isAutoSaved={isAutoSaved}
                isModerateMode={edit_moderate === "moderate"}
                contentState={[content, setContent]}
                publishBtnTextAndOnClickAndDisabled={{
                  text: publishButtonConfig.display ? disablePublishButton() ? `Fill all required fields to ${publishButtonConfig.display}` : publishButtonConfig.display : "No actions",
                  onClick: publishOnClick || publishButtonConfig.onClick,
                  disabled: publishButtonDisabled,
                  wrapperOnClick: publishButtonConfig.wrapperOnClick
                }}
                //header comps
                {...{
                  H_PrimaryCTA:PRIMARY_BUTTON,
                  H_SaveStatus,
                  H_CloseButton:CLOSE_BUTTON
                }}
              />
              {/* { genTemplateInterfaceByCategory() } */}
            </>
          )
        );
      default:
        return;
    }
  };

  return (
    <>
    {isLoadingPrimaryActions && <LoaderOverlay loaderText={loadingTextPrimaryActions}/>}
    <SendBackModal
      sendBackMutate={sendBackMutate}
      openSendBackModal={openSendBackModal}
      setOpenSendBackModal={setOpenSendBackModal}
      moderationQueryData={moderationQueryData}
      />
      {moderationApproveQuery.status === "loading" && (
        <LoaderOverlay loaderText={"Approving..."} />
      )}
      {submitPublishContent.status === "loading" && (
        <LoaderOverlay loaderText={"Publishing..."} />
      )}
      <div className="kp-contribute-flow">{genComp()}</div>
    </>
  );
}
