import React from "react";
import { ModalConfirmAction, TextInput, icons } from "oolib";
import { SearchBarStyled, TextInputWrapperStyled } from "./styled";
import { useState } from "react";
import styled from "styled-components";
const { ArrowClockwise, PaperPlaneRight } = icons;

const TextAreaStyled = styled.textarea`
  border: none;
  resize: none;
  width: 100%;
  ::placeholder {
    color: #000;
    margin-top: 0.5rem;
  }
`;

const TextArea = ({ placeholder, onKeyDown, onChange, value }) => {
  return (
    <TextAreaStyled
      onKeyDown={onKeyDown}
      onChange={(e) => onChange(e.target.value)}
      className="UI_BODY_SEMIBOLD_SM"
      placeholder={placeholder}
      value={value}
    ></TextAreaStyled>
  );
};

export const SearchBar = ({ onSubmit, deleteChat, disableOnSubmit }) => {
  const [value, setValue] = useState("");

  const disableOnClick = disableOnSubmit ||  value.length === 0

  const handleOnClick = () => {
    if (disableOnClick) return;

    onSubmit({ value });
    setValue("");
  };

  const handleOnKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      setTimeout(handleOnClick, 0);
    }
  };

  return (
    <SearchBarStyled>
      <ModalConfirmAction
        title="Are you sure you want to restart"
        enabled
        onConfirmText="Yes"
      >
        <div
          style={{
            borderRadius: "6px",
            padding: ".9rem 1.2rem",
            background: "#dae8ff",
            cursor: "pointer",
          }}
          onClick={deleteChat}
        >
          {" "}
          <ArrowClockwise size={25} />
        </div>
      </ModalConfirmAction>
      <TextInputWrapperStyled>
        <TextArea
          placeholder="Ask any question..."
          value={value}
          onChange={setValue}
          onKeyDown={handleOnKeyPress}
        />
        <div style={{ cursor: disableOnClick?"not-allowed": "pointer" }} onClick={handleOnClick}>
          <PaperPlaneRight color={disableOnClick?"grey":"#000"} weight="bold" size={20} />
        </div>
      </TextInputWrapperStyled>
    </SearchBarStyled>
  );
};
