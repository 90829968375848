import React, { useRef, useState } from "react";
import { MetaPrimary } from "../../../../../../components/generalUI/V2_MetaComps/MetaPrimary"
import Tags__Intro from "../../../../../../components/generalUI/Tags__Intro"
import { ButtonPrimary } from "oolib/dist/v2/components/Buttons"

import { colors2, UI_BODY_SM_DF, UI_HEADLINE_SM, icons } from "oolib";
import { DescriptionText, StyledActionsWrapper, StyledDesc, StyledDescButton, StyledInfoSection, StyledMetaWrapper} from "./styled"
import TplActions from "../../../../../../components/generalUI/TplActions";
import { StyledTPLActionsWrapper } from "../DesktopLayout/styled";
import {StickyTitle} from "../StickyTitle";

const { grey80 } = colors2;
const { ArrowsVertical } = icons;


const DescriptionToggleButton = ({ 
  showDesc, 
  onClick 
}) => (
  <StyledDescButton onClick={onClick}>
    <ArrowsVertical size={14} style={{ marginTop: "3px", marginLeft: "-2px" }} weight="bold" />
    <UI_BODY_SM_DF>{showDesc ? "Hide" : "Show Description"}</UI_BODY_SM_DF>
  </StyledDescButton>
);

export const MobileLayout = ({ content, title, desc, btn, coverImage, coverVideo, metaPrimary, tagsIntro, mobileTitleRef, stickyTitleHeight, coverExists, mobileInfoSectionOpacity, coverSectionRef, coverSectionBottom }) => {
  
  const [ showDesc, setShowDesc ] = useState(false);
  

  return (
    <>  
      <StickyTitle
        style={{zIndex: 101}} // v.v.imp to have bg-image cover the listingmodule items on scroll
        title={title}
        stickyTitleHeight={stickyTitleHeight}
        coverSectionBottom={coverSectionBottom}
        mobileTitleRef={mobileTitleRef}
      />

      <StyledInfoSection style={{
        opacity: mobileInfoSectionOpacity,
        zIndex: 101
      }} ref={coverSectionRef} hasCoverImage={coverImage?.[0]?.publicUrl}>
        <StyledMetaWrapper>
            {metaPrimary && (
                <MetaPrimary {...metaPrimary} content={content} align={'left'} />
            )}

            <div>
              <UI_HEADLINE_SM color={grey80}>{title}</UI_HEADLINE_SM>
            </div>
          

            {(desc && showDesc) && 
              <StyledDesc desc={desc} showDesc={showDesc}>
                <DescriptionText>{desc}</DescriptionText>
              </StyledDesc>
            }

            {desc && ( <DescriptionToggleButton onClick={() => setShowDesc(!showDesc)} showDesc={showDesc}/>
            )}

            {tagsIntro && (
              <Tags__Intro {...tagsIntro} content={content} style={{ marginTop: "1.6rem" }} align="left"/>
            )}
          
        </StyledMetaWrapper>

        
          {btn?.action && btn?.text && (
            <StyledActionsWrapper>
              <ButtonPrimary 
                style={{ maxWidth: '100%', width: "100%", marginTop: '1.9rem' }} 
                onClick={btn.action} 
                icon="Plus" 
                iconSize={"S"}
              >
                {btn.text || "Publish"}
              </ButtonPrimary>
            </StyledActionsWrapper>
          )}
        

          {content?.meta && (
            <StyledTPLActionsWrapper>
              <TplActions content={content} kebabStyle tplActionsIcon="DotsThree" buttonSecondary/>
            </StyledTPLActionsWrapper>
          )}
      </StyledInfoSection>
    </>
  );
};
