import React from 'react';
import { RedirectModal } from './RedirectModal';

function BroadcastModals({ redirectModal, setRedirectModal }) {
  return (
    <>
      {redirectModal && <RedirectModal setRedirectModal={setRedirectModal} />}
    </>
  )
}
export default BroadcastModals