import { cloneDeep } from "lodash";
import { formatCamelCaseToSentenceCase, getText, toArray } from "oolib";
import { queryClient } from "../../index";
import { baseShapeBoilerplateLex, paragraphNodeBoilerplateLex, textNodeBoilerplateLex } from "../../components/inputs/TextEditor/LexicalTextEditor/utils/dataShapeBoilerplates";

export const __GetContentTypeConfigNew = (id, options = {}) => {
  const TCIIsActive  =
    sessionStorage.getItem("TCIIsActive") === "true" || options.TCIIsActive ;

  const fromTpl = queryClient.getQueryData(["tpl", id]);
  if(!fromTpl) return undefined;
  //----for backward compatibility
  let { _ContentTypes } = queryClient.getQueryData("platformConfigs");
  const fromplatformConfigs = _ContentTypes?.find((d) => d.id === id);

  const d = cloneDeep(
    TCIIsActive  ? (fromTpl?.drafts?.active || fromTpl) : fromTpl
  );

  // todo segent on publising
  // if (fromplatformConfigs && !d.general) {
  // old

  d.listing = {
    ...(d.listing || {}),
    enable: TCIIsActive
      ? d.listing?.enable
      : d.listing?.enable || fromplatformConfigs?.showListingPage !== false
  }

  d.general = {
    content: {
      title: TCIIsActive 
        ? d.general?.content?.title
        : d.general?.content?.title ||
          fromplatformConfigs?.content?.title ||
          formatCamelCaseToSentenceCase(d.kp_content_type),
      ctaText: TCIIsActive 
        ? d.general?.content?.ctaText
        : d.general?.content?.ctaText || fromplatformConfigs?.content?.ctaText,
      listingDesc: TCIIsActive 
        ? d.general?.content?.listingDesc
        : d.general?.content?.listingDesc ||
          fromplatformConfigs?.content?.listingDesc,
      singular: TCIIsActive 
        ? d.general?.content?.singular
        : d.general?.content?.singular ||
          fromplatformConfigs?.content?.singular,
    },
    sharing: {
      enable: TCIIsActive 
        ? d?.general?.sharing?.enable
        : d?.general?.sharing?.enable ??
          fromplatformConfigs?.allowShare !== false,
      trackShareCount: TCIIsActive 
      ? d?.general?.sharing?.trackShareCount
      : d?.general?.sharing?.trackShareCount ??
        fromplatformConfigs?.allowShare?.trackShareCount === true,
    },
    viewsCount: {
      enable: TCIIsActive 
      ? d?.general?.viewsCount?.enable
      : d?.general?.viewsCount?.enable ??
        fromplatformConfigs?.viewsCount === true,
    },
    reactions: Object.keys(d?.general?.reactions || {}).reduce((acc, key) => {
      acc[key] = {
        enable: d?.general?.reactions[key]?.enable,
        icon: d?.general?.reactions[key]?.icon
      };
      return acc;
    }, {}),
    comments: {
      enable: TCIIsActive 
        ? d?.general?.comments?.enable
        : d?.general?.comments?.enable ??
          fromplatformConfigs?.allowComments !== false,
    },
    settingsUIStyle: TCIIsActive 
      ? d?.general?.settingsUIStyle
      : d?.general?.settingsUIStyle || fromplatformConfigs?.settingsType,
    templateIndex: {
      type: d?.general?.templateIndex?.type || fromplatformConfigs?.indexType,
      enableOnContributeTpl: d?.general?.templateIndex
        ? d.general.templateIndex.enableOnContributeTpl
        : fromplatformConfigs?.indexType
        ? true
        : false,
      enableOnPublishedPage: d?.general?.templateIndex
        ? d.general.templateIndex.enableOnPublishedPage
        : fromplatformConfigs?.indexType
        ? true
        : false,
    },
    postPblRedirPath: TCIIsActive 
      ? d?.general?.postPblRedirPath
      : d?.general?.postPblRedirPath || fromplatformConfigs?.postPblRedirPath,
    hasUpdateType: TCIIsActive 
      ? d?.general?.hasUpdateType
      : d?.general?.hasUpdateType || fromplatformConfigs?.hasUpdateType,
    segment: d.general?.segment || fromplatformConfigs?.segment,
    csvExport:  {
      enable:  TCIIsActive 
      ? d?.general?.csvExport?.enable 
      : d?.general?.csvExport?.enable ,
      populateTagsArray:TCIIsActive 
      ? d?.general?.csvExport?.populateTagsArray 
      : d?.general?.csvExport?.populateTagsArray ,
      excludeFields:
      TCIIsActive 
      ? d?.general?.csvExport?.excludeFields 
      : d?.general?.csvExport?.excludeFields ,       
    enableUpdateExport:TCIIsActive 
    ? d?.general?.csvExport?.enableUpdateExport 
    : d?.general?.csvExport?.enableUpdateExport ,
    fieldsToSortAtEnd:TCIIsActive 
    ? d?.general?.csvExport?.fieldsToSortAtEnd 
    : d?.general?.csvExport?.fieldsToSortAtEnd
    

    },
    allowQuickTagCreation:d.general?.allowQuickTagCreation
  };
  // }
  // --------------------------------------------

  // userprofiles

  return d;
};

//can pass multiple segments
export const __GetConfigsBySegmentNew = (segment, options = {}) => {
  const TCIIsActive  =  sessionStorage.getItem("TCIIsActive")==="true" || options.TCIIsActive  ;

  const fromAllTpls =
    queryClient.getQueryData("allTpls")
      .filter((d) => {
        if (TCIIsActive ) return true;
        else return d.status !== "unpublished";
      })
      .map((d) => d.kp_content_type) || [];

      return fromAllTpls
      .map((d) => __GetContentTypeConfigNew(d, { TCIIsActive: TCIIsActive }))
      .filter((d) => toArray(segment).includes(d.general.segment));
};

export const __GetAllContentTypeConfig = (options = {}) => {

  const TCIIsActive  =  sessionStorage.getItem("TCIIsActive")==="true" || options.TCIIsActive  ;


  const fromAllTpls =
    queryClient.getQueryData("allTpls")
      .filter((d) => {
        if (TCIIsActive ) return true;
        else return d.status !== "unpublished";
      })
      .map((d) => d.kp_content_type) || [];


  // return;

  return fromAllTpls.map((d) =>
    __GetContentTypeConfigNew(d, { TCIIsActive : TCIIsActive  })
  ).filter(d => ['collections', 'publishing'].includes(d.general?.segment));
};



export const __GetTagTypeConfig = (tagType) => {
  let { _TagTypes } = queryClient.getQueryData('platformConfigs');
  return _TagTypes.find((d) => d.tagType === tagType);
};

export const __GetProfileTypeConfig = (profileTypeId) => {
  let { _ProfileTypes } = queryClient.getQueryData('platformConfigs');
  return _ProfileTypes[_ProfileTypes.findIndex((d) => d.id === profileTypeId)];
};

export const convertToRichText = value => {
  if(!value) return undefined;
  //means its already rich text
  if(value?.blocks) return value;
  //means its unsupported data type
  if(['string', 'number'].indexOf(typeof value) === -1) return undefined
  //else means its string or number
  return ({blocks: [{text: value}], entityMap: {}})
}

export const convertFromRichText = value => getText(value)


export const convertToLexicalText = value => {
  if(!value) return undefined;
  //means its already lexical
  if(value?.isLexical) return value;
  //means its unsupported data type
  if(['string', 'number'].indexOf(typeof value) === -1) return undefined
  //else means its string or number
  const toReturn = { ...baseShapeBoilerplateLex }
  toReturn.editorState.root.children = [
      {
          ...paragraphNodeBoilerplateLex,
          children: [
              {
                  ...textNodeBoilerplateLex,
                  text: value
              }
          ]
      }
  ]
  return toReturn;
}

export const getRichTextAsPlainTextLex = ({value, stitched = [], depthIdx = 0}) => {
  const children = depthIdx === 0 ? (value?.editorState?.root?.children || []) : value;

  for(const child of children){
    if(child.children){
      getRichTextAsPlainTextLex({value: child.children, stitched, depthIdx: depthIdx+1})      
    }else{
      stitched.push(child.text || '')
    }
  }
  if(depthIdx === 0) return stitched.join(' ')
}