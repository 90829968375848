import React, { useContext } from 'react';
import { ModalConfirm } from 'oolib';
import { __GetContentTypeConfigNew } from '../../../../utils/getters/gettersV2';
import { AppSettingsContext } from '../../../../contexts/appSettingsContext';
import { _Locale } from '../../../locale/Locale';
import { trackEventGA } from '../../../../trackers/GA/trackEventGA';
import { useNavigate } from 'react-router-dom';


export const DeleteModal = ({contentType, setShowDeleteModal, contentId, mutate}) => {

    const navigate = useNavigate()
    const { APP_SETTINGS } = useContext(AppSettingsContext)
    const handleDelete = (e) => {

        setShowDeleteModal(false)
    
        mutate(
          { contentType, id: contentId },
          {
            onSuccess: () => {
              trackEventGA("Published Content",`Clicked Confirm Delete Button On Published Content`,`${contentType}`)
              navigate("/my-dashboard/MyContent?activeTab=published")
            },
          }
        )
      }

    return (
    <ModalConfirm
      title={
        // if localizatio doesnt exist at all OR if it exists and lang === english then..
        // be more specific with this statment
        !APP_SETTINGS.lang || APP_SETTINGS.lang?.value === 'en'
          ? `Are you sure you want to delete this ${
              __GetContentTypeConfigNew(contentType).general.content?.singular || 'content'
            } ?`
        : _Locale('Are you sure you want to delete this content ?')
      }
      onClose={() => setShowDeleteModal(false)}
      onConfirm={() => handleDelete()}
    />
    )
}