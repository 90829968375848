import styled from "styled-components";

import { colors, mediaQuery } from "oolib";

export const ReviewNotesContainerStyled = styled.div`
  background-color: ${colors.lightYellow};
  padding: 3rem 0;
  border-bottom: 2px solid ${colors.yellow};
`;
export const ReviewNotesWrapperStyled = styled.div`
  width: 100%;
  margin: auto;
  ${mediaQuery("md")} {
    width: 70%;
  }
  ${mediaQuery("lg")} {
    width: 50%;
  }
`;
