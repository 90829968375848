export const highlightPlugin = ({ cardData, cardConfig }) => {
    
    const campaigns = cardData?.tags?.campaigns?.data
    const shortlist = cardData?.tags?.shortlistPsa?.data

    const highlight = campaigns && (Array.isArray(campaigns) && campaigns[0]?.display) 
        ? 'primary' 
        : (Array.isArray(shortlist) && shortlist[0]?.display) 
            ? 'secondary'
            : undefined 
            console.log({highlight, campaigns})
    
    const tagDisplayPath = highlight && (
        campaigns?.[0]?.display
        ? "tags.campaigns.data.0.display" 
        : shortlist?.[0]?.display
            ? "tags.shortlistPsa.data.0.display"
            : undefined
    )

    //for shortlist we want the status tag to be yellow only if a winner tag doesnt already 
    //exist on it
    const extraProps = (highlight && !campaigns?.[0]?.display && shortlist?.[0]?.display)
        ? {statusTagVariant: "warning"}
        : undefined
        
        

    return {
        cardData: {
            ...cardData,
            highlight
        },
        cardConfig: {
            ...cardConfig,
            tagDisplay: tagDisplayPath
        },
        extraProps
    }
}