export const configs_gelabs_annoBase = {
  contentTypes: {
    // whitelist: ["immersion1AFGDNigeria", "immersion1ATemplates", "immersion1BIDINigeria", "immersion1BFGDNigeria"]
},
  filterConfigs: {
    whitelist: [
      "annoTags_factors",
      "annoTags_influences",
      "annoTags_existingPrograms",
      "annoTags_findings",

      "docTags_participantsRegistrationTemplates_main_DOT_country_DOT_value",
      "ROLLUP_docTags_participantsRegistrationTemplates_segmentationNigeria",
      "docTags_participantsRegistrationTemplates_main_DOT_age_DOT_value",
      "docTags_participantsRegistrationTemplates_main_DOT_maritalStatus_DOT_value",
      "docTags_participantsRegistrationTemplates_main_DOT_educationLevel_DOT_value",
      "ROLLUP_docTags_participantsRegistrationTemplates_vaginalInsertableProductsUsed",
      "ROLLUP_docTags_participantsRegistrationTemplates_useCases",
      "ROLLUP_docTags_participantsRegistrationTemplates_accessToMobilePhones",
      "docTags_participantsRegistrationTemplates_main_DOT_numberOfChildren_DOT_value",
    ],
  },
  cardConfigs: {  
    // docTags: {
    //     whitelist: [
    //         'documentStatus'
    //     ]
    // },
    // annoTags: {
    //     whitelist: []
    // }
  },
};
