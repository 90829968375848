import React from "react";
import { MemoPlaceholder } from "../DesktopLayout";
import {
  StyledCoverBackgroundImgHolder,
  StyledCoverWrapper,
  StyledTransitionWrapper,
  StyledWhiteBgBehindImage,
} from "../MobileLayout/styled";

export const MobileBGCoverWrapper = ({
  coverConfig,
  style = {},
  coverBlur,
  coverShift,
}) => {
  if (!coverConfig?.title) return null;

  return (
    <StyledCoverWrapper
      style={{ ...style, transform: `translateY(-${coverShift}px)` }}
    >
      <StyledWhiteBgBehindImage />
      <StyledTransitionWrapper
        style={{
          height: "100%",
          backdropFilter: `blur(${coverBlur}px)`,
          filter: `blur(${coverBlur}px)`,
        }}
      >
        {coverConfig.coverImage?.[0]?.publicUrl ? (
          <StyledCoverBackgroundImgHolder
            src={coverConfig.coverImage?.[0]?.publicUrl}
          />
        ) : (
          coverConfig?.title && <MemoPlaceholder title={coverConfig?.title} />
        )}
      </StyledTransitionWrapper>
    </StyledCoverWrapper>
  );
};
