import { Container, Section, transition} from "oolib";
import styled from 'styled-components'

export const StyledSection = styled(Section)`
  height: ${({show, contentsHeight}) => show 
    ? (contentsHeight > 40 ? contentsHeight+'px' : '4rem')
    : 0 };
  position: fixed;
  width: 100vw; 
  
  top: 3px;
  z-index: 100000000;
  ${transition('height')}
`
export const StyledContainer = styled(Container)`
  position: absolute; 
  min-height: 4rem; 
  bottom: 0; 
  width: 100%;
  display: flex;
  align-items: center;
`