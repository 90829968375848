import React from "react";

import { Container, LABEL, Section, SwitchDouble } from "oolib";
import styled from "styled-components";
import { useResearchNotesV1Context } from "../../../../../contexts/researchNotesV1Context";

export const RawSynthToggleDesktop = () => {
  const { ResearchNotesV1Context_GET_VIEW, ResearchNotesV1Context_DISPATCH } = useResearchNotesV1Context();
  const { view } = ResearchNotesV1Context_GET_VIEW();

  return (
    <div>
      <SwitchDouble
        value={{ value: view || "raw" }}
        options={[
          { value: "raw", display: "RAW NOTES MODE" },
          { value: "synth", display: " SYNTHESIS MODE" },
        ]}
        onChange={(id, chosenOption) =>
          ResearchNotesV1Context_DISPATCH({
            type: "SET_VIEW",
            payload: { view: chosenOption.value },
          })
        }
      />
    </div>
  );
};

const StyledToggleMobileWrapper = styled.div`
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RawSynthToggleMobile = () => {
  const { ResearchNotesV1Context_GET_VIEW, ResearchNotesV1Context_DISPATCH } = useResearchNotesV1Context();
  const { view } = ResearchNotesV1Context_GET_VIEW();

  return (
    <Section borderTop>
      <Container>
        <StyledToggleMobileWrapper>
          <LABEL>
            {view === "synth" ? "SYNTHESIS MODE" : "RAW NOTES MODE"}
          </LABEL>
          <SwitchDouble
            value={{ value: view || "raw" }}
            options={[
              { value: "raw", display: "" },
              { value: "synth", display: "" },
            ]}
            onChange={(id, chosenOption) =>
              ResearchNotesV1Context_DISPATCH({
                type: "SET_VIEW",
                payload: { view: chosenOption.value },
              })
            }
          />
        </StyledToggleMobileWrapper>
      </Container>
    </Section>
  );
};
