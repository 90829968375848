import { boxShadow1, colors2 as colors, transition } from "oolib";
import styled, { css } from "styled-components";


export const StyledCardShell = styled.div`
  border: 1px solid ${colors.grey20};
  border-radius: 0.5rem;
  cursor: pointer;
  background: ${colors.white};
  /* ${transition("box-shadow", "border-color")}; */
  &:hover {
    /* ${boxShadow1}; */
    border-color: ${colors.grey80};
  }
  width: 100%;
`;

export const StyledButton = styled.button`
  border: none;
  cursor: pointer;
  background-color: ${colors.white};
  /* padding-right: 0.5rem; */
  /* padding-left: 0.5rem; */
  /* margin-left: 0.5rem;   */
  display: inline-flex; 
  gap: 0.4rem; 
  align-items: center;
  ${transition('background-color')}
  :hover{
    background-color: ${colors.grey5};
  }
  border-radius: 0.5rem;
`

export const StyledIconWrapper = styled.div`
  ${transition('transform')};
  ${({show}) => !show && css`
    transform: rotate(180deg);
  `}
  display: flex;
`

export const StyledMetaBlockWrapper = styled.div`
  display: flex; 
  color: ${colors.grey80}; 
  gap: 0.6rem; 
  align-items: center; 
  padding-bottom: 1rem;

`