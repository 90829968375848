


/**
 * Calculates the total number of tags in a tags object structure
 * @param {Object} tagsObj - Object with structure { [collectionId]: { data: Array } }
 * @returns {number} Total count of tags across all collections
 */
const countTotalTags = (tagsObj) => {
    if (!tagsObj) return 0;
    
    return Object.values(tagsObj).reduce((total, collection) => {
      return total + (collection?.data?.length || 0);
    }, 0);
  };
  
  /**
   * Sorts data based on annotation tags count, document tags count, and title
   * @param {Array} data - Array of document objects
   * @returns {Array} Sorted array of data
   */
  const sortByAnnoTagsCountDesc = (data) => {
    return [...data].sort((a, b) => {
      // Count total annotation tags for both data
      const aAnnoCount = countTotalTags(a.annoTags);
      const bAnnoCount = countTotalTags(b.annoTags);
      
      // First sort by annotation tags count
      if (aAnnoCount !== bAnnoCount) {
        return bAnnoCount - aAnnoCount; // Descending order
      }
      
      // If annotation counts are equal, sort by doc tags count
      const aDocCount = countTotalTags(a.docTags);
      const bDocCount = countTotalTags(b.docTags);
      
      if (aDocCount !== bDocCount) {
        return bDocCount - aDocCount; // Descending order
      }
      
      // If both tag counts are equal, sort alphabetically by title
      return (a.main?.title || '').localeCompare(b.main?.title || '');
    });
  };

  /**
 * Sorts data alphabetically by title in ascending order (A to Z)
 * @param {Array} data - Array of document objects
 * @returns {Array} Sorted array of data
 */
const sortByAlphabeticalAsc = (data) => {
    return [...data].sort((a, b) => {
      const titleA = a.main?.title || '';
      const titleB = b.main?.title || '';
      return titleA.localeCompare(titleB);
    });
  };

  const latest = (data) => {
    return [...data].sort((a, b) => {
      const datePublishedA = a.kp_date_published;
      const datePublishedB = b.kp_date_published;
      return datePublishedB.localeCompare(datePublishedA);
    });
  }
  
  const oldest = (data) => {
    return [...data].sort((a, b) => {
      const datePublishedA = a.kp_date_published;
      const datePublishedB = b.kp_date_published;
      return datePublishedA.localeCompare(datePublishedB);
    });
  }

  const sortFnsLookup = {
    annoTagsCountDesc: sortByAnnoTagsCountDesc,
    alphabeticalAsc: sortByAlphabeticalAsc,
    latest: latest,
    oldest: oldest
  }

export const applyActiveSort = ({data, activeSort}) => {
    if(!data) return data;
    const sortFn = sortFnsLookup[activeSort];
    if (!sortFn) return data;
    return sortFn(data);
}