import axios from "axios";
import { useQuery } from "react-query";

export const useGetSuggestions = ({
  tagCategory,
  fieldValuePath,
  text,
  contentType,
  _id,
  enabled,
}) =>
  useQuery({
    queryKey: ["suggestions", _id, fieldValuePath],
    queryFn: () =>
      axios
        .post("/api/aiAnnotation/getSuggestions", {
          tagCategory,
          text,
          metaData: { parentDocId: _id, contentType, fieldValuePath },
        })
        .then((data) => data.data),
    enabled,
  });
