import { Loader } from 'oolib'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { __GetAccessibleContentTypes } from '../../../utils/accessControl'


import { useGetQueryState } from '../../../utils/react-query-hooks/general'
import { __GetAllContentTypeConfig } from '../../../utils/getters/gettersV2'

const RouteAccessSomeContent = ({ children, ACTION = "READ", contentTypes: _contentTypes, apiAccessCheck }) => {
  const location = useLocation()
  const { status, data: userData } = useGetQueryState('userData')

  const allContentTypes = __GetAllContentTypeConfig().map(d => d.kp_content_type);
  const contentTypes = _contentTypes || allContentTypes

  const hasAccess = () => {
		
		if (!userData?.user) {
			// not logged in
			return {
				has: __GetAccessibleContentTypes({user:undefined, ACTION, contentTypes}).length > 0,
				redirectTo: {
					pathname: '/login',
					state: {
						from: location.pathname + location.search,
					},
				},
			}
		}

		// else is logged in, and either has access to this content type or not.
		// if has, then let them through.
		// if not then simply redir back to the page they were coming from.
		return {
			has: __GetAccessibleContentTypes({user: userData?.user, ACTION, contentTypes}).length > 0,
			redirectTo: '/',
		}
	}

  if (status === 'loading') {
    return <Loader debug={'route access some content'} />
  }

  if (!hasAccess().has) {
    return <Navigate to={hasAccess().redirectTo} replace />
  }

  return children
}

export default RouteAccessSomeContent
