import React, { useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { injectQueryParamCarefully } from '../../../../../utils/general';

import { colors, ButtonGhost, getBreakPoint } from 'oolib';

import { StyledIndexBarWrapper, StyledIndexBar, StyledTabsDropdown } from '../../styled.js';

import TableOfContentButton from '../../../TableOfContent/comps/TableOfContentButton/index.js';

const {greyColor5, greyColor100, lightRed, red} = colors;

const TabsNav =({
  id,
  tabs,
  value,
  onChange,
  updateUrl,
  emptyTabs = [],
  barFocus:{barHasFocus, setBarHasFocus},
  screenWidth,
  invert
})=> {

  const navigate = useNavigate();
  const location = useLocation();

  const tabsDropdownWrapperRef = useRef()


  const handleClick = (v) => {
    /**
     * if a 'updateUrl' is being passed, then the parent comp
     * has to be dealing with activeTab state change in it's useEffect.
     * So we don't need to do it using onChange.
     * ONLY IF
     * the only thing onChange is doing in the parent is setActiveTab(...)
     * 
     */
    if(updateUrl){ 
      injectQueryParamCarefully('activeTab', v.value, location, navigate)
    }else{
      onChange && onChange(id, v);
    }
  }
  
  const userDevice = screenWidth > getBreakPoint("xl")? "desktop": "phone";
  const activeTab = tabs.find(tab=> tab.value === value.value);

  const genBarTab =({optionObj, handleClick, value, emptyBlocks})=> {
    const isActive = value && optionObj.value === value.value;
    const hasError = emptyBlocks && emptyBlocks.some(block=> (
      block.valuePath == optionObj.value ||
      block.sectionId == optionObj.value ||
      (block.sectionStack?.some(section=> section.sectionId === optionObj.value))
    ));
    return <TableOfContentButton {...{optionObj, invert, handleClick, isActive, hasError}}/> 
  }
 
  return (
    <StyledIndexBarWrapper barHasFocus={barHasFocus}>
      <StyledIndexBar
        invert={invert}
        barHasFocus={barHasFocus}
        onClick={() => userDevice !== "desktop" && setBarHasFocus(!barHasFocus)}
      >
        {userDevice === "desktop" ? (
          tabs.map((tab) =>
            genBarTab({
              optionObj: tab,
              handleClick,
              value,
              emptyBlocks: emptyTabs,
            })
          )
        ) : (
          <div style={{ position: "relative" }}>
            <div style={{ position: "relative" }}>
              <TableOfContentButton
                style={{
                  backgroundColor: invert ? greyColor100 : greyColor5,
                  boxShadow:
                    !!emptyTabs.length && `inset 0px -2.5px 0px ${red}`,
                  paddingRight: "4rem", // space for the caret
                }}
                {...{
                  optionObj: activeTab,
                  barHasFocus,
                  invert,
                  handleClick: () => setBarHasFocus(!barHasFocus),
                }}
              />
              <ButtonGhost
                icon={`Caret${barHasFocus ? "Down" : "Up"}`}
                invert={invert}
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  zIndex: 5,
                  bottom: "50%",
                  right: "1rem",
                  transform: "translateY(50%)",
                }}
              />
            </div>
            <StyledTabsDropdown
              mobileIndexOpen={barHasFocus}
              tabsDropdownWrapperRef={tabsDropdownWrapperRef}
              unfolded={barHasFocus}
            >
              <div ref={tabsDropdownWrapperRef}>
                {tabs.map((indexObj) =>
                  genBarTab({
                    optionObj: indexObj,
                    handleClick,
                    value,
                    emptyBlocks: emptyTabs,
                  })
                )}
              </div>
            </StyledTabsDropdown>
          </div>
        )}
      </StyledIndexBar>
    </StyledIndexBarWrapper>
  );
}

export default TabsNav;