import React from "react";
import { Tooltip, colors, getVal, icons } from "oolib";
import { StyledEllipsisRemoveWrapper } from "../utils/StyledEllipsisRemoveWrapper";

const { red, green } = colors;
const { CheckCircle, XCircle } = icons;

const RightWrong = ({ value, tableRowData, tooltipContentPath }) => {
  let isRight = value;


  const genTooltip = () => {
    const tooltipText = getVal(tableRowData, tooltipContentPath)?.join("\n");

    return (
      tooltipText && (
        <StyledEllipsisRemoveWrapper>
          <Tooltip
            text={tooltipText}
            position={"right"}
            popOutOfOverflowHiddenParent = {true}
            presetTarget={"infoIcon"}
          />
        </StyledEllipsisRemoveWrapper>
      )
    );
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
      {isRight ? (
        <CheckCircle size={20} color={green} />
      ) : (
        <XCircle size={20} color={red} />
      )}
      {tooltipContentPath && genTooltip()}
    </div>
  );
};

export default RightWrong;
