import { getText } from "oolib";
import { __GetContentTypeConfigNew } from "../getters/gettersV2";

export const fn = ({
  content,
  prevLoc,
  // _ContentTypes,
  _ProfileTypes,
  _TagTypes,
}) => {
  const {
    main: { title, kp_title } = {},
    meta: { kp_content_type: contentType, caseNumber },
  } = content;
  const thisContentTypeConfig = __GetContentTypeConfigNew(contentType)

  const contentTypeTitle = thisContentTypeConfig.general.content?.title;
  const listingPageEnable = thisContentTypeConfig.listing?.enable

  switch (true) {
    // direct land on pbl page via link
    case contentType === "staticPages":
      return undefined;
    case !prevLoc || !prevLoc[0]:
      return [
        //DB > PBL LIST > PBL PG
        { display: "My Dashboard", to: "/" },
        ...( listingPageEnable ? [{
          display: `${contentTypeTitle} Listing`,
          to: `/published-listing/${contentType}`,
        }] : []),
        { display: getText([title, kp_title, caseNumber]) },
      ];
    case prevLoc[0].pathname.includes("published-listing"):
      return [
        { display: "My Dashboard", to: "/" },
        {
          display: `${contentTypeTitle} Listing`,
          to: `/published-listing/${contentType}`,
        },
        { display: getText([title, kp_title, caseNumber]) },
      ];
    case prevLoc[0].pathname.includes("profile/userProfiles"):
      // return 'DB > PROFILE LIST > PROFILE LAND'
      let profileType = prevLoc[0].pathname.split("/").filter(Boolean)[2];
      let { title: profileTypeTitle, singular: PTSingular } =
        _ProfileTypes.find((d) => d.id === profileType).content;

      return [
        { display: "My Dashboard", to: "/" },
        {
          display: `${profileTypeTitle} Listing`,
          to: `/community-listing/${profileType}`,
        },
        { display: `${prevLoc[0].display} (${PTSingular})`, to: prevLoc[0] },
        { display: getText([title, kp_title, caseNumber]) },
      ];

    case prevLoc[0].pathname.includes("profile/groups"):
      // return 'DB > PROFILE LIST > PROFILE LAND'
      let groupProfileType = prevLoc[0].pathname.split("/").filter(Boolean)[2];
      let { title: groupTitle, singular: groupTSingular } =
        _ProfileTypes.find((d) => d.id === groupProfileType).content;

      return [
        { display: "My Dashboard", to: "/" },
        {
          display: `${groupTitle} Listing`,
          to: `/community-listing/${groupProfileType}`,
        },
        { display: `${prevLoc[0].display} (${groupTSingular})`, to: prevLoc[0] },
        { display: getText([title, kp_title, caseNumber]) },
      ];
  

    case prevLoc[0].pathname.includes("/tags/"):
      // return 'DB > TAG LIST > TAG LAND'
      let thisTagType = prevLoc[0].pathname.split("/").filter(Boolean)[1];
      let { title: tagTypeTitle, singular: TTSingular } = _TagTypes.find(
        (d) => d.tagType === thisTagType
      ).content;
      return [
        { display: "My Dashboard", to: "/" },
        { display: `${tagTypeTitle} Listing`, to: `/tags/${thisTagType}` },
        { display: `${prevLoc[0].display} (${TTSingular})`, to: prevLoc[0] },
        { display: getText([title, kp_title, caseNumber]) },
      ];
    case prevLoc[0].pathname.includes("MyContent"):
      return [
        { display: "My Dashboard", to: "/" },
        { display: "My Content", to: prevLoc[0] },
        { display: getText([title, kp_title, caseNumber]) },
      ];

    // pending : , orgs
    // pending participant manager redirect
    default:
      return undefined;
  }
};
