function extractMentions(obj) {
    const mentions = [];
  
    function traverse(node) {
      if (typeof node !== 'object' || node === null) {
        return;
      }
  
      if (node.type === 'mention') {
        mentions.push({mentionId: node.mentionId, mentionName: node.mentionName });
      }
  
      for (const key in node) {
        if (Array.isArray(node[key])) {
          node[key].forEach(traverse);
        } else if (typeof node[key] === 'object') {
          traverse(node[key]);
        }
      }
    }
  
    traverse(obj);
    return mentions;
  }
  
export { extractMentions }