import React from "react";
import { HeadingTools } from "./HeadingTools";
import { LinkTool, EmbedTool } from "./LinkAndEmbedTools";
import { MediaTools } from "./MediaTools";
import { StyledToolbar } from "./styled";
import { useEditorAndToolbarFocus } from "./utils/useEditorAndToolbarFocus";
import { ListTools } from "./ListTools";
import { useGetActiveNodeInfoInSelection } from "./utils/useGetActiveNodeInfoInSelection";
import { FormattingTools } from "./FormattingTools";
import { QuoteTool } from "./QuoteTool";
import { motion } from "framer-motion";
import { TableTool } from "./TableTool";
import { AnnoTool } from "./AnnoTool";
import { AIAnnoTool } from "./AIAnnoTool";
import { colors2, getBreakPoint, useScreenWidth } from "oolib";

const motionDivConfig = ({hasFocus, isMobile}) => ({
  style: {
    display: isMobile ? "grid" : "flex",
    alignItems: "center",
    width: "100%",
    ...(isMobile && {
      gridTemplateColumns: "repeat(6, 1fr)",
      justifyItems: "center",
    }),
  },
  initial: { marginLeft: "1rem", opacity: 0 },
  animate: hasFocus ? { marginLeft: 0, opacity: 1 } : {},
  transition: { type: "tween" }
})

export const ToolbarPlugin = ({ invert, annotation, enableAIAnnotation }) => {
  const { hasFocus, toolbarRef, onToolbarBlur } = useEditorAndToolbarFocus();

  const activeNodeInfo = useGetActiveNodeInfoInSelection();
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < getBreakPoint("sm");

  return (
    <StyledToolbar
      onBlur={onToolbarBlur}
      tabIndex={0}
      ref={toolbarRef}
      invert={invert}
      showToolbar={hasFocus} //}
    >
      <motion.div {...motionDivConfig({hasFocus, isMobile})}>
        <HeadingTools activeNodeInfo={activeNodeInfo} />
        <FormattingTools />
        <QuoteTool activeNodeInfo={activeNodeInfo} />
        <ListTools activeNodeInfo={activeNodeInfo} />
        <div style={{ display: isMobile ? "grid" : "none", gridColumnStart: 1, gridColumnEnd: 7, height: "1px", backgroundColor: colors2.grey5, width: "90%", justifySelf: "center" }}/>
        <MediaTools />
        <EmbedTool />
        <TableTool />
        <LinkTool />
        {annotation && <AnnoTool />}
        {annotation && enableAIAnnotation && <AIAnnoTool/>}
      </motion.div>
    </StyledToolbar>
  );
};
