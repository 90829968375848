import { css } from "styled-components";
import { colors2 } from "oolib";

export const createHoverEffect = (borderRadius) => css`
   &::before {
      content: '';
      background-color: transparent;
      position: absolute;
      border-radius: ${borderRadius || "0px"};
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
   }
   &:hover::before {
      background-color: ${colors2.grey10};
   }
`;