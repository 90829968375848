import { prepareListingRendererProps } from "../comps/ListingConfigsRenderer/prepareListingRendererProps.js";

export const prepareBodyConfig = (subSpaces, content, memo) => {
    return subSpaces.map((sub) =>
      sub.builder === "ListingBuilder"
        ? {
            display: sub.display,
            value: sub.value,
            comp: "ListingRenderer",
            props: prepareListingRendererProps({
              configs: sub.configs,
              content
            }),
          }
        : {
            display: sub.display,
            value: sub.value,
            comp:
              sub.builder === "FormBuilder"
                ? "FormRenderer"
                : sub.builder === "CustomCompInjector" && "CustomCompRenderer",
            props: {
              configs: sub.configs,
              content,
              memo,
            },
          }
    );
  };