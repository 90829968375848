import { Container, SANS_2, SANS_3, getText, getVal, parseCardConfig, PaddingBottom30, PaddingTopBottom30 } from "oolib";
import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";

import {PageScrollIndicator} from "oolib";
import PageMeta from "../../../../components/share/PageMeta";
import { usePlatformBuilderContext } from "../../../../contexts/platformBuilderContext";
import { __GetContentTypeConfigNew } from "../../../../utils/getters/gettersV2";


import groupsStyle1 from "../../../ContributeTpl/layouts/GroupsStyle1/Layout";
import applicationFormsStyle1 from "../../../ContributeTpl/layouts/ApplicationFormsStyle1/Layout";
import knowledgeResources2 from "../../../ContributeTpl/layouts/KnowledgeResources2/Layout";
import researchNotes from "../../../ContributeTpl/layouts/DEPR/ResearchNotes/Layout";
import researchNotes2 from "../../../ContributeTpl/layouts/ResearchNotes2/Layout";
import { defaultListingConfigForCardOrList } from "../../../PublishedListing/config/defaultListingConfigForCardOrList";
import { parseSpecialSyntax } from "../../../../utils/parseSpecialSyntax";
import PdfDownload from "../../../../components/plugins/PdfDownload";
import { useGetQueryData } from "../../../../utils/react-query-hooks/general";
import { ContentEngagementButtonPanel } from "../ContentEngagementButtonPanel";

export default function PublishedPageLayoutRenderer({ content, tpl, contentType}) {

  const location = useLocation();

  const {
    deployment: { _EnablePdfDownload },
  } = useGetQueryData("platformConfigs");

  const contentTypeConfig =  __GetContentTypeConfigNew(tpl.kp_content_type)

  const templateIndex = contentTypeConfig?.general?.templateIndex
  const listingConfig = ['CardsStyle', 'ListStyle'].includes(contentTypeConfig?.listing?.listingStyle)
    ? contentTypeConfig?.listing?.listingConfig.configs 
    : defaultListingConfigForCardOrList.configs //read inside defaultListingConfigForCardOrList why we are doing this

  const extractedToShare = parseCardConfig({data:content, config: listingConfig, parseSpecialSyntax }) || {}


  const {platformBuilderMode} = usePlatformBuilderContext()

  const genTpl = () => {
    const Layouts = {
        researchNotes,
        groupsStyle1,
        knowledgeResources2,
        applicationFormsStyle1,
        researchNotes2
    }
    const Layout = Layouts[tpl.category || 'knowledgeResources2']
    return (
      <>
      <Layout
        {...{
          content,
          tpl,
          readOnly: true,
          showIndexbar: templateIndex?.enableOnPublishedPage,
          indexType: templateIndex?.type,
        }}
        />
      {!platformBuilderMode && <ContentEngagementButtonPanel {...{...listingConfig, content, contentType, contentTypeConfig}}/>}
      <PaddingTopBottom30/>
      </>
    );
  };


  return (
    <div className="OKE-Tpl">
      {tpl && (
        <Fragment>
          {
            //no need for PageMeta during PublishedPage preview inside tci
            !platformBuilderMode && contentType !== "staticPages" && (
              <PageMeta
                title={extractedToShare.title}
                image={extractedToShare.image}
                description={getText(
                  getVal(content, [
                    "main.subtitle",
                    "main.summary",
                    "main.kp_summary",
                    "main.description",
                    "main.kp_about",
                  ])
                )}
                url={window.location.href}
                location={location}
              />
            )
          }

          {/* {updateReducerData.mode !== null && <CreateUpdatePopUp />} */}
          <div
            className={`TemplatePublishedPage ${contentType}`}
            id="tplPublishedPage"
          >
            {
            /**
             * seems kind of ridiculous to show this on collection pages, since 
             * in them, 'about' sections are usually quite short, and the listing
             * sections are inifite scroll so... no need for this.
             */
            tpl.general.segment !== 'collections' && <PageScrollIndicator />
            }
            {_EnablePdfDownload?.enable && tpl.general.segment === 'publishing' ? (
              <Container>
                <div
                  style={{
                    display: "flex",
                    gap: "2rem",
                    alignItems: "center",
                    justifyContent: "end",
                    borderBottom: "1px solid #e1e1e1",
                    padding: "1rem 2rem",
                  }}
                >
                  <SANS_3>To download click here</SANS_3>
                  <PdfDownload />
                </div>
              </Container>
            ) : null}
 
            <div id="TPL"> { /* "id is used for download pdf" */}
              {" "}
             
              {genTpl()}
            </div>
          </div>
        </Fragment>
      )}
      
    </div>
  );
}
