import { transition, colors2, UI_BODY_SM_DF, colors } from "oolib";
import styled from "styled-components";


const { grey50 } = colors2

export const StyledCoverWrapper = styled.div`
   
    position: sticky;
    top: 0;
    height: 25rem;
    width: 100%;
   
    &::before { //probably unused.
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 25rem;
        background: red;
        z-index: -1;
    }
   

    /* ${transition("transform 0.1s ease-out", "filter 0.1s ease-out")}; */
`;

export const StyledWhiteBgBehindImage = styled.div`
position: absolute;
        top: 0;
        left: 0;
        height: 34rem;
        /* background: ${colors2.white}; */
        width: 100%;
        background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 90%,
    rgba(255, 255, 255, 0) 100%
  );
`

export const StyledCoverBackgroundImgHolder = styled.div`
    ${({ src }) => src && `background-image: url(${src});`}
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
`;

export const StyledTransitionWrapper = styled.div`
    ${transition("transform 0.3s ease-out", "filter 0.3s ease-out")};
`

export const StyledInfoSection = styled.div`
    padding: 16px;
    position: relative;
    border-radius: 12px;
    background: ${colors.white};
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    margin: 0px 20px;
    margin-top: -50px;
    /* margin-top: ${({ hasCoverImage, coverExists }) => hasCoverImage || coverExists ? '-50px' : '10px'}; */
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    ${transition("height", "opacity 0.1s ease-out")};
`

export const StyledMetaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    height: auto;
    ${transition("height")}
`

export const StyledActionsWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: stretch;
`  

export const StyledDescButton = styled.button`
    border: none;
    outline: none;
    background: white;
    cursor: pointer;  
    display: flex;
`

export const StyledDesc = styled.div`
    height: ${({ desc, showDesc }) => (desc && showDesc) ? 'max-content' : '0px'};
    overflow: hidden;
    ${transition('height')}
    
`

export const DescriptionText = styled(UI_BODY_SM_DF)`
  align-self: flex-start;
  color: ${grey50};
`;
