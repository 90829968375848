import { 
  ButtonPrimary, 
  colors, 
  Container, 
  Section, 
  Wrapper700, 
  PaddingTopBottom15 } from "oolib";
import React, { useEffect, useRef, useState } from "react";
import IndexBar from "../../../../../components/generalUI/IndexBar";
import KPMetaPrimary from "../../../../../components/generalUI/KPMetaPrimary";
import TplMetaBlock from "../../../../../components/generalUI/TplMetaBlock";
import {
  SubtitleInput,
  TitleInput,
} from "../../../../../components/inputs/TextEditor/RichTextEditor/derivedComps/PresetRichInputs";
import { useResearchNotesV1Context } from "../../../../../contexts/researchNotesV1Context";
import { useFormValidationContext } from "../../../../../contexts/formValidationContext";

import { AnimatePresence, motion } from "framer-motion";

import {
  EditBlockGenerator,
  ViewBlockGenerator,
} from "../../../../../utils/blockGenerators";
import { blockMapper } from "../../../../../utils/templating/blockMapper";
import { DefaultContentBlocksWrapper } from "../../../../../utils/templating/layout";
import { spaceOps } from "../../../../../utils/templating/spaceAndDivisionConfigs";
import { ContentHeader } from "../../../comps/ContentHeader";
import { useLocation } from "react-router-dom";
import { getQueryParam } from "../../../../../utils/general";

const { greyColor100 } = colors;

const Layout = ({
  tpl: tplProp,
  handleOnChange,
  content,
  readOnly: readOnlyProp,
  memo,
  useButtonForViewToggle,
  showIndexbar,
  indexType
}) => {
  const { formValidation } = useFormValidationContext();

  const location = useLocation();

  const tpl = tplProp;

  const pageCoverRef = useRef(null);

  const firstLevelBlocksAreSections = (blocksAry) =>
    blocksAry.every((b) => !!b.blocks === true);

  const getTabbarOptionsFromSynth = (tpl) => {
    if (firstLevelBlocksAreSections(tpl.kp_templates.synth.blocks)) {
      //meaning first level is sections
      return tpl.kp_templates.synth.blocks.map((d) => ({
        display: d.sectionTitle,
        value: d.sectionId,
      }));
    } else {
      return [{ display: "Synth", value: "synth" }];
    }
  };

  const viewFromUrl = getQueryParam(location.search, "activeView");
  const sectionIdFromUrl = getQueryParam(location.search, "activeSection");


  const tabBarOptions = [...getTabbarOptionsFromSynth(tpl)];

  console.log({tabBarOptions,tpl})


  const filterdOption = tabBarOptions.filter(
    (d) => d.value === sectionIdFromUrl
  );

  const [activeTab, setActiveTab] = useState(
    filterdOption.length ? filterdOption[0] : tabBarOptions[0]
  );

  // const [view, setView] = useState("raw");
  const { ResearchNotesV1Context_GET_VIEW, ResearchNotesV1Context_DISPATCH } = useResearchNotesV1Context();

  


  const view =  ResearchNotesV1Context_GET_VIEW().view || viewFromUrl;

  useEffect(() => {
    return () => {
      ResearchNotesV1Context_DISPATCH({ type: "CLEAR_ONE", payload: "view" });
    };
  }, []);

  const viewData = ResearchNotesV1Context_GET_VIEW().data;
  
  useEffect(() => {
    if (!viewData) return;

    setTimeout(() => {
      const elemNode = document.getElementsByClassName(viewData);

      if (elemNode[0]) {
        // const top = elemNode[0].offsetTop
        // window.scroll({ top: top,behavior:"smooth"})
        elemNode[0].click();
      }
    }, 0);

    return () => ResearchNotesV1Context_DISPATCH({ type: "CLEAR_ONE", payload: "scrollToElem" });
  }, [viewData]);

  //   const view = ResearchNotesV1Context_STATE?.view || "raw"

  const genBlocks = ({ spaceId, activeTab }) => {
    let activeSpace = spaceOps.researchNotes.find((d) => d.value === spaceId);
    return blockMapper({
      activeSpace,
      activeTab,
      tplConfig: tpl,
      content,
      blockGenerator: ({ block, blockPath, blockLocation }) => {
        /**
         * readOnly : applied at THIS genBlocks function level
         * readOnlyProp : applied at the entire template level
         */
        let GeneratorComp = readOnlyProp
          ? ViewBlockGenerator
          : EditBlockGenerator;
        return (
          <GeneratorComp
            memo={memo}
            block={block}
            onChange={handleOnChange && handleOnChange}
            content={content}
            Wrapper={PaddingTopBottom15}
            formValidation={formValidation}
            isNewTemplate={true}
          />
        );
      },
      ContentBlocksWrapper: DefaultContentBlocksWrapper,
    });
  };

  return (
    <Section>
      {readOnlyProp && (
        <ContentHeader content={content} pageCoverRef={pageCoverRef} />
      )}
      <div ref={pageCoverRef}>
        <Container style={{ backgroundColor: greyColor100 }}>
          <Wrapper700>
            <header
              style={{ padding: `6rem 0 ${readOnlyProp ? "2rem" : "6rem"}` }}
            >
              <KPMetaPrimary
                data={{ key: "Type", valuePath: ["meta.kp_content_type"] }}
                invert={true}
                content={content}
              />
              <PaddingTopBottom15>
                {content.main?.title && (
                  <TitleInput textAlignment="left" value={content.main.title} readOnly={true} />
                )}
              </PaddingTopBottom15>
              {content.main?.subtitle && (
                <SubtitleInput textAlignment="left" value={content.main.subtitle} readOnly={true} />
              )}

              {
                //readOnly means we are in a published page
                // useButtonForViewToggle is used in TCI
                (useButtonForViewToggle || readOnlyProp) && (
                  <ButtonPrimary
                    icon="Eye"
                    children={
                      view === "synth" ? "View Raw Notes" : "View Synthesis"
                    }
                    onClick={() => {
                      view === "synth"
                        ? ResearchNotesV1Context_DISPATCH({
                            type: "SET_VIEW",
                            payload: { view: "raw" },
                          })
                        : ResearchNotesV1Context_DISPATCH({
                            type: "SET_VIEW",
                            payload: { view: "synth" },
                          });

                      
                    }}
                    style={{ marginTop: "1.5rem" }}
                  />
                )
              }

              {readOnlyProp && (
                <div style={{ paddingTop: "6rem" }}>
                  <TplMetaBlock
                    showSeparator={true}
                    data={[
                      {
                        img: "meta.kp_contributed_by.avatar",
                        user: "meta.kp_contributed_by.name",
                      },
                      { date: "kp_date_published" },
                    ]}
                    content={content}
                    style={{ marginTop: "1rem", padding: "1.4rem 0" }}
                  />
                </div>
              )}
            </header>
          </Wrapper700>
        </Container>
      </div>
      <Container style={{ position: "relative" }}>
        <AnimatePresence>
          {view === "synth" ? (
            <div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ type: "tween" }}
                exit={{ opacity: 0 }}
              >
                {showIndexbar ? (
                  <IndexBar
                  indexType={indexType}
                    options={tabBarOptions}
                    value={activeTab}
                    onChange={(k, v) => setActiveTab(v)}
                    emptyBlocks={
                      !readOnlyProp &&
                      formValidation.makeVisible &&
                      formValidation.failedBlocks
                        .map((block) => block.sectionStack)
                        .flat()
                    }
                    type="tabs"
                  />
                ) : null}
              </motion.div>
              <motion.div
                key={"synth"}
                initial={{ x: 50 }}
                transition={{ type: "tween" }}
                animate={{ x: 0 }}
                exit={{ x: 50, opacity: 0 }}
              >
                {genBlocks({ spaceId: "synth", useTabs: true, activeTab })}
              </motion.div>
            </div>
          ) : (
            <motion.div
              key={"raw"}
              initial={{ x: -50, opacity: 0 }}
              transition={{ type: "tween" }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: -50, opacity: 0 }}
            >
              {genBlocks({ spaceId: "raw" })}
            </motion.div>
          )}
        </AnimatePresence>
      </Container>
    </Section>
  );
};

export default Layout;
