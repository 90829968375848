import React from "react";
import { useLocation } from "react-router-dom";
import { LoginLink } from "../PRIMLinkAndLoginLink";

export const GuestLinks = () => {

  const location = useLocation();
  return (
    <div style={{padding: '0 0.5rem', height: '100%'}}>
    <LoginLink
      to={{
        pathname: "/login",
        state: { from: location.pathname + location.search },
      }}
      display="Login"
    />
    </div>
  );
};
