import React from "react";
import { StyledMainContent } from "./styled";

import { ContentSection } from "./comps/ContentSection";
import { FiltersSidebarShell } from "./comps/FiltersSidebarShell";
import { getBreakPoint, useScreenWidth } from "oolib";

export const DESKTOP_SIDEBAR_WIDTH = 300;
export const MOBILE_SIDEBAR_WIDTH = 450;
export const LEFT_RIGHT_PADDING = 20;

export const Shell = ({
  filter,
  contentConfig,
  isMobile,
  actionBarHeight,
  tabsPanelHeight,
  showSideBarLeft,
  setShowSideBarLeft,
}) => {
  return (
    <StyledMainContent showSideBarLeft={showSideBarLeft} isMobile={isMobile}>
      {filter && showSideBarLeft && (
        <FiltersSidebarShell
          sidebarWidth={isMobile ? MOBILE_SIDEBAR_WIDTH : DESKTOP_SIDEBAR_WIDTH}
          showSideBarLeft={showSideBarLeft}
          setShowSideBarLeft={setShowSideBarLeft}
          top={(actionBarHeight || 0) + (tabsPanelHeight || 0)}
          isMobile={isMobile}
        >
          {filter}
        </FiltersSidebarShell>
      )}
      <ContentSection
        showSideBarLeft={showSideBarLeft}
        contentConfig={contentConfig}
        isMobile={isMobile}
      />
    </StyledMainContent>
  );
};
