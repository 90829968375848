import styled from "styled-components";

export const ErrorMessageStyled = styled.div`
  padding: 1.2rem;
  width: fit-content;
  border-radius: 0.6rem;
  color:#C41717;
  background-color: #fff;
  box-shadow: 0px 4px 10px 0px #0000000f;
`;
