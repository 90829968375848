import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React from "react";
import { ToolbarButton } from "../comps/ToolbarButton";
import { SHOW_ANNO_SUGGESTIONS } from "../../AnnoSuggestionsPlugin";

const config = {
  icon: "MagicWand",
};

export const AIAnnoTool = () => {
  const [editor] = useLexicalComposerContext();

  return (
    <>
      <ToolbarButton
        onClick={() => editor.dispatchCommand(SHOW_ANNO_SUGGESTIONS)}
        icon={config.icon}
      />
    </>
  );
};
