import React, { Fragment, useState } from "react";
import { ButtonGhost, LoaderCircle } from "oolib";
import {
  StyledActionsWrapper,
} from "../styled";
import { handleDataExport } from "../../../../../utils/general";
import { Sort } from "./Sort";
import { Search } from "./Search";

export const MobilePanel = ({
  filtersExist,
  showSearchInput,
  showSortDropdown,
  activeSort,
  setActiveSort,
  sortConfig,
  showSideBarLeft,
  setShowSideBarLeft,
  showDownloadDataButton,
  csvRefetch,
  searchTermInputText,
  handleSearch,
}) => {
  const [showSearchInputMobile, setShowSearchInputMobile] = useState(false);
  const [exporting, setExporting] = useState(false);

  return (
    <StyledActionsWrapper>
        {/* Search */}
        {showSearchInputMobile ? (
          <div style={{ height: "3.4rem", width: "100%" }}>
            <Search 
              isMobile={true}
              onClose={() => setShowSearchInputMobile(false)}
              searchTermInputText={searchTermInputText}
              handleSearch={handleSearch}
            />
          </div>
        ) : (
        <Fragment>
          <div style={{ display: "flex", alignItems: "center", gap: "0.6rem" }}>
            {/* Filter */}
            {filtersExist && (
              <ButtonGhost
                icon="Funnel"
                onClick={() => setShowSideBarLeft(!showSideBarLeft)}
                iconSize={'M'}
              />
            )}

            {/* Search Button */}
            {showSearchInput && (
              <ButtonGhost
                icon="MagnifyingGlass"
                onClick={() => setShowSearchInputMobile(true)}
                iconSize={'M'}
              />
            )}

            {/* Sort */}
            {showSortDropdown &&
              <Sort 
                {...{
                  sortConfig,
                  activeSort,
                  setActiveSort,
                  genCustomSelectComp : () => <ButtonGhost icon="SortDescending"  iconSize={'M'}/>
                }}
              />}
          </div>

            {/* Download */}
            {showDownloadDataButton && (
              exporting ? (
                <div style={{ padding: "0.5rem" }}>
                  <LoaderCircle S />
                </div>
              ) : (
                <ButtonGhost
                  icon="DownloadSimple"
                  onClick={() => handleDataExport({ refetch: csvRefetch, setExporting })} 
                  iconSize={'M'}
                />
              )
            )}
        </Fragment>
      )}
    </StyledActionsWrapper>
  );
};

