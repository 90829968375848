import styled from "styled-components";

export const CardWrapperStyled = styled.div`
  background: #ffffff;
  border: 1px solid #ebebeb;
  display: flex;
  gap: 1rem;
  padding: 1rem;
  height: fit-content;

  border-radius: 2px;
  z-index: 1000;
`;
