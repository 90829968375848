import { Container, Section, Wrapper1000 } from "oolib";
import React from "react";

export const HeaderContributeTpl = ({
  PrimaryCTA,
  SaveStatus,
  CenterComp,
  CloseButton,
  AfterComp,
}) => {
  return (
    <Section
      borderBottom
      white
      style={{ position: "sticky", top: 0, zIndex: 999999 }}
    >
      <Container>
        <Wrapper1000>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              columnGap: "2rem",
              // justifyContent: "space-between",
              minHeight: "6rem",
              padding: "1rem 0",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
              }}
            >
              {PrimaryCTA}
              {SaveStatus}
            </div>
            {CenterComp}

            {CloseButton}
          </div>
        </Wrapper1000>
      </Container>
      {AfterComp}
    </Section>
  );
};
