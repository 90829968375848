import React from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { __CheckAccessToContentType } from '../../../utils/accessControl'
import { useGetQueryState } from '../../../utils/react-query-hooks/general'
import { Loader } from 'oolib'
import { getQueryParam } from '../../../utils/general'

const RouteAccessOneContent = ({
  children,
  ACTION,
  ignoreExtendedIsMemberCheck
}) => {
  const location = useLocation()
  const params = useParams() // use hooks to get params
  const { status, data: userData } = useGetQueryState('userData')

  if (status === 'loading') {
    return <Loader S debug={'route access one content'} />
  }

  // Get content type from URL params directly
  const contentType = params.contentType || params.content_type

  const checkAccess = () => {
    const contentId = getQueryParam(location.search, 'id')
    const accessCheckOptions = {
      contentId,
      ignoreExtendedIsMemberCheck
    }

    if (!userData?.user) {
      return {
        has: __CheckAccessToContentType(
          undefined,
          ACTION || 'READ',
          contentType,
          accessCheckOptions
        ),
        redirectTo: '/login'
      }
    }

    return {
      has: __CheckAccessToContentType(
        userData?.user,
        ACTION || 'READ',
        contentType,
        accessCheckOptions
      ),
      redirectTo: '/'
    }
  }

  const access = checkAccess()

  if (!access.has) {
    return (
      <Navigate 
        to={access.redirectTo} 
        state={{ from: location.pathname + location.search }}
        replace 
      />
    )
  }

  return children
}

export default RouteAccessOneContent