import React, { Fragment } from 'react';
import KPShareActions from '../../../../components/share/KPShareActions';
import useShareContent from '../../../../utils/react-query-hooks/ShareUrl/useShareContent';
import { CommentsButton } from './Comps/CommentsBtn';
import { ReactionBtn } from './Comps/ReactionBtn';
import { ShareBtn } from './Comps/ShareBtn';
import { StyledDivider } from './Comps/styled';
import { colors } from 'oolib';
import { useGetQueryData } from '../../../../utils/react-query-hooks/general';


export const ContentEngagementButtonPanel = ({content, contentType, invert, contentTypeConfig}) => {
  const userData = useGetQueryData("userData");
    const { sharing, reactions, comments } = contentTypeConfig?.general
    
    const ShowShareButton = sharing.enable; //content type config flag

    const ShowReactionButton = reactions?.like?.enable && !!userData?.user  //content type config flag
    const ShowCommentsButton = comments?.enable && !!userData?.user
    
    const {
        _id: contentId,
        comments: _comments
      } = content;
    
    const { handleShareButtonClick, shortUrl, setShortUrl, genShareUrl } = useShareContent(contentId, contentType, content)    
    
    const renderAry = [
      ...((ShowReactionButton && ReactionBtn) ? [<ReactionBtn {...{ invert }}/>] : []),
      ...((ShowCommentsButton && CommentsButton) ? [<CommentsButton {...{ _comments, invert}}/>] : []),
      ...((ShowShareButton && ShareBtn) ? [<ShareBtn {...{ handleShareButtonClick, genShareUrl, invert, sharesCount: sharing?.trackShareCount ? content?.shares?.count : undefined}} />] : []),
    ];

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem'}}>
            {renderAry.map((d, i) => (
              <Fragment>
                {d}
                {i !== renderAry.length - 1 && <StyledDivider style={{ backgroundColor: colors.greyColor15 }} />}
              </Fragment>
            ))}
            {shortUrl && <KPShareActions {...{shortUrl, setShortUrl}} /> }
        </div>
  )
}
