import {
  Container,
  EmptyStates,
  makeArrayFromLength,
  SANS_4,
  SkeletonLoader,
  WrapperCardGrid,
} from "oolib";
import React, { useEffect, useState } from "react";
import { TotalCount } from "../../pageTpls/ListingPage/comps/TotalCount";
import { CardBuilder, CardExpandComps } from "../Synthesis/comps";
import { stockResearchNotesCardConfig } from "../Synthesis/tenantConfigs/qakms/listingConfig/cardsConfig";
import { stockResearchNotesExpandCompConfig } from "../Synthesis/tenantConfigs/qakms/listingConfig/expandCompConfig";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

const genSkeletonCards = (int) =>
  makeArrayFromLength(int).map(() => (
    <SkeletonLoader
      style={{
        height: "20rem",
      }}
    />
  ));
export const AnnotationCardStyle = ({
  data,
  tagCategory,
  tag,
  cardConfig,
  expandCompConfig,
  totalCount,
  searchSortBarRef,
  status,
  isFetchingNextPage,
  observerRef,
  onClickAction = "sidebar"
  // tagIdSliderRef,??
}) => {
  const [expandCard, setExpandCard] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (tag) {
      setExpandCard(false);
    }
  }, [tag]);

  const handleCardClick = (d) => {
    if (onClickAction === "link") {
      navigate(`/published-page/${d.meta.kp_content_type}?id=${d._id}`);
    } else {
      if (expandCard && d._id === expandCard._id) {
        setExpandCard(false);
        return;
      }

      setExpandCard(d);
    }
  };

  if(status==="error"){
    return <Container><SANS_4>Something went wrong!</SANS_4></Container>
  }

  return (
    <div style={{ flex: 1, display: "flex" }}>
      <div style={{ width: "100%" }}>
        {status === "success" && data.length === 0 ? (
          <>
            <div style={{ paddingTop: "6rem" }}>
              <EmptyStates preset={"dashboardListing"} />
            </div>
          </>
        ) : (
          <Container>
            <div
              style={{
                display: "flex",
                marginRight: "1rem",
                paddingBottom: "1rem",
              }}
            >
              <TotalCount totalCount={totalCount} />
            </div>

            <WrapperCardGrid>
              {status === "loading" ? (
                genSkeletonCards(6)
              ) : (
                <CardBuilder
                  onCardClick={(d) => handleCardClick(d)}
                  data={data}
                  annoTagType={tagCategory.value}
                  listingCardConfig={cardConfig || stockResearchNotesCardConfig}
                  activeCardId={expandCard._id}
                  tagId={tag}
                  observerRef={observerRef}
                />
              )}

              {isFetchingNextPage && genSkeletonCards(6)}
            </WrapperCardGrid>
          </Container>
        )}
      </div>
      <AnimatePresence>
        {expandCard && (
          <CardExpandComps
            expandCard={expandCard}
            handleClose={() => setExpandCard(false)}
            annoTagType={tagCategory}
            tagId={tag}
            stickBelowElementRef={searchSortBarRef}
            listingExpandCompConfig={
              expandCompConfig || stockResearchNotesExpandCompConfig
            }
          />
        )}
      </AnimatePresence>
    </div>
  );
};
