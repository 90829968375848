import React from 'react'
import { _Locale } from '../../locale/Locale'
import { SANS_2 } from 'oolib'

const SaveStatus = (props) => {
  //this passes a props.status.type which gives success | failed | inactive , but we are not using it right now
  return (
    <div className="kp-save-status-wrapper" style={props.style}>
      {props.status && (
        <SANS_2>{_Locale(props.status.msg || props.status)}</SANS_2>
      )}
    </div>
  )
}

export default SaveStatus
