import { ProfileImageInput, getBreakPoint, useScreenWidth } from 'oolib';
import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { useLogout } from '../../../../../../../utils/react-query-hooks/auth';
import { useGetQueryData } from '../../../../../../../utils/react-query-hooks/general';
import { DropdownSingle } from 'oolib';

import { MyDashboardNavMenu } from '../../../../../MyDashboardMenu';
import { useIsThisLinkActive } from '../../utils/useIsThisLinkActive';
import { useGetMyAccountMenuOptions } from './utils/useGetMyAccountMenuOptions';
import { usePlatformBuilderContext } from '../../../../../../../contexts/platformBuilderContext';



export const MyAccountMenu = () => {

    const screenWidth = useScreenWidth()
    const navigate = useNavigate()
    const LG = screenWidth >= getBreakPoint('lg')
    const [showDashboardMenu, setShowDashboardMenu] = useState(false);
    const {platformBuilderMode} = usePlatformBuilderContext()
    const {getActiveMenuOption} = useIsThisLinkActive()

    const myAccountMenuOptions = useGetMyAccountMenuOptions()

    const {logout} = useLogout()

    const {user} = useGetQueryData('userData') || {}

  const genCustomSelectComp = (onClick) => (
    <button className="OKE-Header__myAccountMenuButton" onClick={onClick} style={{ cursor: "pointer" }}> 
      <div id={"OKE-Header-myAccountMenuButton"}> {/* Id required of Coachmarks */}
        <ProfileImageInput
          readOnly
          value={user?.avatar?.userUploaded}
          size={30}
          imageTitle={user?.name}
          />
      </div>
    </button>
  );

  const handleChange = (k, v) => {
    if (platformBuilderMode)  return;

    if (v.value === "logout") {
      logout();
    } else {
        navigate(v.to);
    }
  };

  if(LG){
    return (
      <DropdownSingle
        id='myAccountMenu'
        className='OKE-Header__myAccountMenu'
        genCustomSelectComp={genCustomSelectComp}
        value={getActiveMenuOption({options: myAccountMenuOptions})}
        options={myAccountMenuOptions}
        onChange={(k, v) => handleChange(k, v)}
        optionsAnchor='right'
        optionsClassName={'SANS_2'}
      />
    );
  }else{
    return (
      <Fragment>
        <div style={{height: '100%'}}>{
          genCustomSelectComp(
            (ev) => {
              ev.stopPropagation()
              setShowDashboardMenu(true)
            }
        )}</div>
        <MyDashboardNavMenu
          open={showDashboardMenu}
          onClose={() => setShowDashboardMenu(false)}
          />
      </Fragment>
    )
  }

  
};