import React from "react";
import { TableOfContentButtonsList } from "../comps/TableOfContentButtonsList";
import TableOfContentButton from "../comps/TableOfContentButton";
import { ButtonGhost, colors, transition } from "oolib";
import { useState } from "react";
import { useRef } from "react";
import styled from "styled-components";

const StyledTableOfContentMobileOptionsWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 100%;
  border: 1px solid ${colors.greyColor5};
  border-bottom: none;
  overflow: hidden; // for clipping animation
  height: ${({ openTableOfContent, tabsDropdownWrapperRef }) =>
    openTableOfContent
      ? tabsDropdownWrapperRef.current?.getBoundingClientRect().height + "px"
      : 0};
  ${transition("height")}
`;

export const TableOfContentMobile = ({
  onChange,
  value,
  invert,
  failedBlocks,
  options,
}) => {
  const tabsDropdownWrapperRef = useRef();
  const [openTableOfContent, setOpenTableOfContent] = useState(false);
  return (
    <div style={{ position: "sticky", bottom: 0, zIndex: 10 }}>
      <div style={{ position: "relative" }}>
        <TableOfContentButton
          style={{
            backgroundColor: invert ? colors.greyColor100 : colors.greyColor5,
            paddingRight: "4rem", // space for the caret
          }}
          {...{
            optionObj: value,
            invert,
            handleClick: () => setOpenTableOfContent((prev) => !prev),
          }}
        />
        <ButtonGhost
          icon={`Caret${openTableOfContent ? "Down" : "Up"}`}
          invert={invert}
          style={{
            backgroundColor: "transparent",
            position: "absolute",
            zIndex: 5,
            bottom: "50%",
            right: "1rem",
            transform: "translateY(50%)",
          }}
        />
      </div>
      <StyledTableOfContentMobileOptionsWrapper
        openTableOfContent={openTableOfContent}
        tabsDropdownWrapperRef={tabsDropdownWrapperRef}
      >
        <div ref={tabsDropdownWrapperRef}>
          <TableOfContentButtonsList
            options={options}
            handleClick={(v) => {
              onChange(undefined, v)
              setOpenTableOfContent(false)
            }}
            failedBlocks={failedBlocks}
            value={value}
            invert={invert}
          />
        </div>
      </StyledTableOfContentMobileOptionsWrapper>
    </div>
  );
};
