import React from "react";

import { colors2, icons } from "oolib";
import { StyledSideBar } from "./styled";

const { CaretLeft } = icons;


export const FiltersSidebarShell = ({
  sidebarWidth,
  showSideBarLeft,
  children,
  isMobile,
  top,
}) => (
  <StyledSideBar
    showSideBarLeft={showSideBarLeft}
    top={top}
    isMobile={isMobile}
    sidebarWidth={sidebarWidth}
  >
    {children}
  </StyledSideBar>
);
