import { useMemo } from "react";
import { useAppSettingsContext } from "../../contexts/appSettingsContext";
import { useGetQueryData } from "../react-query-hooks/general";
import { fn as PublishedPage } from "./PublishedPage";

const fns = {
  PublishedPage,
};

export const useGetBreadcrumbLinksData = ({ content, comp }) => {
  const { APP_SETTINGS } = useAppSettingsContext();
  const { prevLoc } = APP_SETTINGS;
  const { _ProfileTypes, _TagTypes } = useGetQueryData("platformConfigs");

  let breadcrumbLinksData = useMemo(() => {
    if (content) {
      return fns[comp]({ content, prevLoc, _ProfileTypes, _TagTypes });
    }
  }, [content]);

  return breadcrumbLinksData;
};
