import React from "react";

import {
  StyledActionsPanelWrapper,
} from "./styled";
import { MobilePanel } from "./comps/MobilePanel";
import { DesktopPanel } from "./comps/DesktopPanel";

export const ActionsPanel = (props) => {

  const {
  showSideBarLeft,
  isMobile,

  actionRef,
  tabsPanelHeight,
  stickyTitleHeight,
  
  style = {},
  } = props
  
  return (
    <StyledActionsPanelWrapper
      style={{
        ...style,
      }}
      ref={actionRef}
      tabsPanelHeight={tabsPanelHeight}
      sidebar={showSideBarLeft}
      isMobile={isMobile}
      stickyTitleHeight={stickyTitleHeight}
    >
      {isMobile ? (
        <MobilePanel {...props} />
      ) : (
        <DesktopPanel {...props} />
      )}
    </StyledActionsPanelWrapper>
  );
};
