import { useEffect, useRef, useState } from "react";


export const useSectionHeightTracker = ({ screenWidth, isMobile, activeTab }) => {
  
  const actionRef = useRef()
  const tabsRef = useRef()
  const mobileTitleRef = useRef(null);

  const [ tabsPanelHeight, setTabsPanelHeight ] = useState(0)
  const [ actionBarHeight, setActionBarHeight ] = useState(0); 
  const [ stickyTitleHeight, setStickyTitleHeight ] = useState(0);

  useEffect(() => { 
    
    if(actionRef?.current){
      setActionBarHeight(actionRef?.current?.getBoundingClientRect().height )
    }else{
      setActionBarHeight(0)
    }

    if(tabsRef?.current){
      setTabsPanelHeight(tabsRef?.current?.getBoundingClientRect().height )
    }else{
      setTabsPanelHeight(0)
    }

    if( isMobile && mobileTitleRef?.current ){ // this only works on mobile for sticky title
      setStickyTitleHeight(mobileTitleRef?.current?.getBoundingClientRect().height )
    }else{
      setStickyTitleHeight(0)
    }

  }, [ screenWidth, activeTab ]);
  /* 
   * because when tab on collection page changes, 
   * tabs / actions may or may not be there. hence we need active tab change also to recalculate
   */

  return ({
    tabsRef,
    tabsPanelHeight,
    
    actionRef,
    actionBarHeight,
    
    mobileTitleRef,    
    stickyTitleHeight,
  })
}