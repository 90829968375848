import { colors, colors2, ellipsis, SANS_3 } from 'oolib';
import styled, { css } from 'styled-components';

const applyFreezeFirstColCss = ({row_header}) => ({freezeFirstCol, cellIdx, rowIdx}) => freezeFirstCol && cellIdx === 0 && css`
  position: sticky;
  left: 0;
  z-index:  ${row_header === 'header' ? 10 : 1};
  border-right-width: 4px;
  background-color: ${
    row_header === 'header'
    ? colors.white
    : rowIdx % 2 === 0
      ? colors.greyColor3
      : colors.white
  };
`
//header

export const StyledDataTableHeader = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.greyColor10};
  border-bottom: 2px solid ${colors.greyColor10};
  display: flex;
  align-items: center;
`

export const StyledDataTableHeaderCell = styled.div`
  flex: 0 0 auto;
  border-right: 1px solid ${colors.greyColor10};
  cursor: pointer;

  ${applyFreezeFirstColCss({row_header : 'header'})}
  
`

export const StyledDataTableHeaderCellText = styled(SANS_3)`
  ${ellipsis}
`

export const StyledHeaderCellContentWrapper = styled.div`
position: relative;
  display: flex; 
  align-items: center; 
  gap: 0.5rem; 
  min-width: 0;
  height: 4rem;
`

//rows

export const StyledDataTableRowsContainer = styled.div`
  border: 1px solid ${colors.greyColor10};
  border-top: none;
`


export const StyledRowCellContentWrapper = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  align-items: center;
  height: 100%;
  
`

export const StyledTableRowCell = styled.div`
   flex: 0 0 auto;
   height: 100%;
   border-right: 1px solid ${colors.greyColor10};
   ${applyFreezeFirstColCss({row_header : 'row'})}
   ${({highlight}) => {
    const colorLookup = {
      error: colors2.error,
    };
    if(highlight){
      return css`
        border: 2px solid ${colorLookup[highlight]};
      `
    }
   }}
   h1, h2, h3, h4, h5, h6{
    ${ellipsis}
  }

`