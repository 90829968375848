import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, SANS_4, Section, Wrapper1000 } from "oolib";
import styled from "styled-components";
import { AIMessage } from "./comps/Messages/AI/index.js";
import { SearchBar } from "./comps/SearchBar/index.js";
import { useGetQueryData } from "../../utils/react-query-hooks/general.js";
import { ChatbotInfo } from "./comps/ChatbotInfo/index.js";
import { ErrorMessage } from "./comps/Messages/Error/index.js";
import { ChatHistory } from "./comps/ChatHistory/index.js";
import { useDeleteChat, useLoadChat } from "./hooks/index.js";

const getResponse = async (query) => {
  return axios.get("/api/aiChat/getResponse", { params: { query } });
};

// radial gradient
const WrapperStyled = styled.div`
  position: fixed;
  z-index: -1;

  height: 100vh;
  width: 100%;

  background-image: radial-gradient(circle at bottom, #e3efff 5%, #ffffff 70%);
`;

export const AiChat = () => {
  const userData = useGetQueryData("userData");
  const { data: chat, status: chatLoadStatus } = useLoadChat(userData.user._id);

  const { mutate: deleteChat, status: deleteChatStatus } = useDeleteChat();

  const [chatHistory, setChatHistory] = useState([]);
  const [getResponseStatus, setGetResponseStatus] = useState("idel");
  const [retryRequestConfig, setRetryRequestConfig] = useState(); // state to store the request config in case of a failure so that it can retried on a click.

  useEffect(() => {
    if (chatLoadStatus === "success" && chat) {
      setChatHistory(chat.messages);
    }
  }, [chatLoadStatus]);

  const handleRetryRequest = async () => {
    setGetResponseStatus("loading");

    if (!retryRequestConfig) {
      throw new Error("retry request config missing");
    }

    try {
      const response = await axios(retryRequestConfig);
      setGetResponseStatus("idel");
      setChatHistory((prev) => [...prev, response.data]);
    } catch (error) {
      setGetResponseStatus("error");
    }
  };

  const handleSendMessage = async ({ value }) => {
    setChatHistory((prev) => [
      ...prev,
      { author: "user", content: { type: "text", value } },
    ]);

    setGetResponseStatus("loading");

    let response;

    try {
      response = await getResponse(value);
      setGetResponseStatus("idel");
      setChatHistory((prev) => [...prev, response.data]);
    } catch (error) {
      setRetryRequestConfig(error.config);
      setGetResponseStatus("error");
    }
  };

  const scrollIntoView = (node) => {
    if (node) {
      node.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <WrapperStyled />

      <Container>
        <Wrapper1000>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "calc(100vh - 6rem)",
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <Section
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                  padding: "2rem 0",
                }}
              >
                {chatLoadStatus === "loading" ? (
                  <SANS_4>Loading...</SANS_4>
                ) : chatLoadStatus === "error" ? (
                  <SANS_4>Error...</SANS_4>
                ) : (
                  <ChatHistory chatHistory={chatHistory} />
                )}

                {getResponseStatus === "loading" ||
                getResponseStatus === "error" ? (
                  <div ref={scrollIntoView}>
                    {getResponseStatus === "loading" ? (
                      <AIMessage
                        key="loading..."
                        streaming={true}
                        message={"Loading..."}
                      />
                    ) : (
                      <ErrorMessage onRetry={handleRetryRequest} />
                    )}
                  </div>
                ) : null}
              </Section>
            </div>

            <div style={{ position: "sticky", bottom: 0 }}>
              <SearchBar
                deleteChat={() =>
                  deleteChat(undefined, { onSuccess: () => setChatHistory([]) })
                }
                disableOnSubmit={getResponseStatus !== "idel"}
                deleteChatStatus={
                  deleteChatStatus === "loading" || deleteChatStatus === "error"
                    ? deleteChatStatus
                    : undefined
                }
                onSubmit={handleSendMessage}
              />
              <ChatbotInfo />
            </div>
          </div>
        </Wrapper1000>
      </Container>
    </div>
  );
};
