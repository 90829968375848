import {
  ButtonPrimary,
  colors,
} from "oolib";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  __CheckAccessToActionAsOwnerOrModerator,
  __CheckAccessToModule,
} from "../../../utils/accessControl";

import {
  useUpdateComment,
} from "../../../utils/react-query-hooks/comments";


import RichTextEditor from "../../inputs/TextEditor/RichTextEditor";
import { _Locale } from "../../locale/Locale";
import { useQueryClient } from "react-query";
import LexicalTextEditor from "../../inputs/TextEditor/LexicalTextEditor";
import { convertToLexicalText } from "../../../utils/getters/gettersV2";
import { extractMentions } from "../../inputs/TextEditor/LexicalTextEditor/plugins/MentionsPlugin/utils/extractMentions";
import { useSendNotification } from "../../../utils/react-query-hooks/notifications";

const UpdateCommentModule=(
    {
        comment,       
        idFlaggedToUpdate,      
        userData,
        contentType,
        contentId,  
        setIdFlaggedToUpdate,

        
    }
)=>
{
 
  /**
   * this rando stuff because we made a mistake in the shape of the comment object at some point in time
   * some comments now have a structure of comment.text.text some others have comment.text
   */
  const [updateCommentVal,setUpdateCommentVal]= useState(
    comment.text.isLexical ? comment.text : comment.text.blocks ? comment.text : comment.text.text 
  )
 
  const updateComment = useUpdateComment();
  const navigate = useNavigate();
  const location = useLocation();
  const sendNotification = useSendNotification()

    const handleUpdateComment = () => {
    updateComment.mutate(
      {
        text: updateCommentVal,
        contentType,
        contentId,
        commentId: idFlaggedToUpdate,
        onSuccess: ({ data }) => { 
          const mentions = extractMentions(updateCommentVal)
          
          const userIds = mentions?.map(m => ({ id: m.mentionId }))
          if (userIds.length > 0) {
            sendNotification.mutate({
              userIds, 
              body: {
                dateCreated: new Date(data.comments[0].dateCreated).getTime(),
                activityType: "MENTION_IN_COMMENT",
                content: {
                  title: data.main.title,
                  contentType: data.meta.kp_content_type,
                  id: data._id,
                  commentId: data.comments[0]._id,
                }
              }
            })
          }      
          setUpdateCommentVal(comment.text) 
          setIdFlaggedToUpdate(undefined)
          // setUpdateCommentVal(updateCommentVal) 
          }
      }     
    )
    
  };

  return(  
  <div style={{paddingTop: '0.5rem'}}>
    <LexicalTextEditor 
                  id={`PostedComment_${comment._id}`}
                  style={{
                    padding: '2rem',
                    minHeight: '10rem',
                    backgroundColor: colors.greyColor5
                  }}
                  typo='SANS_3'
                  placeholderColor={colors.greyColor40}
                  enableMentions={true}
                  variant="simple"
                  // forceValue={commentInputResetValue}
                  value={updateCommentVal}
                  readOnly={(idFlaggedToUpdate === comment._id)?false:true}      
                  onChange={(k, v) =>       
                    setUpdateCommentVal(v)        
                  }
              />
    {/* <RichTextEditor
      id={`PostedComment_${comment._id}`}
      style={
        idFlaggedToUpdate === comment._id
          ? {
              padding: "2rem",
              minHeight: "10rem",
              backgroundColor: colors.greyColor5,
            }
          : undefined
      }
      value={updateCommentVal}
      readOnly={(idFlaggedToUpdate === comment._id)?false:true}      
      variant="simple"
      placeholderColor={colors.greyColor40}
      onChange={(k, v) =>       
        setUpdateCommentVal(v)        
      }
      typo={"SANS_3"}
    /> */}
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: "1rem",
        gap: "1rem",
      }}
    >
      {idFlaggedToUpdate === comment._id && (
        <ButtonPrimary
          onClick={() => {
            userData?.user
              ? handleUpdateComment()
              : navigate("/login", {
                  state: {
                    from: {
                      pathname: location.pathname,
                      search: location.search,
                      state: { updateCommentVal },
                    },
                  },
                });
          }}
        >         
          {
            (updateComment.status === "loading"&&idFlaggedToUpdate === comment._id) 
            ? `${_Locale("Loading")}...`:( userData?.user &&idFlaggedToUpdate === comment._id)
            ? _Locale("Save")
            : _Locale("Login To Comment")}
        </ButtonPrimary>
      )}
      {idFlaggedToUpdate === comment._id && (
        <ButtonPrimary
          onClick={ ()=>{
            setIdFlaggedToUpdate(undefined)
          } }
        >
          {_Locale("Cancel")}
        </ButtonPrimary>
      )}
    </div>
  </div>)
}
export default UpdateCommentModule