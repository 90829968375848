import styled, { css } from "styled-components";
import { colors, mediaQuery, transition } from "oolib";

const { lightRed, white, red, greyColor70, greyColor80, greyColor100 } = colors;

export const StyledWrapper = styled.div`
  padding: 0.75rem 2rem;
  min-height: 4rem;
  background-color: ${({ isActive, invert, theme }) => {
    if (invert) {
      return isActive ? greyColor80 : greyColor70;
    }
    return isActive ? colors.greyColor5 : white;
  }};
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  ${transition("background-color")}
  &:hover {
    background-color: ${({ theme, invert }) =>
      invert ? greyColor80 : colors.greyColor5};
  }

  ${mediaQuery("md")} {
    background-color: ${({ isActive, hasError, invert, theme }) => {
      if (invert) {
        return hasError ? lightRed : isActive ? greyColor80 : greyColor70;
      }
      return hasError
        ? lightRed
        : isActive
        ? colors.greyColor5
        : white;
    }};
    padding: 0.75rem 3rem;
  }

  ${(props) => {
    return (
      props.hasError &&
      css`
        color: ${red};
        background-color: ${lightRed};
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 3px;
          background-color: ${red};
        }
      `
    );
  }}
`;

export const StyledButton = styled.button`
  background-color: transparent;
  border: 0;
  text-align: left;
  color: ${(props) => (props.hasError ? red : greyColor100)};
`;
