import React from "react";
import {
  CONTENT_BODY,
  Divider,
  ModalSmall,
  OKELink,
  UI_BODY_SM,
  UI_CAPTION,
  UI_TAG,
} from "oolib";
import { AIMessageStyled } from "./styled";
import { useState } from "react";
import Markdown from "react-markdown";
import { Copy } from "../../UserActions/Copy";
import { Feedback } from "../../UserActions/Feedback";
import { __GetContentTypeConfigNew } from "../../../../../utils/getters/gettersV2";
import { SummaryWrapperStyled } from "../../../../Synthesis/comps/AnnotationCard/styled";
import styled from "styled-components";

const MarkdownStyled = styled(UI_BODY_SM)`
  p,
  ol,
  ul,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height:inherit
  }
`;

export const AIMessage = ({ message, streaming = false }) => {
  return (
    <AIMessageStyled key={message.id}>
      <MarkdownStyled>
        <Markdown>{streaming ? message : message.content.value}</Markdown>
      </MarkdownStyled>

      {message.vectorSearchInfo?.chunks?.length > 0 ? (
        <>
          {" "}
          <Divider />
          <Chunks chunks={message.vectorSearchInfo?.chunks.slice(0, 3)} />
        </>
      ) : null}

      {streaming ? null : (
        <>
          {" "}
          <Divider />
          <Actionables message={message} />
        </>
      )}
    </AIMessageStyled>
  );
};

const Actionables = ({ message }) => {
  return (
    <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
      <Copy color="#e5e5e5" />
      {/* <LineVertical size={30} /> */}
      <Feedback
        reaction={message.userFeedback?.reaction}
        comments={message.userFeedback?.comments}
        messageId={message._id}
      />
    </div>
  );
};

const Chunks = ({ chunks }) => {
  const [modalContent, setModalContent] = useState("");
  return (
    <>
      <div style={{ padding: "1rem" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <UI_TAG>TOP REFERENCES</UI_TAG>{" "}
          <UI_CAPTION color="#e5e5e5">View all</UI_CAPTION>
        </div>
        <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
          {chunks.map((chunk) => (
            <div
              style={{
                backgroundColor: "#F3F3F3",
                borderRadius: "6px",
                padding: "1rem",
                flex: 1,
                cursor: "pointer",
              }}
              onClick={() => setModalContent(chunk)}
            >
              <SummaryWrapperStyled noOfLines={5}>
                <CONTENT_BODY>{chunk.text}</CONTENT_BODY>
              </SummaryWrapperStyled>
            </div>
          ))}
        </div>
      </div>

      {modalContent ? (
        <ModalSmall
          onClose={() => setModalContent("")}
          title={
            __GetContentTypeConfigNew(modalContent.metaData.contentType).general
              .content.title
          }
        >
          <div
            style={{
              padding: "0 2rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <CONTENT_BODY>
              {modalContent.text}
            </CONTENT_BODY>
            <OKELink
              link={`/published-page/${modalContent.metaData.contentType}?id=${modalContent.metaData.parentDoc}`}
              style={{ width: "fit-content" }}
            >
              <UI_BODY_SM>Go to source</UI_BODY_SM>{" "}
            </OKELink>
          </div>
        </ModalSmall>
      ) : null}
    </>
  );
};
