import React from "react";
import { SANS_2, SANS_7_8 } from "oolib";
import { Stars } from "oolib/dist/icons/custom";
import { useEffect } from "react";
import { useRef } from "react";
import { UserMessage } from "../Messages/User";
import { AIMessage } from "../Messages/AI";

export const ChatHistory = ({ chatHistory }) => {
  const lastMessageRef = useRef();

  useEffect(() => {
    const elem = lastMessageRef.current;
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory.length]);

  if (chatHistory.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <SANS_7_8>
          Ask Ai <Stars size={20} />
        </SANS_7_8>
        <SANS_2 style={{ marginLeft: "1rem" }}>(Beta version)</SANS_2>
      </div>
    );
  }

  return chatHistory.map((message, idx) => (
    <div
      // key={message._id}
      style={{
        alignSelf: message.author === "user" ? "flex-end" : "flex-start",
      }}
      ref={chatHistory.length - 1 === idx ? lastMessageRef : null}
    >
      {message.author === "user" ? (
        <UserMessage message={message} />
      ) : (
        <AIMessage message={message} />
      )}
    </div>
  ));
};
