import React from 'react';
import styled from 'styled-components';
import {CheckCircle, XCircle} from 'phosphor-react';
import { colors2, UI_BODY_SM } from 'oolib';

const StyledFilterActionsButton = styled.button`
  border: none;
  outline: none;
  background: none;
  display: flex;
  gap: 0.4rem;
  align-items: center;
  cursor: pointer;
  &:disabled {
    color: ${colors2.grey30};
  }
`;

export const FilterActionsButton = ({ 
  icon, 
  children, 
  onClick,
  disabled
}) => {
    const iconsLookup = {
        XCircle,
        CheckCircle
    }
    const IconComp = iconsLookup[icon];
  return (
    <StyledFilterActionsButton disabled={disabled} onClick={onClick}>
      {IconComp && <IconComp weight={'bold'} size={16} color={disabled ? colors2.grey30 : colors2.black}/>}
      <UI_BODY_SM>{children}</UI_BODY_SM>
    </StyledFilterActionsButton>
  );
};
