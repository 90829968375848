import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useBeginPublishingFlow } from "../../../../utils/contributionFlowUtils";
import { __GetConfigsBySegmentNew } from "../../../../utils/getters/gettersV2";
import { useGetQueryData } from "../../../../utils/react-query-hooks/general";
import { __CheckSomeAccessToSomeContentType } from "../../../../utils/accessControl";
import { LoaderOverlay } from "oolib";
import { extractAllBlocksFromTpl } from "../../../../utils/templating/extractAllBlocksFromTpl";
import ErrorStates from "../../../../components/ErrorStates";
import { useGetDataQuery } from "../../../../utils/react-query-hooks/getData";

const CreateContentQuickLinkParser = () => {
  // example link for tag - http://localhost:3000/plugins/fill-survey-quick-link/expressionOfInterest?tags.studyReferences=bhumi
  const { contentType } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { generateFn } = useBeginPublishingFlow();
  const data = useGetQueryData("userData");
  const tpl = useGetQueryData(["tpl", contentType]);
  let isErrorOccured = !tpl;

  const searchParams = new URLSearchParams(location.search);
  const valuePath = Array.from(searchParams.keys())[0];
  const value = searchParams.get(valuePath);

  const valuePathSplit = valuePath?.split(".");
  const { data: tagData, isLoading: tagDataLoading, error: tagFetchError } = useGetDataQuery({
    contentTypes: [valuePathSplit?.[1]],
    findQuery: {
      tagId: value,
      kp_published_status: "published",
    },
  });

  const tagObj = tagData?.data?.[0];

  const user = data?.user;
  let fieldValue, targetFieldBlock;
  if(user && tpl) {
    const allBlocks = extractAllBlocksFromTpl({ tpl });
    targetFieldBlock = allBlocks.find(block => block.valuePath === valuePath);
    if(targetFieldBlock?.comp === "TagsInputSingle" && tagObj) {
      fieldValue = {
        collectionId: valuePathSplit?.[1],
        data: [{
          _id: tagObj._id,
          display: tagObj.main.title,
          tagId: tagObj.tagId,
        }],
      };
    } else if(targetFieldBlock?.comp === "RadioList") {
      fieldValue = targetFieldBlock.props?.options?.find(option => option.value === value);
    }

    isErrorOccured = !fieldValue;
  }
  
  useEffect(() => {
    if(!isErrorOccured) {
      if(!user) {
        navigate("/login", {
          state: { from: location.pathname + location.search },
        });
      } else {
        const presetValues = {
          key: valuePathSplit?.[0],
          value: { [valuePathSplit[1]]: fieldValue },
        };
        generateFn(tpl, {}, presetValues);
      }
    }
  }, [isErrorOccured]);

  const errorMessage = !tpl
    ? "Invalid URL(contentType in the url is invalid)"
    : !targetFieldBlock
    ? "Invalid URL(valuePath in the url is invalid)"
    : !fieldValue
    ? "Invalid URL(value provided in the url is invalid)"
    : "";

  let errorObj = {
    response: {
      status: 404,
      data: {
        errors: [{ msg: errorMessage }],
      },
    },
  };

  if(tagFetchError && valuePathSplit?.[0] === "tags") errorObj = tagFetchError;
  
  return (
    isErrorOccured && !tagDataLoading ? (
      <ErrorStates
        errorResponseObject={errorObj}
        enableLinkToHomepage={false}
      />
    ) : (
      <LoaderOverlay />
    )
  );
}

export default CreateContentQuickLinkParser;