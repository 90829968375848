/**
 * Recursively extracts plain text from Lexical editor state
 * @param {Object} node - Lexical node object
 * @returns {string} Extracted plain text
 */
const extractTextFromLexical = (node) => {
    // Handle text nodes
    if (node.type === 'text') {
      return node.text || '';
    }
  
    // Handle nodes with children
    if (node.children) {
      return node.children.map(child => extractTextFromLexical(child)).join(' ');
    }
  
    // Handle root node
    if (node.root) {
      return extractTextFromLexical(node.root);
    }
  
    return '';
  };
  
  /**
   * Performs keyword search on filtered data only on the title & annotation text
   * @param {Array} data - Array of document objects
   * @param {string} keyword - Search term
   * @returns {Array} Filtered array of documents matching the keyword
   */
  export const applyKeywordSearch = ({data, keyword}) => {
    if(!data) return data;
    if (!keyword) return data;
    
    const searchTerm = keyword.toLowerCase();
  
    return data.filter(item => {
      // Search in title
      const titleMatch = item.main?.title?.toLowerCase().includes(searchTerm);
  
      // Extract and search in fragment content
      let fragmentText = '';
      if (item.fragment?.isLexical && item.fragment.editorState) {
        fragmentText = extractTextFromLexical(item.fragment.editorState);
      }
      const fragmentMatch = fragmentText.toLowerCase().includes(searchTerm);
  
      return titleMatch || fragmentMatch;
    });
  };
  
  // Example usage:
  // const searchResults = searchByKeyword(data._data, "your search term");