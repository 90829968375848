// hooks/useQuickLinkParser.js
import { useEffect, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { handleQuickLink, parseQuickLink } from './utils';


/**
 * 
 * this setup will parse quick links like:
 * /self-serve-quick-link/expressionOfInterest?tags.studyReferences=bhumi__<some_id>&tags.category=health&main.title=some-title
 * 
 */

export const useQuickLinkParser = ({
  originalUrl,
  userData,
  generateFn,
  generateTplIsLoading,
  setSurveyData
}) => {
  const queryClient = useQueryClient();

  const handleQuickLinkTriggered = useRef(false)

  useEffect(() => {
    if (!originalUrl) return;
    if(handleQuickLinkTriggered.current === true) return;
    const quickLinkData = parseQuickLink(originalUrl);

    if (quickLinkData) {
      handleQuickLinkTriggered.current = true;
      handleQuickLink({
        quickLinkData,
        userData,
        queryClient,
        generateFn,
        generateTplIsLoading,
        setSurveyData
      });
    }
  }, [originalUrl, generateTplIsLoading, userData]);
};