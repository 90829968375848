import { CardContent, SkeletonLoader, makeArrayFromLength, WrapperCardGrid } from "oolib";
import React from "react";


import { TotalCount } from "../../../TotalCount";
import { ListingSectionTitle } from "../../../ListingSectionTitle";
import { listingWrapperSelector } from "../../utils/listingWrapperSelector";
import DownloadDataButton from "../../DownloadDataButton";
import { getTenant } from "../../../../../../TENANT_CONFIGS";
import { pluginConfigs } from "../../../../../../Plugins/_EXPORTS";

const FallbackWrapper = ({children, enableContainerQuery}) => <div enableContainerQuery={enableContainerQuery}>{children}</div>


const CardsStyle = ({
  data,
  status,
  isFetchingNextPage,
  observerRef,
  totalCount,
  sectionTitle,
  sectionLink,
  configs: configs_,
  ListingWrapper,
  onClick,
  enableContainerQuery,
  inTCI,
  csvRefetch,
  hasAccessToDowloadCsv


}) => {



  const Wrapper = listingWrapperSelector({
    ListingWrapper,
    FallbackWrapper
  })

  const genSkeletonCards = () =>
    makeArrayFromLength(6).map(() => (
      <SkeletonLoader
        style={{
          height: "20rem",
        }}
      />
    ));
  const genContentCards = (data) =>
    data?.map((content, index) => {
      let d, configs, pluginExtraProps;
      
      if(pluginConfigs[getTenant()]?.ListingModule?.highlightPlugin){
        const {cardData, cardConfig, extraProps} = pluginConfigs[getTenant()]?.ListingModule?.highlightPlugin({cardData: content, cardConfig: configs_});
        d = cardData;
        configs = cardConfig;
        pluginExtraProps = extraProps
      }else{
        d = content;
        configs = configs_;
      }
      
      let contentType = d.meta.kp_content_type;
      let cardLinkUrl = !inTCI && `/published-page/${contentType}?id=${d._id}`;

      return (
       
        <div key={d._id} ref={data.length === index + 1 ? observerRef : null}>
          <CardContent
            id={d._id}
            data={d}
            to={cardLinkUrl}
            onClick = {onClick}
            highlight={d?.highlight}
            config={configs}
            {...(pluginExtraProps || {})}
          />
        </div>
      );
    });
   
  return (

      <Wrapper left enableContainerQuery={enableContainerQuery}>
       <div style={{display:"flex",marginRight: "1rem",paddingBottom:"1rem" }}> 
       
        {totalCount !== undefined && <TotalCount  totalCount={totalCount}  />}
      
              {
                 hasAccessToDowloadCsv && <DownloadDataButton  csvRefetch={csvRefetch}/>
              }
         </div>       
          
        {(sectionTitle || sectionLink) && (
          <ListingSectionTitle
            sectionTitle={sectionTitle}
            sectionLink={sectionLink}
          />
        )}
        <WrapperCardGrid>
          {status === "loading" ? genSkeletonCards() : genContentCards(data)}
          {isFetchingNextPage && genSkeletonCards()}
        </WrapperCardGrid>
      </Wrapper>
  );
};

export default CardsStyle;
