import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ButtonGhost, getBreakPoint, SANS_3, icons, useScreenWidth } from "oolib";


import { useGetQueryData } from "../../../utils/react-query-hooks/general";
import { useLogout } from "../../../utils/react-query-hooks/auth";
import { _Locale } from "../../locale/Locale";
import { LABEL, SANS_4, colors } from "oolib";
import { genMyDashboardMenuOptions } from "./utils";


const { white, greyColor40 } = colors;

const TypoComp = (props) =>
  props.isDesktop ? (
    <SANS_3 {...props}>{props.children}</SANS_3>
  ) : (
    <SANS_4 {...props}>{props.children}</SANS_4>
  );

export const MyDashboardNavMenu = (props) => {
  // Manage Scroll action in  Menu
  useEffect(() => {
    document.body.style.overflow = props.open? 'hidden':'auto';
  }, [props.open]);


  return (
    <div
      className={`myDashboardNavMenu ${props.open === true ? "-open-" : ""}`}
      //next events to allow smooother menu closing
      onMouseLeave={()=> document.body.addEventListener('click', props.onClose)}
      onMouseEnter={()=> document.body.removeEventListener('click', props.onClose)}
      onClick={(ev)=> ev.stopPropagation() } // to prevent bubbling of the event added above to avoid menu closure upun clicking on any blank area
    >
      <div className="myDashboardNavMenu__header">
        <SANS_3 color={"white"}>My Dashboard</SANS_3>

        <div className="closeMenuBtn-wrapper">
          <ButtonGhost icon={"X"} onClick={props.onClose} invert={true} />
        </div>
      </div>
      <MyDashboardMenu onClose={props.onClose} />
    </div>
  );
};

export const MyDashboardMenu = (props) => {
  // const queryClient = useQueryClient();
  
  const {logout} = useLogout()
  const userData = useGetQueryData('userData');
  const { pathname, search } = useLocation();
  const screenWidth = useScreenWidth();
  const isDesktop = screenWidth > getBreakPoint("sm");

  const isActive = (pathname, link) => {
    let fullLink =
      typeof link === "string" ? link : link.pathname + link.search;
    return pathname + search === fullLink;
  };

  const LogoutIcon = icons.SignOut;

  return (
    <div className="myDashboardMenu">
      <div style={{ position: "relative", height: "100%" }}>
        {genMyDashboardMenuOptions(userData).map((d, idx) => (
          <div className="myDashboardMenu__linkSection" key={idx}>
            {d.title && (
              <LABEL
                className="linkSection__title"
                style={{ color: greyColor40 }}
              >
                {d.title}
              </LABEL>
            )}
            <div>
              {d.links.map((dd, iidx) => {
                let Icon = icons[dd.icon];

                return (
                  <Link
                    key={iidx}
                    to={dd.link}
                    onClick={props.onClose}
                    style={dd.style}
                    className={`myDashboardMenu__link ${
                      isActive(pathname, dd.link) ? "-active-" : ""
                    }`}
                  >
                    {Icon && (
                      <Icon
                        size={20}
                        color={white}
                        className="myDashboardMenu__linkIcon"
                      />
                    )}
                    <TypoComp isDesktop={isDesktop}>
                      {_Locale(dd.display)}
                    </TypoComp>
                  </Link>
                );
              })}
              {/* {d.id === "myAccount" && (
                
              )} */}
            </div>
          </div>
        ))}
        <div className="myDashboardMenu__linkSection">
        <button
                  onClick={() => logout()}
                  className={`myDashboardMenu__link`}
                >
                  <LogoutIcon
                    size={20}
                    color={white}
                    className="myDashboardMenu__linkIcon"
                  />
                  <TypoComp isDesktop={isDesktop}>{_Locale("Logout")}</TypoComp>
                </button>
        </div>
      </div>
    </div>
  );
};

export default MyDashboardMenu;
