import { getTagTypeTitle } from "../../../inputs/DynamicTagsInputs/FilterInput/utils/getTagTypeTitle";
import { trackEventGA } from "../../../../trackers/GA/trackEventGA";

// Convert array of filters to object format
export const convertToInput = (activeFilters) =>
  activeFilters.reduce(
    (acc, filter) => ({
      ...acc,
      [filter.filterId]: filter.values,
    }),
    {}
  );

// Flatten nested filter config
const flattenFilterConfig = (filterConfig) => {
  return filterConfig.flatMap((filter) => {
    if (!filter.configs) {
      // Base case: this is a direct filter
      return [filter];
    }

    const configItems = filter.configs;
    // Recursive case: flatten the configs array
    return configItems.flatMap(item => {
      if (item.configs) {
        // If this item has configs, recursively flatten them
        return flattenFilterConfig([item]);
      }
      // If no configs, it's a direct filter
      return [item];
    });
  });
};

// Convert object format back to array format with target/source info
const convertToOutput = ({ updated, filterConfig }) => {
  const flatConfig = flattenFilterConfig(filterConfig);

  return Object.entries(updated).map(([filterId, values]) => {
    const config = flatConfig.find((f) => f.filterId === filterId);
    return {
      filterId,
      values,
      target: config.target,
      source: config.source,
    };
  });
};

const getGACategory = ({ filterConfig, filterId }) => {
  const config = flattenFilterConfig(filterConfig).find(
    (d) => d.filterId === filterId
  );
  if (!config) return "";

  // First try to use explicit display name
  if (config.display) return config.display;

  // Handle profile types
  if (config.source?.profileTypes) {
    return config.source.profileTypes.length > 1
      ? "Users"
      : getTagTypeTitle({ tagType: config.source.profileTypes[0] });
  }

  // Fallback to tag type
  return getTagTypeTitle({ tagType: config.source?.tagType });
};

// Handle filter changes
export const handleOnChange = ({
  id,
  value,
  filterConfig,
  activeFilters,
  setActiveFilters,
  usedIn,
}) => {
  const currentFilters = convertToInput(activeFilters);
  

  // Handle removal
  if (value.length === 0) {
    const { [id]: removed, ...remaining } = currentFilters;
    setActiveFilters(convertToOutput({ updated: remaining, filterConfig }));
    return;
  }

  // Handle addition
  const updated = { ...currentFilters, [id]: value };

  // Track new additions for analytics
  if (usedIn) {
    const gaCategory = getGACategory({ filterConfig, filterId: id });
    const prevValues = currentFilters[id] || [];
    const newValue = value.find(
      (v) => !prevValues.some((pv) => pv.value === v.value)
    );
    if (newValue) {
      trackEventGA(gaCategory, `${usedIn} Filter`, newValue.display);
    }
  }

  setActiveFilters(convertToOutput({ updated, filterConfig }));
};
