import React from 'react'
import { Modal } from "oolib";
import LexicalTextEditor from "../../../../components/inputs/TextEditor/LexicalTextEditor";
import { useState } from "react";
import { sendBroadcastMessage } from '../../../../components/BroadcastNavigator';


export const SendBackModal = ({
    sendBackMutate,
    setOpenSendBackModal,
    openSendBackModal,
    moderationQueryData
}) => {
    const [notes, setNotes] = useState(moderationQueryData?.contribution.reviewerNotes || {});
    const handleOnSendNotes = () => {
        sendBackMutate({
          contentType: moderationQueryData?.contribution.meta.kp_content_type,
          id: moderationQueryData?.contribution._id,
          ogDocId:moderationQueryData?.contribution.kp_pre_mod_doc,
          reviewerNotes: notes,
        });
        setOpenSendBackModal(false);
        sendBroadcastMessage('redirect', window.location.href);
      };

      return openSendBackModal ? (
        <Modal
          showActionPanel={true}
          title="Notes from the reviewer"
          onClose={() => setOpenSendBackModal(false)}
          fitToContentHeight
          desktopWidth="50%"
          onConfirmText="Send Back"
          onConfirm={notes.allText?.length > 0 ? handleOnSendNotes : () => {}}
          onCloseText="Cancel"
        >
          <div style={{ padding: "2rem" }}>
            <div
              style={{
                backgroundColor: "#f5f5f5",
                padding: "2rem",
                minHeight: "20rem",
              }}
            >
              <LexicalTextEditor
                placeholder="Write your notes for the author here"
                value={notes}
                typo="SANS_3"
                variant={"simple"}
                onChange={(id, value) => setNotes({ ...value })}
              />
            </div>
          </div>{" "}
        </Modal>
      ) : null
}