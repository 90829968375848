import React from "react";
import { UserMessageStyled } from "./styled";
import { SANS_3, UI_BODY_SEMIBOLD_SM } from "oolib";

export const UserMessage = ({ message }) => {
  return (
    <UserMessageStyled key={message.id}>
      <UI_BODY_SEMIBOLD_SM>{message.content.value}</UI_BODY_SEMIBOLD_SM>
    </UserMessageStyled>
  );
};
