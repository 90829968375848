import { ButtonPrimary, ButtonSecondary, LoaderOverlay } from "oolib";
import React, { Fragment, useEffect } from "react";
import { useFormValidationContext } from "../../../contexts/formValidationContext";
import { FormConfigsRenderer } from "../../../pageTpls/ContributeTpl/layouts/GroupsStyle1/comps/FormConfigsRenderer";
import { useHandleContentChange } from "../../../utils/contributionFlowUtils";
import {
  useCreateUpdate,
  useEditUpdate,
} from "../../../utils/react-query-hooks/update";
import { formValidationCheckV2 } from "../../../utils/validation/formValidation";

const getThisSubjectTplBlocks = (tpl, subject) => {
  if(!tpl.kp_settings || tpl.kp_settings?.length === 0){
    return tpl.kp_templates.data.default
  }
  if (!subject) return undefined;
  return (tpl.kp_templates.data[subject.value][0]?.blocks || //this structure should be deprecated
    tpl.kp_templates.data[subject.value])
};

const FormWrapper = ({ children }) => (
  <div style={{ padding: "1.5rem 2rem" }}>{children}</div>
);

export const Form = ({
  tpl,
  content,
  setContent,
  parentContentType,
  parentResourceId,
  closeModal,
  mode,
}) => {
  const createUpdate = useCreateUpdate();

  const editUpdate = useEditUpdate();

  const configs = getThisSubjectTplBlocks(tpl, content?.meta?.subject);
  
  const {
    setFormValidation,
    formValidation,
    resetFormValidation,
    makeFormValidationVisible,
  } = useFormValidationContext();

  useEffect(() => {
    const { failedBlocks } = formValidationCheckV2({
      blocks: configs,
      content,
    });
    setFormValidation((p) => ({ ...p, failedBlocks }));

    return () => resetFormValidation();
  }, []);

  const { handleContentChange } = useHandleContentChange();

  const handleOnChange = (block, value, options = {}) =>
    handleContentChange({
      block,
      value,
      setContent,
      setFormValidation,
      blocksToValidate: configs,
      ...options,
    });
  console.log({ content });
  return (
    <Fragment>
      {(editUpdate.status === "loading" || createUpdate.status === "loading") && <LoaderOverlay loaderText={"Submitting update..."}/>}
      <FormConfigsRenderer
        FormWrapper={FormWrapper}
        configs={configs}
        handleOnChange={handleOnChange}
        content={content}
        formValidation={formValidation}
        setFormValidation={setFormValidation}
      />
      <div
        style={{
          padding: "1.5rem 2rem",
          display: "flex",
          gap: "1rem",
        }}
      >
        <div
          onClick={() => {
            if (
              formValidation.failedBlocks.length > 0 ||
              formValidation.invalidInputBlocks.length > 0
            )
              makeFormValidationVisible('Some required blocks are not filled');
          }}
        >
          <ButtonPrimary
            disabled={
              formValidation.failedBlocks.length > 0 ||
              formValidation.invalidInputBlocks.length > 0
            }
            value="Submit"
            onClick={() => {
              mode === "create"
                ? createUpdate.mutate(
                    {
                      body: content,
                      parentContentType,
                      parentResourceId,
                    },
                    {
                      onSuccess: () => closeModal(),
                    }
                  )
                : editUpdate.mutate(
                    {
                      body: content,
                      parentContentType,
                      parentResourceId,
                    },
                    {
                      onSuccess: () => closeModal(),
                    }
                  );
            }}
          />
        </div>
        <ButtonSecondary value="Cancel" onClick={() => closeModal()} />
      </div>
    </Fragment>
  );
};
