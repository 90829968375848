import { getAnnoExplorerConfigsForThisTenant } from "./getAnnoExplorerConfigsForThisTenant";


export const applyFilterConfigsAsPerTenant = (allFilters) => {
  const thisTenantConfigs = getAnnoExplorerConfigsForThisTenant();
  const applyTenantConfigs = (filterConfigs) => {
    const { whitelist } = thisTenantConfigs?.filterConfigs || {};

    if (!whitelist || whitelist.length === 0) return filterConfigs;
    //else
    const filteredConfigs = filterConfigs.filter((f) => {
      const whitelistItem = whitelist.find(item => 
        typeof item === 'string' 
          ? item === f.filterId
          : item.filterId === f.filterId
      );

      if (!whitelistItem) return false;

      // If whitelist item has filterOptionsBy, apply it to the filter config
      if (typeof whitelistItem === 'object') {
        if (whitelistItem.filterOptionsBy) {
          f.source = {
            ...f.source,
            filterOptionsBy: whitelistItem.filterOptionsBy
          };
        }
        if (whitelistItem.displayConditions) {
          f.displayConditions = whitelistItem.displayConditions;
        }
      }

      return true;
    });

    // Then sort based on whitelist order
    return filteredConfigs.sort((a, b) => {
      const aIndex = whitelist.findIndex(item => 
        typeof item === 'string' 
          ? item === a.filterId 
          : item.filterId === a.filterId
      );
      const bIndex = whitelist.findIndex(item => 
        typeof item === 'string' 
          ? item === b.filterId 
          : item.filterId === b.filterId
      );
      return aIndex - bIndex;
    });
  };
  
  return allFilters
    .map((filterSection) => {
      let finalFilterConfigs;
      if(!filterSection.configs){
        //because then, its not a filterSection but actually a single filterConfig
        finalFilterConfigs = applyTenantConfigs([filterSection]);  
        if (finalFilterConfigs.length === 0) return undefined;
        //else
        return filterSection
      }else{
        const configsThatAreSubSections = filterSection.configs.filter(c => !!c.configs === true)
        const configsThatAreFilterConfs = filterSection.configs.filter(c => !!c.configs === false)

        const finalThatAreFilterConfs = applyTenantConfigs(configsThatAreFilterConfs);  
        const finalThatAreSubSections = configsThatAreSubSections.map(conf => {
          const filteredByTenantConfs = applyTenantConfigs(conf.configs)
          if(filteredByTenantConfs.length === 0) return undefined;
          //else
          return ({
            ...conf,
            configs: filteredByTenantConfs
          })
        }).filter(Boolean)
        finalFilterConfigs = [
          ...finalThatAreFilterConfs,
          ...finalThatAreSubSections
        ]
        if (finalFilterConfigs.length === 0) return undefined;
        //else
        return {
          ...filterSection,
          configs: finalFilterConfigs,
        };
      }
    })
    .filter(Boolean);
};
