import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { getTenant } from "../../TENANT_CONFIGS";
import Components from "./comps";
import { __CheckAccessToModule } from "../../utils/accessControl";
import { useGetQueryData } from "../../utils/react-query-hooks/general";

const tenantPluginRoutes = {
  wqn: [
    {
      path: "WQMCourse",
      comp: "WQMCourse",
    },
  ],
  nkms: [
    {
      path: "mediaLibrary",
      comp: "MediaLibrary",
    },
  ],
  gelabs: [
    {
      path: "aiChat",
      comp: "AiChat",
      accessControl: true,
      moduleId: "aiChat",
      ACTION: "READ",
    },
  ],
  gtdc: [
    {
      path: "/fill-survey-quick-link/:contentType",
      comp: "CreateContentQuickLinkParser",
    },
  ],
};

export default function PluginRoutes() {
  const userData = useGetQueryData("userData");
  const thisTenantPluginRoutes = tenantPluginRoutes[getTenant()];

  if (!thisTenantPluginRoutes) return null;

  // Create a wrapper component for protected routes
  const ProtectedRoute = ({ component: Component, accessControl, ACTION, moduleId }) => {
    const hasAccess = accessControl
      ? __CheckAccessToModule(userData?.user, ACTION, moduleId)
      : true;

    return hasAccess ? <Component /> : <Navigate to="/" replace />;
  };

  return (
    <Routes>
      {thisTenantPluginRoutes.map((route) => {
        const Component = Components[route.comp];
        
        return (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute
                component={Component}
                accessControl={route.accessControl}
                ACTION={route.ACTION}
                moduleId={route.moduleId}
              />
            }
          />
        );
      })}
    </Routes>
  );
}