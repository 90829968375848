import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from 'react-router-dom';

import { getRedirectUrl } from "../utils";
import { UIContent } from "../../../UIContent";
import { genErrMsgs } from "../../../utils/general";
import {  __GetContentTypeConfigNew } from "../../../utils/getters/gettersV2";
import { useBannerContext } from "oolib";

export const useSubmitPublishContent = () => {
  const { SET_ALERT_BANNER } = useBannerContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(
    ({ contentType, contentId, userAction, updateSelfServeSurvey }) => {
      const apiUrl = userAction === 'PUBLISH'
        ? `/api/content/publishContent/${contentType}/${contentId}${updateSelfServeSurvey ? '?updateSelfServeSurvey=true' : ""}` //applied only for publish, because there is no submit possibility in self serve
        : `/api/content/submitContent/${contentType}/${contentId}`
      return axios.put(
        apiUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    {
      onSuccess: (res, mutationArgs) => {

        const {_id, meta: {kp_content_type}} = res.data.content;
        const { userAction, disableRedirectOnPublish } = mutationArgs;

        queryClient.invalidateQueries(["RelatedContent"]);
        queryClient.invalidateQueries([
          "contentListing",
          "lazy",
          [kp_content_type],
        ]);
        queryClient.removeQueries([
          "Contributions", 'view', kp_content_type, _id
        ]);

        let alertBannerConfig =
          userAction === "SUBMIT"
            ? [
                UIContent.reactQueryHooks_contributions.submitForModSuccessfulMsg,
                "green",
                5000,
              ]
            : [
                {
                  color: "green",
                  msg: UIContent.reactQueryHooks_contributions.publishSuccessfulMsg,
                  cta: disableRedirectOnPublish? {
                    text: UIContent.general.viewHereCtaText,
                    to: {
                      pathname: `/published-page/${kp_content_type}`,
                      search: `?id=${_id}`,
                    }
                  }:undefined,
                  timeOut: 5000,
                },
              ];

              
        
        if(!disableRedirectOnPublish){
          navigate('/OKFPublishSuccessful',{
            state: { 
              redirectUrl: getRedirectUrl.onPublish({ userAction, content: res.data.content }),
              userAction,
              alertBannerConfig 
            }
          });
        }
        

        //V.V important, else, if you try to editPublished the same piece a second time, it
        //returns the previous data from cache momentarily, ( while background refetch happens )
        //this means, that we get the previous editPublished clone's id, and we try to autosave
        //against it, which creates a new 'draft' since this prev editPublished clonne is already deleted.
        //and this ends up creating a draft instead of over writing the new editPublished clone.
        //and therefore, when you hit publish,
        //( by now usually the background refetch is done, so the new EditPublished data becomes available)
        // some of that initial data which got saved to a new draft is lost, and not saved to this editPublished
        // console.log("STATUS", ['Contributions', 'edit', kp_content_type, _id])

        //ALSO V.IMP this is done after redirect, cuz if query is being used, it will be immediately refetched after
        //removeQueries. which we dont want.
        queryClient.removeQueries(
          [
            "Contributions",
            "edit",
            kp_content_type,
            _id,
          ],
          { exact: true }
        );
      },
      onError: (err, { Contribution }) => {
        
        let contentTypeTitle = __GetContentTypeConfigNew(
          Contribution.meta.kp_content_type
        ).general.content?.singular

        const errMsgs = genErrMsgs({
          err,
          cases: {
            DUPLICATE_KEY: `A ${contentTypeTitle} with this name already exists`,
            default: 'Error while publishing'
          }
        })

        errMsgs.forEach(msg => {
          SET_ALERT_BANNER({
            color: "red",
            msg,
            timeOut: 4000
          })
        })
          
      },
    }
  );
};
