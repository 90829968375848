
const validFieldsForQuantVizLookup = [
    "DropdownSingle",
    "DropdownMulti",
    "RadioList",
    "CheckboxList",
    "TagsInputSingle",
    "TagsInputMulti"
  ];
  
  export const getValidFieldsForQuantViz = (blocks) => {
    return blocks.filter((d) => validFieldsForQuantVizLookup.includes(d.comp))
  }