import { createContentCardDate } from "../../../../../../utils/momentManipulate";
import { getVal } from 'oolib';
export const populateConfigWithContent = ({ config, content }) => {
  if (!config || !content) return undefined;
  //if the meta Obj is passed through it means the metaData is coming from the template config
  return config.map((_d) => {
    let d = { ..._d };

    if (d.text) {
      d = { ...d, text: `${d.prefix|| ''}${getVal(content, d.text)}` };
    }

    if (d.user) {
      let pathToUserData = d.user.endsWith('.name') 
        ? d.user.replace('.name', '') //backwards compat
        : d.user
      let userData = getVal(content, pathToUserData);
      d = {
        ...d,
        user: userData?.name,
        accountDeactivated: userData?.accountDeactivated,
        userProfileLink: `/profile/userProfiles/${userData?.profileType}/${userData?._id}?activeTab=about`,
      };
    }

    if (d.img) {
      d = { ...d, img: getVal(content, d.img) };
    }

    if (d.bool) {
      let boolVal = getVal(content, d.bool) 
      d = { 
        ...d,
        ...(boolVal !== undefined ? { text: boolVal ? d.trueText : d.falseText } : {}) 
      };
    }

    if (d.date) {
      d = {
        ...d,
        date: createContentCardDate(getVal(content, d.date)),
      };
    }

    return d;
  });
};
