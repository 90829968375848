import { SANS_2, getText, useScroll } from "oolib";
import React, { useRef, useState } from "react";
import TplActions from "../../../../components/generalUI/TplActions";
import { usePlatformBuilderContext } from "../../../../contexts/platformBuilderContext";


import { StyledContainer, StyledSection } from "./styled";

export const ContentHeader = ({ pageCoverRef, content, invert = true }) => {
  const ContainerRef = useRef(null);

  const [show, setShow] = useState(false);
  const showContentHeaderFunction = () => {
    if (pageCoverRef.current) {
      const { bottom } = pageCoverRef.current.getBoundingClientRect();
      const threshold = 10;

      if (bottom <= threshold) {
        setShow(true);
      } else if (bottom > threshold) {
        setShow(false);
      }
    }
  };
  useScroll(showContentHeaderFunction, { detectScrollInAllDivs: true });

  // const [overflow, setOverflow] = useState("hidden");

  const { platformBuilderMode } = usePlatformBuilderContext();
  return (
    <StyledSection
      borderBottom
      style={{ overflow: 'hidden' }}
      show={show}
      contentsHeight={ContainerRef.current?.getBoundingClientRect().height}
      greyColor100={invert}
    >
      <StyledContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
          ref={ContainerRef}
        >
          <SANS_2 style={{ padding: "0.8rem 0" }} semibold invert={true}>
            {getText(content.main?.title || content.main?.kp_title)}
          </SANS_2>
          {!platformBuilderMode && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <TplActions 
                popOutOfOverflowHiddenParent
                {...{ content, invert }} 
                />
            </div>
          )}
        </div>
      </StyledContainer>
    </StyledSection>
  );
};
