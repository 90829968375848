import styled from "styled-components";

export const SearchBarStyled = styled.div`
  padding: 0rem 0 1rem 0;
  width: 100%;
  align-items: center;
  /* background-color: #ffffff; */
  display: flex;
  gap: 1rem;
`;

export const TextInputWrapperStyled = styled.div`
  width: 100%;
  padding: .5rem 1rem;
  border-radius: 0.6rem;
  border: 0.2rem solid #000;
  background-color: #fff;
  display: flex;
  gap:2rem;
  align-items: center;
`;
