import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { createCommand, COMMAND_PRIORITY_EDITOR } from "lexical";
import React, { useEffect, useState } from "react";
import { ButtonGhost, SANS_2 } from "oolib";
import { findRange, getAllText, getSelectionRange } from "./utils";
import { $wrapSelectionInMarkNode } from "@lexical/mark";
import { useAnnoContextLex } from "../AnnoPlugin/context";
import { useGetSuggestions } from "./hooks";
import { SidebarContainerStyled, SidebarWrapperStyled } from "./styled";

// import data from "./data.json";
import { Cards } from "./components/card";

export const SHOW_ANNO_SUGGESTIONS = createCommand("SHOW_ANNO_SUGGESTIONS");

export const AnnoSuggestionsPlugin = ({
  annotation,
  fieldValuePath,
  _id,
  contentType,
}) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [editor] = useLexicalComposerContext();
  const { handleSetAnnoData } = useAnnoContextLex();

  const tagCategory = annotation.tagTypesConfig.map((d) => d.tagType);

  const { data: suggestionsData, status } = useGetSuggestions({
    text: getAllText(editor),
    tagCategory,
    fieldValuePath,
    _id,
    contentType,
    enabled: showSidebar,
  });

  useEffect(
    () =>
      editor.registerCommand(
        SHOW_ANNO_SUGGESTIONS,
        () => {
          setShowSidebar(true);
        },
        COMMAND_PRIORITY_EDITOR
      ),
    []
  );

  useEffect(() => {
    if (status === "success" && suggestionsData) {
      setSuggestions(suggestionsData);
    }
  }, [status]);

  //TODO: optimization
  const handleApply = ({ text, tagsData, _id }) => {
    const { startNodeKey, endNodeKey, startOffset, endOffset } = findRange(
      editor,
      text
    );

    editor.update(() => {
      const selection = getSelectionRange({
        startNodeKey,
        endNodeKey,
        startOffset,
        endOffset,
      });

      const data = {};

      tagsData.forEach(({ tagCategory, tags }) => {
        data[tagCategory] = { collectionId: tagCategory, data: tags };
      });

      $wrapSelectionInMarkNode(selection, selection.isBackward, _id);
      handleSetAnnoData({
        type: "tags",
        data,
        dataId: _id,
        disableParentOnChange: true,
      });
    });
  };

  return showSidebar ? (
    <SidebarContainerStyled
      initial={{ width: 0, opacity: 1, height: "100%" }}
      animate={{ width: 350 }}
      transition={{ type: "tween" }}
      exit={{ width: 0, opacity: 0 }}
    >
      <ButtonGhost icon="X" onClick={() => setShowSidebar(false)} />
      <SidebarWrapperStyled>
        {status === "loading" ? (
          <SANS_2>Loading...</SANS_2>
        ) : (
          suggestions.map((d) => (
            <Cards {...d} key={d._id} handleApply={handleApply} />
          ))
        )}
      </SidebarWrapperStyled>
    </SidebarContainerStyled>
  ) : null;
};
