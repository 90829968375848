import styled, { css } from "styled-components";
import { colors, colors2, transition } from "oolib";
const { greyColor5, greyColor15, white } = colors;


export const StyledAsideFilter = styled.div`
  ${({ top }) =>
    top !== undefined
      ? css`
          position: sticky;
          top: ${top}px;
        `
      : css`
          position: relative;
          height: 100%;
        `}
  background-color: ${white};
  z-index: 98;
  height: 100%;
`;

export const StyledAsideFilterContainer = styled.div`
  overflow: hidden;
  height: 100%;
`;

export const StyledAsideFilterContentsWrapper = styled.div`
  height: ${({ headerHeight }) =>
    headerHeight === 'auto' 
    ? 'auto'
    : headerHeight !== undefined ? `calc(100% - ${headerHeight}px)` : "auto"};

  /* box-shadow: ${({ width }) => width > 250 ? 'none' : `inset 2px 0px 10px ${greyColor15}`} ; */

  
  width: ${({ width }) => width}px;
  transition: width 0.3s ease-out;
`;
export const SideFilterHeaderStyled = styled.div`
  
  
  padding: 0 2rem;
  background-color: ${colors2.white};
  
  /* height: fit-content; */
  height: 4rem;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid ${colors2.grey10};
`;

export const SideFilterListWrapper = styled.div`
  /* overflow-y: scroll;

  scrollbar-width: none; /* Firefox */

  /* ::-webkit-scrollbar {
    width: 0px; /* webkit */
  /*} */
`;



export const StyledSideFilterSectionPaddingShell = styled.div`
  padding: 0.8rem 1.4rem;
  position: relative; //this is important, because each individual filter module's options lightbox is positioned absolutely against this
`;

export const StyledSideFilterFlexVerticalGapShell = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-direction: column;
`;

export const StyledFilterAccordionHeader = styled.div`
  display: flex;
  padding: 1rem 2rem;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const StyledOptionWrapper = styled.div`
  padding: 0.5rem 2rem;
  cursor: pointer;
  ${transition("background-color 0.3s")}

  @media (hover:hover) {
    &:hover {
      background-color: ${greyColor5};
    }
  }
`;

export const CheckBoxOptionStyled = styled.div`
  display: flex;
  gap: 1rem;
  cursor: pointer;
  padding: 0.5rem 2rem;
  transition: background-color 0.3s ease-out;
  border-radius: 5px;

  align-items: center;
  :hover {
    background-color: ${greyColor5};
  }
  button {
    flex-shrink: 0;
  }
  /* :first-child{
    margin-top: 1rem;
  } */
`;

export const StyledToggleButtonWrapper = styled.div`
  position: absolute;
  top: 30vh;
  right: 0;
  transform: translateX(100%);
`;

export const StyledClearAllButton = styled.div`
  border: none;
  outline: none;
  background: none;
  display: flex;
  gap: 0.4rem;
  align-items: center;
  cursor: pointer;
`

export const StyledFilterHeaderInnerWrapper = styled.div`
/* border-bottom: 1px solid ${colors2.grey10}; */
display: flex;
align-items: center;
height: 100%;
`