import styled from "styled-components";
import { colors, mediaQuery, transition} from "oolib";

const { white, greyColor70, greyColor5} = colors;

const StyledIndexBarWrapper = styled.div` // to prvide the float effect on the side || fixed on bottom
  width: 100%;
  position: sticky;
  left: 0; 

  bottom: 0;
  z-Index: 5;
  box-shadow: 0px 1.5px 5px ${greyColor70};
  ${mediaQuery("xl")}{
    width: unset;
    position: absolute;
    left: 0;
    height: 100%;
    max-width: 23rem;
    box-shadow: none;
    background-color: ${colors.white};
  }

`

const StyledIndexBar = styled.aside`
  width: 100%;
  background-color: ${({invert})=> invert? greyColor70:white};
  position: relative;

  ${mediaQuery("xl")}{
    border: none;
    position: sticky;
    top: 10rem;
    margin-top: 4rem;
    &  .caret {display: none;}
  }

`;

const StyledTabsDropdown = styled.div`
  width: 100%;
  position: absolute;
  bottom: 100%;

  border: 1px solid ${greyColor5};
  border-bottom: none;

  overflow: hidden; // for clipping animation
  height: ${({mobileIndexOpen, tabsDropdownWrapperRef}) => mobileIndexOpen 
    ? tabsDropdownWrapperRef.current?.getBoundingClientRect().height + 'px'
    : 0
  };
  ${transition('height')}
`;


export {
  StyledIndexBarWrapper,
  StyledIndexBar,
  StyledTabsDropdown,
};