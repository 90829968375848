import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";

import { serialize } from "../../general";
import { UIContent } from "../../../UIContent";

import { useGenErrorMsgBanners } from "../../useGenErrorMsgBanners";

import { useBannerContext } from "oolib";

export const useUpdateOnboardingChecklist = () => {
  const queryClient = useQueryClient();
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  return useMutation(
    ({ property, value }) =>
      axios.patch(
        `/api/users/update/onboardingChecklist/${property}`,
        { value },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    {
      onSuccess: (res) => {
        console.log("onboarding checklist updated successfully", res.data);
        //update the logged in user's query cache
        queryClient.setQueryData("userData", (prev) => ({
          ...prev,
          user: res.data,
        }));
      },
      onError: (err) => {
        genErrorMsgBanners({ err });
      },
    }
  );
};

export const useUpdateUserAgreementAccepted = () => {
  const queryClient = useQueryClient();
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  return useMutation(
    ({ value }) =>
      axios.patch(
        `/api/users/update/userAgreementAccepted`,
        { value },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    {
      onSuccess: (res) => {
        // console.log('user agreement accepted updated successfully', res.data)
        //update the logged in user's query cache
        queryClient.setQueryData("userData", (prev) => ({
          ...prev,
          user: res.data,
        }));
      },
      onError: (err) => {
        genErrorMsgBanners({ err });
      },
    }
  );
};

export const useUpdateGaAgreementAccepted = () => {
  const queryClient = useQueryClient();
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  return useMutation(
    ({ value }) =>
      axios.patch(
        `/api/users/update/gaAgreementAccepted`,
        { value },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    {
      onSuccess: (res) => {
        // console.log('user agreement accepted updated successfully', res.data)
        //update the logged in user's query cache
        queryClient.setQueryData("userData", (prev) => ({
          ...prev,
          user: res.data,
        }));
      },
      onError: (err) => {
        genErrorMsgBanners({ err });
      },
    }
  );
};

export const useGetUsers = (options = {}) => {
	const {
		findQuery : _findQuery,
		filters, //deprecated in favor of findQuery
		populateTaggedResources,
		enabled
	} = options;
	const findQuery = _findQuery || filters //options.filters is deprecated. this is for backwards compat
	let queryKey = findQuery ? ['allUsers', findQuery] : 'allUsers'
	let queryConf = [
		findQuery ? `findQuery=${encodeURIComponent(JSON.stringify(findQuery))}` : undefined,
		populateTaggedResources ? `populateTaggedResources=true` : undefined
	]
	let totQuery = queryConf.filter(Boolean).length > 0 ? queryConf.join('&') : undefined
	let queryUri = `/api/users/getAllUsers${totQuery ? `?${totQuery}` : ''}`
	return useQuery(queryKey, () =>
		axios.get(queryUri).then((res) => res.data.data),
		{
			enabled ,// if undefined, is assumed as TRUE
		
		}
	)
}
export const useGetCountUsers = (options = {}) => {
	const {
		findQuery : _findQuery,
		activeFilters, //deprecated in favor of findQuery		
		facet
	} = options;
	const findQuery = _findQuery 
	let queryKey = findQuery ? ['countUsers', findQuery] : 'countUsers'
	let queryConf = [
		findQuery ? `findQuery=${encodeURIComponent(JSON.stringify(findQuery))}` : undefined,
		activeFilters?`activeFilters=${encodeURIComponent(JSON.stringify(activeFilters))}` : undefined,
		facet?`facet=${encodeURIComponent(JSON.stringify(facet))}` : undefined,
	]
	let totQuery = queryConf.filter(Boolean).length > 0 ? queryConf.join('&') : undefined
	let queryUri = `/api/users/getCountUsers/${totQuery ? `?${totQuery}` : ''}`
	return useQuery(queryKey, () =>
		axios.get(queryUri).then((res) => res.data)
		
	)
}


const getUsersAuthoredContent = (configs) => ({
  queryKey: ["allUsers", "authoredContent", configs],
  queryFn: () =>
    axios
      .get(`/api/users/allUsersPublishedContent`, {
        params: { configs: serialize(configs) },
      })
      .then((res) => res.data),
  ...(configs.queryOptions || {}),
});

export const useGetUsersAuthoredContent = (configs = {}) => {
  const { usersFindQuery, population, projection, queryOptions } = configs;

  return useQuery(
    getUsersAuthoredContent({
      usersFindQuery,
      population,
      projection,
      queryOptions,
    })
  );
};



export const useBatchRegisterUsers = (options = {}) => {
  const { SET_ALERT_BANNER } = useBannerContext();

  return useMutation(
    ({ data, tagsSplitCharacter }) => {
      const params = new URLSearchParams();
      if (options.validateAndRegister) {
        params.append('validateAndRegister', 'true');
      }
      if (tagsSplitCharacter) {
        params.append('tagsSplitCharacter', encodeURIComponent(tagsSplitCharacter));
      }
      const queryString = params.toString() ? `?${params.toString()}` : "";
      return axios.post(`/api/users/batchRegister${queryString}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    },
    {
      onSuccess: (res) => {
        
        if (options.disableDefaultSuccessMsg !== true) {
          SET_ALERT_BANNER({
            color: "green",
            msg: UIContent.general.batchRegisterSuccessfulMsg,
            timeOut: 10000,
          });
        }
      },
      onError: (err) =>{
        if (options.disableDefaultErrorMsg !== true) {
          SET_ALERT_BANNER({
            color: "red",
            msg: UIContent.general.serverErrorMsg,
            timeOut: 10000,
          })
        }
       
      }

        
    }
  );
};

export const useValidateBatchRegisterDataUsers = (options = {}) => {
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  
  return useMutation(
    ({ data, tagsSplitCharacter }) => {
      const params = new URLSearchParams();
      if (tagsSplitCharacter) params.append('tagsSplitCharacter', encodeURIComponent(tagsSplitCharacter));
      const queryString = params.toString() ? `?${params.toString()}` : "";
      return axios.post(`/api/users/validateBatchRegisterData${queryString}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    },
    {
      onSuccess: () => {},
      onError: (err) => genErrorMsgBanners({ err }),
    }
  );
};

export const useUpdateCoachmarkChecklist = () => {
  const queryClient = useQueryClient();
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  return useMutation(
    ({ property, value }) => {
      axios.patch(
        `/api/users/update/coachmarkChecklist/${property}`,
        { value },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    },
    {
      onSuccess: (res) => {
        console.log("coachmark checklist updated successfully");
        queryClient.invalidateQueries("userData");
      },
      onError: (err) => genErrorMsgBanners({ err }),
    }
  );
};
