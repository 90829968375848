import React, { Fragment } from "react";

import AsideFilter from "./comps/AsideFilter";
import MobileFilter from "./comps/MobileFilter";
import TopbarFilter from "./comps/TopbarFilter";



import { getBreakPoint, useScreenWidth } from "oolib";

import { handleOnChange, convertToInput } from "./utils/handleOnChange";
/**
 * Why are presetActiveFilters being passed into FilterModules?
 *
 * these are only meant to be considered while parsing display conditions,
 * because the parent that is using this filterModule,
 * HAS the presetActiveFilters applied along with activeFilters.
 *
 * The reason why we parse the display conditions inside the FilterModule is
 * because that way it becomes a global feature rather than specific to one
 * particular module.
 */

const FilterModule = ({
  presetActiveFilters = [],
  activeFilters = [],
  setActiveFilters,
  filterConfig: _filterConfig,
  /**
   * //OPTIONAL: about contentTypes prop
   * the reason this is passed thru, is so that the FilterInput component
   * inside Topbar/Aside/Mobile Filter knows what contenttypes are
   * being filtered, and hence fetch only the tags that have these
   * content types as tagged resources.
   *
   * This should not be used all the time. For example, if your filters
   * are using 'rollup', then obv this content type that is being filtered
   * wont exist as a taggedResource on the tag doc (since the rollup resource
   * will exist instead). In such cases, best not to pass this contentTypes prop only
   *
   */

  contentTypes,
  orientation = "sidebar", //alt topbar - but topbar is deprecated
  headerRef,
  totalResult,
  usedIn,
  mobileHeader,
  isMobile,
  topPanelHeight,
}) => {
  const screenWidth = useScreenWidth();

  const filterConfig = [
    // {
    //   filterId: 'datetypetest',
    //   display: 'Date Type Test',
    //   source: {
    //     filterType: 'dateRangeType'
    //   },
    //   target: {
    //     filterType: 'dateRangeType',
    //     valuePath: 'kp_date_published'
    //   }
    // },
    ..._filterConfig

  ]

  const _handleOnChange = ({ id, value }) => {
    handleOnChange({
      id,
      value,
      activeFilters,
      setActiveFilters,
      filterConfig,
      usedIn
    })
  }; 

  return (
    <Fragment>
      {orientation === "sidebar" ? (
        <AsideFilter
          selectedValues={convertToInput(activeFilters)}
          filterConfig={filterConfig}
          onChange={_handleOnChange}
          // headerRef={headerRef} this is not used
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          presetActiveFilters={presetActiveFilters}
          contentTypes={contentTypes}
          mobileHeader={mobileHeader}
          isMobile={isMobile}
          topPanelHeight={topPanelHeight}
        />
      ) : screenWidth >= getBreakPoint("md") ? (
        <TopbarFilter
          selectedValues={convertToInput(activeFilters)}
          onChange={_handleOnChange}
          filterConfig={filterConfig}
          activeFilters={activeFilters}
          presetActiveFilters={presetActiveFilters}
          contentTypes={contentTypes}
          setActiveFilters={setActiveFilters}
        />
      ) : (
        <MobileFilter
          onChange={_handleOnChange}
          selectedValues={convertToInput(activeFilters)}
          totalResult={totalResult === "loading" ? "..." : totalResult ?? 0}
          filterConfig={filterConfig}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          presetActiveFilters={presetActiveFilters}
          contentTypes={contentTypes}
        />
      )}
    </Fragment>
  );
};

export default FilterModule;
