import { cloneDeep } from "lodash";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { _carefullyMergeRawAnnosIntoSynth } from "../components/inputs/KPRichInput/annotation/functions/helpers";
import { useDebounce } from "./customHooks";
import { handleSetOtherValuePaths } from "./general";
import { setVal } from "oolib";
import { useGetQueryData } from "./react-query-hooks/general";
import { formValidationCheckV2 } from "./validation/formValidation";
import { carefullyMergeRawAnnosIntoSynthLex } from "../components/inputs/TextEditor/LexicalTextEditor/plugins/AnnoPlugin/utils/carefullyMergeRawAnnosIntoSynthLex";
import { useCreateContent } from "../pageTpls/ContributeTpl/queryHooks/useCreateContent";

export const useBeginPublishingFlow = () => {
  const navigate = useNavigate();
  const createContent = useCreateContent();

  const baseContent = (contentType, presetValues = []) => {
    let base = {
      main: {},
      meta: {
        kp_content_type: contentType,
        // kp_content_tpl: '123'
      }
    }

    presetValues.map(p => {
      base = setVal(base, p.valuePath, p.value)
    })
    return base;
  };

  const [generatingContentType, setGeneratingContentType] = useState(undefined);

  const generateFn = (contentTypeConfig, options = {}, presetValues = []) => {
    if (contentTypeConfig.kp_settings[0]?.blocks?.length > 0) {
      navigate(`/contribute-config/${contentTypeConfig.kp_content_type}`);
    } else {
      setGeneratingContentType(contentTypeConfig.kp_content_type);
      createContent.mutate(
        {
          contentType: contentTypeConfig.kp_content_type,
          content: baseContent(contentTypeConfig.kp_content_type, presetValues),
          ...(options.updateSelfServeSurvey ? {updateSelfServeSurvey: options.updateSelfServeSurvey} : {})
        },
        {
          onSuccess: (res) => {
            options.onSuccess
              ? options.onSuccess(res)
              : navigate(
                  `/edit/${res.data.content.meta.kp_content_type}?id=${res.data.content._id}`
                );
          },
        }
      );
    }
  };

  return {
    generateFn,
    thisTemplateIsGenerating: (d) =>
      d.kp_content_type === generatingContentType && createContent.isLoading, // this content type check if relevant on choose content type page
    generateTplIsLoading: createContent.isLoading, // a general boolean that tells if createContent is running or no
  };
};

export const useHandleContentChange = () => {
  const debounce = useDebounce();

  

  const handleContentChange = ({
    block,
    value,
    setFormValidation,
    blocksToValidate,
    setContent,
    mutate, //optional
    options = {}
    //these are passed via the options argument
  }) => {
    const { valuePath, setValuePath, setValuePathFn, setValuePathArgs } = block;
    const { mergeAnnoArgsFromRepeater } = options
    setContent((prev) => {
      let newC = cloneDeep(prev);
      newC = setVal(newC, valuePath, value);

      /** OPTIMIZE:  run this only if this block is found in failed blocks */
      const { failedBlocks } = formValidationCheckV2({
        blocks: blocksToValidate,
        content: newC,
      });
      setFormValidation && setFormValidation((p) => ({ ...p, failedBlocks }));
      
      if (
        (block.props?.annotation?.enable || mergeAnnoArgsFromRepeater)
        /**
         * in the case of a regular block we check to see if annotation is enabled on it.
         * in the case that the update is coming from a block inside the repeater, if 
         * mergeAnnoArgsFromRepeater exists, then that essentially means annotation is enabled
         * on that child block of the repeater.
         */
      ) {
        const blockComp = mergeAnnoArgsFromRepeater?.blockComp || block.comp
        if (blockComp !== "LexicalTextEditor") {
          
          newC.annotations = _carefullyMergeRawAnnosIntoSynth({
            value,
            prevObj: newC.annotations,
            // newObj: consolidatedAnnos,
            thisBlockValuePath: block.valuePath,
          });
        } else if (
          blockComp === "LexicalTextEditor" ||
          mergeAnnoArgsFromRepeater
        ) {
          const mergeAnnoArgs = mergeAnnoArgsFromRepeater ?
          {
            prevObj: newC.annotations?.tags,
            newLexValue: mergeAnnoArgsFromRepeater.newLexValue,
            thisBlockValuePath: `${block.valuePath}.${mergeAnnoArgsFromRepeater.idxAndValuePath}`,
          }
          : {
            prevObj: newC.annotations?.tags,
            newLexValue: value,
            thisBlockValuePath: block.valuePath,
          }

          newC.annotations = {
            ...(newC.annotations || {}),
            tags: carefullyMergeRawAnnosIntoSynthLex(mergeAnnoArgs),
          };
        }
      }
      if (setValuePath) {
        handleSetOtherValuePaths({
          setValuePath,
          setValuePathFn,
          setValuePathArgs,
          value,
          content: newC,
        });
      }

      if (mutate) {
        debounce(() => mutate({ updatedContent: newC }));
      }

      return newC;
    });
  };

  return { handleContentChange };
};
