import React, { useEffect, useRef, useState } from "react";
import { observe } from "react-intersection-observer";

import { ButtonGhost, colors, getBreakPoint, SANS_2 } from "oolib";

import {
  StyledIndexBar,
  StyledIndexBarWrapper,
  StyledTabsDropdown,
} from "../../styled.js";

import TableOfContentButton from "../../../TableOfContent/comps/TableOfContentButton/index.js";

const { red, greyColor5, greyColor100 } = colors;

const IndexNav = ({
  id,
  indexList,
  value,
  onChange,
  emptyBlocks,
  screenWidth,
  invert,
  indexType,
}) => {
  const getElementToObserve = (indexObj) => {
    return indexType === "section"
      ? document.getElementById(`TplSection_${indexObj.block.sectionId}`)
      : document.getElementById(`TplBlock_${indexObj.block.props.id}`);
  };

  useEffect(() => {
    let observersDestroyers = [];
    indexList.forEach((indexObj) => {
      let elemToObserve = getElementToObserve(indexObj);
      //ideally elemToObserve should always exist. 
      //If it doesnt exist then something else is wrong in the code
      //but we are putting this if to prevent the page from unnecessarily breaking
      if(elemToObserve){ 
        let destroyIndexObserver = observe(
          elemToObserve,
          (inView, entry) => {
            if (inView) {
              onChange(id, indexObj);
            }
          },
          { threshold: 0.25 }
        );
        observersDestroyers.push(destroyIndexObserver);
      }
    });
    // }

    return () => observersDestroyers.forEach((destroyer) => destroyer());
  }, []);

  const handleClick = (indexObj) => {
    const activeIndexOp = getElementToObserve(indexObj);

    activeIndexOp.scrollIntoView({
      behavior: "smooth",
    });

    onChange(id, indexObj);
  };

  const userDevice = screenWidth > getBreakPoint("xl") ? "desktop" : "phone";
  const activeIndex = indexList.find(index => index.value === value?.value);

  const genBarTab = ({ optionObj, handleClick, value, emptyBlocks }) => {
    const isActive = value && optionObj.value === value.value;
    const hasError =
      emptyBlocks &&
      emptyBlocks.some(
        (block) =>
          block.valuePath === optionObj.value ||
          block.sectionId === optionObj.value ||
          block.sectionStack?.some(
            (section) => section.sectionId === optionObj.value
          )
      );
    return (
      <TableOfContentButton {...{ optionObj, invert, handleClick, isActive, hasError }} />
    );
  };

  const tabsDropdownWrapperRef = useRef(null);

  const [mobileIndexOpen, setMobileIndexOpen] = useState(false);

  return (
    <StyledIndexBarWrapper >
      <StyledIndexBar invert={invert}>
        {userDevice === "desktop" ? (
          <>
            <SANS_2 style={{ padding: "0.75rem 3rem", color: colors.greyColor70 }} invert={invert}>
              Index
            </SANS_2>
            {indexList.map((indexObj) =>
              genBarTab({
                optionObj: indexObj,
                handleClick,
                value,
                emptyBlocks,
              })
            )}
          </>
        ) : (
          <div style={{ position: "relative" }}>
            <div style={{ position: "relative" }}>
              <TableOfContentButton
                style={{
                  backgroundColor: invert ? greyColor100 : greyColor5,
                  boxShadow:
                    !!emptyBlocks.length && `inset 0px -2.5px 0px ${red}`,
                  paddingRight: "4rem", // space for the caret
                }}
                {...{
                  optionObj: {
                    ...(activeIndex || {}),
                    display: "Index: " + (activeIndex?.display || ''),
                  },
                  invert,
                  handleClick: () => setMobileIndexOpen((prev) => !prev),
                }}
              />
              <ButtonGhost
                icon={`Caret${mobileIndexOpen ? "Down" : "Up"}`}
                invert={invert}
                style={{
                  backgroundColor: "transparent",
                  position: "absolute",
                  zIndex: 5,
                  bottom: "50%",
                  right: "1rem",
                  transform: "translateY(50%)",
                }}
              />
            </div>
            <StyledTabsDropdown
              mobileIndexOpen={mobileIndexOpen}
              tabsDropdownWrapperRef={tabsDropdownWrapperRef}
            >
              <div
                ref={tabsDropdownWrapperRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                {indexList.map((indexObj) =>
                  genBarTab({
                    optionObj: indexObj,
                    handleClick,
                    value,
                    emptyBlocks,
                  })
                )}
              </div>
            </StyledTabsDropdown>
          </div>
        )}
      </StyledIndexBar>
    </StyledIndexBarWrapper>
  );
};

export default IndexNav;
