import React, { useEffect, useState } from "react";

import { _Locale } from "../../components/locale/Locale";

import { useNavigate, useLocation } from 'react-router-dom';

import { WrapperButtons } from "../../components/layout/Wrappers";
import CreateUpdatePopUp from "../../components/updatesModule/CreateUpdatePopUp/index";
import { tplBlocks } from "../../importGroups/tplBlocks";
import {
  __CheckAccessToContentType,
  __IsAuthor,
} from "../../utils/accessControl";
import { ViewBlockGenerator } from "../../utils/blockGenerators";
import {
  getQueryParam,
  injectQueryParamCarefully,
  simpleDrillDown,
} from "../../utils/general";
import { createContentCardDate } from "../../utils/momentManipulate";
import { useGetTpl } from "../../utils/react-query-hooks/tpls";
import { useDeleteUpdate } from "../../utils/react-query-hooks/update";
import {
  ModalConfirm,
  ButtonPrimary,
  ButtonSecondary,
  PaddingTopBottom15,
  Accordion,
  colors,
} from "oolib";
import { useScrollHashLinkIntoView } from "../../utils/customHooks/useScrollHashLinkIntoView";

export const UpdatesModule = ({
  parentContent,
  userData,
  parentContentType,
  memo,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: tpl, status: tplStatus } = useGetTpl(
    `${parentContentType}__update`
  );

  const [showModal, setShowModal] = useState(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState(undefined);

  const deleteUpdate = useDeleteUpdate();

  const handleDeleteUpdate = () => {
    deleteUpdate.mutate(
      {
        parentContentType,
        parentResourceId: parentContent._id,
        updateId: showDeleteModal.toDeleteUpdateId,
      },
      {
        onSuccess: () => setShowDeleteModal(undefined),
      }
    );
  };

  // const Components = { ...tplBlocks }

  const initBoilerplateContent = () => ({
    meta: {},
    main: {},
  });

  const triggerCreateUpdateModal = () =>
    setShowModal({ mode: "create", data: initBoilerplateContent() });

  const triggerEditUpdateModal = (update) =>
    setShowModal({ mode: "edit", data: update });

  const closeUpdateModal = () => setShowModal(undefined);

  //handle showing / hiding edit modal based on query params
  useEffect(() => {
    let createUpdateValue = getQueryParam(location.search, "createUpdate");
    if (tplStatus === "success") {
      if (!createUpdateValue || createUpdateValue === "0") {
        !!showModal === true && closeUpdateModal();
      } else if (createUpdateValue === "create") {
        triggerCreateUpdateModal();
      } else if (createUpdateValue?.includes("edit")) {
        /**
         * the query param actually is ?createUpdate=edit+123
         * '+' is converted back to a 'space' when extracted from query param
         */
        let updateId = createUpdateValue.split(" ")[1];
        let updateContent = parentContent.updates.find(
          (d) => d._id === updateId
        );

        triggerEditUpdateModal(updateContent);
      }
    }
   
  }, [tplStatus, location.search]);

  useScrollHashLinkIntoView()

  const genCreateModal = () => (
    <CreateUpdatePopUp
      mode={showModal.mode}
      updateData={showModal.data}
      parentResourceId={parentContent._id}
      closeModal={() =>
        location.state?.postPblRedirect //from the casereport table we redirect to pblpage to show update popup and then redirect back to listing
          ? navigate(location.state?.postPblRedirect)
          : injectQueryParamCarefully("createUpdate", "0", location, navigate)
      }
      initBoilerplateContent={initBoilerplateContent}
    />
  );

  const genDeleteModal = () => (
    <ModalConfirm
      title={_Locale("Are you sure want to delete this ?")}
      onClose={() => setShowDeleteModal(undefined)}
      onConfirm={handleDeleteUpdate}
    />
  );

  const genActions = (update) => {
    const canEditUpdate = () => {
      return (
        __CheckAccessToContentType(
          userData?.user,
          "UPDATE_UPDATE",
          parentContentType
        ) && __IsAuthor(update.meta.kp_contributed_by?._id, userData?.user._id)
      );
    };

    const canModerateUpdate = () => {
      return __CheckAccessToContentType(
        userData?.user,
        "MODERATE",
        parentContentType
      );
    };

    const canDeleteUpdate = () => {
      return (
        __CheckAccessToContentType(
          userData?.user,
          "MODERATE",
          parentContentType
        ) ||
        (__CheckAccessToContentType(
          userData?.user,
          "DELETE_UPDATE",
          parentContentType
        ) &&
          __IsAuthor(update.meta.kp_contributed_by?._id, userData?.user._id))
      );
    };

    const actionsConfig = [
      ...(canEditUpdate()
        ? [
            {
              comp: "ButtonPrimary",
              text: "Edit",
              onClick: () =>
                injectQueryParamCarefully(
                  "createUpdate",
                  `edit+${update._id}`,
                  location,
                  navigate
                ),
              icon: "PencilSimple",
            },
          ]
        : []),
      ...(canModerateUpdate() && !canEditUpdate() //show mod button only if current user cant also Edit the update
        ? [
            {
              comp: "ButtonPrimary",
              text: "Moderate",
              onClick: () =>
                injectQueryParamCarefully(
                  "createUpdate",
                  `edit+${update._id}`,
                  location,
                  navigate
                ),
              icon: "PencilLine",
            },
          ]
        : []),
      ...(canDeleteUpdate()
        ? [
            {
              comp: "ButtonPrimary",
              text: "Delete",
              onClick: () =>
                setShowDeleteModal({
                  toDeleteUpdateId: update._id,
                }),
              icon: "Trash",
            },
          ]
        : []),
    ];

    const buttonComps = {
      ButtonPrimary,
      ButtonSecondary,
    };

    return (
      <WrapperButtons>
        {actionsConfig.map((a) => {
          let ButtonComp = buttonComps[a.comp];
          return (
            <ButtonComp value={a.text} icon={a.icon} onClick={a.onClick} S />
          );
        })}
      </WrapperButtons>
    );
  };
 
  return (
    <>
      {showModal && genCreateModal()}
      {showDeleteModal && genDeleteModal()}
      {parentContent && parentContent.updates && tplStatus === "success" && (
        <div className="UpdatesModule" id="updatesModule">
          {/* <ContainerProp Header={genHeader}> */}
              
          {parentContent.updates.length > 0 && (
            <div >
              {parentContent.updates.map((update, updateI) => {
             
                return (
                  <div id={ `UPDATE_${update._id}`}>
                  <Accordion
                  /**
                   * vry imp to add dateEdited to the key here, cuz, 
                   * what this does is, when an update is edited, 
                   * it forces a rerender of these update accordions, 
                   * because of which, the content inside uncontrolled 
                   * comps such as RTE also gets updated to the 
                   * latest content, without having to refresh the screen
                   */
                    key={`${updateI}_${update.dateEdited}`} 
                    HeaderStyle="HeaderStyle2"
                    className="UpdatesModule__publishedUpdates"
                    label={createContentCardDate(update.dateCreated)}
                    title={
                      update.meta.subject?.title ||
                      `Update #${parentContent.updates.length - updateI}`
                    }
                  >
					<div  style={{borderBottom: `2px solid ${colors.greyColor10}`, paddingBottom: '3rem'}}>
                    <PaddingTopBottom15>
                      {simpleDrillDown(
                        tpl.kp_templates.data[
                          update.meta.subject?.value || "default"
                        ],
                        (block) => {
                          return (
                            <ViewBlockGenerator
                              memo={memo}
                              block={block}
                              content={update}
                              Wrapper={PaddingTopBottom15}
                              isNewTemplate={false}
                            />
                          );
                        }
                      )}
                      
                    </PaddingTopBottom15>
						{genActions(update)}
					</div>
                  </Accordion>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};
