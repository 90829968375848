import { useNavigate } from "react-router-dom";
import { useSubmitPublishContent } from "../queryHooks/useSubmitPublishContent";
import { useModerationApprove } from "../../../utils/react-query-hooks/contributions";
import { _GetPublishOrSubmitAction } from "../../../utils/accessControl";
import { queryClient } from "../../..";
import { useFormValidationContext } from "../../../contexts/formValidationContext";
import { trackEventGA } from "../../../trackers/GA/trackEventGA";
import { isDeviceOnline } from "../../../utils/environmentConfig";
import { UIContent } from "../../../UIContent";
import { useState } from "react";
import { getBreakPoint, useBannerContext, useScreenWidth } from "oolib";
import { useDeleteUnderModeration, useMoveUnderModerationToAwaiting, useSendBackUnderModeration } from "../../../utils/react-query-hooks/moderation";
import { useGetQueryData } from "../../../utils/react-query-hooks/general";
import { getRedirectUrl } from ".";
import { extractAllBlocksFromTpl } from "../../../utils/templating/extractAllBlocksFromTpl";
import { sendBroadcastMessage } from "../../../components/BroadcastNavigator";

export const useGetPrimaryActionsConfig = ({
  kp_published_status,
  contentType,
  contributionQueryData,
  moderationQueryData,
  isAutoSaving,
  edit_moderate,
  content,
  contributionId,
}) => {
  const screenWidth = useScreenWidth();
  const navigate = useNavigate();
  const { SET_INFO_BANNER } = useBannerContext();
  const userData = queryClient.getQueryState("userData");

  // ---- query hooks
  const submitPublishContent = useSubmitPublishContent();
  const moderationApproveQuery = useModerationApprove();
  const { mutate: moveMutate, isLoading: isLoadingMoveToAwaiting } =
    useMoveUnderModerationToAwaiting();
  const { mutate: deleteMutate, isLoading: isLoadingDelete } =
    useDeleteUnderModeration();

  const { mutate: sendBackMutate, isLoading: isLoadingSendBack } =
    useSendBackUnderModeration();
  // ----

  const { formValidation, makeFormValidationVisible } =
    useFormValidationContext();
  const {
    deployment: {
      _EnableConfirmationBeforePublish,
      _EnableConfirmationBeforeExit,
    },
  } = useGetQueryData("platformConfigs");
  const { display: primaryButtonDisplay, action: primaryButtonAction } =
    _GetPublishOrSubmitAction(userData.data.user, contentType) || {};

  const [openSendBackModal, setOpenSendBackModal] = useState(false);
  const isMobile = screenWidth < getBreakPoint('sm');
  let isModerate = edit_moderate === "moderate";

  const publishButtonDisabled =
    !isDeviceOnline() ||
    isAutoSaving ||
    formValidation.failedBlocks?.length > 0 ||
    formValidation.invalidInputBlocks?.length > 0;

/**
 * Finds the index of the earliest occurrence of an error in the main array.
 * It checks both the formValidation.failedBlocks and formValidation.invalidInputBlocks arrays
 * to determine the first element with an error.
 */
function findMinIndexOfArrayElems(mainArr, arr2, arr3) {
  const i2 = mainArr.findIndex(item => item?.valuePath === arr2[0]?.valuePath);
  const i3 = mainArr.findIndex(item => item?.valuePath === arr3[0]?.valuePath);
  if (i2 === -1) return i3;
  if (i3 === -1) return i2;
  return Math.min(i2, i3);
}


/**
 * Scrolls to the first block with an error in the form.
 * Checks both failed and invalid input blocks for errors.
 * Retrieves the block list from the template and finds the index
 * of the first block with an error. If the error block is found,
 * it attempts to locate the corresponding DOM element by its ID.
 * If the element is located, the function scrolls the page smoothly
 * to the position of the element, adjusted by a predefined offset.
 */

  const scrollToError = () => {
    if (!formValidation.failedBlocks?.length && !formValidation.invalidInputBlocks?.length) return;

    const tplb = extractAllBlocksFromTpl({ tpl: contributionQueryData.tpl })
    let ind = findMinIndexOfArrayElems(tplb, formValidation.failedBlocks, formValidation.invalidInputBlocks)

    if (ind === undefined || !tplb[ind]) return;

    const ele = Array.from(document.querySelectorAll('*'))
    const element = ele.find(div => div.id && div.id.includes(tplb[ind].props.id))

    if (!element) {
      console.log('Error element not found');
      return;
    }

    const offset = 100;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

  const publishClick = (primaryButtonAction) => {
    if (primaryButtonAction !== "MODERATE") {
      const content = contributionQueryData.contribution;
      /**
       * we need to get the contentId from the contributionQueryData
       * and not from the url, cuz the url has the _id of the OG
       * document. This document that is currently being rendered may
       * be an editPublished clone, and the submit publish content api
       * expects the contentId of THE doc that is being edited. Meaning,
       * if the og doc is being edited (in case of status === draft), then it
       * expects the og id, but if a clone doc is being edited (in case of status === editPublished),
       * then it expects the clone's id.
       */
      submitPublishContent.mutate({
        contentType: content.meta.kp_content_type,
        contentId: content._id,
        userAction: primaryButtonAction,
      });
    } else {
      moderationApproveQuery.mutate({
        modCloneId: moderationQueryData.contribution._id,
        contentType,
      });
    }
  };

  const publishButtonConfig = {
    wrapperOnClick: (e) => {
      if (!isDeviceOnline())
        SET_INFO_BANNER(
          "Publish is deactivated when you are offline. Please try again when you are online.",
          "grey",
          3000
        );
      if (
        formValidation.failedBlocks.length > 0 ||
        formValidation.invalidInputBlocks.length > 0
      ){
        scrollToError()
        makeFormValidationVisible();
        e.stopPropagation();
      }
    },
    confirmAction:
      _EnableConfirmationBeforePublish?.enable &&
      _EnableConfirmationBeforePublish?.contentTypes?.[contentType]
        ? {
            enabled: true,
            ..._EnableConfirmationBeforePublish?.contentTypes?.[contentType],
          }
        : { enabled: false },
    id: "h_PrimaryCTA",
    style: { pointerEvents: isAutoSaving ? "none" : "" },
    disabled: publishButtonDisabled,
    ...(() => {
      switch (kp_published_status) {
        case "draft":
          return {
            onClick: () => {
              if(publishButtonDisabled) return;
              trackEventGA(
                `Publishing Flow`,
                `Clicked ${primaryButtonDisplay.onDraft} On Template`,
                `${contentType}`
              );
              publishClick(primaryButtonAction);
              sendBroadcastMessage('redirect', window.location.href);
            },
            display: primaryButtonDisplay.onDraft,
          };
        case "editPublished":
          return {
            onClick: () => {
              if(publishButtonDisabled) return;
              trackEventGA(
                `Published Content`,
                `Clicked ${primaryButtonDisplay.onEditPublished} On Published Content`,
                `${contentType}`
              );
              publishClick(primaryButtonAction);
              sendBroadcastMessage('redirect', window.location.href);
            },
            display: primaryButtonDisplay.onEditPublished,
          };
        case "underModeration":
          return {
            onClick: () => {
              if(publishButtonDisabled) return;
              publishClick("MODERATE")
              sendBroadcastMessage('redirect', window.location.href);
            },
            display: "Approve",
          };
        default:
          return {
            onClick: () => {
              sendBroadcastMessage('redirect', window.location.href);
            },
            display: "No Action Permitted",
          };
      }
    })(),
  };

  const sendbackButtonConfig = {
    confirmAction: {
      title: UIContent.confirmActionSendBack.title,
      subtitle: UIContent.confirmActionSendBack.subtitle,
      onConfirmText: UIContent.confirmActionSendBack.onConfirmText,
    },
    onClick: () => setOpenSendBackModal(true),
    disabled: isAutoSaving,
    display: UIContent.sendBackBtn,
  };

  const closeButtonConfig = {
    disabled: isAutoSaving,
    id: "h_CloseButton",
    onClick: () => {
      sendBroadcastMessage('redirect', window.location.href);
      navigate(getRedirectUrl.onClose({ content, isModerate }));
      if (kp_published_status === "draft") {
        trackEventGA(
          `My Content`,
          `Click Close Button & Save As Draft`,
          `${contentType}`
        );
        SET_INFO_BANNER({
          color: "grey",
          msg: "Your Draft Has Been Safely Saved.",
          cta: {
            text: "View Drafts",
            action: () =>
              navigate({
                pathname: `/my-dashboard/MyContent`,
                search: `?activeTab=draft`,
              }),
          },
          timeOut: 3000,
        });
      } else if (kp_published_status === "editPublished") {
        trackEventGA(
          `Published Content`,
          `Click Close & Save Unpublished Changes`,
          `${contentType}`
        );
        SET_INFO_BANNER({
          color: "grey",
          msg: "Your Unpublished Changes Have Been Safely Saved.",
          cta: {
            text: "Go To My Content",
            action: () =>
              navigate({
                pathname: `/my-dashboard/MyContent`,
                search: `?activeTab=published`,
              }),
          },
          timeOut: 3000,
        });
      } else if (kp_published_status === "underModeration") {
        SET_INFO_BANNER({
          color: "grey",
          msg: "Your changes to the document have been saved. You can access it at any time from this page until you approve the changes.",
          timeOut: 5000,
        });
      }
      //read note in react-query-hooks/conntributions ( line 193) explaining why removeQueries
      queryClient.removeQueries([
        isModerate ? "ContributionCloneForModeration" : "Contributions",
        edit_moderate, // = edit or moderate
        contentType,
        contributionId,
      ]);
    },
    ...(() => {
      switch (kp_published_status) {
        case "draft":
          return {
            display: UIContent.editLater,
            confirmAction: {
              enabled: true,
              title: UIContent.confirmActionEditLaterDraft.title,
              onConfirmText:
                UIContent.confirmActionEditLaterDraft.onConfirmText,
            },
          };
        case "editPublished":
          return {
            display: UIContent.editLater,
            confirmAction: {
              enabled: true,
              title: UIContent.confirmActionEditLater.title,
              onConfirmText: UIContent.confirmActionEditLater.onConfirmText,
            },
          };
        case "underModeration":
          return {
            display: UIContent.reviewLater,
            confirmAction: {
              enabled: true,
              title: UIContent.confirmActionReviewLater.title,
              onConfirmText: UIContent.confirmActionReviewLater.onConfirmText,
            },
          };
        default:
          return {
            display: "No Action Permitted",
            confirmAction: { enabled: false },
          };
      }
    })(),
  };

  

  const moveToAwaitingAndDeleteActions = (isModerate && !moderationQueryData?.contribution?.kp_is_pbl_taken_into_mod) ? [
    {
      display: "Move to awaiting review ",
      disabled: isAutoSaving,
      onClick: () => {
        moveMutate(
          {
            contentType: moderationQueryData.contribution.meta.kp_content_type,
            modCloneId: moderationQueryData.contribution._id,
            ogDocId:moderationQueryData.contribution.kp_pre_mod_doc
          }
       
        );
        sendBroadcastMessage('redirect', window.location.href);
      },
      confirmAction: {
        enabled: true,
        onConfirmText:
          UIContent.confirmActionMoveToAwaitingReview.onConfirmText,
        title: UIContent.confirmActionMoveToAwaitingReview.title,
        subtitle: UIContent.confirmActionMoveToAwaitingReview.subtitle,
      },
    },
    {
      display: "Delete Content",
      disabled: isAutoSaving,
      onClick: () => {
        deleteMutate(
          {
            contentType: moderationQueryData.contribution.meta.kp_content_type,
            id: moderationQueryData.contribution._id,
          }
     
        );
        sendBroadcastMessage('redirect', window.location.href);
      },
      confirmAction: {
        enabled: true,
        onConfirmText: UIContent.confirmActionDelete.onConfirmText,
        title: UIContent.confirmActionDelete.title,
      },
    },
  ] : [];
  const otherLeftsideButtonConfigs = (isMobile || !isModerate) ? [] : [sendbackButtonConfig]
  const otherRightsideButtonConfigs = [
    {
        comp: 'ActionMenu',
        props: {
            actions: isMobile ? [
                ...(isModerate ? [sendbackButtonConfig] : []),
                closeButtonConfig,
                ...moveToAwaitingAndDeleteActions
            ] : moveToAwaitingAndDeleteActions
        }
    }
  ]

  return {
    publishButtonConfig,
    closeButtonConfig: isMobile ? undefined : closeButtonConfig,
    otherLeftsideButtonConfigs,
    otherRightsideButtonConfigs,
    submitPublishContent,
    moderationApproveQuery,
    
    setOpenSendBackModal,
    openSendBackModal,
    sendBackMutate,
    isLoading: isLoadingDelete || isLoadingMoveToAwaiting || isLoadingSendBack,
    loadingText: isLoadingDelete
        ? "Deleting..."
        : isLoadingMoveToAwaiting
        ? "Moving to awaiting review..."
        : isLoadingSendBack
        ? "Sending back..."
        : "",
    publishButtonDisabled
  };
};
