import {
  CardContent,
  colors2,
  Container,
  LoaderOverlay,
  OKELink,
  PaddingBottom20,
  PaddingTop40,
  SANS_2,
  Section,
  UI_BODY_SEMIBOLD_SM,
  UI_TITLE_SM,
  Wrapper700,
} from "oolib";
import React, { Fragment, useRef } from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { HeaderLogo } from "../../components/nav/Header/PrimaryHeaderV2/HeaderUI/comps/HeaderLogo";
import { useBeginPublishingFlow } from "../../utils/contributionFlowUtils";
import { useSearchParamsState } from "../../utils/customHooks";
import {
  __GetContentTypeConfigNew,
  __GetProfileTypeConfig,
} from "../../utils/getters/gettersV2";
import { useGetQueryData } from "../../utils/react-query-hooks/general";
import ContributeTpl from "../ContributeTpl";
import { useSubmitPublishContent } from "../ContributeTpl/queryHooks/useSubmitPublishContent";
import PublishedPageV2 from "../PublishedPage";
import { useQuickLinkParser } from "./useQuickLinkParser";

/** this sort of config needed for the requird profileType, to activate this module 
      "selfServeSurveyConfig": {
        "surveys": [
          {
            "contentType": "expressionOfInterest",
            "initActive": true,
            "enableTriggerNextSurvey": true
          },
          {
            "contentType": "surveyQuestionnaires",
            "initActive": false
          }
        ]
      }
 */

const SelfServeSurvey = () => {
  const location = useLocation();
  const userData = useGetQueryData("userData");
  const profileConfig = __GetProfileTypeConfig(userData?.user?.profileType);
  const originalFrom = useRef(location.state?.from);
  const queryClient = useQueryClient();
  const { generateFn, generateTplIsLoading } = useBeginPublishingFlow();

  const [surveyData, setSurveyData] = useSearchParamsState({
    key: "survey",
    value: null, // will contain { contentType, documentId, mode: 'edit' | 'view' }
  });

  useQuickLinkParser({
    // orignalFrom is just pathname+query. so need to add this dummy url, otherwise the new Url class initiation will fail inside
    originalUrl: originalFrom.current
      ? "http://somedummyurlthatdoesntmatter.com" + originalFrom.current
      : originalFrom.current,
    userData,
    generateFn,
    generateTplIsLoading,
    setSurveyData,
  });

  const getActiveSurveys = () => {
    const configuredSurveys =
      profileConfig?.selfServeSurveyConfig?.surveys || [];
    const userSurveys = userData?.user?.selfServeSurveys || [];

    return configuredSurveys.filter((configSurvey) => {
      if (userSurveys.length === 0) {
        return configSurvey.initActive;
      }
      const userSurvey = userSurveys.find(
        (us) => us.contentType === configSurvey.contentType
      );
      return !!userSurvey;
    });
  };

  const getCardData = (survey) => {
    const userSurvey = userData?.user?.selfServeSurveys?.find(
      (us) => us.contentType === survey.contentType
    );

    return {
      contentType: survey.contentType,
      status: userSurvey?.status || "Not Started",
      title:
        __GetContentTypeConfigNew(survey.contentType)?.general?.content
          ?.title || survey.contentType,
      description: userSurvey?.status === 'published'
        ? ''
        : userSurvey
          ? "Click to continue your survey"
          : "Click to start the survey",
    };
  };

  const cardConfig = {
    title: "title",
    cardLabel: "Survey",
    tagDisplay: "status",
    desc: "description",
  };

  const submitPublishContent = useSubmitPublishContent();

  const { _Nav, deployment } = useGetQueryData("platformConfigs");

  const handleSurveyClick = (e, data) => {
    const userSurvey = userData?.user?.selfServeSurveys?.find(
      (us) => us.contentType === data.contentType && !!us.documentId === true
    );

    if (userSurvey) {
      setSurveyData({
        contentType: data.contentType,
        documentId: userSurvey.documentId,
        mode: userSurvey.status === "published" ? "view" : "edit",
      });
    } else {
      if (!generateTplIsLoading) {
        generateFn(
          {
            kp_content_type: data.contentType,
            kp_settings: [],
          },
          {
            onSuccess: async (res) => {
              await queryClient.invalidateQueries("userData");

              setSurveyData({
                contentType: data.contentType,
                documentId: res.data.content._id,
                mode: "edit",
              });
            },
            updateSelfServeSurvey: true,
          }
        );
      }
    }
  };

  if (surveyData) {
    return surveyData.mode === "view" ? (
      <div>
        <div style={{ height: "4rem", padding: "0 2rem" }}>
          <OKELink onClick={() => setSurveyData(null)}>
            <SANS_2>Go Back</SANS_2>
          </OKELink>
        </div>
        <PublishedPageV2
          onboardingProps={{
            contentType: surveyData.contentType,
            contributionId: surveyData.documentId,
          }}
        />
      </div>
    ) : (
      <Fragment>
        {submitPublishContent.isLoading && (
          <LoaderOverlay loaderText="Publishing..." />
        )}
        <ContributeTpl
          onboardingProps={{
            edit_moderate: "edit",
            contentType: surveyData.contentType,
            contributionId: surveyData.documentId,
          }}
          publishOnClick={() => {
            if (!submitPublishContent.isLoading) {
              submitPublishContent.mutate(
                {
                  contentType: surveyData.contentType,
                  contentId: surveyData.documentId,
                  userAction: "PUBLISH",
                  updateSelfServeSurvey: true,
                },
                {
                  onSuccess: async () => {
                    await queryClient.invalidateQueries("userData");
                    setSurveyData(null);
                  },
                }
              );
            }
          }}
        />
      </Fragment>
    );
  }

  const activeSurveys = getActiveSurveys();

  return (
    <div>
      {generateTplIsLoading && (
        <LoaderOverlay loaderText="Loading survey form..." />
      )}
      <Section borderBottom style={{ zIndex: 10, position: "relative" }}>
        <Container>
          <div
            style={{
              height: "6rem",
              display: "flex",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <HeaderLogo config={{ _Nav, deployment }} />
            <UI_TITLE_SM>Self Serve Surveys</UI_TITLE_SM>
          </div>
        </Container>
      </Section>
      <Section>
        <div
          style={{ background: colors2.grey5, height: "calc(100dvh - 6rem)" }}
        >
          <Wrapper700 style={{ height: "100%" }}>
            <div
              style={{
                background: "white",
                height: "100%",
                boxShadow: "2px 2px 8px rgba(0,0,0,0.15)",
              }}
            >
              <Container>
                <PaddingTop40 />
                <div
                  style={{
                    borderBottom: `1px solid ${colors2.grey10}`,
                    paddingBottom: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <UI_BODY_SEMIBOLD_SM>My Surveys</UI_BODY_SEMIBOLD_SM>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  {activeSurveys.map((survey) => (
                    <Fragment>
                      <CardContent
                        key={survey.contentType}
                        id={`survey-${survey.contentType}`}
                        data={getCardData(survey)}
                        config={cardConfig}
                        onClick={handleSurveyClick}
                        statusTagVariant={
                          survey.status === "published" ? "positive" : "neutral"
                        }
                      />
                    </Fragment>
                  ))}
                </div>
                <PaddingBottom20 />
              </Container>
            </div>
          </Wrapper700>
        </div>
      </Section>

      {activeSurveys.length === 0 && (
        <div className="text-center text-gray-500 mt-8">
          No active surveys available
        </div>
      )}

      {/* {originalFrom.current && (
        <div className="mt-4 text-sm text-gray-500">
          Original URL: {originalFrom.current}
        </div>
      )} */}
    </div>
  );
};

export default SelfServeSurvey;
