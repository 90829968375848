import React from "react";
import DisplayLinksToOtherImmersion from "./comps/displayLinksToOtherImmersion";
import { getTenant } from "../../../TENANT_CONFIGS";
import { ModuleContainer } from "../../../pageTpls/PublishedPage/comps";

const configs = {
  cata: {
    researchNotes: {
      comp: DisplayLinksToOtherImmersion,
      title: "Related Immersion Notes",
    },
    knowledgeResources2: {
      comp: DisplayLinksToOtherImmersion,
      title: "Related Immersion Notes",
    },
  },
};

export default function PluginModule(props) {
  const tenantConfig = configs[getTenant()];

  if (!tenantConfig) return null;

  const { comp: Comp, title } = tenantConfig[props.tpl.category] || {};

  return Comp ? (
    <ModuleContainer title={title}>
      {" "}
      <Comp {...props} />{" "}
    </ModuleContainer>
  ) : null;
}
