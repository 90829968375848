import React, { useState } from 'react';

import { getBreakPoint, useScreenWidth } from 'oolib';

import TabsNav from './comps/TabsNav';
import IndexNav from './comps/IndexNav';




const IndexBar = ({
  id,
  options,
  value,
  onChange,
  updateUrl,
  emptyBlocks = [],
  type,
  invert,
  
  indexType
}) => {

  const screenWidth = useScreenWidth();
  
  const [barHasFocus, setBarHasFocus] = useState(screenWidth > getBreakPoint("xl"));
  

  return type === "tabs"?(
    <TabsNav
      screenWidth={screenWidth}
      barFocus={{barHasFocus, setBarHasFocus}}
      tabs={options}
      emptyTabs={emptyBlocks}
      {...{id, value, onChange, updateUrl, invert}}
    />
  ): ( //else index
    <IndexNav
      screenWidth={screenWidth}
      barFocus={{barHasFocus, setBarHasFocus}}
      indexList={options}
      emptyBlocks={emptyBlocks}
      {...{id, value, onChange, invert, indexType}}
    />
  )
}

export default IndexBar