import { useCallback, useEffect, useRef } from "react";

export const useTableOfContentAnchorLinks = ({
  onChange,
  indexType,
  options,
  enabled,
}) => {
  const observerRef = useRef(null);

  const getElementToObserve = (indexObj) => {
    return indexType === "section"
      ? document.getElementById(`TplSection_${indexObj.block.sectionId}`)
      : document.getElementById(`TplBlock_${indexObj.block.props.id}`);
  };

  useEffect(() => {
    if (!enabled) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const indexObj = options.find(
              (option) => getElementToObserve(option) === entry.target
            );
            onChange(undefined, indexObj);
          }
        });
      },
      { threshold: 0.25 }
    );

    options.forEach((indexObj) => {
      const elemToObserve = getElementToObserve(indexObj);
      if (elemToObserve) {
        observer.observe(elemToObserve);
      }
    });

    observerRef.current = observer;

    return () => {
      observerRef.current.disconnect();
    };
  }, [enabled, options, indexType]);

  const handleClick = useCallback((notRequired, indexObj) => {
    const activeIndexOp = getElementToObserve(indexObj);
    const rect = activeIndexOp.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
  
    let scrollOffset = 0;
    const sectionHeight = rect.height;
  
    if (sectionHeight < windowHeight) {
      // If the section height is smaller than the window height
      scrollOffset = (windowHeight - sectionHeight) / 2; // Center the section vertically
    } else {
      // If the section height is larger than the window height
      scrollOffset = 25; // Scroll with a 20px offset from the top
    }
  
    const scrollOptions = {
      behavior: "smooth",
      top: rect.top - scrollOffset,
      left: rect.left,
    };
  
    window.scrollBy(scrollOptions);
    onChange(undefined, indexObj);
  },
  [onChange]
 )

  return { handleClick };
};