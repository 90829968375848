import React, { useState, useEffect } from "react";
import { transition, UI_TITLE } from "oolib";
import styled from "styled-components";
import { m } from "framer-motion";

const StyledStickyTitle = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  overflow: hidden;
  z-index: 40;
  ${transition("height")};
  display: flex;
  align-items: flex-end;
`;

export const StickyTitle = ({
  title,
  stickyTitleHeight,
  coverSectionBottom,
  mobileTitleRef,
  style = {},
}) => {
  const getStickyTitleHeight = (mobileTitleRef) => {
    const h = mobileTitleRef?.current?.getBoundingClientRect().height;
    return h ? h + "px" : "auto";
  };

  return (
    <StyledStickyTitle
      id="sticky_title"
      style={{
        ...style,
        height:
          coverSectionBottom <
          stickyTitleHeight + 10 /** slight offset makes it smoother */
            ? getStickyTitleHeight(mobileTitleRef)
            : 0,
        // background: 'white'
      }}
    >
      <div
        ref={mobileTitleRef}
        style={{
          position: "relative",
          zIndex: 1,
          padding: "1.4rem 2rem 0.8em 2rem",
        }}
      >
        <UI_TITLE>{title}</UI_TITLE>
      </div>
    </StyledStickyTitle>
  );
};
