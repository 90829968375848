import React, { useMemo } from "react";
import { colors2, UI_CAPTION_SEMIBOLD, UI_TAG } from "oolib";
import { getProperTitleFromContentTypeId } from "../../../utils/getProperTitleFromContentTypeId";
import { TagLink } from "oolib/dist/v2/components/Tags";


export const TagList = ({ tags, title, cardWidth }) => {

    const tagWidth = useMemo(() => cardWidth ? cardWidth / 2 : 0, [cardWidth])
    
  return (
    <div>
      <UI_TAG style={{ color: colors2.grey40 }}>{title}</UI_TAG>
      <div style={{ display: "flex", flexDirection: 'column', gap: '1.2rem', marginTop: '0.8rem' }}>
      {Object.values(tags).map((d, index) => {
        if (!d?.data?.length) return null;
        
        return (
          <div key={`${d.collectionId}-${index}`}>
            <UI_CAPTION_SEMIBOLD 
              style={{ 
                paddingBottom: "0.2rem", 
                color: colors2.grey50 
              }}
            >
              {getProperTitleFromContentTypeId(d.collectionId)}
            </UI_CAPTION_SEMIBOLD>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.5rem",
              }}
            >
              {d.data.map((dd, idx) => (
                <div
                  key={`${dd.display}-${idx}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                > 
                <div className="tagwrapper" style={{ width: `calc(${tagWidth}px - 32px)`}}>
                  <TagLink
                    target={"_blank"}
                    XS
                    display={dd.display}
                    to={`/published-page/${d.collectionId}?id=${dd._id}`}
                  />
                  {/* <UI_CAPTION_SEMIBOLD style={{ color: colors2.grey80 }}>{dd.display}</UI_CAPTION_SEMIBOLD> */}
                </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
      </div>
    </div>
  );
};
