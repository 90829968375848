import React, { useMemo } from "react"
import { Container, icons, colors2 as colors, UI_BODY_SEMIBOLD_SM, DropdownSingle } from "oolib"
import { Search } from "../../../../../pageTpls/ListingPage/newComps/ActionsPanel/comps/Search"
import { Sort } from "../../../../../pageTpls/ListingPage/newComps/ActionsPanel/comps/Sort"
import { StyledSortButton } from "../../../../../pageTpls/ListingPage/newComps/ActionsPanel/styled"

const { SortDescending } = icons;

export const SearchSortPanel = ({ setKeyword, keyword, defaultSortOptions, activeSort, setActiveSort }) => {

    const activeSortValue = useMemo(() => defaultSortOptions.filter(item => item.value === activeSort), [activeSort])[0]

    return(
        <div style={{ position: 'sticky', top: 0, background: 'white', zIndex: 10 }}>
            <div style={{height: '4rem',  backgroundColor: colors.white, display: 'flex', gap: '2rem', borderBottom: `1px solid ${colors.grey10}`, padding: '0 6rem' }}>
                <div style={{flexGrow: 1}}>
                <Search
                    isMobile={false}
                    handleSearch={(k,v) => setKeyword(v)}
                    searchTermInputText={keyword}
                    placeholder="Search Annotations"
                />
                </div>
            <div style={{display: 'flex', alignItems: 'center', gap: '1rem',}}>
                <Sort
                    genCustomSelectComp={() => (
                        <StyledSortButton>
                            <SortDescending size={16} weight="bold" />
                            <UI_BODY_SEMIBOLD_SM>{`Sort by: ${activeSort?.display || activeSortValue?.display}`}</UI_BODY_SEMIBOLD_SM>
                        </StyledSortButton>
                    )}
                    saveValueAsString
                    sortConfig={defaultSortOptions}
                    activeSort={activeSort}
                    setActiveSort={setActiveSort}
                />
            </div>
            </div>
        </div>
    )
}