import { SANS_3, Tooltip } from "oolib";
import React from "react";
import { StyledEllipsisRemoveWrapper } from "../utils/StyledEllipsisRemoveWrapper";

export const TableTextInput = ({
  value,
  onChange,
  readOnly,
  tooltip,
}) => {

  return (
    <StyledEllipsisRemoveWrapper>
      {readOnly ? (
        <SANS_3>{value}</SANS_3>
      ) : (
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          {tooltip && (
            <Tooltip
              presetTarget="infoIcon"
              popOutOfOverflowHiddenParent
              position="right"
              {...tooltip}
            />
          )}
          <input
            style={{
              width: "100%",
             border: 'none',
              background: "none",
            }}
            type="text"
            className="SANS_3"
            value={value}
            onChange={(e) => onChange(null, e.target.value)}
          />
        </div>
      )}
    </StyledEllipsisRemoveWrapper>
  );
};
