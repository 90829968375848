import React, { useRef, useState } from "react";
import { useHandleClickOutside } from "oolib";
import { OptionsLightbox } from "./comps/OptionsLightbox";
import { AccordionHeader } from "./comps/AccordionHeader";
export const AccordionMultiSelect = ({
  id,
  value,
  lightboxConfig,
  title,
  onChange,
  broadcastExpandState,
  ref_asideFilter,
  ref_sideFilterHeader,
  filterConfig
}) => {

  const [expand, setExpand] = useState(false);

  const ref_optionsLightboxWrapper = useRef(null);

  useHandleClickOutside(ref_optionsLightboxWrapper, () => {
    setExpand(false);
    broadcastExpandState && broadcastExpandState(false);
  });

  const handleAccordionToggle = () => {
    const newExpandState = !expand;
    setExpand(newExpandState);
    broadcastExpandState && broadcastExpandState(newExpandState);
  };
  return (
    <>
      <AccordionHeader 
        filterConfig={filterConfig}
        title={title}
        value={value}
        expand={expand}
        onToggle={handleAccordionToggle}
        onChange={onChange}
        id={id}
      />

      {expand && (
        <OptionsLightbox
          ref_sideFilterHeader={ref_sideFilterHeader}
          ref_asideFilter={ref_asideFilter}
          lightboxConfig={lightboxConfig}
          title={title}
          setExpand={setExpand}
          value={value}
          expand={expand}
          ref_optionsLightboxWrapper={ref_optionsLightboxWrapper}
          onChange={onChange}
        />
      )}
    </>
  );
};
