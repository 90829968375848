import React from "react";
import { DesktopLayout } from "./comps/DesktopLayout";
import { getBreakPoint, useScreenWidth } from "oolib";
import { MobileLayout } from "./comps/MobileLayout";


export const CoverSection = (props) => {

  const screenWidth = useScreenWidth();
  if(!props?.title) return;
  
  return (
    screenWidth > getBreakPoint("md") 
    ? <DesktopLayout {...props}/> 
    : <MobileLayout {...props}/>
  );
};
