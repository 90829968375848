import { colors } from 'oolib';
import React, { Fragment, useEffect, useRef, useState } from "react";

import { TplMeta } from './comps';
import TplActions from '../TplActions';
import { usePlatformBuilderContext } from '../../../contexts/platformBuilderContext';




const { greyColor80, greyColor15 } = colors; 
 
const TplMetaBlock = ({
  invert = true,
  data, //deprecated in favour of the 'config' prop
  config : configProp,
  metaObj, //prob deprecated in favour of content
  content : contentProp,
  className ,
  align = 'left'
}) => {

  const config = configProp || data
  const content = JSON.parse(metaObj || null) || contentProp;

  const {platformBuilderMode} = usePlatformBuilderContext()

const TplMetaBlockRef = useRef(null)
const [childNodesLen, setChildNodesLen] = useState(2)
useEffect(() => {
  if(TplMetaBlockRef.current){ 
    setChildNodesLen(TplMetaBlockRef.current.childNodes.length)
  }
},[])

  return (
    <Fragment>
      
      { childNodesLen > 0 &&
        <div
          ref={TplMetaBlockRef}
          className={`TplMetaBlock  ${className}`}
          style={{
            justifyContent: childNodesLen > 1 ? 'space-between' : align === 'left' ? 'flex-start': 'center',
            borderTop: `1px solid ${invert ? greyColor80 : greyColor15 }`
          }}
        >
         <TplMeta {...{content, invert, config }} />
         { !platformBuilderMode &&
          <TplActions
          {...{
            content,
            invert
          }}
        />}
        </div>
      }
    </Fragment>
  )
}

export default TplMetaBlock
