import React from "react";
import { icons, colors2 } from "oolib";
import {
  StyledSearchInputDesktop,
} from "../styled";

const { MagnifyingGlass, X } = icons;

export const Search = ({
    onClose,
    isMobile,
    searchTermInputText,
    handleSearch,
    placeholder
  }) => {
    return(
      <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flex: "1 0 auto",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "100%",
          gap: "0.4rem",
          width: "100%",
        }}
      > 
        <div style={{ display: 'flex', alignItems: 'center', paddingRight: '0.5rem', marginLeft: '-2px' }}>
          <MagnifyingGlass size={isMobile ? 20 : 16} weight="bold"/>
        </div>
        <StyledSearchInputDesktop
          style={
            !isMobile
              ? { borderRight: `1px solid ${colors2.grey10}` }
              : { background: 'none' } 
          }
          placeholder={placeholder || "Search cards or content within it"}
          className="UI_BODY_SEMIBOLD_SM"
          type="text"
          value={searchTermInputText}
          onChange={(e) => handleSearch(null, e.target.value)}
        />
      </div>
      {onClose && (
        <button
          style={{ border: "none", background: "none", cursor: "pointer", padding: '0.5rem' }}
          onClick={onClose}
        >
          <X size={20} weight="bold" />
        </button>
      )}
    </div>
    )
  }
  