import { colors, icons, SANS_2 } from 'oolib';
import React from 'react';
import { _Locale } from '../../../../locale/Locale';

import { StyledWrapper, StyledButton } from './styled';

const {red} = colors;

const {WarningCircle} = icons;


const TableOfContentButton =({optionObj, invert, handleClick, isActive, hasError, style})=> {

  return (
    <StyledWrapper
      key={optionObj?.value}
      onClick={() => handleClick(optionObj)}
      {...{isActive, hasError, invert, style}}
    >
      <StyledButton hasError={hasError}>

        <SANS_2 
          semibold={isActive}
          // color={isActive && theme.colors.primaryColorText}
          invert={!hasError && invert}
          >
          { _Locale(optionObj?.display)}
        </SANS_2>

      </StyledButton>

      { hasError && <WarningCircle color={red} size={20} weight="bold"/>}
    </StyledWrapper>
  );
}

export default TableOfContentButton;