import { SANS_2, colors } from "oolib";
import React from "react";
import styled from "styled-components";
import { TableOfContentButtonsList } from "../comps/TableOfContentButtonsList";

export const StyledDesktopWrapper = styled.div`
  width: unset;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  max-width: 23rem;
  box-shadow: none;
  background-color: ${colors.white};
  z-index: 100;
`;

const StyledDesktopInnerWrapper = styled.aside`
  border: none;
  position: sticky;
  top: 10rem;
  margin-top: 4rem;
`;

export const TableOfContentDesktop = ({
  options,
  failedBlocks,
  onChange,
  value,
  invert,
}) => {
  return (
    <StyledDesktopWrapper>
      <StyledDesktopInnerWrapper>
        <SANS_2
          style={{ padding: "0.75rem 3rem", color: colors.greyColor70 }}
          invert={invert}
        >
          Index
        </SANS_2>
        <TableOfContentButtonsList
          options={options}
          handleClick={(v) => onChange(undefined, v)}
          failedBlocks={failedBlocks}
          value={value}
          invert={invert}
        />
      </StyledDesktopInnerWrapper>
    </StyledDesktopWrapper>
  );
};
