import { extractAllBlocksFromTpl } from "../../../utils/templating/extractAllBlocksFromTpl";


export const parseQuickLink = (url) => {
   
  if (!url || !url.includes('self-serve-quick-link')) {
   
    return null;
  }

  try {
   
    const urlObj = new URL(url);
   
    const pathParts = urlObj.pathname.split('/');
    const contentType = pathParts[pathParts.length - 1];
    
    const searchParams = new URLSearchParams(urlObj.search);
    
    const presetValues = [];
    searchParams.forEach((value, valuePath) => {
      presetValues.push({
        valuePath,
        value
      });
    });

    return {
      contentType,
      presetValues
    };
  } catch (error) {
    console.log('Error parsing quicklink URL:', error);
    return null;
  }
};

export const handleQuickLink = async ({
  quickLinkData,
  userData,
  queryClient,
  generateFn,
  generateTplIsLoading,
  setSurveyData
}) => {
  if (!quickLinkData) return;
  
  const { contentType, presetValues } = quickLinkData;

  const existingSurvey = userData?.user?.selfServeSurveys?.find(
    (us) => us.contentType === contentType && !!us.documentId === true
  );

  if (existingSurvey) {
    setSurveyData({
      contentType: contentType,
      documentId: existingSurvey.documentId,
      mode: existingSurvey.status === "published" ? "view" : "edit",
    });
    return;
  }

  const tpl = queryClient.getQueryData(["tpl", contentType]);
  if (!tpl) return;

  const allBlocks = extractAllBlocksFromTpl({ tpl });
  
  const processedPresetValues = presetValues.map(({ valuePath, value }) => {
    const targetFieldBlock = allBlocks.find(block => block.valuePath === valuePath);
    let processedValue;

    if (targetFieldBlock?.comp === "TagsInputSingle") {
        /**
         * this is a bit of a hack, but for TagsInputSingle, 
         * the value has to be in the format of <tagId>__<_id>
         */
      const valuePathSplit = valuePath.split(".");
      processedValue = {
        collectionId: valuePathSplit[1],
        data: [{
          _id: value.split('__')[1],
          display: value,
          tagId: value.split('__')[0],
        }],
      };
    } else if (targetFieldBlock?.comp === "RadioList") {
      processedValue = targetFieldBlock.props?.options?.find(option => option.value === value)?.value;
    } else {
      processedValue = value;
    }

    return {
      valuePath,
      value: processedValue
    };
  });

  

  if (!generateTplIsLoading) {
    generateFn(
      {
        kp_content_type: contentType,
        kp_settings: [],
      },
      {
        onSuccess: async (res) => {
          await queryClient.invalidateQueries("userData");
          setSurveyData({
            contentType: contentType,
            documentId: res.data.content._id,
            mode: "edit",
          });
        },
        updateSelfServeSurvey: true,
      },
      processedPresetValues,
    );
  }
};