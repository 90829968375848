import { propertyExists, stitchLink } from "../general";
import { convertToRichText } from "../getters/gettersV2";

export const valueNotFalsyOrEmptyAryObj = (value) => propertyExists(value);

export const valueArrayIsNotEmpty = (value) => value?.length > 0;

export const tagsSelectCompHasValue = (value) => value?.data?.length > 0;

export const groupQuestionsInputSingleHasValue = (value, props) => {
    if (!value || typeof value !== 'object') {
    return false;
  }
  const qLen = props.subQuestionLabels.length;
  
  return Object.values(value).length === qLen && Object.values(value).every(v => !!v === true)

};

export const repeaterHasValue = (value) =>
  value?.length > 0 && value.some((d) => propertyExists(d));

export const fragmentsSetHasValue = (value) => true;

export const fragmentsDictHasValue = (value) => true;

export const inputIsTruthy = (value) => !!value;

export const valueIsNotUndefined = (value) => value !== undefined;

export const linkEmbedWithInputHasValue = (value) => !!value?.url;

export const isAlwaysConsideredPopulatedAndValid = (value) => true;

export const linkHasValue = (value, props) => {
  let to = stitchLink(props.tableRowData, props.to);
  return !!to;
}

export const dateRangePickerHasValue = (value) =>
  value && value[0] && (value[1] || value[1] === null);

/**
 * logic is, excluding the pre-filled rows (via initValue prop)
 * at least 1 more row should have some text in each of its cells.
 */
export const simpleTableHasValue = (value, props) => {
  const getFilledRows = (value) =>
    value
      ? value.filter((row) =>
          row.cellData.every(
            (cell) =>
              cell.value?.blocks && cell.value?.blocks[0]?.text?.length > 0
          )
        ).length
      : 0;

  const noOfFilledRowsNeededToSayItsComplete = 1;

  const numberOfFilledRows = getFilledRows(value?.data || value); //value.data is then new shape in this comp

  return numberOfFilledRows >= noOfFilledRowsNeededToSayItsComplete;
};

export const richTextHasValue = (valueProp) => {
  let value = convertToRichText(valueProp);
  if (!value) return false;
  return !!(
    (
      value.blocks.some((block) => block.text.length > 0) ||
      (value.entityMap &&
        Object.values(value.entityMap).some(
          (entity) =>
            entity.data &&
            Object.values(entity.data).some((dataVal) =>
              propertyExists(dataVal)
            )
        ))
    ) //basically we check to see if there is any written text OR at least 1 entity has at the least 1 truthy value inside the data object
  );
};

export const lexicalTextEditorHasValue = value => {
  if(!value) return false;
  if (!value?.editorState?.root) return false;
  if (!value?.editorState?.root?.children) return false; //technically this is not needed, cuz if root exists children would always exist. but there is some other bug in auto-translate which is why we have put this temp band-aid here.
  if(value.editorState.root.children.length === 0) return false
  if(value.editorState.root.children.every(child => child.children?.length === 0)) return false
  //else
  return true;
}


/**
 * useful for components like SummaryInput that have been migrated
 * to lexical, but the data shape hasnt been migrated. Meaning at any given 
 * point of time on the platform, its possible some SummaryInput will have 
 * the draft js shape, and some others will have the lexical shape.
 * Hence this validator function
 */
export const BACKWARDS_COMPAT_lexicalTextEditorHasValue = (value) => {
  return richTextHasValue(value) || lexicalTextEditorHasValue(value)
}

//------- PLATFORM BUILDER SPECIFIC ------- //

export const displayConditionsConfigBlockHasValue = (value) => {
  if(!value?.operator) return false;
  if(!value?.valuePath) return false;
  if(!value?.value || value.value.length === 0) return false;
  //else
  return true;
}

