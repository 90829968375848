import { colors, getBreakPoint, useScreenWidth } from "oolib";
import React, { Fragment, useContext } from "react";
import { useNavigate } from 'react-router-dom';

import { queryClient } from "../../..";
import { AppSettingsContext } from "../../../contexts/appSettingsContext";
import { trackEventGA } from "../../../trackers/GA/trackEventGA";
import {
  __CheckAccessToContentType,
  __IsAuthor,
} from "../../../utils/accessControl";

import { useModal } from "../../../utils/customHooks";

import { __GetContentTypeConfigNew } from "../../../utils/getters/gettersV2";
import { useGetQueryData } from "../../../utils/react-query-hooks/general";

import KPShareActions from "../../share/KPShareActions";

import styled from "styled-components";
import { CommentsButton } from "../../../pageTpls/PublishedPage/comps/ContentEngagementButtonPanel/Comps/CommentsBtn";
import { ReactionBtn } from "../../../pageTpls/PublishedPage/comps/ContentEngagementButtonPanel/Comps/ReactionBtn";
import { ShareBtn } from "../../../pageTpls/PublishedPage/comps/ContentEngagementButtonPanel/Comps/ShareBtn";
import useShareContent from "../../../utils/react-query-hooks/ShareUrl/useShareContent";
import { DeleteModal } from "./Comps/DeleteModal";
import { TPLActionMenu } from "./Comps/TplActionMenu";
import { StyledDivider } from "../../../pageTpls/PublishedPage/comps/ContentEngagementButtonPanel/Comps/styled";
import { useDeleteContent } from "../../../pageTpls/ContributeTpl/queryHooks/useDeleteContent";
// import { useCheckIfPblDocHasBeenEditedAndResubmitted } from "../../../pageTpls/ContributeTpl/utils/useCheckIfPblDocHasBeenEditedAndResubmitted";
import { useDeleteUnderModeration } from "../../../utils/react-query-hooks/moderation";

const StyledPaddingLeftRight10 = styled.div`
padding: 0 1rem;
`;

const StyledPaddingLeft10 = styled.div`
padding-left: 1rem;
`;


const TplActions = ({
  content,
  invert,
  kebabStyle,
  popOutOfOverflowHiddenParent,
  tplActionsIcon,
  buttonSecondary
}) => {
  const { APP_SETTINGS } = useContext(AppSettingsContext);

  //react-query-hook

  const { mutate } = useDeleteContent();
  const {mutate:modDelete} = useDeleteUnderModeration()

  const [showDeleteModal, setShowDeleteModal] = useModal(false);

  const {
    meta: { kp_content_type: contentType, kp_contributed_by },
    _id: contentId,
    kp_published_status: contentPublishedStatus,
    currentEditor,
    reactions: reactionsOnContent,
    comments: _comments,
  } = content;

  const {
    deployment: {
      _AllowDeleteContributions,
      _AllowEditContributions,
      _ShowModerationFunctions,
    },
  } = useGetQueryData("platformConfigs");

  

  // const {screenWidth} = useSelector(s => s.environment)
  const screenWidth = useScreenWidth();

  const userData = useGetQueryData("userData");

  const { handleShareButtonClick, shortUrl, setShortUrl, genShareUrl } =
    useShareContent(contentId, contentType, content);

  const {  latestContent } = queryClient.getQueryData([
    "Contributions",
    "view",
    contentType,
    contentId,
    APP_SETTINGS.lang?.value, // TODOs_kishan fix it.
  ]) || {};

  const currentStatus = latestContent?.kp_published_status

  const contentTypeConfig = __GetContentTypeConfigNew(contentType);
  const { sharing, reactions, comments } = contentTypeConfig?.general;

  const navigate = useNavigate();

  const handleEditPublished = () => {
    trackEventGA(
      `Published Content`,
      `Clicked Edit On Published Content`,
      `${contentType}`
    );

    if (currentStatus === "editPublished") {
      navigate(
        `/edit/${contentType}?id=${latestContent.kp_og_published_doc}`
      );
    } else {
      //sent back || draft
      // check getContentForEdit api for the sentback case
      navigate(`/edit/${contentType}?id=${latestContent._id}`);
    }
  };

  const handleModeratePublished = (d) => {
    if (currentStatus === "underModeration") {
      navigate({
        pathname: `/moderate/${contentType}`,
        search: `?id=${latestContent.kp_pre_mod_doc}`,  // if already underMod then use the doc id from which mod clone was created
      });
    } else {
      //published or awaitingMod
      navigate({
        pathname: `/moderate/${contentType}`,
        search: `?id=${latestContent._id}`, 
      });
    }
  };

  //------ checks to show actions -------- //



      const isCurrentEditor = () =>
        typeof latestContent.currentEditor === "string"
          ? latestContent.currentEditor === userData?.user._id
          : latestContent.currentEditor?._id === userData?.user._id;
          

      const isAuthor= ()=>__IsAuthor(kp_contributed_by._id,userData?.user._id) 
     

  const canAccessAsAuthor = (ACTION) => {
    if (!kp_contributed_by) return false; // if kp_contributed_by is undefined, and userData.user is undefined, then it will end up returning true for can access as author.
    let authorId = kp_contributed_by?._id;
    let user = userData?.user;
    let userId = userData?.user?._id;

    let canAccess = __CheckAccessToContentType(user, ACTION, contentType);
    let isAuthor = __IsAuthor(authorId, userId);
    return isAuthor && canAccess;
  };

  const isCurrentModerator = () => {
    return __IsAuthor(latestContent.kp_moderator, userData?.user?._id)

  }

  

  const canUpdate = () => {
    let user = userData?.user;
    let canAccess = __CheckAccessToContentType(user, "UPDATE", contentType);
    return canAccess;
  };

  const canDelete = () => {
    let user = userData?.user;
    let canAccess = __CheckAccessToContentType(user, "DELETE", contentType);
    return canAccess;
  };

  const isTheseSegments = ({ segments }) => {
    return (
      segments.indexOf(
        __GetContentTypeConfigNew(contentType).general.segment
      ) !== -1
    );
  };







  // check whether an action is even possible 
  const canUserEdit = isTheseSegments({
    segments: ["staticPages", "collections"],
  })
    ? canUpdate()
    : canAccessAsAuthor("UPDATE");
   
  const canUserDelete = isTheseSegments({
    segments: ["staticPages", "collections"],
  })
    ? canDelete()
    : canAccessAsAuthor("DELETE");
  const canModReview = canUserEdit
    ? false
    :!isAuthor() && __CheckAccessToContentType(userData?.user, "MODERATE", contentType);
  const canModDelete = latestContent?.kp_is_pbl_taken_into_mod
    ? false
    : canModReview;


  

   




// check whether the action button should be enabled
  const ShowEditButton =
    _AllowEditContributions !== false && //global flag
    (isTheseSegments({ segments: ["staticPages", "collections"] })
      ? currentStatus === "editPublished"
        ? isCurrentEditor()
        : currentStatus === "published"
        ? canUpdate()
        : false
      : currentStatus === "published" ||
        currentStatus === "editPublished" ||
        currentStatus === "sentBack"
      ? canUserEdit
      : false);

  const ShowModButton =
    _ShowModerationFunctions !== false &&
    isTheseSegments({ segments: ["staticPages", "collections"] })
      ? false
      : currentStatus === "underModeration"
      ? isCurrentModerator()
      : currentStatus === "awaitingModeration" || currentStatus === "published"
      ? canModReview
      : false;

  const ShowDeleteButton =
    _AllowDeleteContributions !== false &&
    isTheseSegments({ segments: ["staticPages", "collections"] })
      ? currentStatus === "editPublished"
        ? isCurrentEditor()
        : currentStatus === "published"
        ? canDelete()
        : false
      : currentStatus === "published" ||
        currentStatus === "editPublished" ||
        currentStatus === "sentBack"
      ? canUserDelete
      : currentStatus === "underModeration"
      ? isCurrentModerator()
      : false;


      



  const ShowShareButton = sharing.enable; //content type config flag
  const ShowReactionButton = reactions?.like?.enable && !!userData?.user; //content type config flag
  const ShowCommentsButton = comments?.enable && !!userData?.user;

  const ShowTplActions = ShowEditButton || ShowModButton || ShowDeleteButton || ShowShareButton;


  const ShowActionMenu = kebabStyle || (!kebabStyle && screenWidth < getBreakPoint("md"))
      ? ShowTplActions
      : true;
      


      
      //meaning its not a kebabStyle && its greater than mobile width, which means only delete button will be there in the action menu
  const TplActionsMenu = (
    <TPLActionMenu
      {...{
        contentType,
        genShareUrl,
        kebabStyle,
        invert,
        popOutOfOverflowHiddenParent,
        ShowShareButton,
        ShowModButton,
        ShowEditButton,
        ShowCommentsButton,
        ShowDeleteButton,
        handleShareButtonClick,
        handleModeratePublished,
        handleEditPublished,
        setShowDeleteModal,
        currentStatus,
        canUserDelete,
        canUserEdit,
        canModDelete,
        canModReview,
        tplActionsIcon,
        buttonSecondary
      }}
    />
  );
  // const typesOfReaction = Object.fromEntries( Object.entries(reactions).filter(([key, value]) => value.enable !== undefined) );
  
  const renderAry = kebabStyle
    ? [TplActionsMenu]
    : [
        ...(ShowReactionButton ? [
          <StyledPaddingLeftRight10>
            <ReactionBtn
              {...{
                invert,
                textVariant: "short",
                popOutOfOverflowHiddenParent
              }}
            />
          </StyledPaddingLeftRight10>
        ] : []),
        ...(ShowCommentsButton && screenWidth > getBreakPoint("md")
          ? [
              <StyledPaddingLeftRight10>
                <CommentsButton {...{ _comments, invert, textVariant: "short" }} />
              </StyledPaddingLeftRight10>,
            ]
          : []),
        ...(ShowShareButton && ShareBtn && screenWidth > getBreakPoint("md")
          ? [
              <StyledPaddingLeftRight10>
                <ShareBtn
                  {...{ handleShareButtonClick, genShareUrl, invert, showText: false, sharesCount: sharing?.trackShareCount ? content?.shares?.count : undefined }} />
              </StyledPaddingLeftRight10>,
            ]
          : []),
        ...(ShowActionMenu && TPLActionMenu ? [<StyledPaddingLeft10>{TplActionsMenu}</StyledPaddingLeft10>] : []),
      ];

  if (!ShowTplActions) return null;

  return (
    <Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        {shortUrl && <KPShareActions {...{ shortUrl, setShortUrl }} />}

        {showDeleteModal && (
          <DeleteModal
            {...{
              setShowDeleteModal,
              contentType,
              contentId:
                canModDelete && ShowDeleteButton
                  ? latestContent?._id
                  : contentId,
              mutate: canModDelete && ShowDeleteButton ? modDelete : mutate,
            }}
          />
        )}

        <div className="TplMetaBlock__actions">
          {renderAry.map((d, i) => (
            <Fragment>
              {d}
              {i !== renderAry.length - 1 && (
                <StyledDivider
                  style={{ backgroundColor: colors.greyColor80 }}
                />
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default TplActions;
