import axios from "axios";
import { useBannerContext } from "oolib";
import { useMutation, useQueryClient } from "react-query";


import { useGenErrorMsgBanners } from "../useGenErrorMsgBanners";


const useCreateUpdate = () => {
  let queryClient = useQueryClient();
  const {SET_INFO_BANNER} = useBannerContext()
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  return useMutation(
    ({ body, parentContentType, parentResourceId }) =>
      axios.post(
        `/api/updates/createUpdate/${parentContentType}/${parentResourceId}`,
        body,
        {
          headers: { "Content-Type": "application/json" },
        }
      ),
    {
      onSuccess: (res, { parentContentType, parentResourceId }) => {
        SET_INFO_BANNER('Update Published Successfully!', 'green', 3000)
        queryClient.invalidateQueries([
          "Contributions",
          "view",
          parentContentType,
          parentResourceId,
        ]);
      },
      onError: (err) => {
			genErrorMsgBanners({err})
			  },

    }
  );
};

const useEditUpdate = () => {
  let queryClient = useQueryClient();
  const {SET_INFO_BANNER} = useBannerContext()
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  return useMutation(
    ({ body, parentContentType, parentResourceId }) =>
      axios.put(`/api/updates/updateUpdate/${parentContentType}/${parentResourceId}`, body, {
        headers: { "Content-Type": "application/json" },
      }),
    {
      onSuccess: (res, { parentContentType, parentResourceId }) => {
        SET_INFO_BANNER('Update Edited Successfully!', 'green', 3000)
        queryClient.invalidateQueries([
          "Contributions",
          "view",
          parentContentType,
          parentResourceId,
        ]);
      },
      onError: (err) => {
			
				genErrorMsgBanners({err})
			  },
    }
  );
};

const useDeleteUpdate = () => {
  let queryClient = useQueryClient();
  let {SET_INFO_BANNER} = useBannerContext()
  const { genErrorMsgBanners } = useGenErrorMsgBanners();
  return useMutation(
    ({ parentContentType, parentResourceId, updateId }) =>
      axios.delete(
        `/api/updates/deleteUpdate/${parentContentType}/${parentResourceId}/${updateId}`
      ),
    {
      onSuccess: (res, { parentContentType, parentResourceId }) => {
        SET_INFO_BANNER(
          'Update deleted successfully',
          'green',
          3000
        )
        queryClient.invalidateQueries([
          "Contributions",
          "view",
          parentContentType,
          parentResourceId,
        ]);
      },
      onError: (err) => {
			
				genErrorMsgBanners({err})
			  },
    }
  );
};

export { useCreateUpdate, useEditUpdate, useDeleteUpdate };