import React from 'react';
import Tags__Intro from '../../../../../generalUI/Tags__Intro';



const TableTags = ({
  value,
  valuePath,
  formatter
}) => {

    return (
        <Tags__Intro
        key={value}
            value={value}
            valuePath={valuePath}
            formatter={formatter}
            XS
            align='left'
            style={{
                justifyContent: "flex-start",
                flexWrap: "nowrap"
            }}
            />
    )    

    
}

export default TableTags;