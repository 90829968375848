import React, { useEffect, useState } from "react";
import { ModalSmall, OKELink, UI_BODY_SM } from "oolib";
import LexicalTextEditor from "../../../inputs/TextEditor/LexicalTextEditor";
import { infoData } from "./data";
import { getTenant } from "../../../../TENANT_CONFIGS";

export const ChatbotInfo = () => {
  const [infoModal, setInfoModal] = useState(false);

  const data = infoData[getTenant()];

  useEffect(() => {
    if (!localStorage.getItem("infoText")) {
      setInfoModal(true);
    }
  }, []);

  return (
    <>
      <div
        style={{
          width: "fit-content",
          margin: "auto",
          marginBottom: "1rem",
        }}
      >
        <UI_BODY_SM>
          <OKELink text={"Click here"} onClick={() => setInfoModal(true)} /> for
          details about the functioning of the Q&A Chatbot.
        </UI_BODY_SM>
      </div>

      {infoModal ? (
        <ModalSmall
          title="Please Note"
          onClose={() => {
            localStorage.setItem("infoText", true);
            setInfoModal(false);
          }}
        >
          {" "}
          <LexicalTextEditor readOnly={true} value={data} />
        </ModalSmall>
      ) : null}
    </>
  );
};
