import {
  ButtonPrimary,
  Container,
  Section,
  colors,
  useScreenWidth,
} from "oolib";
import React, { useRef } from "react";
import IndexBar from "../../../../components/generalUI/IndexBar";
import { useFormValidationContext } from "../../../../contexts/formValidationContext";

import { AnimatePresence, motion } from "framer-motion";

import { useLocation } from "react-router-dom";
import { useSearchParamsState } from "../../../../utils/customHooks";
import { ContentHeader } from "../../comps/ContentHeader";
import { FormConfigsRenderer } from "../../layouts/GroupsStyle1/comps/FormConfigsRenderer";
import { CoverConfigsRenderer } from "../GroupsStyle1/comps/CoverConfigsRenderer";
import { RawSynthToggle2 } from "./comps/RawSynthToggle2";
import { HeaderContributeTpl } from "../../comps/HeaderContributeTpl";
import { injectFragmentsDictBlocksAsPerToggleTagTypesConfig } from "./utils/injectFragmentsDictBlocksAsPerToggleTagTypesConfig";
import { TableOfContentDesktop } from "../../../../components/generalUI/TableOfContent/TableOfContentDesktop";
import { TableOfContent } from "../../../../components/generalUI/TableOfContent";

const { greyColor100 } = colors;

const Layout = ({
  tpl: tplProp,
  handleOnChange,
  content,
  readOnly,
  memo,
  useButtonForViewToggle,
  inTCI,
  indexType,

  //header comps
  H_PrimaryCTA,
  H_SaveStatus,
  H_CloseButton,
}) => {
  const { formValidation } = useFormValidationContext();

  const location = useLocation();

  // const tpl = tplProp;

  /**
   * SHOULD PROBABLY MEMOIZE THIS SHIT
   *
   * inject synth > toggle tag type sections, if toggle tag type configs exist
   * for any of the lexicaltexteditor blocks within the template.
   *
   * so for example, if even one of the lex RTEs in this entire tpl has a
   * toggleTagTypesConfig for quotes & vocabs, then, those two sections
   * should be there in synth
   */
  const tpl = injectFragmentsDictBlocksAsPerToggleTagTypesConfig(tplProp);

  const pageCoverRef = useRef(null);

  const tabBarOptions = tpl.kp_templates.synth.subSpaces.map(
    ({ display, value }) => ({
      display,
      value,
    })
  );

  const [activeSpaceAndSubSpace, setActiveSpaceAndSubSpace] =
    useSearchParamsState({
      key: "activeSpaceAndSubSpace",
      value: {
        space: "raw",
        subSpace: tabBarOptions[0],
      },
    });

  const renderers = {
    FormBuilder: FormConfigsRenderer,
  };

  const genRenderer = ({ space_subSpace }) => {
    const Renderer = renderers[space_subSpace?.builder];

    return !Renderer ? null : (
      <Renderer
        {...{
          configs: space_subSpace.configs,
          wrapperConfig: space_subSpace.wrapper,
          readOnly,
          content,
          memo,
          handleOnChange,
          tpl
          // indexList
        }}
      />
    );
  };

  const props_RawSynthToggle2 = {
    view: activeSpaceAndSubSpace.space,
    setView: (view) =>
      setActiveSpaceAndSubSpace({
        space: view,
        subSpace: activeSpaceAndSubSpace.subSpace,
      }),
  };

  const getUIElementForRawSynthToggle = () => {
    return useButtonForViewToggle || readOnly
      ? "ButtonPrimary"
      : "SwitchDouble";
  };

  /**
   * youll see a lot of inline styling with flexflow & flex: 1 0 auto
   * this is because on the synthesis side of things, we want each tab section
   * to stretch to the full height of the screen, even if they have no content,
   * so that the indexbar sticks to the bottom of the screen (on mobile)
   */
  return (
    <div style={{ display: "flex", flexFlow: "column" }}>
      {!inTCI && !readOnly && (
        <HeaderContributeTpl
          PrimaryCTA={<H_PrimaryCTA />}
          SaveStatus={<H_SaveStatus />}
          CloseButton={<H_CloseButton />}
          {...(getUIElementForRawSynthToggle() === "SwitchDouble"
            ? {
                CenterComp: (
                  <RawSynthToggle2
                    desktop_mobile="desktop"
                    {...props_RawSynthToggle2}
                  />
                ),
                AfterComp: (
                  <RawSynthToggle2
                    desktop_mobile="mobile"
                    {...props_RawSynthToggle2}
                  />
                ),
              }
            : {})}
        />
      )}
      <Section
        style={{ flex: "1 0 auto", display: "flex", flexFlow: "column" }}
      >
        {readOnly && (
          <ContentHeader content={content} pageCoverRef={pageCoverRef} />
        )}
        <div ref={pageCoverRef}>
          <CoverConfigsRenderer
            {...{
              readOnly,
              content,
              tpl,
              memo,
              handleOnChange,
              align: "left",
              coverComp: "KnowledgeResources2Intro1",
              customComp_afterTagsIntro: getUIElementForRawSynthToggle() ===
                "ButtonPrimary" && (
                <ButtonPrimary
                  icon="Eye"
                  children={
                    props_RawSynthToggle2.view === "synth"
                      ? "View Raw Notes"
                      : "View Synthesis"
                  }
                  onClick={() => {
                    props_RawSynthToggle2.setView(
                      props_RawSynthToggle2.view === "synth" ? "raw" : "synth"
                    );
                  }}
                  style={{ marginTop: "1.5rem" }}
                />
              ),
            }}
          />
        </div>
        <div
          style={{
            position: "relative",
            flex: "1 0 auto",
            display: "flex",
            flexFlow: "column",
          }}
        >
          <div
            style={{
              display:
                activeSpaceAndSubSpace.space === "raw" ? "block" : "none",
            }}
          >
            {genRenderer({ space_subSpace: tpl.kp_templates.raw })}
          </div>
          <div
            style={{
              /**
               * important to note that we must handle toggle using display : flex/none
               * otherwise the 'click on anno in synth and toggle to raw and scroll to the anno' won't work 
               */
              display:
                activeSpaceAndSubSpace.space === "synth" ? "flex" : "none",
              flexFlow: "column",
              flex: "1 0 auto",
            }}
          >
            <div style={{ flex: "1 0 auto" }}>
              {genRenderer({
                space_subSpace: tpl.kp_templates.synth.subSpaces.find(
                  (d) => d.value === activeSpaceAndSubSpace.subSpace.value
                ),
              })}
            </div>
            <TableOfContent
              options={tabBarOptions}
              failedBlocks={
                !readOnly &&
                formValidation.makeVisible &&
                formValidation.failedBlocks
                  .map((block) => block.sectionStack)
                  .flat()
              }
              value={activeSpaceAndSubSpace.subSpace}
              onChange={(k, v) =>
                setActiveSpaceAndSubSpace({
                  space: activeSpaceAndSubSpace.space,
                  subSpace: v,
                })
              }
            />
            
          </div>
        </div>
      </Section>
    </div>
  );
};

export default Layout;
