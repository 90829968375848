import { Container, mediaQuery, PaddingBottom10, PaddingTopBottom10, PaddingTopBottom30, Section, Wrapper1500 } from "oolib";
import React from "react";
import styled from "styled-components";
import TplActions from "../../../../../../../../components/generalUI/TplActions";
import { MetaPrimary } from "../../../../../../../../components/generalUI/V2_MetaComps/MetaPrimary";
import Tags__Intro from "../../../../../../../../components/generalUI/Tags__Intro";


export const StyledIntroContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  width: 100%;
  margin: auto;
  & > *:first-child {
      grid-row:2;
  } 

  ${mediaQuery('lg')}{
    width: unset;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 4rem;
    & > *:first-child {
      grid-row: unset;
  } 
  }
`

export const Cover = ({
    pageCoverRef,
    generateBlock,
    tpl,
    content,
    readOnly,
    align = 'center', //alt 'left'
    invert
}) => {

    const metaPrimaryProps = (
      tpl.kp_templates.intro.configs?.metaPrimary?.props || 
      tpl.tplMeta?.metaPrimary //backwards compat
    )

    const injectAlignmentLeftIntoTitle = block => ({
      ...block,
      props: {
        ...block.props,
        textAlignment: 'left'
      }
    })

    const injectInvertIntoBlock = (block) => ({
      ...block,
      props: {
        ...block.props,
        invert
      }
    })


    const injectAlignmentLeftIntoBlock = block => align === 'center' ? block : ({
      ...block,
      props: {
        ...block.props,
        textAlignment: 'left'
      }
    })
    
    return (
      <Section greyColor100>
      <Container>
        <Wrapper1500 left>
          <PaddingTopBottom30>
            <StyledIntroContainer>
              <div>
                { metaPrimaryProps && (
                  <PaddingBottom10>
                    <MetaPrimary 
                      {...metaPrimaryProps} 
                      content={content}
                      invert
                      align={'left'}
                      />
                  </PaddingBottom10>
                )}
                {tpl.kp_templates.intro.configs?.inputs?.title &&
                  generateBlock({
                    block: injectAlignmentLeftIntoTitle(
                      tpl.kp_templates.intro.configs.inputs.title
                    ),
                  })}
                {tpl.kp_templates.intro?.configs?.inputs?.subtitle && (
                  <PaddingBottom10>
                    {generateBlock({
                      block: injectAlignmentLeftIntoBlock(
                        injectInvertIntoBlock(tpl.kp_templates.intro.configs.inputs.subtitle)
                      ),
                    })}
                  </PaddingBottom10>
                )}
                {tpl.kp_templates.intro?.configs?.tagsIntro?.props && (
                <Tags__Intro
                    {...tpl.kp_templates.intro.configs.tagsIntro.props}
                    content={content}
                    invert={true}
                    align='left'
                    Wrapper={PaddingTopBottom10}
                  />
                )}
              </div>
              {tpl.kp_templates.intro.configs?.inputs?.coverComponent && 
                generateBlock({
                  block: tpl.kp_templates.intro.configs.inputs.coverComponent,
                })
              }
              {readOnly && (
                <div style={{ position: "absolute", top: 0, right: 0 }}>
                  <TplActions invert content={content} kebabStyle />
                </div>
              )}
            </StyledIntroContainer>
          </PaddingTopBottom30>
        </Wrapper1500>
      </Container>
    </Section>
    )
}