import {
  Section,
  Container,
  colors,
  ButtonPrimary,
  ButtonGhost,
  SANS_2,
  CheckboxList,
  Modal,
} from "oolib";
import React, { useState } from "react";

import { __GetTagTypeConfig } from "../../../../../utils/getters/gettersV2";

import {
  MobileFilterBottomHeaderStyled,
  MobileFilterListItemStyled,
  MobileFilterListStyled,
} from "./styled";
import { BadgeNumber } from "../../../../generalUI/BadgeNumber";
import { FilterInput } from "../../../../inputs/DynamicTagsInputs/FilterInput";

const { greyColor100, white } = colors;

const Badge = ({ value }) => {
  return (
    <div className="badge">
      <SANS_2 bold color={white}>
        {value}
      </SANS_2>
    </div>
  );
};

export default function MobileFilter({
  onChange,
  selectedValues,
  totalResult,
  filterConfig,
  activeFilters,
  setActiveFilters,
  presetActiveFilters,
  contentTypes,
}) {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleClearAllTags = () => setActiveFilters([]);


  const countSelectedTags = () => {
    let count = 0;
    Object.keys(selectedValues).forEach((key) => {
      count = count + selectedValues[key].length;
    });
    return count;
  };

  const count = countSelectedTags();
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {" "}
        <ButtonGhost icon="Faders" onClick={openModal}>
          Filters
        </ButtonGhost>
        {count ? (
          <div style={{ marginLeft: "-1.5rem" }}>
            {" "}
            <BadgeNumber number={count} />{" "}
          </div>
        ) : null}
      </div>

      {showModal && (
        <Modal onClose={closeModal} title={"Filters"}>
          <Container>
            <MobileFilterListStyled>
              {Object.keys(filterConfig).map((key) => {
                let { filterId } = filterConfig[key];
                return (
                  <MobileFilterListItemStyled>
                    <Section borderBottom>
                      <div style={{ padding: "1.5rem 0 2rem 0" }}>
                        <FilterInput
                          comp={"CheckboxList"}
                          key={filterId}
                          value={selectedValues[filterId]}
                          filterConfig={filterConfig[key]}
                          onChange={(_, value) => {
                            onChange({
                              id: filterId,
                              value,
                            });
                          }}
                          activeFilters={activeFilters}
                          presetActiveFilters={presetActiveFilters}
                          contentTypes={contentTypes}
                          initEnableDataFetch={true}
                        />
                      </div>
                    </Section>
                  </MobileFilterListItemStyled>
                );
              })}
            </MobileFilterListStyled>
          </Container>
          <MobileFilterBottomHeaderStyled>
            <ButtonGhost onClick={handleClearAllTags}>Clear All</ButtonGhost>
            <ButtonPrimary onClick={closeModal}>
              Show Results {`(${totalResult})`}
            </ButtonPrimary>
          </MobileFilterBottomHeaderStyled>
        </Modal>
      )}
    </>
  );
}
