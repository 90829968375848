import {
  Container,
  LABEL,
  Section,
  SwitchDouble,
  getBreakPoint,
  useScreenWidth,
} from "oolib";
import React from "react";
import styled from "styled-components";

export const RawSynthToggle2 = ({ view, setView, desktop_mobile }) => {
  const screenWidth = useScreenWidth();

  const props = { view, setView };
  return desktop_mobile === "desktop"
    ? screenWidth >= getBreakPoint("md") && (
        <RawSynthToggleDesktop2 {...props} />
      )
    : desktop_mobile === "mobile" && screenWidth < getBreakPoint("md") && (
        <RawSynthToggleMobile2 {...props} />
      );
};

const RawSynthToggleDesktop2 = ({ view, setView }) => {
  return (
    <div>
      <SwitchDouble
        value={{ value: view || "raw" }}
        options={[
          { value: "raw", display: "RAW NOTES MODE" },
          { value: "synth", display: " SYNTHESIS MODE" },
        ]}
        onChange={(id, chosenOption) => setView(chosenOption.value)}
      />
    </div>
  );
};

const StyledToggleMobileWrapper = styled.div`
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RawSynthToggleMobile2 = ({ view, setView }) => {
  return (
    <Section borderTop>
      <Container>
        <StyledToggleMobileWrapper>
          <LABEL>
            {view === "synth" ? "SYNTHESIS MODE" : "RAW NOTES MODE"}
          </LABEL>
          <SwitchDouble
            value={{ value: view || "raw" }}
            options={[
              { value: "raw", display: "" },
              { value: "synth", display: "" },
            ]}
            onChange={(id, chosenOption) => setView(chosenOption.value)}
          />
        </StyledToggleMobileWrapper>
      </Container>
    </Section>
  );
};
