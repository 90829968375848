import styled, { css } from 'styled-components'
import { colors2 } from 'oolib'


export const StyledSideBar = styled.aside`
  border-right: 1px solid ${colors2.grey10};
  /* min-height: 70svh;
  max-height: 100svh; */
  height: ${({ top }) => `calc(100svh - ${top}px)`};
  width: ${({ showSideBarLeft, sidebarWidth, isMobile }) => 
        (showSideBarLeft && !isMobile) 
      ? `${sidebarWidth}px` 
      : (showSideBarLeft && isMobile) 
      ? `min(100svw, ${sidebarWidth}px)`
      : '0px'};

  z-index: 9;
  position: sticky;
  top: ${({ top }) => `${top}px`};
  /* overflow-y: auto; */
  overflow-x: hidden; // this only exists to ensure that the white backgroud on clear all filters div (which is one of this elements grandchildren) doesnt overlap on this elemments border-right
  
  ${({ isMobile}) => isMobile && css`
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11000;
      height: 100svh;
      background-color: white;
  `}
`