import { isArray, isEqual } from "lodash";
import { testJSON, getVal, toArray, setVal, getDaysDiff } from "oolib";
import uuid from "uuid";
import { formatTitle } from "../general";
import { convertToRichText } from "../getters/gettersV2";

export const identifyIfFailedFormValidation = (block, formValidation) => {
  if (!formValidation) return {};
  if (!formValidation.makeVisible) return {};
  const { makeVisible } = formValidation;
  //else
  let thisBlockInBlacklist = formValidation.failedBlocks.find(
    (d) => d.valuePath === block.valuePath
  );
  if (thisBlockInBlacklist) {
    const { subBlocksFormValidation } = thisBlockInBlacklist;
    let toReturn = {
      errorMsgs: [{ text: "* Required Field" }],
    };
    if (!subBlocksFormValidation) {
      return toReturn;
    } else {
      return {
        ...toReturn,
        subBlocksFormValidation: isArray(subBlocksFormValidation)
          ? subBlocksFormValidation.map((d) => ({ ...d, makeVisible }))
          : { ...subBlocksFormValidation, makeVisible },
      };
    }
  }
  //else
  return {};
};

const stringifyContent = ({ block }) => {
  if (!block.initProps && block.props) {
    let stringifiedProps = JSON.stringify(block.props);
    block.initProps = JSON.parse(stringifiedProps);
  }
};

const reshapeValueFns = {
  convertToRichText: (val) => convertToRichText(val),
};

const formatContentTitle = ({ block, value }) => {
  //----- //
  // probably useless now. was used in the news template to populate the title block
  const valPath = block.valuePath;
  value = block.reshapeValueFn
    ? reshapeValueFns[block.reshapeValueFn](value)
    : value;
  //----- //

  if (
    valPath &&
    (valPath.includes("kp_title") || valPath.includes("kp_story_title"))
  ) {
    value.blocks[0].text = formatTitle(value.blocks[0].text);
  }
};

const temporaryMetaObjHack = ({ block, content }) => {
  return (
    (block.comp === "TplMetaBlock" ||
      block.comp === "KPMetaPrimary" ||
      block.comp === "PageIconBlock" ||
      block.comp === "Tags__Intro") &&
    JSON.stringify({
      ...content.meta,
      kp_date_published: content.kp_date_published || content.kp_date_created,
    })
  );
};

const isSiblingValueSameFn = (
  siblingValuePath,
  prevContent,
  currentContent
) => {
  if (!siblingValuePath) return true;
  if (!prevContent) return true; //on first mount prevcontent will always be undefined
  let prevV = getVal(prevContent, siblingValuePath);
  let newV = getVal(currentContent, siblingValuePath);

  let isEqualCheck = isEqual(prevV, newV);

  if (!isEqualCheck) {
    return false;
  } else {
    return true;
  }
};

const valueSetter = ({ _value, targetValue, fallback }) => {
  switch (true) {
    case _value === "$setToTargetValue":
      return targetValue;

    case _value === "$getDaysDiff":
      return (
        targetValue?.startDate &&
        targetValue?.endDate &&
        getDaysDiff(targetValue?.startDate, targetValue?.endDate)?.count
      );

    /**
     * object values should always be saved as stringified jsons in mongo
     */
    case _value &&
      testJSON(_value) &&
      typeof JSON.parse(_value) === "object" &&
      _value.includes("$setToTargetValue"):
      if (!targetValue && fallback !== undefined) return fallback;
      //else
console.log({targetValue})
      const toReturn = JSON.parse(
        ["string", "number", "boolean"].includes(typeof targetValue)
          ? _value.replace("$setToTargetValue", targetValue)
          : _value.replace(
              '"$setToTargetValue"', //notice the slight difference in the double inverted. this cuz JSON.parse and stringify is wierd. this has been set after testing..
              JSON.stringify(targetValue)
            )
      );
      console.log({toReturn})
      return toReturn;

    default:
      return _value;
  }
};

/**
 * @usecase1 if the value at some other path ('main.fruit') is equal to 'apple' then:
 * 1// set a prop -> 'multiple = true'
 * 2// switch the comp to VideoInput 
 * {
 *  propModifiers: [
 *    {
 *      targetValuePath: 'main.fruit',
 *      expectedValue: 'apple',
 *      propSideEffects: [
 *        {
 *          valuePath: 'multiple',
 *          value: true
 *        },
 *        {
 *          valuePath: '$COMP',
 *          value: 'VideoInput'
 *        }
 *      ]
 *    }
 *  ]
 * }
 */

const targetValuePathFnsLookup = {
  convertTagsDataAryIntoMongoFindQuery : value => {
    if(!Array.isArray(value)) return value;
    if(value.length === 0) return value;
    //else
    return {
      $in: value.map(d => d._id)
    }
  }
}

const runPropModifier = ({
  content,
  parentContent,
  props,
  targetValuePath,
  targetValuePathFn, // philosophy behind this is the same as setValuePathFn
  expectedValue,
  propSideEffects,
  block, //only needed for the hacky scenario where one might wanna replace the comp via a prop modifier
}) => {
  if (!targetValuePath || !propSideEffects) return; //only so tci doesnt break.

  let targetValue = targetValuePath.includes("$PARENT_CONTENT")
    ? getVal(parentContent, targetValuePath.replace("$PARENT_CONTENT.", ""))
    : getVal(content, targetValuePath);

  if(targetValuePathFn){
    targetValue = targetValuePathFnsLookup[targetValuePathFn]
      ? targetValuePathFnsLookup[targetValuePathFn](targetValue)
      : targetValue
  }
  /**
   * in some cases we dont have an expected value condition
   * e.g. settings tagManager tagType depending on the value of another field
   */
  if (expectedValue && !toArray(targetValue).includes(expectedValue)) return;

  /**
   * since condition is met, lets write the sideeffect
   * props to the props object.
   */
  propSideEffects.map(({ valuePath, value: _value, fallback }) => {
    let value = valueSetter({ _value, targetValue, fallback });
    if (valuePath === "$COMP") {
      setVal(block, "comp", value);
    } else {
      setVal(props, valuePath, value);
    }
  });
};

export const populateDefaultValue = ({ content, onChange, block, props }) => {
  if (!getVal(content, block.valuePath)) {
    //if fn property exists then fn must be run
    const fns = {
      setUuid: () => uuid.v4(),
    };
    //else defaultValue is set to exactly what is in the config
    let defVal = props.defaultValue;
    if (props.defaultValue.fn) {
      const { fn, args } = props.defaultValue;
      defVal = fns[fn](...(args || []));
    }
    onChange(block, defVal);
  }
};

export {
  stringifyContent,
  formatContentTitle,
  temporaryMetaObjHack,
  isSiblingValueSameFn,
  runPropModifier,
  // modeViewConditionals,
  // shouldContentBlockRenderSwitch,
};
