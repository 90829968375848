import { motion } from "framer-motion";
import styled from "styled-components";
import { ButtonPrimary, mediaQuery, colors2 as colors } from "oolib"; 
import { createHoverEffect } from "../../../../utils/createHoverEffect";

const { grey10, grey5, secondaryContainer, onSecondary } = colors

export const StyledCoverSection = styled(motion.header)`
  /* position: ${({ isMobile }) => !isMobile && 'sticky'};
  top: 0; */
  background-color: white;
  z-index: 1000;
  position: relative;
  
  width: 100%;
  height: max-content;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid ${grey10};
  /* grid-template-columns: 1fr; */
  /* column-gap: 2rem; */
  /* row-gap: 2rem; */
  /* width: fit-content; */
  /* & > *:first-child {
    grid-row:2;
  }  */
  
`;

export const StyledLeftSection = styled.div`
  display: grid; 
  grid-template-columns: repeat(8, 1fr);
  gap: 1rem;
  height: auto;
  width: 100%;

  position: relative;

  #coverCTA {

  }

  & > div:nth-child(1) {
    grid-column: span 5; /* First child takes 5 blocks */
  }

  & > div:nth-child(2) {
    grid-column: span 3; /* Second child takes 3 blocks */
    grid-row: span 2; /* Default to taking 2 rows */
  }

  & > div:nth-child(3) {
    grid-column: span 5; /* Third child (if exists) takes 5 blocks on the next line */
  }

  & > div:nth-child(1):only-child {
    grid-column: span 8; /* If the first div is the only child, it spans 8 blocks */
  }

  & > div:nth-child(2):nth-last-child(1) {
    grid-row: span 1; /* If there are only two children, the second child takes 1 row */
  }

  /* When there are two children and the second one is #coverCTA */
  &:has(> div:nth-child(2):nth-last-child(1)#coverCTA) {
    & > div:nth-child(1) {
      grid-column: span 6; /* First child takes 6 blocks */
    }

    & > #coverCTA {
      grid-column: span 2; /* Second child (#coverCTA) takes 2 blocks */
      grid-row: span 1; /* It takes 1 row */
    }
  }
`;


export const StyledMetaSection = styled.div`
  display: flex; 
  gap: 1rem; 
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  background-color: ${grey5};
  border-radius: 8px;
  padding: 14px;

  flex-grow: 1;
  min-height: 150px;
  position: relative;

`

export const StyledDescSection = styled.div`
  display: flex; 
  gap: 0.4rem; 
  flex-direction: column;
  height: auto;
  background-color: #0000000D;  // todo grey5
  border-radius: 8px;
  padding: 14px;
  /* width: 40%; */

  flex-grow: 1;

  position: relative;
  
`;

export const StyledButtonPrimary = styled(ButtonPrimary)`
  width: fit-content;
  /* align-self: center; */
  justify-self: start;
  margin: 0 2rem;
  ${mediaQuery("md")} {
    align-self: baseline; 
    justify-self: end;
  }
  width: 100%;
`;

export const StyledTPLActionsWrapper = styled.div`
   position: absolute; 
   top: 1.4rem; 
   right: 1.4rem; 
   z-index: 3000; 

`

export const StyledMediaSection = styled.div`
  width: 40%;
  border-radius: 8px;
  overflow: hidden;
  height: auto;
  #StyledAspectRatioWrapper{
    border-radius: 8px; // border radius image comp shell
  }
`
export const StyledMediaSectionPlaceholder = styled.div`
  width: 40%;
  border-radius: 8px;
  overflow: hidden;

  #StyledAspectRatioWrapper{
    border-radius: 8px; // border radius image comp shell
  }
`

export const StyledCTA = styled.button`
  display: flex; 
  gap: 1rem;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  padding: 14px;
  position: relative;
  cursor: pointer;  
  outline: none;
  border: none;
  
  display: flex;
  align-items: ${({ desc, coverExists }) => desc && coverExists ? "center" : desc ? "center" : "" }; 

  background: ${({ theme : { colors }}) => colors.secondaryContainer ? colors.secondaryContainer : secondaryContainer };
  color: ${({ theme : { colors }}) => colors.onSecondary || onSecondary };
  /* background-position: 0% 50%;
  background-size: 100% 100%;
  background-image: radial-gradient(circle at 2.29% 50%, #CAE9F0 0%, #F2F2F2 100%);
  transition: background-size 0.3s ease;

  &:hover {
    background-size: 250% 250%;
  } */

  // Add the hover effect
  ${createHoverEffect("8px")}
`;

export const StyledImage = styled.div`
   ${({ src }) => src && `background-image: url(${src});`}
   width: 100%;
   height: 100%;
   background-size: cover;
   background-position: center;
`;