import { getBreakPoint, useScreenWidth } from "oolib";
import React from "react";
import { TableOfContentDesktop } from "./TableOfContentDesktop";
import { TableOfContentMobile } from "./TableOfContentMobile";
import { useTableOfContentAnchorLinks } from "./utils/useTableOfContentAnchorLinks";


export const TableOfContent = ({
  onChange: _onChange,
  value,
  invert,
  failedBlocks,
  options,
  type = "pageLinks", //alt : anchorLinks
  indexType,
}) => {
  const screenWidth = useScreenWidth();
  const { handleClick } = useTableOfContentAnchorLinks({
    onChange: _onChange,
    indexType,
    options,
    enabled: type === "anchorLinks",
  });
  const onChange = type === "anchorLinks" ? handleClick : _onChange;

  return screenWidth >= getBreakPoint("xl") ? ( // temp fix for overlapping issue, xl is not ideal for TableOfContentMobile
    <TableOfContentDesktop
      {...{
        onChange,
        value,
        invert,
        failedBlocks,
        options,
      }}
    />
  ) : (
    <TableOfContentMobile
      {...{
        onChange,
        value,
        invert,
        failedBlocks,
        options,
      }}
    />
  );
};
