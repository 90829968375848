import React from "react";
import { tplBlocks } from "../../importGroups/tplBlocks";
import { isEqual } from 'lodash'
import {blockIsPopulatedConditions} from '../validation/blockIsPopulatedConditions'
import handleBlockDisplayConditions from '../handleBlockDisplayConditions'
import {useGetQueryData} from '../../utils/react-query-hooks/general'

import {
	temporaryMetaObjHack,
	formatContentTitle,
	isSiblingValueSameFn,
	identifyIfFailedFormValidation,
	runPropModifier,
	populateDefaultValue
} from './functions'
import { usePrevious } from "../customHooks";
import styled from 'styled-components'
import {
  colors,
  SANS_3,
  SANS_2,
  PaddingTopBottom15,
  PaddingBottom20,
  PaddingBottom5,
  PaddingTopBottom10,
  PaddingTop5,
  PaddingTop20,
  getVal,
  deleteVal
} from "oolib";
import ErrorBoundary from "../../components/ErrorBoundary";



const StyledConditionalWrapper = styled.div`
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	background-color: ${colors.greyColor5};
`

function ContentBlock({
	props,
	value,
	metaObj,
	block,
	Wrapper : _Wrapper,
	Component,
	readOnly,
	key,
	isConditionalClassName,
	errorMsgs,
	subBlocksFormValidation,
	content,
	onChange,
	blockProps,
	memo,
	setFormValidation,
}) {

	const Wrappers = {
		div: 'div',
		PaddingTopBottom15,
		PaddingBottom20,
		PaddingTop20,
		PaddingBottom5,
		PaddingTop5,
		PaddingTopBottom10
	}
	
	// Wrapper can be passed as a Wrapper component or a string ( that should map to a component in the Wrappers object above )
	const Wrapper = typeof _Wrapper === 'string' ? Wrappers[_Wrapper] : _Wrapper
	
	let comp = (
		<div className='blockIndexTracker'> {/** i think this is redundant now. check carefully then remove */}
			<Wrapper
				id={`TplBlock_${block.props.id}`}
				className={`Tpl__block Tpl__block--${block.props.className}`}
			>
				<Component
					readOnly={readOnly}
					value={value} //value before props cuz sometimes we overwrite the value by updating blockprops using propModifiers
					{...(props || block.props)}
					className=''
					metaObj={readOnly && metaObj}
					content={content}
					errorMsgs={errorMsgs} /** wont exist if in ViewBlockGenerator */
					subBlocksFormValidation={subBlocksFormValidation} /** wont exist if in ViewBlockGenerator */
					onChange={(key, val, options ) => {
						!readOnly && onChange(block, val, options)
					}}
					valuePath ={block.valuePath}
					pass
					memo={memo}
					passValidationErrorToFormValidation={(status) => {
						console.log('pass validation fired', status);
						if(status === 'error'){
							setFormValidation &&
							setFormValidation(prev => ({
								...prev,
								invalidInputBlocks: [
									...((prev.invalidInputBlocks || []).filter(b => b.valuePath !== block.valuePath)),
									{ valuePath: block.valuePath }
								]
							}))
						}else{ //success
							setFormValidation &&
							setFormValidation(prev => ({
								...prev,
								invalidInputBlocks: prev.invalidInputBlocks.filter(b => b.valuePath !== block.valuePath)
							}))
						}
						
					}}
					{...blockProps}
				/>
				
			</Wrapper>
		</div>
	)

	return (
		<ErrorBoundary key={block.props.id}>
			{isConditionalClassName
			? <StyledConditionalWrapper>{comp}</StyledConditionalWrapper>
			: comp }
		</ErrorBoundary>
		
		
	)
}

const checkContentBlockMemo = (prevProps, currentProps) => {
	const {
		value: prevVal,
		content: prevContent,
		block: prevBlock,
		errorMsgs: prevErrorMsgs,
		displayConditionsResult: prevDisplayConditionsResult
	} = prevProps
	const {
		value: currentVal,
		content: currentContent,
		block: currentBlock,
		errorMsgs: currentErrorMsgs,		
		displayConditionsResult: currentDisplayConditionsResult
	} = currentProps

	const isValueSame = isEqual(prevVal, currentVal)

	

	const isSiblingValueSame = isSiblingValueSameFn(
		currentBlock.props.siblingValuePath,
		prevContent,
		currentContent
	) /** a sibling input wholes value affects THIS. hence a sibling value change should rerender THIS */
	

	//prop modifier dependent value change detection
	let prevDependentValues, currentDependentValues;
	if(currentBlock.propModifiers){
		prevDependentValues = prevBlock.propModifiers.map(d => getVal( prevContent, d.targetValuePath, d.expectedValue))
		currentDependentValues = currentBlock.propModifiers.map(d => getVal( currentContent, d.targetValuePath, d.expectedValue))
		
	}
	
	// get prev dependent value by getting value against valpath from prev content
	// get this dependent value by getting value against valpath from this content
	// isPropModUnchanged = prevDependentValue === currentDependentValue
	const isPropModUnchanged = !prevDependentValues || prevDependentValues.every((prevValue,i) => currentDependentValues[i] === prevValue )
	const isShouldDisplaySame = prevDisplayConditionsResult === currentDisplayConditionsResult
	const isErrorMsgsSame = prevErrorMsgs === currentErrorMsgs
	let toReturn =  isValueSame && isShouldDisplaySame && isErrorMsgsSame && isSiblingValueSame && isPropModUnchanged ;
	return toReturn
}

const MemomizedContentBlock = React.memo(ContentBlock, checkContentBlockMemo)

function ViewBlockGenerator({
	block,
	Wrapper = `div`,
	content,
	blockProps,
	parentContent,
	
}) {
	/**
   * STEPS:
   
   * 1.  Block Ref create
   * 2.  Get Value
   * 3.  Get Component
   * 4.  Format content title casing
   * 5.  Temporary Meta Object hack. ( will go soon )
   * 6.  Get customDisplayConditions result ( if they exist )
   * 7.  Final Verdict on should content block render
   * 		- is NOT inputOnly block
   * 		- block is populated with valid content
   * 		- displayConditions result is TRUE
   */

	let {deployment : {_EnableAIAnnotation} } = useGetQueryData('platformConfigs')
	
	let props = { 
		...block.props, 
		// enableAIAnnotation: _EnableAIAnnotation ? true : false //this way we ensure that even if via tpl config a enableAIAnno prop is passed, that is disregarded and only deployment config has the power to control this prop
	}

	let value = block.valuePath && getVal( content, block.valuePath)


	if (block.propModifiers) {
		block.propModifiers.forEach(({targetValuePath, targetValuePathFn, expectedValue, propSideEffects}) => {
			runPropModifier({
				content,
				parentContent,
				props,
				targetValuePath,
				targetValuePathFn,
				expectedValue,
				propSideEffects,
				block
			})
		})
		// console.log({modifiedProps: props})
		}

	const Component = tplBlocks[block.comp]

	

	// formats the property 'kp_title' / 'kp_story_title' if it exists
	formatContentTitle({ block, value })

	// need to get rid of this completely.
	const metaObj = temporaryMetaObjHack({ block, content })


	/**
	 * we limit this just to InfoText for now, to deal with the chatbot usecase
	 * where we show 'is ai generated' conditionally. The limitation to infotext is 
	 * becuase we arent sure what sideffects it may have if we apply it to every block
	 */
	const displayConditionsResult = (block.displayConditions && block.comp === 'InfoText')
		? handleBlockDisplayConditions({content, block, parentContent})
		: true

	const shouldContentBlockRender =
		!block.inputOnly &&
		blockIsPopulatedConditions(block, value) &&
		displayConditionsResult
	
	
	
	const contentBlockProps = {
		props,
		block,
		value,
		Wrapper: block.Wrapper || Wrapper,
		Component,
		metaObj,
		readOnly: true,
		content,
		blockProps,
	}
	/**
	 * v. imp that the shouldContentBlockRender check is done here itself,
	 * rather than in the <ContentBlock/>
	 * else, the renderedCounter in the mapper function will not work.
	 */
	return (
		shouldContentBlockRender && <MemomizedContentBlock {...contentBlockProps} />
	)
}

function EditBlockGenerator({
	block,
	Wrapper = 'div',
	formValidation,
	setFormValidation,
	content,
	onChange,
	
	parentContent,

	/**
	 * in tci we dont want to memoize the contentblock
	 * so that we can an instant rerender.
	 * this flag is for that
	 */
	memo
}) {

	

	/**
	 * STEPS:
	 * 1.  Inject isRequired into 'props'
	 * 2.  Block Ref create
	 * 3.  Get Value
	 * 4.  Get Component
	 * 6.  Detect change in sibling value, and if so, reset THIS value ( community - paralegal dropdowns usecase in nct)
	 * 5.  Get displayConditions result
	 * 6.  Set isConditionalClassName to handle UI Styling of conditionals
	 * 7.  Final Verdict on should content block render
	 * 		- is NOT displayOnly block
	 * 		- displayConditions result is TRUE
	
	 */

	//richinput needs this. we dont want to do this calc inside rich input cuz then we wont be able to move
	//it to oolib ( cuz of react-query dependency of __GetContentTypeConfigNew )
	let {deployment : { _EnableAIAnnotation} } = useGetQueryData('platformConfigs')
	
	let prevContent = usePrevious(content)
	/**
	 * relevant in the case of repeaters,
	 * where sometimes, you wanna set display conditions to a repeater block,
	 * wrt. the 'parent content' within which this repeater's content resides 
	 */
	let prevParentContent = usePrevious(parentContent)
	
	

	if(!block.props){
		return <div style={{padding: '2rem', border: `1px solid ${colors.red}`, backgroundColor: colors.lightRed }}>
			<SANS_3 semibold>{`Block Name: ${block.comp}`}</SANS_3>
			<SANS_2>{'Some of the required props are missing'}</SANS_2>
		</div>
	}
	
	let props = { 
		...block.props, 
		isRequired: block.isRequired, 
		enableAIAnnotation: _EnableAIAnnotation? block.props.enableAIAnnotation:false //this way we ensure that even if via tpl config a enableAIAnno prop is passed, that is disregarded and only deployment config has the power to control this prop 
	}
	let value = (block.valuePath || block.valuePath === null) && // null scenario is in tci display conditions, where the content at a given index itself is the value
		getVal( content, block.valuePath)


		// if(props.id==="main.raw2") return null;

	


	
	
	if(!isSiblingValueSameFn(props.siblingValuePath, prevContent, content)){
		value = undefined;
		deleteVal(content, block.valuePath)
	}

	const displayConditionsResult = block.displayConditions
		? handleBlockDisplayConditions({content, block, parentContent})
		: true

	const prevDisplayConditionsResult = block.displayConditions
		? handleBlockDisplayConditions({content: prevContent, block, parentContent: prevParentContent})
		: true

	//PENDING
	//if block has been hidden in this render iteration
	//and if a value exists against it
	//then delete the value that exists against it
	if(
		displayConditionsResult === false &&
		prevDisplayConditionsResult !== displayConditionsResult &&
		block.valuePath //this might be valueable in the future, cuz technically StaticRichText doesnt need a valuepath..
	){
		const hasValue = !!getVal( content, block.valuePath)
		if(hasValue) {
			deleteVal(content, block.valuePath)
		}
	}

	//used to set a default value against a path, 
	//if no val already exists against it.
	if(props.defaultValue){
		populateDefaultValue({
			content, 
			onChange, 
			block, 
			props
		})
		
	}
	
	// this literally changes the data in the props object passed into it.
	if (block.propModifiers) {
		block.propModifiers.forEach(({targetValuePath, targetValuePathFn, expectedValue, propSideEffects}) => {
			runPropModifier({
				content,
				parentContent,
				props,
				targetValuePath,
				targetValuePathFn,
				expectedValue,
				propSideEffects,
				block
			})
		})
		// console.log({modifiedProps: props})
		}

		const Component = tplBlocks[block.comp];
 
	if(!Component){
		//We dont want to throw an eror
		//but instead show a message saying this block doesnt exist.

		return <div style={{padding: '2rem', border: `1px solid ${colors.red}`, backgroundColor: colors.lightRed }}>
			<SANS_3 semibold>{`Block Name: ${block.comp}`}</SANS_3>
			<SANS_2>{'This block is currently incompatible with the TCI. Contact dev to get this fixed.'}</SANS_2>
		</div>
	}

	/**
	 * this classname renders the block with a grey background
	 * we want this for conditional questions.
	 * we DONT want this styling for the events conditionalities
	 *
	 * hence the below condition is required
	 */
	const isConditionalClassName =
		block.displayConditions &&
		!block.dontStyleAsConditional
		? '-conditional-'
		: ''

	

	const {errorMsgs, subBlocksFormValidation} = identifyIfFailedFormValidation(block, formValidation )
 
	const shouldContentBlockRender = !block.displayOnly && displayConditionsResult

	const contentBlockProps = {
		props,
		content,
		block,
		value,
		Wrapper: block.Wrapper || Wrapper,
		Component,
		readOnly: false,
		isConditionalClassName,
		errorMsgs,
		subBlocksFormValidation, //for nested renderer comps like fragmentDisplayComps && repeaters
		onChange,
		memo, // we pass it through, only so that we can get the repeater comp to also follow this memo command if it exists.
		displayConditionsResult,
		setFormValidation
	}
	/**
	 * v. imp that the shouldContentBlockRender check is done here itself,
	 * rather than in the <ContentBlock/>
	 * else, the renderedCounter in the mapper function will not work.
	 */

	const _memo = props.memo!==undefined?props.memo:memo
	return (
		shouldContentBlockRender && 
		( _memo===false
		? <ContentBlock {...contentBlockProps} />
		: <MemomizedContentBlock {...contentBlockProps} />
		)
	)
}

export { EditBlockGenerator, ViewBlockGenerator };
