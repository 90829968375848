import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useGetQueryState } from '../../../utils/react-query-hooks/general';
import Loader from 'oolib';

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { status } = useGetQueryState('userData');

  if (status === 'loading') {
    return <Loader debug={'private route'} />;
  }

  if (status === 'error') {
    return (
      <Navigate
        to="/login"
        state={{
          from: location.pathname + location.search,
          authType: 'login',
        }}
        replace
      />
    );
  }

  if (status === 'success') {
    return children;
  }

  return null;
};



export { PrivateRoute };