import { Loader } from 'oolib'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useGetQueryState } from '../../../utils/react-query-hooks/general'

const RouteSuperAdmin = ({ children }) => {
  const location = useLocation()
  const { status, data: userData } = useGetQueryState('userData')

  const hasAccess = () => {
    if (!userData?.user) {
      // not logged in
      return {
        has: false,
        redirectTo: {
          pathname: '/login',
          state: {
            from: location.pathname + location.search,
          },
        },
      }
    }

    // else is logged in, is user suerAdmin?
    // if is, then let them through.
    // if not then simply redir back to the page they were coming from.
    return {
      has: ['superAdmin', 'god'].indexOf(userData.user.role) !== -1,
      redirectTo: '/',
    }
  }

  if (status === 'loading') {
    return <Loader debug={'route super admin access'} />
  }

  if (!hasAccess().has) {
    return <Navigate to={hasAccess().redirectTo} replace />
  }

  return children
}

export default RouteSuperAdmin
