import { customAlphabet } from "nanoid";
import { useGetQueryData } from "../../../utils/react-query-hooks/general";
import { isDeviceOnline } from "../../../utils/environmentConfig";
import { useMutation } from "react-query";
import axios from "axios";
import { useGenErrorMsgBanners } from "../../../utils/useGenErrorMsgBanners";

const genOfflineContent = ({content, userData}) => ({
    ...(content || {}),
    _id: '00'+customAlphabet('1234567890', 22)(),
    kp_published_status: 'draft',
    main: {
        title: `offline_draft_${customAlphabet('1234567890', 4)()}`
    },
    meta : {
      ...(content?.meta || {}),
      kp_contributed_by : {_id : userData.user._id }
    }
})

export const useCreateContent = () => {
    const userData = useGetQueryData('userData');
    const { genErrorMsgBanners } = useGenErrorMsgBanners();
    return useMutation(
        ({ contentType, content: _content = {}, updateSelfServeSurvey }) => {
            const content = !isDeviceOnline()
                ? genOfflineContent({content: _content, userData})
                : _content
        
            return axios.post(
                `/api/content/createContent/${contentType}${updateSelfServeSurvey ? '?updateSelfServeSurvey=true' : ""}`,
                content,
                { headers: { 'Content-Type': 'application/json' } }
                )
        
        },
        {
            onError: (err) => {
                genErrorMsgBanners({ err })
            }
        }
    )
}
  