import React from "react";
import {  DropdownSingle } from "oolib";

export const Sort = ({
    genCustomSelectComp,
    sortConfig,
    activeSort,
    setActiveSort,
    saveValueAsString
  }) => (
    <DropdownSingle
      options={sortConfig}
      value={activeSort}
      onChange={(id, value) => setActiveSort(value)}
      className="sortDropdown"
      popOutOfOverflowHiddenParent={true}
      alignDropdown={"right"}
      genCustomSelectComp={genCustomSelectComp}
      saveValueAsString={saveValueAsString}
    />
);