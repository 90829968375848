import {
  ButtonSecondary,
  SERIF_5_6,
  Wrapper820,
  Container,
  getBreakPoint,
  useScreenWidth,
} from "oolib";
import React from "react";



const ModuleContainer = ({ children, title, action }) => {
  const screenWidth = useScreenWidth();

  return (
    <Wrapper820>
      <div style={{ padding: "2rem 0" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            paddingBottom: "1rem",
            paddingLeft:
              screenWidth >= getBreakPoint("sm") &&
              screenWidth < getBreakPoint("md")
                ? "6rem"
                : screenWidth >= 820
                ? 0
                : "2rem",
          }}
        >
          {title && <SERIF_5_6>{title}</SERIF_5_6>}
          {action && (
            <ButtonSecondary
              S
              children={action.text}
              onClick={action.onClick}
            />
          )}
        </div>

        {children && (
          <Container
            style={{
              background: "white",
              paddingTop: "2rem",
              paddingBottom: "2rem",
            }}
          >
            {children}
          </Container>
        )}
      </div>
    </Wrapper820>
  );
};

export default ModuleContainer;
