import React from "react";
import { SidebarLayoutV2 } from "../../layout/SidebarLayoutV2";

import {
  colors,
  Container,
  DataVizAccordion,
  DropdownSingle,
  formatCamelCaseToSentenceCase,
  getVal,
  makeArrayFromLength,
  MetaBlock,
  PaddingTopBottom20,
  PaddingTopBottom40,
  SANS_2,
  SANS_4_5,
  SERIF_4_5,
  SkeletonLoader,
  transition,
  Wrapper700,
} from "oolib";
import styled from "styled-components";
import { __GetAllContentTypeConfig } from "../../../utils/getters/gettersV2";
import { useDynamicLayoutHeight } from "../InsightMatrix/utils/useDynamicLayoutHeight";
import ErrorStates from "../../ErrorStates";
import { tplBlocks } from "../../../importGroups/tplBlocks";
import { blockIsPopulatedConditions } from "../../../utils/validation/blockIsPopulatedConditions";

import { useElemInView } from "../../../utils/customHooks/useElemInView";

const StyledButton = styled.button`
  background-color: ${({active}) => active ? colors.primaryColor10 : colors.white};
  border: 0;
  border-bottom: 1px solid ${colors.greyColor5};
  padding: 1rem 2rem;
  text-align: left;
  &:hover {
    background-color: ${colors.primaryColor10}
  }
  ${transition('background-color')}
  display: block;
  width: 100%;
  cursor: pointer;
`;

export const CompRendererDataPivot = ({
  pivotableBlocks,
  setActiveBlock,
  activeBlock,
  data,
  dataFetchStatus,
  dataFetchError,
  dataVizAccordion,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage
}) => {
  const { parentWrapperRef, gridHeightCSS } = useDynamicLayoutHeight();
  

  const BlockComp = activeBlock && tplBlocks[activeBlock.comp];
  const blockProps = {
    readOnly: true,
    ...(activeBlock?.props || {}),
    label: undefined, //removing label at the block level cuz we are showing it as the page header
    sublabel: undefined,
  };

  const getAppropriateBlockLabel = bl => bl.props?.shortLabel || bl.props?.label || formatCamelCaseToSentenceCase(bl.valuePath.replace('main.', ''))
  const { observerRef } = useElemInView({ callback: fetchNextPage });
  const renderBlocks = () => {
    
    const flattenedData =
      data?.pages.reduce((a, b) => [...a, ...b.data], []) || [];

    return (
      <div>
        <div style={{padding: '1rem 0', borderBottom: `1px solid ${colors.greyColor10}` }}>
        <Container>
          <SERIF_4_5>
            {getAppropriateBlockLabel(activeBlock)}
          </SERIF_4_5>
          </Container>
        </div>
        {
          dataVizAccordion && 
          <Wrapper700>
            <PaddingTopBottom20>
            <DataVizAccordion
              {...dataVizAccordion}
              />
              </PaddingTopBottom20>
          </Wrapper700>
        
          }
      
      <Container style={{backgroundColor: colors.greyColor3}}>
        <PaddingTopBottom40>
        <Wrapper700 style={{display: 'flex', flexDirection: 'column', gap: '2rem'}}>
        {flattenedData.map((d, index) => {
          const value = getVal(d, activeBlock.valuePath);
          const shouldDisplayBlock = blockIsPopulatedConditions(
            activeBlock,
            value
          );
          
          if (!shouldDisplayBlock){
            if(flattenedData.length === index + 1){
              return <div ref={(hasNextPage && flattenedData.length === index + 1) ? observerRef : null}/> 
            }else{
              return null
            }
          } 
          //else
          return (
            <div key={d._id} style={{padding: '2rem', paddingBottom: '1rem', backgroundColor: colors.white, borderRadius: '0.5rem'}} ref={hasNextPage && flattenedData.length === index + 1 ? observerRef : null}>
              <BlockComp {...blockProps} value={value} />
              <div style={{paddingTop: '0.5rem', borderTop: `1px solid ${colors.greyColor5}`}}>
              <MetaBlock
                data={d}
                config={[
                  {key: 'name', valuePath: 'meta.kp_contributed_by.name'},
                  {key: 'date', valuePath: 'kp_date_published'}
                ]}
                />
                </div>
            </div>
          );
        })}
        </Wrapper700>
        </PaddingTopBottom40>
      </Container>
      </div>
    );
  };
  

  return (
    <div ref={parentWrapperRef}>
      <div style={{display:'grid', gridTemplateColumns: '280px 1fr'}}>
      <div style={{height: '100%'}}>
            <div style={{height: '100vh', borderRight: `1px solid ${colors.greyColor10}`, overflow:'auto', position: 'sticky', top: 0, padding: '0.5rem 0'}}>
              {pivotableBlocks?.map((bl) => (
                <StyledButton active={bl.valuePath === activeBlock?.valuePath} onClick={() => setActiveBlock(bl)}>
                  <SANS_2>{getAppropriateBlockLabel(bl)}</SANS_2>
                  
                </StyledButton>
              ))}
            </div>
          </div>
          {dataFetchStatus === "loading" ? (
          makeArrayFromLength(6).map(() => (
            <SkeletonLoader style={{ height: "13rem", margin: "1rem 0" }} />
          ))
        ) : dataFetchStatus === "error" ? (
          <ErrorStates
            errorResponseObject={dataFetchError}
            enableLinkToHomepage={false}
          />
        ) : (
          dataFetchStatus === "success" && renderBlocks()
          
          
        )}
      </div>
      
    </div>
  );
};
