export const configs_nkms_annoBase = {
  contentTypes: {
    // whitelist: ["immersion1AFGDNigeria", "immersion1ATemplates", "immersion1BIDINigeria", "immersion1BFGDNigeria"]
  },
  filterConfigs: {
    whitelist: [
      "ROLLUP_docTags_states_country",
      {
        filterId: "docTags_states",
        filterOptionsBy: { filterId: "ROLLUP_docTags_states_country" }
      },
      {
        filterId: "docTags_districts",
        filterOptionsBy: { filterId: "docTags_states" }
      },
      "docTags_stakeholder",
      "docTags_subject",
      "docTags_facilityTypes",
      "docTags_conditionAreas",
      
      "annoTags_type",
      "annoTags_lens",
      "annoTags_stakeholder",
      "annoTags_subject",
    ],
  },
  cardConfigs: {
    // docTags: {
    //     whitelist: [
    //         'documentStatus'
    //     ]
    // },
    // annoTags: {
    //     whitelist: []
    // }
  },
};

/**
 *
 *
 *
 */
