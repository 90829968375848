import { useEffect } from 'react'

const PageMeta = (props) => {
	const { title, location } = props

	useEffect(() => {
		let metasToReplace = [
			'base_og_url',
			'base_og_title',
			'base_og_description',
			'base_og_image',
			'base_twitter_url',
			'base_twitter_title',
			'base_twitter_description',
			'base_twitter_image',
		]

		let ogMetaData = {
			base_og_url: '',
			base_og_title: '',
			base_og_description: '',
			base_og_image: '',
			base_twitter_url: '',
			base_twitter_title: '',
			base_twitter_description: '',
			base_twitter_image: '',
		}

		metasToReplace.map((d) => {
			const metaTag = document.querySelector(`meta#${d}`)
			if (metaTag) {
				ogMetaData[d] = metaTag.getAttribute('content')
				let metaType = d.split('_')[2]
				metaTag.setAttribute('content', props[metaType] ? props[metaType] : '')
			}
			const pageTitle = document.querySelector('title')
			if (pageTitle) {
				pageTitle.innerHTML = title
			}
		})

		return () => {
			metasToReplace.map((d, i) => {
				const metaTag = document.querySelector(`meta#${d}`)
				if (metaTag) {
					metaTag.setAttribute('content', ogMetaData[d])
				}
				const pageTitle = document.querySelector('title')
				if (pageTitle) {
					pageTitle.innerHTML = ogMetaData.base_og_title
				}
			})
		}
	}, [location.search])

	return null
}

export default PageMeta
