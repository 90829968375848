import { useQuery } from 'react-query'

import axios from 'axios'


const getExportToCsv = async ({csvExportApi}) => {
	let response = await axios.get(`${csvExportApi}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	})
	return response.data
}

export const useGetExportToCsv = (
	{csvExportApi}
) => {
	return useQuery(
		['exportCsvData',csvExportApi],
		() => getExportToCsv({csvExportApi}),
		{
			enabled: false,
		},
	)
}

const getExportToCsvContent = async ({csvExportApi}) => {
	let response = await axios.get(csvExportApi, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	})
	return response.data
}

export const useGetExportToCsvContent = ({
  findQuery,
  resourceType,
  activeFilters,
  taggedResourcesCount,
  taggedResourcesCount_unoptimized,
  combineFields,
  useAggregation,
  population,
  lookupConfig,
  pluginId,
  populateTagsArray,
  enableUpdateExport,
  fieldsToSortAtEnd
  
}) => {
	
  const csvExportApi = `/api/exportToCsv/contentExport?configs=${encodeURIComponent(
    JSON.stringify({
      findQuery,
      resourceType,
      activeFilters,
      taggedResourcesCount,
      taggedResourcesCount_unoptimized,
      combineFields,
      useAggregation,
	  population,
	  lookupConfig,
	  pluginId,
	  populateTagsArray,
	  enableUpdateExport,
	  fieldsToSortAtEnd
      
    })
  )}`;
  return useQuery(
    ["exportCsvDataContent", csvExportApi],
    () => getExportToCsvContent({ csvExportApi }),
    {
      enabled: false,
    }
  );
};
const getExportToCsvUserWorkSheet = async ({csvExportApi}) => {
	let response = await axios.get(csvExportApi, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
	})
	return response.data
}

export const useGetExportToCsvUserWorkSheet = (
	
) => {
	const csvExportApi=`/api/exportToCsv/userWorkSheetExport?configs=${encodeURIComponent(JSON.stringify({workSheetContentType:"lessons",profileType:""}))}`
	return useQuery(
		['exportCsvDataContent',csvExportApi],
		() => getExportToCsvUserWorkSheet({csvExportApi}),
		{
			enabled: false,
		},
	)
}