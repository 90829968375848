import { formatCamelCaseToSentenceCase, formatTextToCamelCase } from "oolib"
import { createContentCardDate } from "../../../../../../utils/momentManipulate";
import { getTplTitle } from "../../../../../../utils/general";
import { queryClient } from "../../../../../..";


const getRoleDisplay = role => {
    const {allRoles} = queryClient.getQueryData('platformConfigs') || {};
    if(!allRoles) return role;
    //else
    return allRoles.find(r => r.value === role)?.display || role
}

const getProfileTypeDisplay = profileType => {
    const {_ProfileTypes} = queryClient.getQueryData('platformConfigs') || {};
    if(!_ProfileTypes) return profileType;
    //else
    return _ProfileTypes.find(p => p.id === profileType)?.content?.title || profileType
}

export const formatters = {
    formatCamelCaseToSentenceCase,
    formatTextToCamelCase,
    createContentCardDate,
    getTplTitle,
    getRoleDisplay,
    getProfileTypeDisplay
}