import React, { useCallback, useEffect, useState } from "react";

import { useScreenWidth, getKeyCode } from "oolib";
import FilterModule from "../../../../components/discovery/FilterModule";
import { useGetQueryData } from "../../../../utils/react-query-hooks/general";
import { useGetDataQuery } from "../../../../utils/react-query-hooks/getData";
import { prepareData } from "./utils/prepareData";
import { genfilterConfig } from "./configs/filterConfig";
import ImageModal from "./comps/ImageModal";
import MediaGrid from "./comps/MediaGrid";
import { StyledWrapper } from "./styled";


const MediaLibrary = (props) => {
  const contentTypes = [
    "reports", 
    "trainingReports", 
    "programPerformanceReports", 
    "routineVisits",
    "successStory", 
  ];

  // Image Gallery Comps paths
  // routineVisits = main.uploadImagesRelatedToThisVisit
  // learningReports // trainingReports = main.uploadImagesRelatedToTheActivity
  // program performance reports = main.uploadImagesRelatedToThisActivity
  // successStory = main.uploadImagesRelatedToThisActivity
  // training reports = main.uploadImages
  const [ mediaCollection, setMediaCollection ] = useState([]);


  const { data, status } = useGetDataQuery({
    contentTypes,
    findQuery: { kp_published_status: "published" },
    projection: {
      //needsFinding
      "main.images": 1,
      "main.uploadImagesRelatedToTheActivity": 1,
      "main.uploadImagesRelatedToThisActivity": 1,
      "main.uploadImagesRelatedToThisVisit": 1,
      "main.uploadImages": 1,

      "tags.country": 1,
      "tags.states": 1,
      "tags.districts": 1,
      "tags.stakeholder": 1,
      "tags.subject": 1,
      "tags.conditionAreas": 1,
      "tags.teams" : 1,
      "main.facilityType": 1,
      // successStory, routineVisits & launchTemplate
      "main.imagesRepeater": 1,
      "meta.kp_content_type": 1,
    },
  });
  const [showModal, setShowModal] = useState(false);

  const screenWidth = useScreenWidth();

  const [activeFilters, setActiveFilters] = useState([]);

  const allTpls = useGetQueryData("allTpls");


  const [ activeIndex, setActiveIndex ] = useState(0)

  useEffect(() => {
    const handleKeyDown = (event) => {
      let code = getKeyCode(event);
      if (code === 'ArrowRight') {
        setActiveIndex((prevIndex) => (prevIndex + 1) % mediaCollection.length); 
      } else if (code === 'ArrowLeft') {
        setActiveIndex((prevIndex) => (prevIndex - 1 + mediaCollection.length) % mediaCollection.length); 
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeIndex]);

  const handleItemClick = useCallback((index) => {
    setShowModal(true);
    setActiveIndex(index);
  }, []);

  useEffect(() => {
    setMediaCollection(data ? prepareData({ data, activeFilters }) : []);
  }, [ activeFilters, status ])

  return (
    <StyledWrapper>
      <ImageModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        activeIndex={activeIndex}
        onPrevious={() => setActiveIndex((prevIndex) => (prevIndex - 1 + mediaCollection.length) % mediaCollection.length)}
        onNext={() => setActiveIndex((prevIndex) => (prevIndex + 1) % mediaCollection.length)}
        mediaCollection={mediaCollection}
        screenWidth={screenWidth}
        allTpls={allTpls}
      />
      <FilterModule
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        
        filterConfig={genfilterConfig({ contentTypes, allTpls })}
        // headerRef={headerRef}
        usedIn={"NKMS Media Library"}
      />
      <MediaGrid 
        status={status}
        mediaCollection={mediaCollection}
        onItemClick={handleItemClick}
      />
    </StyledWrapper>
  );
};

export default MediaLibrary;
