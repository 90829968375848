import { colors, OKELink, SANS_2 } from "oolib";
import React, { Fragment } from "react";
import { propertyExists } from "../../../../../utils/general";
import { populateConfigWithContent } from "./utils";
import { __GetContentTypeConfigNew } from "../../../../../utils/getters/gettersV2";


const { greyColor80, greyColor15 } = colors;

const TplMeta = ({ invert = true, config, content }) => {
  const tplMetaData = populateConfigWithContent({ content, config });
  const { viewsCount } = __GetContentTypeConfigNew(content.meta.kp_content_type).general;

  const checkIfKeyHasValue = (possValuesAry) => {
    let hasValue = false;
    for (var i = 0; i < possValuesAry.length; i++) {
      if (propertyExists(possValuesAry[i])) {
        hasValue = true;
        break;
      }
    }
    return hasValue;
  };

  const genActionDivider = (style) => {
    return (
      <div
        className="actionDivider"
        style={{
          backgroundColor: invert ? greyColor80 : greyColor15,
          ...style,
        }}
      />
    );
  };

  if (!tplMetaData) return null;

  return (
    <div className="TplMetaBlock__content">
      {tplMetaData.map((d, i) => {
        return (
          <Fragment key={i}>
            <div className="TplMetaBlock__text-wrapper">
              <div className="TplMetaBlock__text">
                {d.key && checkIfKeyHasValue([d.text]) && (
                  <SANS_2 invert={true} style={{ paddingRight: "0.5rem" }}>
                    {d.key}
                  </SANS_2>
                )}

                {d.text ? (
                  <SANS_2 invert={true}>{d.text}</SANS_2>
                ) : d.user ? (
                  d.accountDeactivated ? (
                    <SANS_2 invert={true}>{d.user}</SANS_2>
                  ) : (
                    <SANS_2>
                    <OKELink
                      invert={invert}
                      type="internal"
                      to={d.userProfileLink}
                    >
                      {d.user}
                    </OKELink>
                    </SANS_2>
                  )
                ) : (
                  d.date && <SANS_2 invert={invert}>{d.date}</SANS_2>
                )}
              </div>
            </div>
            {i !== tplMetaData.length - 1 &&
              genActionDivider({ margin: "0 1rem" })}
          </Fragment>
        );
      })}

      {viewsCount?.enable && 
      <Fragment>
        {genActionDivider({margin: "0 1rem" })}
        <SANS_2 invert={true} style={{ paddingRight: "0.5rem" }}>
          {`${content?.views?.count} View${content?.views?.count !== 1 ? 's' : ''}`}
        </SANS_2>
      </Fragment>
      }
    </div>
  );
};

export default TplMeta;
