import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Container,
  Divider,
  DropdownMulti,
  PaddingTopBottom20,
  icons,
  colors2,
  UI_BODY_SEMIBOLD,
  colors,
} from "oolib";
import { TagClear } from "oolib/dist/v2/components/Tags";
import { useElemInView } from "../../../utils/customHooks/useElemInView";
import { useGetQueryData } from "../../../utils/react-query-hooks/general";
import { useGetDataQuery } from "../../../utils/react-query-hooks/getData";
import { extractAllBlocksFromTpl } from "../../../utils/templating/extractAllBlocksFromTpl";
import FilterModule from "../../discovery/FilterModule";

import { SidebarLayoutV2 } from "../../layout/SidebarLayoutV2";
import { CardAnnoExplorer } from "./comps/CardAnnoExplorer";
import { MasonryCardGridShell } from "./comps/MasonryCardGridShell";
import { autoGenFilterConfigsFromTpl } from "./utils/autoGenFilterConfigsFromTpl";
import { separateEachAnnoIntoIndependentDoc } from "./utils/separateEachAnnoIntoIndependentDoc";
import { getAnnoExplorerConfigsForThisTenant } from "./utils/getAnnoExplorerConfigsForThisTenant";
import { applyCardConfigs } from "./utils/applyCardConfigs";
import { applyFilterConfigsAsPerTenant } from "./utils/applyFilterConfigsAsPerTenant";
import { applyActiveSort } from "./utils/applyActiveSort";
import { applyKeywordSearch } from "./utils/applyKeywordSearch";
import styled from "styled-components";
import { filterAsPerActiveFilters } from "./utils/filterAsPerActiveFilters"
import { SearchSortPanel } from "./comps/SearchSortPanel";

const { CaretDown } = icons
const { grey5 } = colors2

const getTplsWithAnnosEnabled = ({ allTpls }) => {
  return allTpls.filter((tpl) => {
    const allBlocks = extractAllBlocksFromTpl({ tpl });
    return (
      allBlocks.filter((d) => d.props?.annotation?.enable === true)?.length > 0
    );
  });
};

const useFilterAsPerActiveFilters = ({
  separatedData, 
  activeFilters, 
  selectedTpls
}) => {
  const tagTypesToFetch = activeFilters.flatMap(filter => {
    if (filter.target.filterType === "nestedRollupTagType") {
      // In nestedRollupTagType, the last item in rollupPath (e.g., 'country') 
      // is already prefetched as part of loading the filter options.
      // It's available in the React Query cache via 'contentListing' queries,
      // so we only need to fetch the intermediate entities (e.g., districts, states)
      return filter.target.rollupPath.slice(0, -1);
    } else if (filter.target.valuePathInRolledUpCollection) {
      return [filter.target.tagTypeCollectionToRollup];
    }
    return [];
  }).filter(Boolean);

  const { status, data: tagTypeCollectionToRollupDocs } = useGetDataQuery({
    contentTypes: tagTypesToFetch,
    findQuery: { kp_published_status: 'published' }
  });

  const filteredData = useMemo(() => {
    if (separatedData && status !== 'loading') {
      return filterAsPerActiveFilters({
        separatedData,
        activeFilters,
        selectedTpls,
        tagTypeCollectionToRollupDocs
      });
    }
  }, [separatedData, activeFilters, selectedTpls, status, tagTypeCollectionToRollupDocs]);

  return {
    filteredData,
    fetchFilteredDataStatus: status === 'loading'
  };
};

export const AnnotationExplorer = () => {
  const allTpls = useGetQueryData("allTpls");
  const { _ProfileTypes } = useGetQueryData("platformConfigs");
  const annoExplorerConfigs = getAnnoExplorerConfigsForThisTenant()
  const tplOptions = useRef(
    getTplsWithAnnosEnabled({
      allTpls: annoExplorerConfigs?.contentTypes?.whitelist?.length > 0
        ? allTpls.filter((d) =>
          annoExplorerConfigs?.contentTypes.whitelist.includes(
              d.kp_content_type
            )
          )
        : allTpls,
    })
  );
  const [selectedTpls, setSelectedTpls] = useState(tplOptions.current);

  const [activeFilters, setActiveFilters] = useState([]);

  //performance optimization
  const initialLimit = 20;
  const [cardsLimit, setCardsLimit] = useState(initialLimit);
  const { observerRef } = useElemInView({
    callback: () => {
      console.log("call back ran");
      setCardsLimit((prev) => {
        return prev + initialLimit;
      });
    },
  });

  useEffect(() => {
    setCardsLimit(initialLimit);
  }, [activeFilters, selectedTpls]);

  const fieldsToProject = tplOptions.current
    .map((tpl) => {
      const allBlocks = extractAllBlocksFromTpl({ tpl });

      return allBlocks.filter((bl) => bl.props.annotation?.enable === true);
    })
    .reduce((a, b) => [...a, ...b], []);
  

  const { data, status, error } = useGetDataQuery({
    contentTypes: tplOptions.current.map((d) => d.kp_content_type),
    findQuery: { kp_published_status: "published" },
    population : [
      {
        path: "meta.kp_contributed_by",
        model: "user",
        select: ["name"],
      },
    ],
    projection: {
      ...fieldsToProject.reduce(
        (a, b) => ({ ...a, [`${b.valuePath}.annoData`]: 1 }),
        {}
      ),
      tags: 1,
      "main.title": 1,
      "meta.kp_content_type": 1,
      "kp_date_published": 1,
      "meta.kp_contributed_by": 1,
    },
  });

  const separatedData = useMemo(() => {
    if (status === "success") {
      return separateEachAnnoIntoIndependentDoc({
        data,
        selectedTpls,
      });
    }
  }, [status, data, selectedTpls]);

  const {fetchFilteredDataStatus, filteredData: _filteredData} = useFilterAsPerActiveFilters({
    separatedData, 
    activeFilters, 
    selectedTpls
  })

  const defaultSortOptions = [
    {display: 'Anno Tags - Highest to Lowest', value: 'annoTagsCountDesc'},
    {display: 'Alphabetical - A to Z', value: 'alphabeticalAsc'},
    {display: 'Latest', value: "latest"},
    {display: "Oldest", value: "oldest"}
  ]
  const [activeSort, setActiveSort] = useState(defaultSortOptions[0])

  const sortedData = applyActiveSort({data: _filteredData, activeSort})

  const [keyword, setKeyword] = useState('')
  const filteredData = applyKeywordSearch({data: sortedData, keyword: keyword})


  const filterConfigs = useMemo(
    () => {
      const autoGenFilters = autoGenFilterConfigsFromTpl({
        selectedTpls,
        _ProfileTypes,
        allTpls,
      })

      return applyFilterConfigsAsPerTenant(autoGenFilters)
    },
    [selectedTpls, _ProfileTypes]
  );

  

  const contentTypeSelectRef = useRef(null);

  const selectedTPLsArrayOptions = useMemo(() => {
    return selectedTpls.map((d) => ({
      display: d.general?.content?.title || d.kp_content_type,
      value: d.kp_content_type,
    }))
  }, [ selectedTpls, activeSort ]) 
  
  const handleTagClearClick = (value) => {
    setSelectedTpls((prevSelectedTpls) => 
      prevSelectedTpls.filter((tpl) => tpl.kp_content_type !== value)
    );
  };

  return (
    <div>
      <SidebarLayoutV2
        gridHeightCSS="calc( 100vh - 60px )"
        lsbWidth={300}
        lsbComp={
          <>
            <div ref={contentTypeSelectRef}>
              <div style={{ padding: "1rem 2rem 0.5rem 2rem" }}>
                <UI_BODY_SEMIBOLD>{"Template"}</UI_BODY_SEMIBOLD>
              </div>
              <div style={{ padding: "0 2rem", paddingBottom: "1rem" }}>
                <DropdownMulti
                  S
                  genCustomSelectComp={() => <CustomSelectComp 
                    placeholder={"Select Content Types"}
                    display={`${selectedTPLsArrayOptions?.length} Selected`}
                    />
                  }
                  options={tplOptions.current.map((d) => ({
                    display: d.general?.content?.title || d.kp_content_type,
                    value: d.kp_content_type,
                  }))}
                  value={selectedTPLsArrayOptions}
                  onChange={(k, v) =>
                    setSelectedTpls(
                      v.map((d) =>
                        tplOptions.current.find(
                          (dd) => dd.kp_content_type === d.value
                        )
                      )
                    )
                  }
                />
                {selectedTPLsArrayOptions?.length > 0 && 
                  <div style={{ display: "flex", gap: "0.5rem", width: '100%', flexWrap: "wrap", margin: '0.5rem 0' }}>
                    {selectedTPLsArrayOptions.map(v => (
                      <TagClear 
                        key={v.value}
                        grey 
                        id={v.value} 
                        display={v.display} 
                        XS 
                        onClick={() => handleTagClearClick(v.value)}
                      />
                    ))}
                  </div>
                }
              </div>
              <Divider />
            </div>
            <div style={{height: `calc(100dvh - ${contentTypeSelectRef.current?.getBoundingClientRect()?.bottom}px)`}}>
            <FilterModule
              key={filterConfigs.map(conf => conf.sectionId).join('__')}
              filterConfig={filterConfigs}
              {...{ activeFilters, setActiveFilters }}
            />
            </div>
          </>
        }
      >
      <div style={{ position: "relative" }}>
      
        <SearchSortPanel {...{setKeyword, keyword, defaultSortOptions, activeSort, setActiveSort}}/>

        <Container style={{ background: colors.greyColor3 }}>
        
          <PaddingTopBottom20>
            {filteredData && (
              <div style={{ paddingBottom: "1rem" }}>
                <UI_BODY_SEMIBOLD>{`Total Count: ${filteredData?.length}`}</UI_BODY_SEMIBOLD>
              </div>
            )}
            
            <MasonryCardGridShell
              dataFetchStatus={status === 'success' ? fetchFilteredDataStatus : status} //meaning, if the core data has already successfully loaded, then regard the fetchFilteredDataStatus as the dataFetchStatus
              dataFetchError={error}
              data={
                filteredData && filteredData.filter((d, i) => i < cardsLimit)
              }
              renderCard={(cols, colI, singleAnno, singleAnnoI, thisAry) => (
                <CardAnnoExplorer
                  singleAnno={applyCardConfigs(singleAnno)}
                  lastCardObserverRef={
                    singleAnnoI === thisAry.length - 1 && colI === cols - 1
                      ? observerRef
                      : null
                  }
                />
              )}
            />
          </PaddingTopBottom20>
        </Container>
      </div>  
      </SidebarLayoutV2>
    </div>
  );
};


const StyledWrapper = styled.div`
  display: flex;
  height: 36px;
  padding: 10px 12px 10px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #E7E7E7;
  background: #FFF;
  cursor: pointer;
  &:hover{
    background-color: ${grey5};
  }
`

const CustomSelectComp = ({ display, placeholder,  }) => {
  return(
    <StyledWrapper>
      <div>
        <UI_BODY_SEMIBOLD capitalize style={{ color: display ? colors2.black : colors2.grey60 }}>{display || placeholder}</UI_BODY_SEMIBOLD>
      </div>
      <CaretDown size={20}/>
    </StyledWrapper>
  )
}