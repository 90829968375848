import { useEffect, useRef, useState } from "react"


/**
 * 
 * @abstract Basically, what we figured was, if a POST contribution is currently ‘running’ and another POST contribution is fired on 
 * the same document, then we get a write conflict. Now, in general there is a lot that is wrong with that API, but that requires a deeper 
 * fix with more time later. for now, i figured we might as well fix the write conflict issue asap, cuz its happening too many times, 
 * especially when the server is running slow. Obviously right cuz, each POST contribution will take more time to complete on a slower server. 
 * so this is how i am dealing with it:
 * - Everytime a user makes any ‘inputs’ in a template, the POST contribution fires at a debounce of 1 second. meaning, if their is no user input 
 * for at least a second, then the POST contribution fires with the current most updated content as the payload.
 * - Now, what we need to do is while the POST contribution is in a state of ‘saving’, if another update is made by the user that tries to trigger 
 * off another autosave, then dont allow it to fire. Instead, save this ‘newer’ updated content to a local state. Only when the currently running 
 * POST contribution is completed successfully, then trigger another POST contribution with this ‘newer’ updated content. And so on and so forth.
 * - Imagine if a third POST contribution tries to fire while the first one is still running. Remember the 2nd POST contribution content, has been 
 * saved to local state, so that it can be fired later. But not that there is a 3rd POST contribution, we simply overwrite the 2nd POST contribution 
 * content in local state, because, 3rd POST contribution content IS the latest content after all.
 */
export const useFireOneAutosaveAtATime = ({
    autosave,
    _isAutoSaving,
    isAutoSaved,
    isModerateMode
  }) => {
  
    //this ref hack is only needed because, we have to access isAutoSaving within 
    //the handleChange, which is fired by memo-ized components, so without ref, we will 
    //not be able to access the latest value of _isAutoSaving.
    const isAutoSaving = useRef(_isAutoSaving)
  
    useEffect(() => {
      isAutoSaving.current = _isAutoSaving
    },[_isAutoSaving])
  
    const [ pendingAutosaveContent, setPendingAutosaveContent ] = useState(undefined)
  
    // console.log({isAutoSaved, isAutoSaving: isAutoSaving.current})
    
    useEffect(() => {
      if(isAutoSaved && pendingAutosaveContent){
        // console.log('pending autosave fired', pendingAutosaveContent)
        autosave({
          Contribution: pendingAutosaveContent,
          ACTION: isModerateMode ? "MODERATE" : undefined,
        })
        setPendingAutosaveContent(undefined)
      }
    },[isAutoSaved])
  
    return ({
      isAutoSaving, // we have to return the entire ref, and not isAutoSaving.current, else the hack described above wont work
      setPendingAutosaveContent
    })
  }