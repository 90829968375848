import styled from "styled-components";
import { colors2, mediaQuery } from "oolib";

export const StyledTabsPanelWrapper = styled.div`
  position: sticky;
  
  
  top: ${({ stickyTitleHeight }) => stickyTitleHeight ? `${stickyTitleHeight}px` : '0'};
  
  
  /* border-radius: 8px; */
  padding-bottom: 0.8rem;
  padding-left: 2rem;
  padding-right: 2rem;

  ${mediaQuery('md')}{
    padding: 0
  }
`

export const StyledInnerWrapper = styled.div`
  
  ${mediaQuery('md')}{
    background-color: ${colors2.white};
    padding: 0.6rem 2rem;
    border-bottom: 1px solid ${colors2.grey10};
    box-shadow: 0px 3px 5px hsla(0, 0%, 0%, 0.06);
  }
`