import React from "react";
import { getBreakPoint, icons, UI_BODY_SEMIBOLD_SM, useScreenWidth } from "oolib";

import {
  StyledActionBar,
  
  StyledSortButton,
} from "../styled";

import { LEFT_RIGHT_PADDING, DESKTOP_SIDEBAR_WIDTH, MOBILE_SIDEBAR_WIDTH } from "../../Shell";
import { Sort } from "./Sort";
import { Search } from "./Search";
import { ButtonGhost } from "oolib/dist/v2/components/Buttons";
import { FilterModuleHeader } from "../../Shell/comps/FiltersSidebarShell/comps/FilterModuleHeader";

const { Funnel, SortDescending } = icons;


export const DesktopPanel = ({
  filtersExist,
  showSearchInput,
  showSortDropdown,
  activeSort,
  setActiveSort,
  sortConfig,
  showSideBarLeft,
  setShowSideBarLeft,
  searchTermInputText,
  handleSearch,
}) => {

  const screenWidth = useScreenWidth();

  const isMobile = screenWidth <= getBreakPoint("sm");
  const SIDEBAR_WIDTH = isMobile ? MOBILE_SIDEBAR_WIDTH : DESKTOP_SIDEBAR_WIDTH;
  return (
    <StyledActionBar>
      {filtersExist && (
        <FilterModuleHeader
        style={{
          paddingRight: '2rem'
        }}
          showSideBarLeft={showSideBarLeft}
          width={
            !showSideBarLeft
              ? "max-content"
              : SIDEBAR_WIDTH - LEFT_RIGHT_PADDING
          }
          onOpen={() => !showSideBarLeft && setShowSideBarLeft(true)}
          onClose={() => setShowSideBarLeft(false)}
        />
      )}

      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        {showSearchInput && (
          <Search
            isMobile={false}
            handleSearch={handleSearch}
            searchTermInputText={searchTermInputText}
          />
        )}

        {showSortDropdown && (
          <Sort
            {...{
              sortConfig,
              activeSort,
              setActiveSort,
              genCustomSelectComp: () => (
                <StyledSortButton>
                  <SortDescending size={16} weight="bold" />
                  <UI_BODY_SEMIBOLD_SM>{`Sort by: ${activeSort.display}`}</UI_BODY_SEMIBOLD_SM>
                </StyledSortButton>
              ),
            }}
          />
        )}
      </div>
    </StyledActionBar>
  );
};
