export const plugins_UIContent = {
    gsl: {
        rowActionPlugin: {
            shortlist: {
                actionMenuBtnDisplay: "Shortlist",
                modalTitle: "Select a campaign",
                modalOnConfirmText: "Shortlist",
                modalRemoveTagButtonText: "Remove shortlist",
                removeTagSuccessBannerContent: "Shortlist removed successfully",
                onSuccessBannerContent: "Content shortlisted successfully",
            },
            markAsWinner: {
                actionMenuBtnDisplay: "Mark as winner",
                modalTitle: "Select a campaign",
                modalOnConfirmText: "Mark as Winner",
                modalRemoveTagButtonText: "Remove winner tag",
                removeTagSuccessBannerContent: "Winner tag removed successfully",
                onSuccessBannerContent: "Content marked as winner successfully",
            }
        }
    }
}