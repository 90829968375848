import { getVal } from "oolib";
import { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import {  __GetContentTypeConfigNew } from "../getters/gettersV2";
import handleBlockDisplayConditions from "../handleBlockDisplayConditions";
import { extractAllBlocksFromTpl } from "../templating/extractAllBlocksFromTpl";
import { blockIsPopulatedConditions } from "../validation/blockIsPopulatedConditions";
import { valueNotFalsyOrEmptyAryObj } from "../validation/validatorFns";

export const useCreateIndexList = ({ tpl, content, readOnly }) => {
  const queryClient = useQueryClient();

  const indexList = useMemo(() => {
    
    return createIndexList({
      tpl,
      content,
      contentType: content.meta?.kp_content_type,
      readOnly,
      BASE_LAYOUT_CONFIGS:
        queryClient.getQueryData("platformConfigs").BASE_LAYOUT_CONFIGS,
    });

  }, [tpl,content]);

  const [activeIndexOp, setActiveIndexOp] = useState(indexList[0]);

  return {
    activeIndexOp,
    setActiveIndexOp,
    indexList,
  };
};

const createBlocksBasedIndexList = ({ tpl, readOnly, content }) => {
  let allBlocks = extractAllBlocksFromTpl({ tpl, buildersWhitelist: ['FormBuilder'] });
  if (readOnly) {
    allBlocks = allBlocks.filter(
      (b) =>
        !b.inputOnly &&
        blockIsPopulatedConditions(b, getVal(content, b.valuePath))
    );
  } else {
    allBlocks = allBlocks.filter((b) => !b.displayOnly);
  }
  /**
   * filter down to those blocks that  
   * - either dont have display conditions
   * - or have a displayCond which evalutes to true
   * - AND doesnt have a single section in block.sectionStack that evaluates to false
   */
  allBlocks = allBlocks.filter((b) => handleBlockDisplayConditions({ content, block: b }) &&
    (
      !valueNotFalsyOrEmptyAryObj(b.sectionStack) || // if sectionstack doesnt exist then evaluate to true
      b.sectionStack.every(sec => handleBlockDisplayConditions({content, block: sec}))  
    )
  );
  
  return allBlocks.map((b) => ({
    block: b,
    value: b.valuePath,
    display: b.props.shortLabel || b.props.label
  }));
};

/**
 * known issues:
 * - if block is within a conditional section, and that section is not showing,
 * STILL the block is indexed.
 *
 * - sometimes, one the option in the indexlist that is supposed to be 'highlighted' is not highlighted.
 * ( i think this problem will have to be solved in the indexList component. )
 */
export const createIndexList = ({
  tpl,
  content,
  contentType,
  readOnly,
  noFilter,
  list = [],
  BASE_LAYOUT_CONFIGS,
}) => {
  
  const isVersion0Tpls = BASE_LAYOUT_CONFIGS[tpl.category].version === 0;

  const indexType = (
    tpl.general?.templateIndex?.type || 
    __GetContentTypeConfigNew(contentType)?.general?.templateIndex?.type || // once all configs have been migrated to the tpl, we won't need this line
    'block'
  )   //backwards compat

    

  if (isVersion0Tpls) {
    tpl = {
      ...tpl,
      kp_settings: [],
      kp_templates: {
        data: tpl.kp_templates.data.filter(
          (section) =>
            section.subSectionName === "body" || section.divisionId === "body"
        ),
      },
    };
    if (indexType === "section") {
      const bodySec = tpl.kp_templates.data?.find(d => d.divisionId === 'body') || { blocks: []}
      let allLevel1Sections = bodySec.blocks.filter(
        (b) => !!b.sectionId
      );
// console.log({allLevel1Sections})
      allLevel1Sections = allLevel1Sections.filter(sec => handleBlockDisplayConditions({content, block: sec}))

      return allLevel1Sections.map((s) => ({
        value: s.sectionId,
        display: s.sectionTitle,
        block: s
      }));
    } else {
      return createBlocksBasedIndexList({
        tpl,
        content,
        readOnly,
      });
    }
  } else {
    //version1 up templates
    if (indexType === "section") {
      const allSectionsAry = [];

      for (let spaceId in tpl.kp_templates) {
        let conf = tpl.kp_templates[spaceId];

        if (!conf.subSpaces) {
          if(conf.builder === 'FormBuilder'){
            conf.configs.forEach(block_section => {
              if(block_section.sectionId){
                allSectionsAry.push({
                  value: block_section.sectionId,
                  display: block_section.sectionTitle,
                  block: block_section
                })
              }
            })
          }
        } else {
          //pending..
        }
      }

      return allSectionsAry;

    } else {
      return createBlocksBasedIndexList({
        tpl,
        content,
        readOnly,
      });
    }
  }
};
