import { getVal } from "oolib";


export const prepareCoverConfig = ({ tpl, content, configs }) => {

  const { inputs: { coverComponent, title, subtitle }, metaPrimary, tagsIntro } = configs || {};

  return {
      title: title?.valuePath && getVal(content, title.valuePath),
      desc: subtitle?.valuePath ? getVal(content, subtitle.valuePath) : tpl.general?.content?.listingDesc,
      metaPrimary: metaPrimary?.props || tpl.tplMeta?.metaPrimary,
      tagsIntro: tagsIntro?.props,
      coverImage: (coverComponent?.comp === "ImageInput" && coverComponent?.valuePath) && getVal(content, coverComponent.valuePath),
      coverVideo: (coverComponent?.comp === "VideoInput" && coverComponent?.valuePath) && getVal(content, coverComponent.valuePath),
      coverExists: Boolean(coverComponent?.comp === "ImageInput" && coverComponent?.valuePath) || (coverComponent?.comp === "VideoInput" && coverComponent?.valuePath),
      content,
  };
};
  