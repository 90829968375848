import axios from 'axios'
import {
  useInfiniteQuery,
  useMutation, useQueries, useQuery,
  useQueryClient
} from 'react-query'
import { useNavigate } from 'react-router-dom'

import { UIContent } from '../../UIContent'
import { __GetProfileTypeConfig } from '../getters/gettersV2'
import { useBannerContext } from 'oolib'

export const useDeleteProfile = () => {
	const queryClient = useQueryClient()
	const { SET_ALERT_BANNER } = useBannerContext()
	

	return useMutation(
		({ profileType, id }) => {
			let profileCategory = __GetProfileTypeConfig(profileType).category
			let apiString = `/api/${
				profileCategory ? `${profileCategory}/` : ''
			}${profileType}/${id}`
			return axios.delete(apiString)
		},

		{
			onError: () => {
				SET_ALERT_BANNER({
					color: 'red',
					msg: UIContent.reactQueryHooks_profile.deleteProfileErrorMsg,
					timeOut: 3000,
				})
			},
			onSuccess: (res, { profileType, id }) => {
				queryClient.invalidateQueries(['Profile', profileType, id])

				SET_ALERT_BANNER({
					color: 'green',
					msg: UIContent.reactQueryHooks_profile.deleteProfileSuccessfulMsg,
					timeOut: 3000,
				})

			},
		},
	)
}

export const useGetProfile = (id, profileType, options = {}) => {

  return useQuery(
    ['Profile', profileType, id],
    () =>
      axios.get(
        `/api/userProfiles/${profileType}/${id}`
      ),
    {
      enabled: options.enabled !== undefined ? options.enabled : true,
    }
  )
}


export const useGetParallelProfiles = (profileTypes) => {
  return useQueries(

    profileTypes.map(id => {

      let profileCategory = __GetProfileTypeConfig(id).category

      return {

        queryKey: ['Profile', id],

        queryFn: () => axios
          .get(`/api/${profileCategory ? `${profileCategory}/` : ''}${id}`)
          .then((res) => res.data)

      }

    })

  )
}

const getMixedProfiles = async (profileTypes) => {
  try {
    let toReturn = []
    let promises = profileTypes.map(async (profileType) => {
      
      let profileCategory = __GetProfileTypeConfig(profileType)?.category
      try {
        let res = await axios.get(
          `/api/${profileCategory ? `${profileCategory}/` : ''}${profileType}`
        )
        toReturn = [...toReturn, ...res.data]
      } catch (err) {
        console.log('err in map in getMixedProfiles react query', err)
      }
    })

    await Promise.all(promises)
    return toReturn
  } catch (err) {
    console.log('err in getMixedProfiles react query', err)
  }
}

export const useGetRelatedContent = (id, profileType, options = {}) => {
  let profileCategory = __GetProfileTypeConfig(profileType).category

  return useQuery(
    ['RelatedContent', id], // PENDING:  add profiletype to the key
    () =>
      axios.get(
        `/api/${
          profileCategory ? `${profileCategory}/` : ''
        }${profileType}/relContent/${id}`
      ).then(res => res.data),
    {
      enabled: options.enabled !== undefined ? options.enabled : true,
    }
  )
}

export const useSaveChanges = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { SET_ALERT_BANNER } = useBannerContext()

  return useMutation(
    ({ userId, formData, profileType }) => {
      const config = { headers: { 'Content-Type': 'application/json' } }

      const saveChanges = async () => {
        try {
          //update profile
          await axios.post(
            `/api/userProfiles/${profileType}`,
            formData,
            config
          )

          //update user
          const updatedUser = await axios.post(
            `/api/users/update/details?profileType=${profileType}`,
            formData.user,
            config
          )

          return updatedUser
         
        } catch (err) {
          throw err
        }
      }

      return saveChanges()
    },
    {
      onError: (error) => {
        
        SET_ALERT_BANNER({
          color: 'red',
          msg: UIContent.reactQueryHooks_profile.updateProfileErrorMsg,
          timeOut: 3000,
        })
        console.log(error)
      },
      onSuccess: (res, { profileType, userId: id, redir }) => {
       
        queryClient.invalidateQueries(['Profile', profileType, id])
        queryClient.invalidateQueries('userData')
        let profileId = res?.data?._id
        // this redir param is to deal with blocking redir in onboarding flow. check out MyProfileEdit to understand this better
        redir !== false &&
          navigate(
            `/profile/userProfiles/${profileType}/${profileId}?activeTab=about`
          )
        
        SET_ALERT_BANNER({
          color: 'green',
          msg: UIContent.reactQueryHooks_profile.updateProfileSuccessfulMsg,
          timeOut: 3000,
        })
      },
    }
  )
}






