import styled from 'styled-components'

export const StyledShellContainer = styled.div`
  /* min-height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const StyledFilterMobileButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    border: none;
    cursor: pointer;
    padding: 1rem;
    gap: 0.4rem;
`

export const StyledMainContent = styled.div`
  display: flex;
  width: 100%;
`;
