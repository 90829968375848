import axios from "axios";



import { queryClient } from "../index";
// import { setSaveStatusToSavedToDb } from '../actions/contributions'
import { useEffect } from "react";

import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { getTodayString } from "./general";
import { useQueryClient } from "react-query";

import { __GetAllContentTypeConfig } from "./getters/gettersV2";
import { BannerContext, getDaysDiff, useBannerContext } from "oolib";
import { getActiveLangFromLocalStorage, useAppSettingsContext } from "../contexts/appSettingsContext";

export const useRegisterSw = ({platformConfigs, enabled}) => {
  const { SET_INFO_BANNER } = useBannerContext();
  
  const queryClient = useQueryClient();

  useEffect(() => {
    if (enabled) {
      const { _EnablePWA } = platformConfigs.deployment;
      if (!_EnablePWA) {
        serviceWorkerRegistration.unregister();
      } else {
        console.log('register PWA')
        serviceWorkerRegistration.register({
          
          handleNewSWActivated: (wasAWaitingWorker) => {
            /**
             * PENDING: handle is activated msg bradcast here.
             * because this message is sent from the service worker itself,
             * AFTER waiting for the app to successfully load.
             * 
             * In other words, this message WILL NOT fall through without
             * the app catching it.
             */
             SET_INFO_BANNER({
              id : 'broadcastSWStatusMsg',
              color: "grey",
              msg: wasAWaitingWorker
              ? "New Updates Activated!"
              : "Website is Offline Ready!", 
              timeOut: 3000
             })
            __CachePublicApis()
            /**
             * `/api/auth/getUserData` & `__precachePrivateApis` 
             * MAY NOT GET CACHED on first load, IF the useUserLoad hook & its success callback (__precachePrivateApis) 
             * fire successfully before the new service worker is activated (`handleNewSwActivated`). 
             * 
             * Hence we fire them again over here, just to be sure
             */
            __getUserDataAndPrecachePrivateApis({SET_INFO_BANNER})
          },

          broadcastUpdateAvailable : (registration) => {
            console.log("Updates Available. Show Update Now Button");
            SET_INFO_BANNER({
              id : 'broadcastSWStatusMsg',
              color: "grey",
              msg: "New Updates Ready!",
              cta: {
                text: "Update Now",
                action: () => __handleLoadWaitingServiceWorker(registration)
              },
              hideCloseButton: true
            });
          },

          broadcastSecondSWStatusMsg: (state) => {
            SET_INFO_BANNER({
              id : 'broadcastSWStatusMsg',
              color: 'grey', 
              msg: state === 'installing' 
              ? 'New Updates are installing...' 
              /**
               * the 'installed' state is not being handled here,
               * cuz when a 2nd SW is installed, we fire the 
               * broadcastUpdateAvailable handler
               */

              /**
               * most likely this msg will fallthrough cuz
               * the activating state is usually completed even before the 
               * app has loaded in the browser.
               */
              : state === 'activating'
                && 'New Updates are activating..'
                /**
                 * @note -- ACTIVATED EVENT HANDLING --
                 * we dont handle broadcasting for the 'activated' state
                 * here, because, this event tracker is not 100%
                 * reliable and might get called even before the app is loaded,
                 * in which case, the activated event will fall through,
                 * which we can't afford, since we have to trigger the precache apis.
                 * 
                 * Hence we have the  handleNewSWActivated handler, which is 100%
                 * reliable
                 */
            })
          },

          broadcastFirstSWStatusMsg: (state) => {
            SET_INFO_BANNER({
              id : 'broadcastSWStatusMsg',
              color: 'grey', 
              msg: state === 'installing' 
              ? 'Downloading assets for offline use' 
              : state === 'installed'
                ? 'Assets downloaded!'
                : state === 'activating'
                  && 'Activating offline ready-ness of website'
                  /**
                   * read @note -- ACTIVATED EVENT HANDLING --
                   */
              })
          },

          onSavedToDb: ({msg, Contribution}) => {
            queryClient.invalidateQueries("RelatedContent")
            const title = Contribution.main.title;
            const infoBannerMsg = `Offline draft titled '${title}' successfully synced online!`
            SET_INFO_BANNER(infoBannerMsg, 'green',3000)
          }
        });
      }
    }
  }, [enabled]);
};

const __handleLoadWaitingServiceWorker = registration => {
	let skipWaitingMessageChannel = new MessageChannel();
	registration.waiting.postMessage(
	  { type: "SKIP_WAITING_AND_RELOAD" },
	  [skipWaitingMessageChannel.port2]
	);

	skipWaitingMessageChannel.port1.onmessage = (event) => {
	  switch (event.data.type) {
		case "REFRESH_PAGE":
		  localStorage.removeItem("userProfileCacheDate");
		  localStorage.removeItem("tplsCacheDate");
		  localStorage.removeItem(
			"contentTypesCacheDate__AFTER_LOGIN"
		  );
		  localStorage.removeItem(
			"contentTypesCacheDate__BEFORE_LOGIN"
		  );
		  window.location.reload();
		  break;
	  }
	};
  }


const __getUserDataAndPrecachePrivateApis = (options = {}) => {
  axios.get("/api/auth/getUserData").then(
    (res) => {
      if(res.data?.user){ //this suggests that the user was successfully retrieved. so now fire the precacheprivateapis
        __precachePrivateApis({
          thisUserProfileType: res.data.user.profileType, 
          thisUserId: res.data.user._id,
          SET_INFO_BANNER: options.SET_INFO_BANNER
        })
      }
    }
  );
}

export const __CachePublicApis = async (options = {}) => {
  
  try {
    const {
      deployment: { _PWACacheConfig, _AppLanguageOptions },
    } = queryClient.getQueryData('platformConfigs');

    
    /**
     * ---- CACHE PLATFORM CONFIGS -----
     * even though on app load these 2 apis fire,
     * there is no guarantee that they fire before or after a
     * new service worker is activated. 
     * Hence we need to fire it once again over here to guarantee
     * that it gets cached, since this function fires only after
     * the sw is registered & activated
     */
    __firePlatformConfigsApi();
    __fireGetAllTplsApi()

    // ---- CACHE PUBLISHED PAGES & LISTINGS ---- //
    if (_PWACacheConfig.publishedListing) {
      __fireContentTypeApis({ cacheAfterLogin: false, activeLang: getActiveLangFromLocalStorage(_AppLanguageOptions) });
    }

    // if(_PWACacheConfig.tagTypes){
    //   __fireTagTypeApis()
    // }

    

  } catch (err) {
    console.log("err in __CachePublicApis util");
  }
};


const __firePlatformConfigsApi = async () => {
  try {
    await axios.get("/api/platformConfigs");
    console.log("platform config cached");
  } catch (err) {
    console.log("err in caching platform config", err);
  }
};


const __fireGetAllTplsApi = async () => {
  try {
    await axios.get("/api/tpl/getAllTpls");
    console.log("getAllTpls cached");
  } catch (err) {
    console.log("err in caching getAllTpls", err);
  }
};


export const __precachePrivateApis = async ({thisUserProfileType, thisUserId, SET_INFO_BANNER}) => {

  try {
    const {
      deployment: { _PWACacheConfig, _AppLanguageOptions },
    } = queryClient.getQueryData('platformConfigs');


    // // ---- CACHE PARTICIPANTS ( GOC ) ----- //
    // if (_PWACacheConfig.participants) {
    //   __fireParticipantsApi();
    // }

    // ---- CACHE PUBLISHED PAGES & LISTINGS ---- //
    if (_PWACacheConfig.publishedListing) {
      __fireContentTypeApis({ cacheAfterLogin: true, activeLang: getActiveLangFromLocalStorage(_AppLanguageOptions) });
    }

    // ---- CACHE TPLS ---- //
    // __fireTplApis()
		
    // ---- CACHE THIS USER'S PROFILE DATA & RELATED CONTENT DATA ---- //
    __fireMyProfileAndRelatedContentApis(
      thisUserProfileType, 
      thisUserId,
      SET_INFO_BANNER
    )

   

  } catch (err) {
    console.log("err in __CachePublicApis util");
  }
};


export const __fireContentTypeApis = async (
  options = {} //boolean
) => {
  
  try {
    let { cacheAfterLogin, activeLang } = options;
    
    let todayString = getTodayString();
    //fire this api to trigger sw cache only once a day.
    /**
     * NOTE:
     * the BEORE_LOGIN today checkk stuff is prob unnecessary,
     * cuz those apis fire only when a new sw is activated, which is fine.
     * no need for today check.
     * that is only for post login api firing.
     */
    if (
      (cacheAfterLogin &&
        localStorage.getItem("contentTypesCacheDate__AFTER_LOGIN") ===
          todayString) ||
      (!cacheAfterLogin &&
        localStorage.getItem("contentTypesCacheDate__BEFORE_LOGIN") ===
          todayString)
    ) {
      console.log(
        "content type cache already updated once today. no need to update again"
      );
      return;
    }

    let {
      _Roles,
      deployment: { _PWACacheConfig },
    } = queryClient.getQueryData('platformConfigs');

    const allContentTypes = __GetAllContentTypeConfig();
  
    // detect from roles config which content type listings are ONLY accessible to logged in users.
    let cacheAfterLoginContentTypes = Object.keys(_Roles['0'].contentTypes).filter(key => !_Roles['0'].contentTypes[key].includes('READ'))

    let filterCondition = (d) =>
      cacheAfterLogin
        ? cacheAfterLoginContentTypes.includes(d)
        : !cacheAfterLoginContentTypes.includes(d);

    let promises = allContentTypes
      .filter((d) => filterCondition(d.kp_content_type))
      .map(async (d) => {
        try {
        let res =   axios.get(`/api/discovery/getData`, {
            params: { configs: JSON.stringify({
              contentTypes : [d.kp_content_type],
              findQuery: {
                kp_published_status: 'published'
              },
              ...(activeLang ? {activeLang: activeLang.value} : {})
            }) },
            // paramsSerializer: (params) => qs.stringify(params),
          })
          return { status: "success", data: res.data };
        } catch (err) {
          return { status: `error in cache listing of type : [ ${d.kp_content_type} ]`, msg: err };
        }
      });

    let results = await Promise.all(promises);

    if (results.every((result) => result.status === "success")) {
      console.log("listing pages saved for offline use");

      if (_PWACacheConfig.publishedLanding) {
        let contrPromises = results.map(async (d) => {
          try {
            //d is an array of this content types listing
            let thisContrPromises = d.data.map(async (dd) => {
              try {
                await axios.get(
                  `/api/content/viewContent/${dd.meta.kp_content_type}/${dd._id}`                

                );
                return { status: "success" };
              } catch (err) {
                console.log(
                  `err in get [ ${dd.meta.kp_content_type} : ${dd._id} ] content type contribution promises`,
                  err
                );
                return {status: `err in get [ ${dd.meta.kp_content_type} : ${dd._id} ] content type contribution promises`, msg: err }
              }
            });

            let thisContrResults = await Promise.all(thisContrPromises);
            if (thisContrResults.every((r) => r.status === "success")) {
              return { status: "success" };
            } else {
              throw { status: 'error in caching published landing pages', results: thisContrResults};
            }
          } catch (err) {
            return err;
          }
        });

        let contrResults = await Promise.all(contrPromises);

        if (contrResults.every((c) => c.status === "success")) {
          console.log("published pages saved for offline use");
          localStorage.setItem(
            cacheAfterLogin
              ? "contentTypesCacheDate__AFTER_LOGIN"
              : "contentTypesCacheDate__BEFORE_LOGIN",
            todayString
          );
        } else {
          console.log("error in caching published pages", contrResults);
          throw "error in caching published pages";
        }
      }
    } else {
      console.log("error in caching listing pages", results);
      throw "error in caching listing pages";
    }
  } catch (err) {
    console.log("err in __fireContentTypeApis", err);
  }
};

//Fire user profile and rel content apis for SW to store
export const __fireMyProfileAndRelatedContentApis = async (
  profileType,
  userId,
  SET_INFO_BANNER
) => {

  try {
    let todayString = getTodayString();
    let {
      deployment: { _PWACacheConfig },
    } = queryClient.getQueryData('platformConfigs');
    //fire this api to trigger sw cache only once a day.
    if (
      _PWACacheConfig.myUserProfile &&
      localStorage.getItem("userProfileCacheDate") !== todayString
    ) {
      /** 
       * @note SET CACHE DATE BEFORE AWAIT
       * important to set this before the async stuff starts
       * else we might get 2-3 repeat fires of this, up until 
       * the first api call succeeds
       */
      localStorage.setItem("userProfileCacheDate", todayString);
      await axios.get(`/api/userProfiles/${profileType}/${userId}`);
      await axios.get(`/api/userProfiles/${profileType}/relContent/${userId}`);
      SET_INFO_BANNER({
        msg: "Your Profile & Content Are Saved For Offline Access",
        color: 'grey',
        timeOut: 4000
      })
      
    }
  } catch (err) {
    localStorage.removeItem("userProfileCacheDate");
    console.log("err in __fireMyProfileAndRelatedContentApis", err);
  }
};

