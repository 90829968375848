import { cloneDeep } from "lodash";
import { colors, Loader, Modal, SANS_3, setVal } from "oolib";
import React, { Fragment, useContext, useState } from "react";
import { useParams } from "react-router";
import { AppSettingsContext } from "../../../contexts/appSettingsContext";
import {
  useGetQueryData,
  useGetQueryState,
} from "../../../utils/react-query-hooks/general";

import { DropdownSingle } from "oolib";
import { WrapperFlexBlocks } from "../../layout/Wrappers";
import { Form } from "./Form";
import { trackEventGA } from "../../../trackers/GA/trackEventGA";
const { greyColor100, greyColor70 } = colors;

function CreateUpdatePopUp({
  mode,
  updateData,
  closeModal,
  initBoilerplateContent,
  parentResourceId,
}) {
  const { content_type: parentContentType } = useParams();
  const { data: tpl, status: tplStatus } = useGetQueryState([
    "tpl",
    `${parentContentType}__update`,
  ]);

  const {
    deployment: { _AppLanguageOptions },
  } = useGetQueryData("platformConfigs");

  const { APP_SETTINGS, APP_SETTINGS_DISPATCH } =
    useContext(AppSettingsContext);

  const [content, setContent] = useState(cloneDeep(updateData));

  const handleSelectSubject = (key, selectedSubject) => {
    setContent((prev) => {
      let newContent = initBoilerplateContent();
      setVal(newContent, "meta.subject", selectedSubject);

      return newContent;
    });
  };

  const genSubjectSelect = (tpl) => {
    if(!tpl.kp_settings || tpl.kp_settings?.length === 0) return null;

    let subjectPage = tpl.kp_settings.find((d) =>
      d.blocks.some((bl) => bl.valuePath === "meta.subject")
    );
    let subjectBlock = subjectPage.blocks.find(
      (bl) => bl.valuePath === "meta.subject"
    );
    let options = subjectBlock.props.options.map((d) => ({
      ...d,
      display: d.title,
      desc: d.subtitle,
    }));
    return (
      <DropdownSingle
        invert={true}
        label={"Update Type"}
        placeholder="Select Update Type"
        value={content?.meta?.subject}
        options={options}
        onChange={handleSelectSubject}
        selectConfig={["display"]}
      />
    );
  };

  const handleLanguageChange = (key, value) => {
    trackEventGA("Traslation Flow","UpdateModule Translate",`${value?.value}`)
    APP_SETTINGS_DISPATCH({
      type: "SET_LANG",
      payload: value,
    });
  };

  

  return (
    <Modal
      title={mode === "create" ? "Add An Update" : "Edit"}
      desktopWidth="740px"
      onClose={closeModal}
    >
      {tplStatus === "loading" ? (
        <Loader />
      ) : (
        <Fragment>
          
            { (!_AppLanguageOptions || [0,1].includes(_AppLanguageOptions.length)) &&
            (!tpl.kp_settings || tpl.kp_settings?.length === 0)
            ? null
              : 
              <div style={{ padding: "3rem 2rem", backgroundColor: greyColor100 }}>
                <WrapperFlexBlocks
              WrapperMob={({ children }) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2rem",
                  }}
                >
                  {children}
                </div>
              )}
            >
              { _AppLanguageOptions.length > 1 &&
                <DropdownSingle
                invert={true}
                label={"Language"}
                placeholder="Choose"
                value={APP_SETTINGS.lang}
                options={_AppLanguageOptions}
                onChange={handleLanguageChange}
              />}
              {mode === "create" && genSubjectSelect(tpl)}
            </WrapperFlexBlocks>
            </div> }
          
          <div>
            {tpl.kp_settings?.length > 0 && !content?.meta?.subject && (
              <div style={{ padding: "3rem 2rem" }}>
                <SANS_3>
                  {"Select an update type above to generate the form"}
                </SANS_3>
              </div>
            )}
            {(content?.meta?.subject || !tpl.kp_settings || tpl.kp_settings?.length === 0) && (  
              <Form
                {...{
                  tpl,
                  content,
                  setContent,
                  parentContentType,
                  parentResourceId,
                  closeModal,
                  mode,
                }}
              />
            )}
          </div>
        </Fragment>
      )}
    </Modal>
  );
}

export default CreateUpdatePopUp;
