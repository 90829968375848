import React from "react";
import TableOfContentButton from "../TableOfContentButton";

export const TableOfContentButtonsList = ({
    options,
    failedBlocks,
    handleClick,
    value,
    invert
}) => {
  return options.map((op) => {
    const isActive = op.value === value?.value;
    const hasError =
      failedBlocks &&
      failedBlocks.some((block) =>
        block.sectionStack?.some((section) => section.sectionId === op.value)
      );
    return (
      <TableOfContentButton
        {...{
          optionObj: op,
          invert,
          handleClick,
          isActive,
          hasError,
        }}
      />
    );
  });
};
