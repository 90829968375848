import React, { Fragment } from "react";
import { _Locale } from "../../locale/Locale";
import { getVal, LABEL } from "oolib";
import { useGetQueryData } from "../../../utils/react-query-hooks/general";

export const getVal__backwardsCompat = (parentObjs, valuePath) => {
  let val = parentObjs[0] && getVal(parentObjs[0], valuePath);
  if(!val && parentObjs[1]) val = getVal(parentObjs[1], valuePath);
  return val;
}

const KPMetaPrimary = ({
  className,
  data,
  invert,
  content,
  value: masterVal,
  style,
  align = 'left'
}) => {
  //masterVal is nothing but the 'main value' that is passed through by the tpl block generator, which feeds of the valuePath defined in the template files on postman
  //can either pass value which has to be a text string or an array of text strings.
  //or can pass valuePath+content, which would only come from templates (sometimes)
  //now can also passs icon name as string

  let parentObj = content?.meta; // to phase out eventually
  let backupParentObj = content; //takes over
  const {_Theme} = useGetQueryData('platformConfigs')
  

  let functions = {
    
    makeMasterValDataVal: (masterVal) => {
      return { value: masterVal };
    },
  };

  let value = Array.isArray(data.value) ? data.value.join(", ") : data.value;
  let key = data.key;
  if ((parentObj || backupParentObj) && data.keyPath) {
    key = getVal__backwardsCompat([parentObj, backupParentObj], data.keyPath) || key; //i.e fallback to explicit key string if defined
  }
  if ((parentObj || backupParentObj) && data.valuePath) {
    value = getVal__backwardsCompat(
      [parentObj, backupParentObj],
      data.valuePath
    ) || value;
  }

  if ((parentObj || backupParentObj) && data.keyValFn) {
    key = functions[data.keyValFn](masterVal).key;
    value = functions[data.keyValFn](masterVal).value;
  }

  if (data.keyValFn) {
    key = functions[data.keyValFn](masterVal).key;
    value = functions[data.keyValFn](masterVal).value;
  }

  return (
    <Fragment>
      {(value || key) && (
        <div 
          className={`kp-meta-primary ${className}`} 
          style={{
            justifyContent: align === 'center' ? 'center' : align === 'left' && 'flex-start',
            ...(style || {}),
          }}
          >
          {key && (
            <LABEL className="kp-meta-primary__text kp-meta-primary__text--key">
              {_Locale(key)}
            </LABEL>
          )}
          {value && (
            <LABEL
              style={{ color: !invert && _Theme.colors.primaryColorText }}
              invert={invert}
              className="kp-meta-primary__text kp-meta-primary__text--value"
            >
              {_Locale(value)}
            </LABEL>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default KPMetaPrimary;
