import { useState, useCallback, useEffect, useRef } from 'react';
import { throttle } from 'lodash';

/**
 * Custom hook for handling scroll-based animations with throttled updates
 * 
 * @param {Object} params - Hook parameters
 * @param {number} params.tabsPanelHeight - Height of the tabs panel
 * @param {number} params.actionBarHeight - Height of the action bar
 * @param {function} params.setMobileInfoSectionOpacity - Callback to update mobile info section opacity
 * @returns {Object} - Object containing current blur and shift values for animations
 */
export const useScrollEffectsManager = ({ 
  tabsPanelHeight, 
  actionBarHeight, 
}) => {
  // Initialize state for blur and shift effects
  const [coverBlur, setCoverBlur] = useState(1);
  const [coverShift, setCoverShift] = useState(0);
  const [mobileInfoSectionOpacity, setMobileInfoSectionOpacity] = useState(1);

  // [Sticky Title related] 
  // Init state for tracking cover section bottom on mobile
  const coverSectionRef = useRef(null);
  const [coverSectionBottom, setCoverSectionBottom] = useState(0);

  /**
   * Maps a scroll value from 0-400 range to opacity values where:
   * - 0 to 250 returns 1 (fully visible)
   * - 250 to 400 maps to 1-0 inversely (fade out)
   * 
   * @param {number} value - Current scroll position
   * @returns {number} - Mapped opacity value between 0 and 1
   */
  const mapValueInverse = (value) => {
    // Ensure value stays within 0-400 range
    const clampedValue = Math.min(Math.max(value, 0), 400);
    
    // Keep full opacity until scroll position 250
    if (clampedValue <= 250) return 1;
    
    // Gradually decrease opacity from 250 to 400 scroll position
    const mappedValue = 1 - (clampedValue - 250) / 150;
    
    // Round to 3 decimal places for performance
    return Math.round(mappedValue * 1000) / 1000;
  };

  /**
   * Maps a scroll value from 0-250 range to a custom range for vertical shifts
   * 
   * @param {number} value - Current scroll position
   * @param {number} maxValue - Maximum output value to map to
   * @returns {number} - Mapped shift value
   */
  const mapVerticalShift = (value, maxValue) => {
    // Ensure value stays within 0-250 range
    const clampedValue = Math.min(Math.max(value, 0), 250);
    
    // Linear mapping from input range to output range
    const mappedValue = (clampedValue / 250) * maxValue;
    
    // Round to 2 decimal places for performance
    return Math.round(mappedValue * 100) / 100;
  };

  // Create memoized scroll handler with throttling
  const handleEffectsOnScroll = useCallback(
    throttle(() => {
      // Update mobile info section opacity based on scroll position
      setMobileInfoSectionOpacity(mapValueInverse(window.scrollY));
      
      // Update blur effect (max blur of 33px)
      setCoverBlur(mapVerticalShift(window.scrollY, 33));
      
      // Update vertical shift based on panel heights
      // 260 is the maximum shift value before considering panel heights
      setCoverShift(
        mapVerticalShift(
          window.scrollY,
          260 - (tabsPanelHeight + actionBarHeight)
        )
      );

      // [Sticky Title related] Update cover section bottom on mobile
      if (coverSectionRef?.current) {
        setCoverSectionBottom(coverSectionRef.current.getBoundingClientRect().bottom);
      }

    }, 25), // Throttle to run at most once every 100ms
    [tabsPanelHeight, actionBarHeight, setMobileInfoSectionOpacity]
  );

  // Set up and clean up scroll listener
  useEffect(() => {
    // Add scroll event listener
    window.addEventListener('scroll', handleEffectsOnScroll);
    
    // Cleanup function
    return () => {
      // Cancel any pending throttled calls
      handleEffectsOnScroll.cancel();
      // Remove scroll event listener
      window.removeEventListener('scroll', handleEffectsOnScroll);
    };
  }, [handleEffectsOnScroll]);

  // Return current animation values
  return {
    coverBlur,
    coverShift,
    mobileInfoSectionOpacity,

    //[Sticky Title related]
    coverSectionBottom,
    coverSectionRef
  };
};