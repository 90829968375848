import React, { useEffect, useState } from "react";
import BroadcastModals from "./comps/BroadcastModals";

const TAB_ID = Math.random().toString(36).substring(7);

/*
USE CASE:
If we ever need to broadcast any message throughout the app, we can use this feature. 
It is already imported into the app, so everything is set up. Simply add the modal inside BroadcastModals, 
control its display, and it will function as expected.

CURRENTLY IN USE:
1. If a user performs an action that takes them away from the document change page 
(e.g., editing a document or moderating a document) and the same document is open in another tab, 
we redirect them from the page. This avoids adding keys to a deleted clone of the document.

2.

*/

const BroadcastNavigator = () => {
  const [redirectModal, setRedirectModal] = useState(false);

  useEffect(() => {
    const channel = new BroadcastChannel("redirect");

    const handleMessage = (event) => {
      // Only show modal if we're not the sender
      if (event.data.sourceId !== TAB_ID) {
        console.log("Received message:", event.data);

        // Check if the current location matches the message
        if (window.location.href === event.data.data) {
          setRedirectModal(true);
        }
      }
    };

    channel.onmessage = handleMessage;

    return () => {
      channel.close();
    };
  }, []);

  return (
    <>
      <BroadcastModals
        redirectModal={redirectModal}
        setRedirectModal={setRedirectModal}
      />
    </>
  );
};

export const sendBroadcastMessage = (CHANNEL_NAME, message) => {
  const channel = new BroadcastChannel(CHANNEL_NAME);

  channel.postMessage({
    sourceId: TAB_ID,
    data: message,
  });

  channel.close();
};

export default BroadcastNavigator;
