import { colors, getVal, LABEL, TagDisplay } from "oolib";
import React from "react";
import styled, { css, useTheme } from "styled-components";
import { parseSpecialSyntax } from "../../../../utils/parseSpecialSyntax";


const StyledWrapper = styled.div`
display: flex;
align-items: center;
gap: 1rem;
${({align}) => align === 'center'
  && css`
    justify-content: center;
  `}
`

export const MetaPrimary = ({
  prefix,
  prefixPath,
  value,
  valuePath,
  content,
  invert,
  align = 'center'
}) => {
  
  const theme = useTheme()

  if(!content) content.map(d => {}) //content prop is compulsory. this way we force a blank screen if content is not passed  
  if(!value && !prefix && !prefixPath && !valuePath) return null;

  

  const genPrefix = () => {
    if(!prefix && !prefixPath) return null;
    
    let text = prefix ? (parseSpecialSyntax({string: prefix, content}) || prefix) : getVal(content, prefixPath)
    return (
      <TagDisplay style={invert ? {color: colors.greyColor15} : {} /** invert thing is temp till we transition to new UI*/ }> 
        {text}
      </TagDisplay>
    )
  }

  const genValue = () => {
    if(!value && !valuePath) return null;
    let text = value ? (parseSpecialSyntax({string: value, content}) || value) : getVal(content, valuePath)
    return (
      <TagDisplay style={{ color: colors}} display={text} XS>
        {text}
      </TagDisplay>
    )
  }

  return (
    <StyledWrapper align={align}>
      {genPrefix()}
      {genValue()}
    </StyledWrapper>
  );
};

