import React from "react";
import { useGetQueryData } from "../../../../../../utils/react-query-hooks/general";
import { GeneratorComp } from "../GeneratorComp";
import { Cover as GroupsStyle1Intro1 } from "./comps/GroupsStyle1Intro1";
import { Cover as KnowledgeResources2Intro1 } from "./comps/KnowledgeResources2Intro1";
import { useFormValidationContext } from "../../../../../../contexts/formValidationContext";

export const CoverConfigsRenderer = ({
  readOnly,
  content,
  tpl,
  memo,
  handleOnChange,
  coverComp,
  align,
  customComp_afterTagsIntro
}) => {

  

  const {formValidation, setFormValidation} = useFormValidationContext()

  const generateBlock = ({ block }) => (
    <GeneratorComp
      {...{
        block,
        readOnly,
        content,
        memo,
        handleOnChange,
        formValidation,
        setFormValidation
      }}
    />
  );

  const CoverComps = {
    GroupsStyle1Intro1,
    KnowledgeResources2Intro1
  }

  const CoverComp = CoverComps[coverComp]

  return (
    <CoverComp  
      generateBlock={generateBlock}
      tpl={tpl}
      content={content}
      readOnly={readOnly}
      align={align} //only required by KnowledgeResources2Intro1
      customComp_afterTagsIntro={customComp_afterTagsIntro}
      />
  );
};
