import React from "react";
import { CardWrapperStyled } from "./styled";
import { ButtonGhost, SANS_2, SANS_3, TagDisplay } from "oolib";
import { TagListStyled } from "../../../AnnoPlugin/AnnoLightbox/AnnoLightboxDisplay/styled";
import { groupTagsByCategory } from "../../utils";
import { __GetContentTypeConfigNew } from "../../../../../../../../utils/getters/gettersV2";

const TagCategoryAndTags = ({ data = [] }) => {
  return data.map(({ tagCategory, tags }) => (
    <div>
      <TagDisplay
        XS
        style={{ width: "fit-content" }}
        display={
          __GetContentTypeConfigNew(tagCategory).general?.content?.title ||
          tagCategory
        }
      />

      <TagListStyled>
        {tags.map((tag, i) => (
          <SANS_2 capitalize>{`${tag.display}${
            i !== tags.length - 1 ? "," : ""
          }`}</SANS_2>
        ))}
      </TagListStyled>
    </div>
  ));
};

export const Cards = ({ _id, text, tags, cached, handleApply }) => {
  const groupedTags = groupTagsByCategory(tags);

  return (
    <CardWrapperStyled>
      <div style={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
        <SANS_3> {text} </SANS_3>

        <TagCategoryAndTags data={groupedTags} />

        {cached ? (
          <TagDisplay XS style={{ width: "fit-content" }} display="cached" />
        ) : null}

        <ButtonGhost
          S
          onClick={() => handleApply({ text, tagsData: groupedTags, _id })}
        >
          Apply
        </ButtonGhost>
      </div>
    </CardWrapperStyled>
  );
};
