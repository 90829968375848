export const UIContent = {
  general: {
    serverErrorMsg: "Server Error",
    batchRegisterSuccessfulMsg: "Batch Registration Completed successfully.",
    viewHereCtaText: "View Here", // used on cta in the publish successful alert banner, to navigate use to the published page
  },
  TagsManager: {
    tagExistsErrorMsg: "Tag with this name already exists.",
  },
  ContentBatchRegister: {
    getBatchRegisterFormatCTA: "Download Batch Register Format for",
    batchRegisterDescription:
      "To import content / tags, upload an excel/google sheet document which follows the following structure:",
  },
  UserSettings: {
    registerSingleUserTitle: "Single User",
    title: "User Management",
    exampleFormatDropdownLabel: "Example Format",
    exampleFormatDropdownPlaceholder: "Select a profile template",
    batchRegisterTitle: "Import Multiple Users",
    infoTooltip:
      "User management allows you to view, add, delete and edit roles of all users on the platform.",
    batchRegisterDescription:
      "To import multiple users, upload an excel/google sheet document which follows the following structure:",
    getBatchRegisterFormatCTA: "Download Batch Register Format for",
    batchRegisterNote:
      "Note: Do not change the columns or the headers as that might lead to bugs",
    singleUserAddedSuccessMsg: "User added successfully!",
  },
  AccessControlBuilder: {
    SubModuleHeader_tooltip:
      "Access control enables admins to manage actions for different user roles like view, publish, edit, delete, review on the platform, pages and admin setting.",
    contentTypes_collections:
      "On collections, to enable a user to submit to a reviewer or  directly publish can be configured under Add. Note - Without enabling publish option, user will not be able to create tags to these collections in publish templates",
    contentTypes_publishing:
      "On publish templates, a user can submit a template to a reviewer or  directly publish under Add",
    contentTypes_staticPages:
      "On static templates, a user can submit a template to a reviewer or  directly publish under Add",
    roles: {
      0: {
        title: "Non logged in user (Default)",
        desc: "Not logged users generally have view access across platform. They will not have access to Platform Builder.",
      },
      admin: {
        title: "Admin (Default)",
        desc: "Admin generally help with setting up of users and templates using Platform Builder.",
      },
      superAdmin: {
        title: "Super admin (Default)",
        desc: "Super admin have complete access of the Platform and can assign access to all other roles",
      },
    },
    sidebarCompRoleDropdown_label: "Role",
    sidebarCompAreaDropdown_label: "Area",
    sidebarCompRoleDropdown_sublabel: "Filter by role",
    sidebarCompAreaDropdown_sublabel: "Filter by area",
  },
  RichTextEditor_Toolbar: {
    disabledLinkBtnClickMsg:
      "You need to select some text to apply a link to it.",
  },
  formValidationContext: {
    defaultValidationErrorMsg: "Fill out the required fields",
  },
  PhoneNoLogin: {
    otpSuccessfullySentMsg: "OTP sent to phone number!",
  },
  PlatformBuilder_SearchIndexes: {
    searchIndexRequestSuccessfulMsg:
      "Your last search index request was successful!",
    searchIndexRequestUnsuccessfulMsg:
      "Your last search index request was unsuccessful!",
    updateKeywordSearchConfigSuccessfulMsg:
      "Update keyword search config successfully!",
    updateKeywordSearchConfigUnsuccessfulMsg:
      "update keyword search config failed!",
  },
  reactQueryHooks_auth: {
    passwordChangedSuccessfullyMsg:
      "Password changed successfully! Please login again",
  },
  reactQueryHooks_profile: {
    deleteProfileErrorMsg: "Error while deleting profile",
    deleteProfileSuccessfulMsg: "Profile deleted successfully",
    updateProfileErrorMsg: "Error while updating profile",
    updateProfileSuccessfulMsg: "Profile updated successfully",
  },
  reactQueryHooks_content: {
    deleteContentSuccessfulMsg: "Content Deleted Successfully!",
    deleteTagSuccessfulMsg: "Tag Deleted Successfully!",
  },
  //contributios is deprecated
  reactQueryHooks_contributions: {
    deleteContentErrorMsg: "delete failed",
    deleteContentSuccessfulMsg: "deleted successfully",
    autosaveErrorMsg: "error during auto Save",
    modApproveErrorMsg: "Error while approving",
    modApproveSuccessfulMsg: "Content Approved Successfully",
    submitForModSuccessfulMsg:
      "Your piece has been submitted for 'review' successfully.",
    publishSuccessfulMsg: "Published Successfully!",
  },
  reactQueryHooks_users: {
    updateUserDataSuccessfulMsg: "User Data Updated Successfully!", //i think this is used for updating role in platform builder. not sure, will have to verify
  },
  CommentsModule: {
    commentInputPlaceholder: "Write your comment here",
  },
  confirmActionApprove: {
    title: "Are you sure you want to approve this content?",
    subtitle: "The content will be published on the platform.",
    onConfirmText: "Yes",
  },
  confirmActionMoveToAwaitingReview: {
    title: "Are you sure you want to move this content to awaiting review?",
    subtitle:
      "All changes made will be reversed/lost when you move content to awaiting review.",
    onConfirmText: "Yes",
  },
  confirmActionSendBack: {
    title: "Are you sure you want to send this content back to the author?",
    subtitle: "All changes made will be reversed/lost",
    onConfirmText: "Yes",
  },
  confirmActionDelete: {
    title: "Are you sure you want to delete this content?",
  },

  editLater: "Continue editing later",
  confirmActionEditLater: {
    title:
      "Are you sure you want to exit and come back to edit this content later?",
    onConfirmText: "Yes",
  },
  confirmActionEditLaterDraft: {
    title:
      "Are you sure you want to exit and come back to edit this draft later?",
    onConfirmText: "Yes",
  },
  reviewLater: "Review later",
  confirmActionReviewLater: {
    title:
      "Are you sure you want to exit and come back to review this content later?",
    onConfirmText: "Yes",
  },
  disableReview:
    "This content is currently being edited by the author, hence moderation is disabled",
  disableReviewInfoTooltip: {
    sentBack:
      "This content was sent back to the auhtor, hence review is disabled",
    editPublished:
      "This content is currently being edited by the author, hence review is disabled",
    underModeration:
      "This content is being reviewed by the reviewer, hence review is disabled",
  },
  disableEditInfoTooltip: {
    underModeration:
      "This content is currently being reviewed by the reviewer, hence edit is disabled",
    awaitingModeration:
      "This content is awaiting review, hence edit is disabled",
    published: "You are not the author, hence the edit is disabled",
    editPublished:
      "This content is currently being edited by someone, hence edit is disabled",
  },
  disableDeleteForModInfoTooltip:
    "You are not the current reviewer, hence the delete is disabled",
  disableDeleteInfoTooltip: {
    underModeration:
      "This content is currently being reviewed by the reviewer, hence delete is disabled",
      editPublished:"This content is currently being edited by the someone, hence delete is disabled",
    awaitingModeration:
      "This content is awaiting review, hence delete is disabled",
    published: "You are not the current reviewer, hence the delete is disabled",
  },

  moveToAwaitingSuccess: "Content moved to awaiting review!",
  moveToAwaitingError: "Content move to awaiting review failed!",
  sendBackSuccess: "Content sent back successfully!",
  sendBackError: "Content send back failed!",
  deleteContentSuccess: "Content deleted!",
  deleteContentError: "Content delete failed, please try again later",

  sendBackBtn: "Send back",
};
