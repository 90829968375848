import React from "react";
import ListingPage from "../../../../../ListingPage";

export const ReadOnlyLayout = ({ content, coverConfig, bodyConfig, tabOps, activeTab, setActiveTab, collectionId }) => {
    return (
      <div key={content._id}>
        <ListingPage
          coverConfig={coverConfig}
          bodyConfig={bodyConfig}
          tabBarConfig={{
            options: tabOps,
            onChange: (k, v) => setActiveTab(v),
            value: activeTab,
          }}
          collectionId={collectionId}
        />
      </div>
    );
};
  