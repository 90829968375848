import { getVal } from 'oolib' 
export const parseSpecialConfigSyntax = ({ 
  config, 
  content 
}) => {
  let filterConfigString = JSON.stringify(config);

  filterConfigString = parseDynamicValuePaths({ filterConfigString, content });

  // console.log({returned: JSON.parse(filterConfigString)})
  return JSON.parse(filterConfigString);
};

/**
 * these functions below are mini-services, that perform a single parsing duty
 * we can keep adding new ones that correspond to new syntaxes
 * e.g the current one corresponds to the $valuePath() syntax.
 */

/**
 * capable of processing multiple matches.
 */

/**
 * also, here content === this collection page
 */
const parseDynamicValuePaths = ({ filterConfigString, content }) => {
  let valPathRegex = /\$valuePath\(.*?\)/g; // this questionmark is VERY IMP. i have no idea why tho... need to read up. it has something to do with returning multiple matches. which is what we need.
  let valPathMatch = filterConfigString.match(valPathRegex);

  /**
   * match with the g flag is diff from match without the g flag
   * with g flag it returns an array of all matches including the surrounding
   * words (i.e. $valuePath etc.)
   *
   * matching without gflag returns in the format described below
   */

  if (!valPathMatch) return filterConfigString;
  
  //else
  let toReturn = filterConfigString;
  for (let singleMatch of valPathMatch) {
    // a single match will look something like: $valuePath(_id)
    let actualValPath = singleMatch.replace("$valuePath(", "");
    actualValPath = actualValPath.replace(")", "");

    let replacementWord = getVal(content, actualValPath);
    toReturn = toReturn.replace(singleMatch, replacementWord);
  }

  return toReturn;
};
