import React from "react";
import { ButtonPrimary, ModalSmall, SANS_3 } from "oolib";
import { _Locale } from "../../../components/locale/Locale";
import { useNavigate } from "react-router-dom";

export const RedirectModal = ({ setRedirectModal }) => {
  const navigate = useNavigate();

  return (
    <>
      <ModalSmall title="Content Expired">
        <div style={{ paddingBottom: "20px" }}>
          <SANS_3>Content has expired. Redirecting to Homepage.</SANS_3>
        </div>
        <ButtonPrimary
          children={_Locale("Redirect to Home")}
          onClick={() => {
            setRedirectModal(false);
            navigate("/");
          }}
        />
      </ModalSmall>
    </>
  );
};
