import { BlockLabel, ButtonPrimary, CardEmbed, CardProfile, Loader } from 'oolib';
import React, { Fragment, useEffect, useState } from "react";
import ContentBlockShell from '../../../../generalUI/ContentBlockShell';

import KPResourceSelectPopUp from '../KPResourceSelectPopUp';
//_config

import { refreshCardsData } from './refreshCardsData';
import { __GetContentTypeConfigNew, __GetProfileTypeConfig } from '../../../../../utils/getters/gettersV2';
import { replaceEntityData } from '../../../../../utils/richInputUtils';
import ExternalLinkCard from '../../../../cards/ExternalLinkCard';


import { defaultProfileCardConfig } from '../../../../../pageTpls/ProfilesListing/configs/defaultProfileCardConfig';



const KPCardLinksDisplay = ({
	label,
	sublabel,
	errorMsgs,
	isRequired,
	id,
	value,
	type,
	contentTypeEmbedOptions,
	profileTypeEmbedOptions,
	cardsData: cardsDataFromParent, //for backwards compatibility. earlier value was called cardsData
	onChange: parentOnChange, //only passed if not inEditor
	readOnly,
	maxSelectLimit,
	allowNestedPublishing,
	forceParentOnChange,
	injectCardConfig,
	//specific to inRichEditorAtomicBlock
	isInEditor,
	editor,
	handleSetTempReadOnly,
	block,
	entityKey,
}) => {
	const labelProps = { label, sublabel, errorMsgs, isRequired, id, readOnly }
	const [cardsData, setCardsData] = useState(
		value || cardsDataFromParent || { type /*from props*/, selectedAry: [] },
	)

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (forceParentOnChange) parentOnChange(id, cardsData)
		refreshCardsData(cardsData, setCardsData, setLoading)
	}, [])

	//SELECT MODAL TRIGGERED FROM THIS COMP IS ONLY FOR INTERNAL RESOURCES
	const [showSelectModal, setShowSelectModal] = useState(false)
	const handleCloseModal = () => setShowSelectModal(false)

	const onConfirm = (newCardsData) => {
		setCardsData(newCardsData)
		parentOnChange && parentOnChange(id, newCardsData) //prob dont need to do this. instead we write a function that fires a api call and refreshes the card config the minute it loads

		if (isInEditor === true) {
			replaceEntityData(editor, entityKey, {
				value: newCardsData,
				contentTypeEmbedOptions,
				profileTypeEmbedOptions,
			})
		}
	}

	const handleRemoveCard = (_id) => {
		let newCardsDataSelectedAry = cardsData.selectedAry.filter(
			(d) => d._id !== _id,
		)
		let newCardsData = { ...cardsData, selectedAry: newCardsDataSelectedAry }
		setCardsData(newCardsData)
		parentOnChange && parentOnChange(id, newCardsData)

		//this only runs if comp is rendered inside rich editor
		if (isInEditor === true) {
			replaceEntityData(editor, entityKey, {
				value: newCardsData,
				contentTypeEmbedOptions,
				profileTypeEmbedOptions,
			})
		}
	}

	const generateCards = () => {
		if (cardsData.type === 'internal') {
			return (
				<Fragment>
					{loading ? (
						<Loader />
					) : (
						<Fragment>
							{cardsData.selectedAry.map((d) => {
								let profileType = d.meta.profile_type
								let contentType = d.meta.kp_content_type
								let cardConfigToInject = injectCardConfig
									? profileType
										? __GetProfileTypeConfig(profileType)[injectCardConfig]
										: __GetContentTypeConfigNew(contentType)[injectCardConfig] //chat with abrar
									: null
								let profileId =
									profileType &&
									(['userProfiles', 'board'].indexOf(profileType) !== -1
										? d.user && d.user._id
										: d._id)
								let cardLinkUrl = profileType
									? `/profile/${
											profileType !== 'board' ? profileType : 'userProfiles'
									  }/${profileId}`
									: `/published-page/${contentType}?id=${d._id}`
								if (profileType) {
						
										
									return (
										<CardProfile
											key={d._id}
											to={cardLinkUrl}
											data={d}
											config={defaultProfileCardConfig}
											/>
									)
								} else {
									return (
									
										<CardEmbed
										key={d.id}
										to={cardLinkUrl}
										data={d}
										config={{
										  title: 'main.title', 
										  metaBlock: 
											[{ key: "name", valuePath: "meta.kp_contributed_by.name" },
											 { key: "date", valuePath: "kp_date_published" },]}}
									  />
									)
								}
							})}
							{!readOnly && (
								<div
									className='col-sm-6 kp-col'
									style={{ alignSelf: 'center' }}>
									{cardsData.selectedAry.length !== maxSelectLimit && (
										<ButtonPrimary onClick={() => setShowSelectModal(true)}>
											{cardsData.selectedAry.length === 0
												? 'Embed Some Content From The Platform'
												: 'Add Another'}
										</ButtonPrimary>
									)}
								</div>
							)}
						</Fragment>
					)}
				</Fragment>
			)
		} else {
			//type === external
			let d = cardsData.data.data.data
			let value = {
				title: d.ogTitle,
				desc: d.ogDescription,
				img: { imgData: [{ secure_url: d.ogImage.url }] },
				url: d.ogUrl,
			}
			return <ExternalLinkCard value={value} />
		}
	}

	return (
		<Fragment>
			{showSelectModal && (
				<KPResourceSelectPopUp
					allowNestedPublishing={allowNestedPublishing}
					cardDisplayBlockId={id}
					onCloseModal={handleCloseModal}
					onConfirm={onConfirm}
					contentTypeEmbedOptions={contentTypeEmbedOptions}
					profileTypeEmbedOptions={profileTypeEmbedOptions}
					linkTypesToShow={['internal']}
					selectedAry={cardsData.selectedAry}
					maxSelectLimit={maxSelectLimit}
				/>
			)}
			<ContentBlockShell
				richInputProps={{
					isInEditor: isInEditor,
					editor: editor,
					block: block,
					handleSetTempReadOnly: handleSetTempReadOnly,
				}}
				readOnly={readOnly}
				className={`kp-card-links-display `}>
				<BlockLabel {...labelProps} />
				<div
					className={`${
						isInEditor === true
							? `kp-in-editor-card-links-display--${
									cardsData && cardsData.type
							  }`
							: ''
					}`}>
					{cardsData && generateCards()}
				</div>
			</ContentBlockShell>
		</Fragment>
	)
}

export default KPCardLinksDisplay;
